import React from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcButton,
  DxcFlex,
  DxcInset,
} from "@dxc-technology/halstack-react";

//custom hook
import useDeploymentPackageCreate from "./useDeploymentPackageCreate";

import AvailableDeploymentPackages from "../deployment-package-definition/AvailableDeploymentPackages";
import DeploymentPackageData from "../deployment-package-definition/DeploymentPackageData";
import DeploymentPackageParameters from "../deployment-package-definition/DeploymentPackageParameters";
import DeploymentPackageConditions from "../deployment-package-definition/DeploymentPackageConditions";
import DeploymentPackageArtefacts from "../deployment-package-definition/DeploymentPackageArtefacts";
import SectionContainer from "../components/SectionContainer";
import NavigationContainer from "../components/NavigationContainer";

//search image
import backImage from "../deployment-package-definition/images/arrow_back_ios-24px.svg";
import { plusIcon } from "../deployment-package-definition/images/plusIcon.js";
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";

function DeploymentPackageCreate() {
  const [
    deploymentPackagesStatus,
    deploymentPackagesMessage,
    deploymentPackageDataInfo,
    deploymentPackageParametersInfo,
    deploymentPackageConditionsInfo,
    availableDeploymentPackagesInfo,
    deploymentPackageArtefactsInfo,
    dismissMessage,
    onSaveChanges,
    navigateDeploymentPackages,
    saveDone,
    error,
  ] = useDeploymentPackageCreate();
  useErrorHandler(error);

  const sections = [
    {
      label: "Information",
      section: (
        <MainContainer>
          <SectionContainer title="Information">
            <DeploymentPackageData
              source={deploymentPackageDataInfo.deploymentPackageSource}
              name={deploymentPackageDataInfo.deploymentPackageName}
              type={deploymentPackageDataInfo.deploymentPackageType}
              onChangeSource={deploymentPackageDataInfo.onChangeDeploymentPackageSource}
              onChangeName={deploymentPackageDataInfo.onChangeDeploymentPackageName}
              onChangeType={deploymentPackageDataInfo.onChangeDeploymentPackageType}
            />
          </SectionContainer>
        </MainContainer>
      ),
    },
    {
      label: "Parameters",
      section: (
        <MainContainer>
          <SectionContainer
            title="Parameters"
            actionLabel="Add parameter"
            actionOnClick={deploymentPackageParametersInfo.onAddOpenClick}
            actionIcon={plusIcon}
          >
            <DeploymentPackageParameters
              deploymentPackageParameters={
                deploymentPackageParametersInfo.deploymentPackageParametersList
              }
              isAddDialogVisible={deploymentPackageParametersInfo.isAddDialogOpen}
              isEditDialogVisible={deploymentPackageParametersInfo.isEditDialogOpen}
              onAddParamClick={deploymentPackageParametersInfo.onAddOpenClick}
              onAddSaveClick={deploymentPackageParametersInfo.onAddSubmitClick}
              onAddCancelClick={deploymentPackageParametersInfo.onAddCancelClick}
              onEditSaveClick={deploymentPackageParametersInfo.onEditSubmitClick}
              onEditCancelClick={deploymentPackageParametersInfo.onEditCancelClick}
              onEditRemoveClick={deploymentPackageParametersInfo.onEditRemoveClick}
            />
          </SectionContainer>
        </MainContainer>
      ),
    },
    {
      label: "Artefacts",
      section: (
        <MainContainer>
          <SectionContainer title="Artefacts">
            <DeploymentPackageArtefacts
              deploymentPackageArtefacts={
                deploymentPackageArtefactsInfo.deploymentPackageArtefactsList
              }
            />
          </SectionContainer>
        </MainContainer>
      ),
    },
    {
      label: "Deployment dependencies",
      section: (
        <MainContainer>
          <SectionContainer title="Deployment dependencies">
            <AvailableDeploymentPackages
              availableDeploymentPackages={
                availableDeploymentPackagesInfo.availableDeploymentPackagesList
              }
              textFilter={availableDeploymentPackagesInfo.textFilter}
              deploymentPackageTypeFilter={
                availableDeploymentPackagesInfo.deploymentPackageTypeFilter
              }
              checkedDeprecated={availableDeploymentPackagesInfo.deprecatedFilter}
              onChangeTextFilter={availableDeploymentPackagesInfo.onChangeTextFilter}
              onChangeDeploymentPackageTypeFilter={
                availableDeploymentPackagesInfo.onChangeDeploymentPackageTypeFilter
              }
              onChangeDeprecated={availableDeploymentPackagesInfo.onChangeDeprecatedFilter}
              onClickSelected={availableDeploymentPackagesInfo.onClickSelected}
              isDependency
            ></AvailableDeploymentPackages>
          </SectionContainer>
        </MainContainer>
      ),
    },
    {
      label: "Conditions",
      section: (
        <MainContainer>
          <SectionContainer
            title="Conditions"
            actionOnClick={deploymentPackageConditionsInfo.onAddOpenClick}
            actionLabel="Add condition"
            actionIcon={plusIcon}
          >
            <DeploymentPackageConditions
              deploymentPackageConditions={
                deploymentPackageConditionsInfo.deploymentPackageConditionsList
              }
              isAddDialogVisible={deploymentPackageConditionsInfo.isAddDialogOpen}
              isEditDialogVisible={deploymentPackageConditionsInfo.isEditDialogOpen}
              onAddConditionClick={deploymentPackageConditionsInfo.onAddOpenClick}
              onAddSaveClick={deploymentPackageConditionsInfo.onAddSubmitClick}
              onAddCancelClick={deploymentPackageConditionsInfo.onAddCancelClick}
              onEditSaveClick={deploymentPackageConditionsInfo.onEditSubmitClick}
              onEditCancelClick={deploymentPackageConditionsInfo.onEditCancelClick}
            />
          </SectionContainer>
        </MainContainer>
      ),
    },
  ];

  return (
    <GlobalContent>
      <Content>
        <StyledLink onClick={navigateDeploymentPackages}>
          <BackImg src={backImage} />
          Back to Deployment Packages
        </StyledLink>
        <ComponentHeaderContainer>
          <DxcInset top="2rem" bottom="1rem">
            <DxcFlex alignItems="center" justifyContent="space-between">
              <DxcHeading
                level={1}
                weight="normal"
                text={`Create deployment package`}
                margin={{ top: "small", bottom: "small" }}
              />
              <DxcButton
                mode="primary"
                label="Save changes"
                disabled={saveDone}
                onClick={onSaveChanges}
              />
            </DxcFlex>
          </DxcInset>

          {deploymentPackagesMessage ? (
            deploymentPackagesMessage.info ? (
              <DxcAlert
                type={deploymentPackagesMessage.type}
                mode="inline"
                inlineText={deploymentPackagesMessage.message}
                size="fillParent"
                onClose={dismissMessage}
                margin={{ bottom: "small" }}
              >
                <span>{deploymentPackagesMessage.info}</span>
              </DxcAlert>
            ) : (
              <DxcAlert
                type={deploymentPackagesMessage.type}
                mode="inline"
                inlineText={deploymentPackagesMessage.message}
                size="fillParent"
                onClose={dismissMessage}
                margin={{ bottom: "small" }}
              />
            )
          ) : null}
        </ComponentHeaderContainer>
        {deploymentPackagesStatus === "fetching" && (
          <DxcSpinner theme="light" label="Loading..." mode="overlay" />
        )}
      </Content>

      <NavigationContainer sections={sections} title="Sections" />
    </GlobalContent>
  );
}

const GlobalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled.div`
  display: flex;
  align-self: flex-start;
  width: fit-content;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  top: 16px;
  :hover {
    cursor: pointer;
  }
`;

const BackImg = styled.img`
  width: 20px;
  height: 20px;
`;

const ComponentHeaderContainer = styled.div`
  width: 80%;
  align-self: flex-start;
`;

export default withErrorBoundary(DeploymentPackageCreate, { FallbackComponent: ApplicationError });
