import React, { useState } from "react";
import styled from "styled-components";
import NonEditableField from "../../components/NonEditableField";
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcButton,
  DxcDialog,
  DxcTextInput,
  DxcParagraph,
  DxcFlex,
  DxcInset,
} from "@dxc-technology/halstack-react";

function EditAccountDialog({
  accountName,
  accountRole,
  accountId,
  editStatus,
  editErrorMessage,
  onEditClick,
  onCancelClick,
}) {
  const [newAccountRole, setNewAccountRole] = useState(accountRole);
  const [accountRoleError, setAccountRoleError] = useState();

  const accountRoleHandler = ({ value, error }) => {
    setNewAccountRole(value);
    setAccountRoleError(error);
  };

  return (
    <DxcDialog isCloseVisible onCloseClick={() => onCancelClick()}>
      <DialogHeader>
        <DxcHeading level={2} text={`Edit ${accountName}`} margin={{ bottom: "xsmall" }} />
        {editStatus === "interaction" && (
          <DxcSpinner
            role="progressbar"
            theme="light"
            mode="small"
            margin={{ left: "xsmall", bottom: "xsmall" }}
            label="Editing..."
          />
        )}
      </DialogHeader>

      {editErrorMessage && (
        <DxcAlert type={"error"} mode="inline" size="fillParent" margin={{ bottom: "small" }}>
          <DxcParagraph>{editErrorMessage.message}</DxcParagraph>
        </DxcAlert>
      )}

      <DxcFlex direction="column">
        <NonEditableField label="AWS account ID" value={accountId} margin={{ bottom: "xsmall" }} />

        <DxcTextInput
          label="Account role"
          id="assureAccountRole"
          placeholder="e.g.: arn:aws:iam::XXXXXXXXXXXXXX:role/my-aws-role"
          name="assureAccountRole"
          size="fillParent"
          value={newAccountRole}
          onChange={accountRoleHandler}
          onBlur={accountRoleHandler}
          clearable={true}
          optional={false}
          error={accountRoleError == null ? "" : accountRoleError}
        />
      </DxcFlex>

      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={() => onCancelClick()}
            mode="text"
          ></DxcButton>
          <DxcButton
            label="Edit"
            onClick={() => onEditClick(newAccountRole)}
            mode="primary"
            disabled={accountRoleError || newAccountRole === "" || editStatus === "interaction"}
          ></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default EditAccountDialog;
