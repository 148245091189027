//React hooks imports
import { useState, useMemo, useReducer, useEffect, useCallback } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { PAGES } from "../utils";

//urls
import { config } from "../config";

//Utils
import {
  formatDate,
  goToTop,
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  PROMISE_FINAL_STATUSES,
} from "../common/utils";

//DXC Hal
import { useHalResource } from "@dxc-technology/halstack-react-hal";

import { HalApiCaller } from "@dxc-technology/halstack-client";
import { getBusinessConsoleURL } from "./AccountDashboardHelper";


const buildEnvList = (halResource) => {
  const itemsList = (halResource && halResource.getItems()) || [];

  return itemsList.map((item) => {
    return {
      environmentName: item.summary.environment_name,
      resourceName: item.summary.resource_name,
      envAccountZoneName: item.summary.env_account_zone_name,
      awsRegion: item.summary.aws_region,
      isLocked: item.summary.is_locked ? "Yes" : "No",
      domain: item.summary.is_private ? "Private" : "Public",
      creator: item.summary.creator ? item.summary.creator : "-",
      lastDeployment: Number(item.summary.last_deployment_date)
        ? formatDate(item.summary.last_deployment_date)
        : item.summary.last_deployment_date,
      lastDeploymentDate: item.summary.last_deployment_date,
    };
  });
};

export const useAccountDashboard = () => {
  const location = useLocation();
  const history = useHistory();
  const customerParams = useParams();

  const customerUrl = `${config.environments_api_url}${location.pathname}`;

  const [unregisterAccountStatus, changeUnregisterAccountStatus] = useState(null);
  const [editAccountStatus, changeEditAccountStatus] = useState(null);
  //temp
  const [environmentStatus, changeEnvironmentStatus] = useState("idle");

  const [accountMessage, changeAccountMessage] = useState(null);
  const [customerMessage, changeCustomerMessage] = useState(null);
  // const [fetchInteractionError, changeFetchInteractionError] = useState(false);
  const [dashboardMessage, changeDashboardMessage] = useState(null);
  const [error, setError] = useState(null);

  const [customerResource, customerStatus, customerError, customerHandlers] = useHalResource({
    url: customerUrl,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });
  const [accountsResource, accountsStatus, accountsError, accountsHandlers] = useHalResource({
    url: `${customerUrl}/accounts`,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const [environmentMessage, changeEnvironmentMessage] = useState(null);
  const [isDeleteEnvironmentDialogVisible, setIsDeleteEnvironmentDialogVisible] = useState(null);
  const toggleDeleteEnvironmentDialogVisible = (environmentData) => {
    setIsDeleteEnvironmentDialogVisible(environmentData);
  };

  //Interaction Handler definition
  const { editCustomerHandler, deleteCustomerHandler, getCustomerInfo } = useMemo(() => {
    if (customerHandlers !== null) {
      return {
        editCustomerHandler: customerHandlers["patch-customer-data"],
        deleteCustomerHandler: customerHandlers["delete-customer"],
        getCustomerInfo: customerHandlers["describe-customer"],
      };
    } else {
      return {};
    }
  }, [customerHandlers]);

  const { createAccountHandler, listAccountsHandler } = useMemo(() => {
    if (accountsHandlers !== null) {
      return {
        createAccountHandler: accountsHandlers["create"],
        listAccountsHandler: accountsHandlers["list-accounts"],
      };
    } else {
      return [];
    }
  }, [accountsHandlers]);

  //retrieve customer Info
  const { customer } = useMemo(() => {
    if (customerResource !== null) {
      const _properties = customerResource.getProperties();
      return {
        customer: {
          customerName: _properties.find((prop) => prop.key === "customer_name").value,
          customerShortName: _properties.find((prop) => prop.key === "client_short_name").value,
        },
      };
    } else {
      return {};
    }
  }, [customerResource]);

  const loadEnvironments = useCallback(async (accountName, url) => {
    try {
      const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
      const response = await HalApiCaller.get({
        url: `${url}/environments`,
        headers: { ...asyncHeaders, ...getPlatformHeaders() },
      });
      response.containsHalResource
        ? dispatch({
            type: "success",
            accountName: accountName,
            resource: response.halResource,
          })
        : dispatch({ type: "error", accountName: accountName });
    } catch (error) {
      error.response?.data?.messages
        ? changeDashboardMessage({
            type: "error",
            message: error.response.data?.messages[0].message,
          })
        : changeDashboardMessage({
            type: "error",
            message: "There was an error while loading environments",
          });
      dispatch({ type: "error", accountName: accountName });
    }
  }, []);

  const accountsInitialState = [];

  function accountsStateReducer(state, action) {
    switch (action.type) {
      case "expand":
        return { ...state, [action.accountName]: { isLoading: true } };
      case "collapse":
        return {
          ...state,
          [action.accountName]: { isLoading: false, environments: null },
        };
      case "success":
        return {
          ...state,
          [action.accountName]: {
            isLoading: false,
            environments: buildEnvList(action.resource),
          },
        };
      case "error":
        return {
          ...state,
          [action.accountName]: { isLoading: false, environments: null },
        };
      default:
        throw new Error();
    }
  }

  const [accountsState, dispatch] = useReducer(accountsStateReducer, accountsInitialState);

  /**
   * Dashboard Message
   */

  const dismissMessage = () => {
    changeDashboardMessage(null);
    changeCustomerMessage(null);
    changeAccountMessage(null);
    changeEnvironmentMessage(null);
  };

  /**
   * Edit customer dialog
   */
  const [isEditCustomerDialogVisible, setEditCustomerDialogVisible] = useState(false);
  const toggleEditCustomerDialogVisible = () => {
    setEditCustomerDialogVisible(!isEditCustomerDialogVisible);
    dismissMessage();
  };

  /**
   * Delete customer dialog
   */
  const [isDeleteCustomerDialogVisible, setDeleteCustomerDialogVisible] = useState(false);
  const toggleDeleteCustomerDialogVisible = () => {
    setDeleteCustomerDialogVisible(!isDeleteCustomerDialogVisible);
    dismissMessage();
  };
  /**
   * Create Account dialog
   */
  const [isCreateAccountDialogVisible, setCreateAccountDialogVisible] = useState(false);
  const toggleCreateAccountDialogVisible = () => {
    setCreateAccountDialogVisible(!isCreateAccountDialogVisible);
    dismissMessage();
  };

  /**
   * Unregister account dialog
   */
  const [isUnregisterAccountDialogIndexVisible, setUnregisterAccountialogIndexVisible] =
    useState(null);
  const toggleUnregisterAccountDialogVisible = useCallback((index) => {
    changeUnregisterAccountStatus("idle");
    setUnregisterAccountialogIndexVisible(index);
    dismissMessage();
  }, []);

  /**
   * Edit account dialog
   */
  const [isEditAccountDialogIndexVisible, setEditAccountialogIndexVisible] = useState(null);
  const toggleEditAccountDialogVisible = useCallback((index) => {
    changeEditAccountStatus("idle");
    setEditAccountialogIndexVisible(index);
    dismissMessage();
  }, []);

  //HAL resources calculated joined status
  const fetchStatus = useMemo(() => {
    if (
      !isCreateAccountDialogVisible &&
      !isEditCustomerDialogVisible &&
      !isDeleteCustomerDialogVisible
    ) {
      if (
        customerStatus === "fetching" ||
        customerStatus === "interaction" ||
        accountsStatus === "fetching" ||
        accountsStatus === "interaction"
      ) {
        return "fetching";
      } else if (customerStatus === accountsStatus) {
        return customerStatus;
      }
    } else {
      return accountsStatus;
    }
  }, [
    isCreateAccountDialogVisible,
    isEditCustomerDialogVisible,
    isDeleteCustomerDialogVisible,
    customerStatus,
    accountsStatus,
  ]);

  //Action Functions
  const registerAccount = (accountName, accountRole, accountId) => {
    let payload = {
      resource_name: accountName,
      account_role_arn: accountRole,
      diaas_aws_account: accountId,
    };
    createAccountHandler(payload)
      .then(() => {
        toggleCreateAccountDialogVisible();
        changeDashboardMessage({
          type: "confirm",
          message: "Your account was successfully registered",
        });
        listAccountsHandler()
          .then(() => {})
          .catch((error) => {
            error.response?.data?.messages
              ? changeAccountMessage({
                  type: "error",
                  message: `${error.response.data.messages[0].message}`,
                })
              : changeAccountMessage("There was an error getting the accounts");
          });
      })
      .catch((error) => {
        error.body?.messages
          ? changeAccountMessage(`${error.body?.messages[0].message}`)
          : changeAccountMessage("There was an error while registering the account");
      })
      .finally(goToTop());
  };

  const editAccount = useCallback(
    async (accountName, accountRole) => {
      let payload = {
        account_role_arn: accountRole,
      };
      changeEditAccountStatus("interaction");
      try {
        const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
        await HalApiCaller.patch({
          url: `${customerUrl}/accounts/${accountName}`,
          headers: { ...asyncHeaders, ...getPlatformHeaders() },
          body: payload,
        });
        changeEditAccountStatus("resolved");
        toggleEditAccountDialogVisible();
        changeDashboardMessage({
          type: "confirm",
          message: "Your account was successfully updated",
        });
        listAccountsHandler()
          .then(() => {})
          .catch((error) => {
            error.response?.data?.messages
              ? changeAccountMessage({
                  type: "error",
                  message: `${error.response.data.messages[0].message}`,
                })
              : changeAccountMessage("There was an error getting the accounts");
          });
      } catch (error) {
        changeEditAccountStatus("rejected");
        error.response?.data?.messages
          ? changeAccountMessage({
              type: "error",
              message: `${error.response.data.messages[0].message}`,
            })
          : changeAccountMessage({
              type: "error",
              message: "An error ocurred editing the account",
            });
      } finally {
        goToTop();
      }
    },
    [customerUrl, listAccountsHandler, toggleEditAccountDialogVisible]
  );

  const unregisterAccount = useCallback(
    async (accountName) => {
      changeUnregisterAccountStatus("interaction");
      try {
        const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
        await HalApiCaller.del({
          url: `${customerUrl}/accounts/${accountName}`,
          headers: { ...asyncHeaders, ...getPlatformHeaders() },
        });
        changeUnregisterAccountStatus("resolved");
        toggleUnregisterAccountDialogVisible();
        changeDashboardMessage({
          type: "confirm",
          message: "The account has been successfully deleted",
        });
        listAccountsHandler()
          .then(() => {})
          .catch((error) => {
            error.response?.data?.messages
              ? changeAccountMessage({
                  type: "error",
                  message: `${error.response.data.messages[0].message}`,
                })
              : changeAccountMessage("There was an error getting the accounts");
            // changeFetchInteractionError(true);
          });
      } catch (error) {
        changeUnregisterAccountStatus("rejected");
        error.response?.data?.messages
          ? changeAccountMessage(`${error.response.data?.messages[0].message}`)
          : changeAccountMessage("There was an error while deleting the account");
      } finally {
        goToTop();
      }
    },
    [customerUrl, listAccountsHandler, toggleUnregisterAccountDialogVisible]
  );

  const editCustomer = (customerShortName) => {
    let payload = {
      client_short_name: customerShortName,
    };
    editCustomerHandler(payload)
      .then(() => {
        toggleEditCustomerDialogVisible();
        changeDashboardMessage({
          type: "confirm",
          message: "Your modification was successfully registered",
        });
        getCustomerInfo()
          .then(() => {})
          .catch((error) => {
            error.response?.data?.messages
              ? changeCustomerMessage({
                  type: "error",
                  message: `${error.response.data.messages[0].message}`,
                })
              : changeCustomerMessage("There was an error getting the accounts");
          });
      })
      .catch((error) => {
        error.body?.messages
          ? changeCustomerMessage({
              type: "error",
              message: `${error.body?.messages[0].message}`,
            })
          : changeCustomerMessage({
              type: "error",
              message: "An error ocurred registering the modification",
            });
      })
      .finally(goToTop());
  };

  const deleteCustomer = (customerName) => {
    let payload = {
      client_name: customerName,
    };
    deleteCustomerHandler(payload)
      .then(() => {
        toggleEditCustomerDialogVisible();
        history.push("/customers");
      })
      .catch((error) => {
        error.body?.messages
          ? changeCustomerMessage(`${error.body?.messages[0].message}`)
          : changeCustomerMessage("There was an error while deleting the customer");
      })
      .finally(goToTop());
  };

  const buildBusinessConsoleURL = (awsRegion, domain, environmentName, envAccountZoneName) => {
    if (domain === "Private" || !envAccountZoneName) {
      changeDashboardMessage({
        type: "error",
        message: "Platform Console is not available",
      });
      goToTop();
    } else {
      window.open(getBusinessConsoleURL(environmentName, awsRegion, envAccountZoneName), "_blank");
    }
  };

  const deleteEnvironmentHandler = useCallback(async (customerId, accountId, environmentId) => {
    changeEnvironmentStatus("interacting");

    const environmentURI = `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}`;
    try {
      const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
      await HalApiCaller.del({
        url: environmentURI,
        headers: { ...asyncHeaders, ...getPlatformHeaders() },
      });
      changeEnvironmentStatus("resolved");
      dispatch({ type: "collapse", accountName: accountId });
      changeDashboardMessage({
        type: "confirm",
        message: "The environment has been successfully deleted",
      });
      toggleDeleteEnvironmentDialogVisible();
    } catch (error) {
      changeEnvironmentStatus("rejected");
      error.response?.data?.messages
        ? changeEnvironmentMessage(`${error.response.data?.messages[0].message}`)
        : changeEnvironmentMessage("There was an error while deleting the environment");
    } finally {
      goToTop();
    }
  }, []);

  const deleteEnvironment = useCallback(
    (customer, account, environment) => {
      toggleDeleteEnvironmentDialogVisible({
        enviromentName: environment,
        deleteClick: () => deleteEnvironmentHandler(customer, account, environment),
        cancelClick: toggleDeleteEnvironmentDialogVisible,
      });
    },
    [deleteEnvironmentHandler]
  );

  const clickOption = useCallback(
    (option, customerId, accountId, environmentId, awsRegion, domain, environmentName, envAccountZoneName) => {
      dismissMessage();
      sessionStorage.setItem("origin", PAGES.ACCOUNTS_DASHBOARD);
      switch (option) {
        case 1: //See Deployments
          history.push(
            `/customers/${customerId}/accounts/${accountId}/environments/${environmentId}/deployments`,
            {
              origin: "accounts",
            }
          );
          break;
        case 2: //Edit Environment
          history.push(`/customers/${customerId}/accounts/${accountId}/environments/${environmentId}`, {
            origin: "accounts",
          });
          break;
        case 3: //Configure Solution
          break;
        case 4: //BusinessConsole
          buildBusinessConsoleURL(awsRegion, domain, environmentName, envAccountZoneName);
          break;
        case 5: //delete environment
          deleteEnvironment(customerId, accountId, environmentId);
          break;
        // TODO: remove clone environment case
        // case 6: //clone environment
        //   history.push(
        //     `/customers/${customer}/accounts/${account}/environments/${environment}/clone`
        //   );
        // break;
        default:
          console.log("option to be implemented");
      }
    },
    [deleteEnvironment, history]
  );

  //main account dashboard message management
  useEffect(() => {
    if (
      PROMISE_FINAL_STATUSES.includes(customerStatus) &&
      PROMISE_FINAL_STATUSES.includes(accountsStatus) &&
      (customerError || accountsError)
    ) {
      const _error = customerError ?? accountsError;
      setError(buildApplicationError(_error));
    }
  }, [accountsError, accountsStatus, customerError, customerStatus]);

  //grouped customer edit dialog state
  const editCustomerInfo = {
    isDialogOpen: isEditCustomerDialogVisible,
    customerStatus: customerStatus,
    customerMessage: customerMessage,
    onOpenClick: toggleEditCustomerDialogVisible,
    onCancelClick: toggleEditCustomerDialogVisible,
    onSubmitClick: editCustomer,
  };

  //grouped customer edit dialog state
  const deleteCustomerInfo = {
    isDialogOpen: isDeleteCustomerDialogVisible,
    customerStatus: customerStatus,
    customerMessage: customerMessage,
    onOpenClick: toggleDeleteCustomerDialogVisible,
    onCancelClick: toggleDeleteCustomerDialogVisible,
    onSubmitClick: deleteCustomer,
  };

  //grouped register account dialog state
  const registerAccountInfo = {
    isDialogOpen: isCreateAccountDialogVisible,
    accountStatus: accountsStatus,
    accountMessage: accountMessage,
    onOpenClick: toggleCreateAccountDialogVisible,
    onCancelClick: toggleCreateAccountDialogVisible,
    onSubmitClick: registerAccount,
  };

  //Message operations
  useEffect(() => {
    if (location?.state?.action && location?.state?.environment_name)
      switch (location.state.action) {
        case "create":
          changeDashboardMessage({
            type: "confirm",
            message: `The environment ${location.state.environment_name} was succesfully created`,
          });
          break;
        case "delete":
          changeDashboardMessage({
            type: "confirm",
            message: `The environment ${location.state.environment_name} was succesfully deleted`,
          });
          break;
        default:
          break;
      }
  }, [location]);

  //account list definition
  const accountList = useMemo(() => {
    const _accountList = (accountsResource !== null && accountsResource.getItems()) || [];

    return _accountList.map((account, index) => {
      return {
        accountName: account.summary.resource_name,
        accountRole: account.summary.account_role_arn,
        accountId: account.summary.diaas_aws_account,
        environmentList: accountsState
          ? accountsState[account.summary.resource_name]?.environments
          : null,
        isLoading: accountsState ? accountsState[account.summary.resource_name]?.isLoading : null,
        unregisterAccountInfo: {
          isDialogIndexOpen: isUnregisterAccountDialogIndexVisible,
          accountStatus: unregisterAccountStatus,
          accountMessage: accountMessage,
          onOpenClick: () => toggleUnregisterAccountDialogVisible(index),
          onCancelClick: toggleUnregisterAccountDialogVisible,
          onSubmitClick: unregisterAccount,
        },
        editAccountInfo: {
          isDialogIndexOpen: isEditAccountDialogIndexVisible,
          accountStatus: editAccountStatus,
          accountMessage: accountMessage,
          onOpenClick: () => toggleEditAccountDialogVisible(index),
          onCancelClick: toggleEditAccountDialogVisible,
          onSubmitClick: (accountRole) => editAccount(account.summary.resource_name, accountRole),
        },
        onClickExpand: () => {
          dispatch({
            type: "expand",
            accountName: account.summary.resource_name,
          });
          loadEnvironments(account.summary.resource_name, account.href);
        },
        onClickCollapse: () => {
          dispatch({
            type: "collapse",
            accountName: account.summary.resource_name,
          });
        },
        onClickRegisterEnv: () => {
          history.push(
            `/customers/${customerParams.customerId}/accounts/${account.summary.resource_name}/environments/new`
          );
        },
        onClickOption: (option, environment, awsRegion, domain, environmentName, envAccountZoneName) =>
          clickOption(
            option,
            customerParams.customerId,
            account.summary.resource_name,
            environment,
            awsRegion,
            domain, 
            environmentName, 
            envAccountZoneName
          ),
      };
    });
  }, [
    accountMessage,
    accountsResource,
    accountsState,
    editAccountStatus,
    unregisterAccountStatus,
    history,
    isEditAccountDialogIndexVisible,
    isUnregisterAccountDialogIndexVisible,
    loadEnvironments,
    toggleEditAccountDialogVisible,
    toggleUnregisterAccountDialogVisible,
    clickOption,
    unregisterAccount,
    editAccount,
    customerParams,
  ]);

  return [
    customer,
    fetchStatus,
    dashboardMessage,
    accountList,
    editCustomerInfo,
    deleteCustomerInfo,
    registerAccountInfo,
    dismissMessage,
    isDeleteEnvironmentDialogVisible,
    environmentMessage,
    environmentStatus,
    error,
  ];
};
