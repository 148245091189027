import React, { useState } from "react";
import styled from "styled-components";
import {
  DxcHeading,
  DxcButton,
  DxcDialog,
  DxcParagraph,
  DxcFlex,
  DxcInset,
  DxcTypography,
} from "@dxc-technology/halstack-react";

import DeploymentPackageCondition from "./DeploymentPackageCondition";

function EditDeploymentPackageCondition({
  oldConditionKey,
  oldValue,
  oldAssert,
  onSaveClick,
  onCancelClick,
  indexCondition,
}) {
  const [condition, changeCondition] = useState({
    conditionKey: oldConditionKey,
    value: oldValue,
    assert: oldAssert,
  });

  const updateCondition = (conditionKey, value, assert) => {
    changeCondition({
      conditionKey: conditionKey,
      value: value,
      assert: assert,
    });
  };

  return (
    <DxcDialog isCloseVisible onCloseClick={() => onCancelClick()}>
      <DialogHeader>
        <DxcHeading level={2} text="Edit condition" margin={{ bottom: "xsmall" }} />
      </DialogHeader>
      <DxcFlex direction="column">
        <DxcParagraph>
          Available placeholders for parameterized key:{" "}
          <DxcTypography as="span" fontStyle="italic" display="inline">
            {" "}
            {"{customer}"}, {"{account}"}, {"{environmentId}"} and {"{environmentType}"}
          </DxcTypography>
        </DxcParagraph>
        <DxcHeading text="Deployment package conditions" level={4} margin={{ top: "small" }} />
        <ConditionsListContainer>
          <DeploymentPackageCondition
            conditionKey={condition.conditionKey}
            value={condition.value}
            assert={condition.assert}
            onChangeElement={updateCondition}
          ></DeploymentPackageCondition>
        </ConditionsListContainer>
      </DxcFlex>

      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={() => onCancelClick()}
            mode="text"
          ></DxcButton>
          <DxcButton
            label="Save"
            onClick={() => onSaveClick(condition, indexCondition)}
            disabled={condition.conditionKey === "" || condition.value === ""}
            mode="primary"
          ></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const ConditionsListContainer = styled.div`
  margin-top: 10px;
  max-height: 240px;
  overflow-y: auto;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(102, 102, 102);
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background-color: rgb(217, 217, 217);
    border-radius: 3px;
  }
`;

export default EditDeploymentPackageCondition;
