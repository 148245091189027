import React from "react";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
import {
  DxcAlert,
  DxcApplicationLayout,
  DxcLink,
  DxcParagraph,
  DxcTypography,
  HalstackProvider,
} from "@dxc-technology/halstack-react";
import styled from "styled-components";
import { useAssureTheme } from "@diaas/assure-platform-react-tools";
import {
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  VerifyContact,
  withAuthenticator,
} from "aws-amplify-react";
import Amplify from "aws-amplify";
import bg from "../assets/bg.jpg";
import logo from "../assets/logo_black.png";

import linkedinLogo from "../assets/linkedinLogo.svg";
import facebookLogo from "../assets/facebookLogo.svg";
import twitterLogo from "../assets/twitterLogo.svg";

import { I18n } from "aws-amplify";

import CustomerDashboard from "../customer-dashboard/CustomerDashboard";
import EnvironmentCreate from "../environment-create/EnvironmentCreate";
import EnvironmentEdit from "../environment-edit/EnvironmentEdit";
// TODO: remove clone import
// import EnvironmentClone from "../environment-clone/EnvironmentClone";
import EnvironmentTypeDashboard from "../environment-type-dashboard/EnvironmentTypeDashboard";
import DeploymentPackagesDashboard from "../deployment-package-dashboard/DeploymentPackagesDashboard";
import DeploymentPackageCreate from "../deployment-package-create/DeploymentPackageCreate";
import DeploymentPackageEdit from "../deployment-package-edit/DeploymentPackageEdit";
import DeploymentPackageDeploymentsDashboard from "../deployment-package-deployments-dashboard/DeploymentPackageDeploymentsDashboard";
import Header from "../common/Header";
import AccountDashboard from "../account-dashboard/AccountDashboard";
import DeploymentDashboard from "../deployment-dashboard/DeploymentDashboard";
import InstallDeploymentPackage from "../install-deployment-package-wizard/InstallDeploymentPackage";
import InstallService from "../install-service-wizard/InstallService";
import ScrollToTop from "../common/ScrollToTop";
import RefreshDeploymentPackage from "../deployment-package-refresh/RefreshDeploymentPackage";
import DeploymentHistory from "../deployment-history/DeploymentHistory";
import LogViewer from "../log-viewer/LogViewer";
import EnvironmentMigration from "../environment-migration/EnvironmentMigration";

I18n.setLanguage("en");

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: "us-east-1",
    userPoolId: window.assureConfig?.cognitoPoolId
      ? window.assureConfig.cognitoPoolId
      : "NOT_PROVIDED",
    userPoolWebClientId: window.assureConfig?.cognitoAppClientId
      ? window.assureConfig.cognitoAppClientId
      : "NOT_PROVIDED",
  },
});

function App() {
  const { isThemeLoaded, theme } = useAssureTheme();

  return (
    <AppContainer>
      <HashRouter>
        <ScrollToTop />
        {(isThemeLoaded === "loading" && <LoadingBackground />) ||
          ((isThemeLoaded === "loadedSuccess" || isThemeLoaded === "loadedError") && (
            <HalstackProvider theme={theme.components}>
              <DxcApplicationLayout
                header={
                  <React.Fragment>
                    {window.assureConfig?.global_message ? (
                      <GlobalMessageContainer>
                        <DxcAlert
                          type="info"
                          mode="inline"
                          size="fillParent"
                          inlineText={
                            <DxcParagraph>
                              <DxcTypography fontSize="0.75rem">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: window.assureConfig.global_message,
                                  }}
                                />
                              </DxcTypography>
                            </DxcParagraph>
                          }
                        />
                      </GlobalMessageContainer>
                    ) : null}
                    <Header />
                  </React.Fragment>
                }
                footer={
                  <DxcApplicationLayout.Footer
                    padding={{ top: "xsmall", bottom: "xsmall" }}
                    socialLinks={[
                      {
                        href: "https://www.linkedin.com/company/dxctechnology",
                        logo: <img src={linkedinLogo} alt="linkedin" />,
                      },
                      {
                        href: "https://twitter.com/dxctechnology",
                        logo: <img src={twitterLogo} alt="twitter" />,
                      },
                      {
                        href: "https://www.facebook.com/DXCTechnology/",
                        logo: <img src={facebookLogo} alt="facebook" />,
                      },
                    ]}
                    bottomLinks={[
                      {
                        href: "https://www.dxc.techonology",
                        text: "DXC technology",
                      },
                      {
                        href: "https://www.dxc.techonology/privacy",
                        text: "Privacy",
                      },
                      {
                        href: "https://www.dxc.techonology/legal",
                        text: "Terms",
                      },
                    ]}
                    copyright="© DXC Technology 2019. All rights reserved."
                  >
                    <CustomContent>
                      <CustomContentTitle>Support</CustomContentTitle>
                      <CustomContentLink>
                        <DxcLink
                          href="https://github.dxc.com/assure/assure-environment-service-ui/blob/master/docs/user_manual_and_guide.md"
                          underlined={false}
                          inheritColor={true}
                          newWindow={true}
                        >
                          User manual
                        </DxcLink>
                      </CustomContentLink>
                      <CustomContentLink>
                        <DxcLink
                          href="https://github.dxc.com/assure/assure-environment-service-ui/issues/new/choose"
                          underlined={false}
                          inheritColor={true}
                          newWindow={true}
                        >
                          Register an issue
                        </DxcLink>
                      </CustomContentLink>
                    </CustomContent>
                  </DxcApplicationLayout.Footer>
                }
              >
                <DxcApplicationLayout.Main>
                  <Content>
                    <Switch>
                      <Route exact path="/">
                        <Redirect to="/customers" />
                      </Route>
                      <Route exact path="/customers">
                        <CustomerDashboard></CustomerDashboard>
                      </Route>
                      <Route
                        exact
                        path="/customers/:customerId/accounts/:accountId/environments/:environmentId/service/:serviceId/install"
                      >
                        <InstallService></InstallService>
                      </Route>
                      <Route
                        exact
                        path="/customers/:customerId/accounts/:accountId/environments/:environmentId/deployment-packages/:deploymentPackageId/install"
                      >
                        <InstallDeploymentPackage></InstallDeploymentPackage>
                      </Route>
                      <Route
                        exact
                        path="/customers/:customerId/accounts/:accountId/environments/:environmentId/deployment-packages/:deploymentPackageId/deployments/:deploymentId/refresh"
                      >
                        <RefreshDeploymentPackage></RefreshDeploymentPackage>
                      </Route>
                      <Route
                        exact
                        path="/customers/:customerId/accounts/:accountId/environments/:environmentId/deployments"
                      >
                        <DeploymentDashboard></DeploymentDashboard>
                      </Route>
                      <Route
                        exact
                        path="/customers/:customerId/accounts/:accountId/environments/:environmentId/deployment-packages/:deploymentPackageId/deployments/:deploymentId/history"
                      >
                        <DeploymentHistory></DeploymentHistory>
                      </Route>
                      <Route
                        exact
                        path="/customers/:customerId/accounts/:accountId/environments/new"
                      >
                        <EnvironmentCreate></EnvironmentCreate>
                      </Route>
                      {/* TODO: remove environment clone route */}
                      {/* <Route
                        exact
                        path="/customers/:customerId/accounts/:accountId/environments/:environmentId/clone"
                      >
                        <EnvironmentClone></EnvironmentClone>
                      </Route> */}
                      <Route
                        exact
                        path="/customers/:customerId/accounts/:accountId/environments/:environmentId"
                      >
                        <EnvironmentEdit></EnvironmentEdit>
                      </Route>
                      <Route
                        exact
                        path="/customers/:customerId/accounts/:accountId/environments/:environmentId/migration"
                      >
                        <EnvironmentMigration></EnvironmentMigration>
                      </Route>
                      <Route
                        exact
                        path="/customers/:customerId/accounts/:accountId/environments/:environmentId/deployment-packages/:deploymentPackageId/deployments/:deploymentId/logs/:logId"
                      >
                        <LogViewer></LogViewer>
                      </Route>
                      <Route path="/customers/:customerId">
                        <AccountDashboard></AccountDashboard>
                      </Route>
                      <Route exact path="/deployment-packages">
                        <DeploymentPackagesDashboard></DeploymentPackagesDashboard>
                      </Route>
                      <Route path="/deployment-packages/new">
                        <DeploymentPackageCreate></DeploymentPackageCreate>
                      </Route>
                      <Route exact path="/deployment-packages/:deploymentPackageName/deployments">
                        <DeploymentPackageDeploymentsDashboard></DeploymentPackageDeploymentsDashboard>
                      </Route>
                      <Route exact path="/deployment-packages/:deploymentPackageId">
                        <DeploymentPackageEdit></DeploymentPackageEdit>
                      </Route>
                      <Route exact path="/environment-types">
                        <EnvironmentTypeDashboard></EnvironmentTypeDashboard>
                      </Route>
                    </Switch>
                  </Content>
                </DxcApplicationLayout.Main>
              </DxcApplicationLayout>
            </HalstackProvider>
          ))}
      </HashRouter>
    </AppContainer>
  );
}

const AppContainer = styled.div``;

const GlobalMessageContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  & > div {
    width: 100%;
  }
  & > div > div {
    width: 100%;
    border-radius: 0px;
  }
`;

const LoadingBackground = styled.div`
  background-color: #000000cc;
  width: 100%;
  height: 100%;
  position: fixed;
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  min-height: 100vh;
  padding: ${window.assureConfig?.global_message ? "80px" : "32px"} 120px;
  flex-direction: column;
`;

const CustomContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomContentTitle = styled.div`
  display: flex;
  color: #0095ff;
  margin-bottom: 6px;
`;

const CustomContentLink = styled.div`
  display: flex;
  border-bottom: 1px solid white;
  color: #ffffff;
  width: fit-content;
  flex-direction: column;
`;

export default window?.assureConfig?.loginPath
  ? App
  : withAuthenticator(App, {
      authenticatorComponents: [
        <SignIn />,
        <ConfirmSignIn />,
        <VerifyContact />,
        <React.Fragment />,
        <ConfirmSignUp />,
        <ForgotPassword />,
        <RequireNewPassword />,
      ],
      theme: {
        container: {
          minHeight: "calc(100vh - 100px)",
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          padding: "50px",
        },
        formContainer: {
          margin: "0px auto",
        },
        formSection: {
          padding: "40px 60px",
        },
        formField: {
          marginBottom: "35px",
        },

        sectionHeader: {
          backgroundImage: `url(${logo})`,
          backgroundSize: "auto 50px",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "center",
          paddingTop: "100px",
          marginBottom: "40px",
          fontSize: "20px",
          fontWeight: "bold",
        },
        sectionBody: {},
        sectionFooter: {
          flexDirection: "column",
          marginTop: "50px",
        },
        sectionFooterPrimaryContent: {
          margin: "0px",
          alignSelf: "center",
          marginBottom: "10px",
        },
        sectionFooterSecondaryContent: { margin: "0px" },

        input: {},
        button: {
          backgroundColor: "#ffed00",
          color: "black",
          fontWeight: "bold",
          outline: "none",
          borderRadius: "3px",
        },
        photoPickerButton: {},
        photoPlaceholder: {},
        signInButton: {},
        signInButtonIcon: {},
        signInButtonContent: {},
        amazonSignInButton: {},
        facebookSignInButton: {},
        googleSignInButton: {},
        oAuthSignInButton: {},

        formRow: {},
        strike: {},
        strikeContent: {},
        actionRow: {},
        a: { textDecoration: "underline", color: "black" },

        hint: {},
        radio: {},
        inputLabel: { fontWeight: "bold" },
        toast: {
          background: "rgba(0, 0, 0, 0.75)",
          fontWeight: "bold",
        },

        navBar: {},
        nav: {},
        navRight: {},
        navItem: {},
        navButton: {},
      },
    });
