import React, { useMemo, useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import { DxcHeading, DxcSpinner, DxcAlert, DxcBox } from "@dxc-technology/halstack-react";

//custom hook
import { useDeploymentHistory } from "./useDeploymentHistory";

//search image
import backImage from "./images/arrow_back_ios-24px.svg";
import CollapsableLink from "../deploy-deployment-package-steps/helper/CollapsableLink";

//custom components
import DeploymentHistoryList from "./DeploymentHistoryList";
import DeploymentHistoryData from "./DeploymentHistoryData";
import { formatDate } from "../common/utils";
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";

function DeploymentHistory() {
  const [
    deploymentHistoryStatus,
    deploymentHistoryMessage,
    activeDeploymentPackageInfo,
    historyInfo,
    navigateToDeployments,
    dismissMessage,
    error,
  ] = useDeploymentHistory();
  useErrorHandler(error);

  const [isViewHistoryCollapsed, changeIsViewHistoryCollapsed] = useState(true);
  const onChange = () => {
    changeIsViewHistoryCollapsed(!isViewHistoryCollapsed);
  };

  const formattedDeploymentDate = useMemo(() => {
    return activeDeploymentPackageInfo.deploymentDate
      ? formatDate(activeDeploymentPackageInfo.deploymentDate)
      : "";
  }, [activeDeploymentPackageInfo.deploymentDate]);

  return (
    <Content>
      <StyledLink onClick={() => navigateToDeployments()}>
        <BackImg src={backImage} />
        Back to Deployments
      </StyledLink>
      <TitleContainer>
        <DxcHeading
          level={2}
          weight="normal"
          text={"Deployment package:"}
          margin={{ top: "medium", bottom: "medium" }}
        />
        <DxcHeading
          level={2}
          text={activeDeploymentPackageInfo.name}
          margin={{
            top: "medium",
            bottom: "medium",
            left: "xsmall",
            right: "xsmall",
          }}
          weight={"bold"}
        />
      </TitleContainer>

      {deploymentHistoryMessage ? (
        <DxcAlert
          type={deploymentHistoryMessage.type}
          mode="inline"
          inlineText={deploymentHistoryMessage.message}
          size="fillParent"
          onClose={dismissMessage}
          margin={{ bottom: "small" }}
        />
      ) : null}

      <MainContainer>
        <DxcBox padding={{ left: "medium", right: "medium" }}>
          <BoxContainer>
            <CollapsableLinkContainer>
              <CollapsableLink
                text={`View deployment history`}
                onClick={onChange}
                isCollapsed={isViewHistoryCollapsed}
              ></CollapsableLink>
              <CollapsableLinkAssitiveText>
                Selected deployment:{" "}
                {formattedDeploymentDate && formattedDeploymentDate !== ""
                  ? ` ${formattedDeploymentDate} `
                  : "No date"}{" "}
                - Deployment status:{" "}
                <span className="es-capitalized-text">
                  {activeDeploymentPackageInfo.deploymentStatus ?? "Not installed"}
                </span>{" "}
                - Testing status:{" "}
                <span className="es-capitalized-text">
                  {activeDeploymentPackageInfo.testingStatus ?? "Not tested"}
                </span>{" "}
                - Requested by: {activeDeploymentPackageInfo.creator}
              </CollapsableLinkAssitiveText>
            </CollapsableLinkContainer>
            {isViewHistoryCollapsed !== true ? (
              <DeploymentHistoryList
                deploymentHistoryList={historyInfo.deploymentHistoryList}
                onClickRow={historyInfo.selectActiveDeployment}
                onClickLog={historyInfo.viewLog}
              />
            ) : null}
          </BoxContainer>
        </DxcBox>
        <DxcBox
          margin={{ top: "medium", bottom: "medium" }}
          padding={{ left: "medium", bottom: "small", right: "medium" }}
        >
          <DeploymentHistoryData
            sourceURL={activeDeploymentPackageInfo.source}
            sourceBranch={activeDeploymentPackageInfo.tag}
            artefactList={activeDeploymentPackageInfo.artefacts}
            duParameterList={activeDeploymentPackageInfo.parameters}
            isLinkCollapsed={activeDeploymentPackageInfo.isLinkCollapsed}
            onChangeIsLinkCollapsed={activeDeploymentPackageInfo.onChangeIsLinkCollapsed}
          />
        </DxcBox>
      </MainContainer>

      {deploymentHistoryStatus === "fetching" && (
        <DxcSpinner theme="light" label="Loading..." mode="overlay" />
      )}
    </Content>
  );
}

const Content = styled.div``;

const TitleContainer = styled.div`
  display: flex;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CollapsableLinkContainer = styled.div`
  margin: 12px 6px 6px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  min-height: 48px;
`;

const CollapsableLinkAssitiveText = styled.div`
  font-style: italic;
  color: #666666;
`;

const BoxContainer = styled.div`
  width: 100%;
`;

const StyledLink = styled.div`
  display: flex;
  width: fit-content;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  top: 16px;
  left: 5%;
  :hover {
    cursor: pointer;
  }
`;

const BackImg = styled.img`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-self: center;
`;

export default withErrorBoundary(DeploymentHistory, { FallbackComponent: ApplicationError });
