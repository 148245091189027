//React hooks imports
import { useState, useMemo, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

//configuation helpers imports
import { config } from "../config";
import checkIcon from "./images/done_black_24dp";
import {
  getAuthenticatedUser,
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  HTTP_ERROR_CODES,
  PROMISE_FINAL_STATUSES,
  sortReleaseByVersionDesc,
} from "../common/utils";

//DXC HAL
import { useHalResource } from "@dxc-technology/halstack-react-hal";
import { HalApiCaller } from "@dxc-technology/halstack-client";

const getDeploymentsFromEmbeddedObject = (embeddedObject) => {
  const _deploymentsCollection = embeddedObject._links.item.reduce((result, dp) => {
    const _deployments = dp._embedded?.item?._embedded?._links?.item.map((item) => ({
      resource_name: item._embedded?.item?.resource_name,
      service_name: item._embedded?.item?.service_name,
      version: item._embedded?.item?.version,
      resource_subtype: item._embedded?.item?.resource_subtype,
      parameters: item._embedded?.item?.parameters,
    }));
    return _deployments ? result.concat(_deployments) : result;
  }, []);
  return _deploymentsCollection.filter(
    (deployment, index, self) =>
      index ===
      self.findIndex(
        (existingDeployment) => existingDeployment.resource_name === deployment.resource_name
      )
  );
};

const findProperty = (props, propName) => {
  return props?.find((prop) => prop.key === propName)?.value ?? null;
};

export const useInstallService = () => {
  const [error, setError] = useState(null);
  const [releaseURI, setReleaseURI] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState();
  const [isMessageDismissed, changeIsMessageDismissed] = useState(false);
  const [installServiceMessage, changeInstallServiceMessage] = useState(null);
  const [tfAction, setTfAction] = useState("apply");
  const [releaseDeploymentPackages, setReleaseDeploymentPackages] = useState([]);
  const [installStatus, changeInstallStatus] = useState("idle");

  const dismissMessage = () => {
    changeIsMessageDismissed(true);
    changeInstallServiceMessage(null);
  };

  const onVersionSelected = (newSelectedVersion) => {
    setSelectedVersion(newSelectedVersion);
  };

  //url management hooks
  const history = useHistory();
  const { customerId, accountId, environmentId, serviceId } = useParams();

  const navigateToDeploymentDashboard = () => {
    history.push(
      `/customers/${customerId}/accounts/${accountId}/environments/${environmentId}/deployments`
    );
  };

  // urls
  const releasesURI = `${config.release_api_url}/releases/${serviceId}/versions?deprecated=false`;
  const environmentURI = `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}?embedded=[deployments.item]&type=service`;

  const [releasesResource, releasesStatus, releasesError] = useHalResource({
    url: releasesURI,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const [environmentResource, environmentStatus, environmentError] = useHalResource({
    url: environmentURI,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const serviceDeploymentVersion = useMemo(() => {
    const _deployments = environmentResource?.resourceRepresentation?._embedded
      ? getDeploymentsFromEmbeddedObject(environmentResource.resourceRepresentation._embedded)
      : [];
    const _deployment = _deployments.find(
      (deployment) =>
        deployment.resource_subtype === "service" && deployment.service_name === serviceId
    );
    return _deployment?.version !== "custom" ? _deployment?.version : null;
  }, [environmentResource, serviceId]);

  //HAL stack hooks
  useEffect(() => {
    const uri =
      releasesResource && selectedVersion
        ? `${config.release_api_url}/releases/${serviceId}/versions/${selectedVersion}`
        : null;
    setReleaseURI(uri);
  }, [releasesResource, selectedVersion, serviceId]);

  const [releaseResource, releaseStatus, releaseError] = useHalResource({
    url: releaseURI,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const environmentDeployments = useMemo(() => {
    const _deployments = environmentResource?.resourceRepresentation?._embedded
      ? getDeploymentsFromEmbeddedObject(environmentResource.resourceRepresentation._embedded)
      : [];
    const _serviceReleaseDescriptor = findProperty(releaseResource?.getProperties(), "descriptor");
    const _deploymentPackageNames = (
      _serviceReleaseDescriptor ? [].concat(_serviceReleaseDescriptor) : []
    ).reduce((names, deploymentPackageDescriptor) => {
      const deploymentPackage = deploymentPackageDescriptor.tags.find((tag) => tag.name);
      return [...names, deploymentPackage.name];
    }, []);

    return _deployments.filter((deployment) =>
      _deploymentPackageNames.includes(deployment.service_name)
    );
  }, [environmentResource?.resourceRepresentation?._embedded, releaseResource]);

  useEffect(() => {
    if (
      releasesStatus === "fetching" ||
      environmentStatus === "fetching" ||
      releaseStatus === "fetching"
    ) {
      changeInstallStatus("loading");
    } else {
      changeInstallStatus("idle");
    }
  }, [releasesStatus, releaseStatus, environmentStatus]);

  const serviceVersions = useMemo(() => {
    return (releasesResource ? [].concat(releasesResource.getItems()) : [])
      .filter((release) => release.summary.type === "service")
      .map((release) => {
        const releaseValues =
          release.summary.resource_version === "latest"
            ? {
                label: `Latest (${release.summary.version})`,
                value: release.summary.version,
              }
            : {
                label: release.summary.version,
                value: release.summary.version,
              };
        return serviceDeploymentVersion === release.summary.version
          ? { ...releaseValues, icon: checkIcon }
          : releaseValues;
      }).sort((a, b) => sortReleaseByVersionDesc(a.value, b.value));
  }, [releasesResource, serviceDeploymentVersion]);

  const buildParametersValuesFromDeployments = ({
    deployments,
    deploymentPackageName,
    deploymentPackageParameters,
  }) => {
    const _deploymentPackageDeployment = deployments.find(
      (deployment) => deployment.service_name === deploymentPackageName
    );
    const _defaultParameters = deploymentPackageParameters.map((parameter) => {
      const _parameterKey = Object.keys(parameter)[0];
      return {
        key: _parameterKey,
        value:
          _deploymentPackageDeployment?.parameters &&
          _parameterKey in _deploymentPackageDeployment.parameters
            ? _deploymentPackageDeployment.parameters[_parameterKey]
            : null,
      };
    });
    const _expertParameters = _deploymentPackageDeployment?.parameters
      ? Object.keys(_deploymentPackageDeployment.parameters)
          .filter((key) => !_defaultParameters.map((param) => param.key).includes(key))
          .map((paramKey) => ({
            key: paramKey,
            value: _deploymentPackageDeployment.parameters[paramKey],
          }))
      : [];
    return { defaultParameters: _defaultParameters, expertParameters: _expertParameters };
  };

  useEffect(() => {
    if (
      PROMISE_FINAL_STATUSES.includes(releasesStatus) &&
      PROMISE_FINAL_STATUSES.includes(environmentStatus)
    ) {
      const _latestVersion = serviceVersions.find((version) => version.label.includes("Latest"));
      setSelectedVersion(serviceDeploymentVersion ?? _latestVersion?.value);
    }
  }, [environmentStatus, releasesStatus, serviceDeploymentVersion, serviceVersions]);

  useEffect(() => {
    if (releaseResource) {
      const _serviceReleaseDescriptor = findProperty(releaseResource.getProperties(), "descriptor");
      const _serviceName = findProperty(releaseResource?.getProperties(), "title");
      setServiceName(_serviceName);
      const _deploymentPackageList = (
        _serviceReleaseDescriptor ? [].concat(_serviceReleaseDescriptor) : []
      ).reduce((deploymentPackages, deploymentPackage) => {
        const newTags = deploymentPackage.tags.filter((tag) => tag.type === "deployment-package");
        const _newDeploymentPackages = newTags.map((tag) => {
          const { defaultParameters, expertParameters } = buildParametersValuesFromDeployments({
            deployments: environmentDeployments,
            deploymentPackageName: tag.name,
            deploymentPackageParameters: deploymentPackage.parameters,
          });
          return {
            version: tag.version,
            name: tag.name,
            defaultParameters: defaultParameters,
            expertParameters: expertParameters,
            deploy_info: deploymentPackage.deploy_info,
            type: tag.type,
            ssm_conditions: deploymentPackage.ssm_conditions,
            artifacts: deploymentPackage.artifacts,
            dependsOn: deploymentPackage.dependsOn,
          };
        });
        return [...deploymentPackages, ..._newDeploymentPackages];
      }, []);
      setReleaseDeploymentPackages(_deploymentPackageList);
    }
  }, [environmentDeployments, releaseResource]);

  const setNewParameterKey = useCallback(
    (newKey, deploymentPackageIndex, parameterIndex) => {
      const _changedDeploymentPackages = [...releaseDeploymentPackages];
      const _deploymentPackage = _changedDeploymentPackages[deploymentPackageIndex];
      const isExistingKey = [
        ..._deploymentPackage.defaultParameters,
        ..._deploymentPackage.expertParameters,
      ].some((parameter) => parameter.key === newKey);
      _deploymentPackage.expertParameters[parameterIndex].invalid = isExistingKey ? true : false;
      _deploymentPackage.expertParameters[parameterIndex].key = newKey;
      setReleaseDeploymentPackages(_changedDeploymentPackages);
    },
    [releaseDeploymentPackages]
  );

  const serviceDeploymentPackages = useMemo(() => {
    return releaseDeploymentPackages?.length > 0
      ? releaseDeploymentPackages.map((deploymentPackage, deploymentPackageIndex) => {
          return {
            version: deploymentPackage.version,
            name: deploymentPackage.name,
            deploy_info: deploymentPackage.deploy_info,
            type: deploymentPackage.type,
            ssm_conditions: deploymentPackage.ssm_conditions,
            artifacts: deploymentPackage.artifacts,
            dependsOn: deploymentPackage.dependsOn,
            defaultParameters: deploymentPackage.defaultParameters.map(
              (parameter, parameterIndex) => ({
                ...parameter,
                setNewValue: (newValue) => {
                  const _changedDeploymentPackages = [...releaseDeploymentPackages];
                  _changedDeploymentPackages[deploymentPackageIndex].defaultParameters[
                    parameterIndex
                  ].value = newValue;
                  setReleaseDeploymentPackages(_changedDeploymentPackages);
                },
              })
            ),
            expertParameters: deploymentPackage.expertParameters.map(
              (parameter, parameterIndex) => ({
                ...parameter,
                invalid: parameter.invalid ?? false,
                assistiveText: parameter.invalid ? "This name is currently in parameters" : null,
                setNewKey: (newKey) =>
                  setNewParameterKey(newKey, deploymentPackageIndex, parameterIndex),
                setNewValue: (newValue) => {
                  const _changedDeploymentPackages = [...releaseDeploymentPackages];
                  _changedDeploymentPackages[deploymentPackageIndex].expertParameters[
                    parameterIndex
                  ].value = newValue;
                  setReleaseDeploymentPackages(_changedDeploymentPackages);
                },
                deleteParameter: () => {
                  const _changedDeploymentPackages = [...releaseDeploymentPackages];
                  _changedDeploymentPackages[deploymentPackageIndex].expertParameters.splice(
                    parameterIndex,
                    1
                  );
                  setReleaseDeploymentPackages(_changedDeploymentPackages);
                },
              })
            ),
            addNewExpertParameter: () => {
              const _changedDeploymentPackages = [...releaseDeploymentPackages];
              _changedDeploymentPackages[deploymentPackageIndex].expertParameters.push({
                key: "",
                value: "",
              });
              setReleaseDeploymentPackages(_changedDeploymentPackages);
            },
          };
        })
      : [];
  }, [releaseDeploymentPackages, setNewParameterKey]);

  //Message operations
  useEffect(() => {
    if (PROMISE_FINAL_STATUSES.includes(releaseStatus) && releaseError) {
      setError(buildApplicationError(releaseError));
    }
    if (
      PROMISE_FINAL_STATUSES.includes(releasesStatus) &&
      PROMISE_FINAL_STATUSES.includes(environmentStatus) &&
      (releasesError || environmentError)
    ) {
      if (!isMessageDismissed && environmentError) {
        const _deploymentError = buildApplicationError(environmentError);
        _deploymentError.status === HTTP_ERROR_CODES.NOT_FOUND
          ? changeInstallServiceMessage({
              type: "warning",
              message: "No current deployment found for this service",
            })
          : setError(_deploymentError);
      }
      if (releasesError) setError(buildApplicationError(releasesError));
    }
  }, [
    environmentError,
    environmentStatus,
    isMessageDismissed,
    releaseError,
    releaseStatus,
    releasesError,
    releasesStatus,
  ]);

  const installService = async () => {
    changeInstallStatus("loading");
    const _deploymentPackages = releaseDeploymentPackages.map((deploymentPackage) => {
      return {
        name: deploymentPackage.name,
        version: deploymentPackage.version,
        deploy_info: deploymentPackage.deploy_info,
        ssm_conditions: deploymentPackage.ssm_conditions,
        type: deploymentPackage.type,
        artifacts: deploymentPackage.artifacts,
        dependsOn: deploymentPackage.dependsOn,
        parameters: [
          ...deploymentPackage.defaultParameters,
          ...deploymentPackage.expertParameters,
        ].reduce((parametersObj, parameter) => {
          return parameter.key && parameter.value
            ? { ...parametersObj, [parameter.key]: `${parameter.value}` }
            : parametersObj;
        }, {}),
      };
    });

    const payload = {
      terraform_action: tfAction,
      customer: customerId,
      account: accountId,
      environment: environmentId,
      name: serviceId,
      title: serviceName,
      type: "service",
      username: await getAuthenticatedUser(),
      version: selectedVersion,
      deploymentPackages: _deploymentPackages,
    };

    const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
    const url =
      selectedVersion === serviceDeploymentVersion
        ? `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}/deployment-packages:refresh`
        : `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}/deployment-packages:deploy`;

    await HalApiCaller.post({
      url: url,
      headers: { ...asyncHeaders, ...getPlatformHeaders() },
      body: payload,
    })
      .then((response) => {
        changeInstallStatus("completed");
        const logURL = response.body.tf_log_url.split("/logs/")[1];
        const infoUrl =
          `/customers/${customerId}` +
          `/accounts/${accountId}` +
          `/environments/${environmentId}/deployment-packages/${serviceId}/deployments/${serviceId}-${environmentId}/logs/`;
        if (logURL) {
          history.push(`${infoUrl}${logURL}`);
        }
      })
      .catch((error) => {
        changeInstallStatus("rejected");
        error.response?.data?.messages
          ? changeInstallServiceMessage({
              type: "error",
              message: `${error.response.data.messages[0].message}`,
            })
          : changeInstallServiceMessage({
              type: "error",
              message: `Error installing the service`,
            });
      });
  };

  const deployInstallationInfo = {
    serviceVersions: serviceVersions,
    selectedVersion: selectedVersion,
    onVersionChange: onVersionSelected,
    serviceDeploymentPackages: serviceDeploymentPackages,
    releaseServiceHref: `${config.release_ui_url}/releases/${serviceId}/versions/${
      serviceVersions
        ?.find((version) => version.value === selectedVersion)
        ?.label.toLowerCase()
        .includes("latest")
        ? "latest"
        : selectedVersion
    }`,
    action: tfAction,
    setAction: setTfAction,
    serviceId: serviceId,
  };
  return {
    navigateToDeploymentDashboard: navigateToDeploymentDashboard,
    installServiceMessage: installServiceMessage,
    installService: installService,
    deployInstallationInfo: deployInstallationInfo,
    dismissMessage: dismissMessage,
    installStatus: installStatus,
    error: error,
  };
};
