//React imports
import React, { useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcButton,
  DxcDialog,
  DxcTextInput,
  DxcFlex,
  DxcInset,
} from "@dxc-technology/halstack-react";

function CreateEnvironmentType({
  environmentTypeMessage,
  isCreating,
  saveEnvironmentType,
  toggleDialogVisible,
}) {
  const [environmentType, changeEnvironmentType] = useState("");
  const [environmentTypeError, setEnvironmentTypeError] = useState();

  const environmentTypeHandler = ({ value, error }) => {
    changeEnvironmentType(value);
    setEnvironmentTypeError(error);
  };

  const createClick = () => {
    saveEnvironmentType(environmentType);
  };

  return (
    <DxcDialog isCloseVisible onCloseClick={toggleDialogVisible}>
      <DialogHeader>
        <DxcHeading level={2} text="Create new environment type" margin={{ bottom: "xsmall" }} />
        {isCreating && (
          <DxcSpinner
            theme="light"
            mode="small"
            margin={{ left: "xsmall", bottom: "xsmall" }}
            role="progressbar"
          />
        )}
      </DialogHeader>

      {environmentTypeMessage && (
        <DxcAlert
          type={environmentTypeMessage.type}
          mode="inline"
          size="fillParent"
          margin={{ bottom: "small" }}
          inlineText={environmentTypeMessage.message}
        />
      )}

      <DxcFlex direction="column">
        <DxcTextInput
          label="Environment type"
          size="fillParent"
          value={environmentType}
          placeholder="e.g.: my-environment-type"
          onChange={environmentTypeHandler}
          onBlur={environmentTypeHandler}
          clearable={true}
          optional={false}
          error={environmentTypeError == null ? "" : environmentTypeError}
          pattern="^[a-z0-9-]+$"
          helperText="Only lowercase alphanumeric and hyphens accepted"
        />
      </DxcFlex>
      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={toggleDialogVisible}
            mode="text"
          ></DxcButton>
          <DxcButton
            label="Create"
            onClick={createClick}
            mode="primary"
            disabled={environmentTypeError || !environmentType}
          ></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default CreateEnvironmentType;
