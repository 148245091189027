import React from "react";
import styled from "styled-components";

//DXC components
import { DxcSpinner, DxcAlert, DxcHeading, DxcButton } from "@dxc-technology/halstack-react";

import Accounts from "./AccountList";
import EditCustomerDialog from "./dialogs/EditCustomer";
import DeleteCustomerDialog from "./dialogs/DeleteCustomer";

import { useAccountDashboard } from "./useAccountDashboardHook";

//images
import DeleteEnvironment from "./dialogs/DeleteEnvironment";
import { editImage } from "./editImage.js";
import { deleteImage } from "./deleteImage.js";
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";

function AccountDashboard() {
  const [
    customer,
    fetchStatus,
    dashboardMessage,
    accountList,
    editCustomer,
    deleteCustomer,
    registerAccountInfo,
    dismissMessage,
    isDeleteEnvironmentDialogVisible,
    environmentMessage,
    environmentStatus,
    error,
  ] = useAccountDashboard();
  useErrorHandler(error);

  return (
    <Content>
      <CustomerAccountInfo>
        <CustomerInfo>
          {customer && (
            <CustomerSection>
              <TitlesContainer>
                <DxcHeading level={2} text={customer.customerName} />
              </TitlesContainer>
              <CustomerActionIcons>
                <EditCustomerActionIcon data-testid="edit" onClick={editCustomer.onOpenClick}>
                  <DxcButton
                    alt="edit"
                    mode="text"
                    onClick={editCustomer.onOpenClick}
                    icon={editImage}
                    margin={{ left: "xxsmall", right: "xxsmall" }}
                    size="small"
                  />
                </EditCustomerActionIcon>
                <DeleteCustomerActionIcon data-testid="delete" onClick={deleteCustomer.onOpenClick}>
                  <DxcButton
                    alt="delete"
                    mode="text"
                    onClick={deleteCustomer.onOpenClick}
                    icon={deleteImage}
                    size="small"
                  />
                </DeleteCustomerActionIcon>
              </CustomerActionIcons>
            </CustomerSection>
          )}

          {editCustomer.isDialogOpen && (
            <EditCustomerDialog
              customerShortName={customer.customerShortName}
              customerStatus={editCustomer.customerStatus}
              customerErrorMessage={editCustomer.customerMessage}
              onEditClick={editCustomer.onSubmitClick}
              onCancelClick={editCustomer.onCancelClick}
            />
          )}

          {deleteCustomer.isDialogOpen && (
            <DeleteCustomerDialog
              customerName={customer.customerName}
              customerStatus={deleteCustomer.customerStatus}
              customerErrorMessage={deleteCustomer.customerMessage}
              onDeleteClick={deleteCustomer.onSubmitClick}
              onCancelClick={deleteCustomer.onCancelClick}
            />
          )}
        </CustomerInfo>

        <Subtitle>{customer?.customerShortName}</Subtitle>

        {dashboardMessage && fetchStatus !== "fetching" ? (
          <DxcAlert
            type={dashboardMessage.type}
            mode="inline"
            inlineText={dashboardMessage.message}
            size={"fillParent"}
            onClose={dismissMessage}
            margin={{ bottom: "small" }}
          />
        ) : null}

        <Accounts
          accountList={accountList}
          fetchStatus={fetchStatus}
          creationStatus={registerAccountInfo.accountStatus}
          creationErrorMessage={registerAccountInfo.accountMessage}
          isCreateAccountDialogVisible={registerAccountInfo.isDialogOpen}
          onRegisterClick={registerAccountInfo.onOpenClick}
          onRegisterConfirmClick={registerAccountInfo.onSubmitClick}
          onRegisterCancelClick={registerAccountInfo.onCancelClick}
        />

        {isDeleteEnvironmentDialogVisible ? (
          <DeleteEnvironment
            errorMessage={environmentMessage}
            status={environmentStatus}
            onCancel={isDeleteEnvironmentDialogVisible.cancelClick}
            onDelete={isDeleteEnvironmentDialogVisible.deleteClick}
            environmentName={isDeleteEnvironmentDialogVisible.enviromentName}
          />
        ) : null}
      </CustomerAccountInfo>

      {fetchStatus === "fetching" ? (
        <DxcSpinner theme="light" label="Loading..." mode="overlay" />
      ) : null}
    </Content>
  );
}

const Content = styled.div``;

const CustomerAccountInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomerInfo = styled.div`
  display: flex;
`;

const CustomerActionIcons = styled.div`
  /**
  Temp change: to be removed after CDK provides a altText with images */
  display: flex;
`;

const EditCustomerActionIcon = styled.div`
  /**
  Temp change: to be removed after CDK provides a altText with images */
`;
const DeleteCustomerActionIcon = styled.div`
  /**
  Temp change: to be removed after CDK provides a altText with images */
`;

const CustomerSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 36px;
`;

const TitlesContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const Subtitle = styled.p`
  font-style: normal;
  font-size: 1.25rem;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0.025em;
  color: inherit;
  margin: 0;
  margin-bottom: 36px;
`;

export default withErrorBoundary(AccountDashboard, { FallbackComponent: ApplicationError });
