import {createRef} from "react";

export const PAGES = {
    ACCOUNTS_DASHBOARD: "accounts",
    DEPLOYMENTS_DASHBOARD: "deployments",
    ENVIRONMENTS_DASHBOARD: "environments",
    DEPLOYMENT_HISTORY: "historical",
};

export const environmentSections = [
  { label: "Information", ref: createRef() },
  { label: "Available deployment packages", ref: createRef() },
];

