import React from "react";
import styled from "styled-components";

//DXC CDK Components
import { DxcButton } from "@dxc-technology/halstack-react";

function DeploymentPackageActions({ message, buttonLabel, onClickButton }) {
  return (
    <DeploymentPackageActionsContainer>
      <Message>{message}</Message>
      <DxcButton mode="secondary" label={buttonLabel} onClick={onClickButton} />
    </DeploymentPackageActionsContainer>
  );
}

const DeploymentPackageActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Message = styled.p``;

export default DeploymentPackageActions;
