import React, { useState } from "react";
import styled from "styled-components";
import { DxcTextInput } from "@dxc-technology/halstack-react";

function DeploymentPackageCondition({ conditionKey, value, assert, onChangeElement }) {
  const [newKey, changeNewKey] = useState(conditionKey);
  const [newValue, changeNewValue] = useState(value);
  const [newAssert, changeNewAssert] = useState(assert);

  const onChangeKey = ({ value }) => {
    changeNewKey(value);
    onChangeElement(value, newValue, newAssert);
  };

  const onChangeValue = ({ value }) => {
    changeNewValue(value);
    onChangeElement(newKey, value, newAssert);
  };

  const onChangeAssert = () => {
    changeNewAssert(!newAssert);
    onChangeElement(newKey, newValue, !assert);
  };

  return (
    <ConditionContainer>
      <DxcTextInput
        label="Key"
        placeholder="e.g.: my-condition-key"
        margin={{ top: "xsmall", bottom: "xsmall", left: "small", right: "small" }}
        size="medium"
        value={newKey}
        onChange={onChangeKey}
      />

      <OperatorContainer onClick={onChangeAssert}>{newAssert ? "=" : "≠"}</OperatorContainer>

      <DxcTextInput
        label="Value"
        placeholder="e.g.: my-condition-value"
        margin={{ top: "xsmall", bottom: "xsmall", left: "small", right: "small" }}
        size="medium"
        value={newValue}
        onChange={onChangeValue}
      />
    </ConditionContainer>
  );
}

const ConditionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ededed48;
  border-radius: 4px;
`;

const OperatorContainer = styled.span`
  border-radius: 50%;
  opacity: 1;
  background-color: #d9d9d9;
  width: 32px;
  height: 32px;
  display: inline-flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 28px;
  :hover {
    cursor: pointer;
  }
`;

export default DeploymentPackageCondition;
