//React hooks imports
import { useState, useMemo, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

//configuation helpers imports
import { config } from "../config";
import {
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  PROMISE_FINAL_STATUSES,
} from "../common/utils";

//DXC HAL
import { useHalResource } from "@dxc-technology/halstack-react-hal";

//helper functions
import {
  buildDeploymentPackageArtefactsPayload,
  buildDeploymentPackageConditionsPayload,
  buildDeploymentPackageDependenciesPayload,
  buildDeploymentPackageParametersPayload,
} from "../deployment-package-definition/DeploymentPackageDefinitionHelper";

const DEPLOY_UNITS_URL = `${config.environments_api_url}/deployment-packages`;

const useDeploymentPackageCreate = () => {
  const [createDeploymentPackageMessage, changeCreateDeploymentPackageMessage] = useState(null);

  //Available Deployment Packages Filter
  const [textFilter, changeTextFilter] = useState("");
  const [deploymentPackageTypeFilter, changeDeploymentPackageTypeFilter] = useState([
    "Infrastructure",
    "External",
    "Integrated",
  ]);
  const [deprecatedFilter, changeDeprecatedFilter] = useState(false);

  //DeploymentPackage Info
  const [deploymentPackageSource, changeDeploymentPackageSource] = useState("");
  const [deploymentPackageName, changeDeploymentPackageName] = useState("");
  const [deploymentPackageType, changeDeploymentPackageType] = useState("");
  const [deploymentPackageParameters, changeDeploymentPackageParameters] = useState([]);
  const [deploymentPackageDependencies, changeDeploymentPackageDependencies] = useState([]);
  const [deploymentPackageConditions, changeDeploymentPackageConditions] = useState([]);
  const [deploymentPackageArtefacts] = useState([]);
  const [error, setError] = useState(null);

  //navigation
  const history = useHistory();

  const [saveDone, disableButton] = useState(false);
  const disableSaveButton = () => {
    disableButton(true);
  };

  /**
   * Add DeploymentPackage param dialog
   */
  const [isAddDeploymentPackageParamVisible, setIsAddDeploymentPackageParamVisible] =
    useState(false);
  const toggleAddDeploymentPackageParamVisible = () => {
    setIsAddDeploymentPackageParamVisible(!isAddDeploymentPackageParamVisible);
  };

  /**
   * Edit DeploymentPackage param dialog
   */
  const [isEditDeploymentPackageParamVisible, setIsEditDeploymentPackageParamVisible] =
    useState(null);
  const toggleEditDeploymentPackageParamVisible = useCallback((deploymentPackageParam) => {
    setIsEditDeploymentPackageParamVisible(deploymentPackageParam);
  }, []);

  /**
   * Add DeploymentPackage condition dialog
   */
  const [isAddDeploymentPackageConditionVisible, setIsAddDeploymentPackageConditionVisible] =
    useState(false);
  const toggleAddDeploymentPackageConditionVisible = () => {
    setIsAddDeploymentPackageConditionVisible(!isAddDeploymentPackageConditionVisible);
  };

  /**
   * Edit DeploymentPackage condition dialog
   */
  const [isEditDeploymentPackageConditionVisible, setIsEditDeploymentPackageConditionVisible] =
    useState(null);
  const toggleEditDeploymentPackageConditionVisible = useCallback((deploymentPackageParam) => {
    setIsEditDeploymentPackageConditionVisible(deploymentPackageParam);
  }, []);

  const [
    deploymentPackagesResource,
    deploymentPackagesStatus,
    deploymentPackagesError,
    deploymentPackagesHandlers,
  ] = useHalResource({
    url: DEPLOY_UNITS_URL,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  //Message operations
  useEffect(() => {
    if (PROMISE_FINAL_STATUSES.includes(deploymentPackagesStatus) && deploymentPackagesError) {
      setError(buildApplicationError(deploymentPackagesError));
    }
  }, [deploymentPackagesError, deploymentPackagesStatus]);

  //HAL resources calculated status
  const createDeploymentPackageStatus = useMemo(() => {
    if (deploymentPackagesStatus === "fetching" || deploymentPackagesStatus === "interaction") {
      return "fetching";
    } else {
      return deploymentPackagesStatus;
    }
  }, [deploymentPackagesStatus]);

  const dismissMessage = () => {
    changeCreateDeploymentPackageMessage(null);
  };

  const onSaveChanges = () => {
    const payload = {
      service_name: deploymentPackageName,
      service_type: deploymentPackageType,
      source: deploymentPackageSource,
      service_parameters: buildDeploymentPackageParametersPayload(deploymentPackageParameters),
      dependsOn: buildDeploymentPackageDependenciesPayload(deploymentPackageDependencies),
      artefacts: buildDeploymentPackageArtefactsPayload(deploymentPackageArtefacts),
      ssm_conditions: buildDeploymentPackageConditionsPayload(deploymentPackageConditions),
    };

    deploymentPackagesHandlers["create"](payload)
      .then((response) => {
        disableSaveButton();
        changeCreateDeploymentPackageMessage({
          type: response.halResource.resourceRepresentation.outcome_report ? "warning" : "confirm",
          message: `Deployment package successfully created in the platform`,
          info: response.halResource.resourceRepresentation.outcome_report
            ? response.halResource.resourceRepresentation.outcome_report.messages.message
            : null,
        });
      })
      .catch((error) => {
        error.body?.messages
          ? changeCreateDeploymentPackageMessage({
              type: "error",
              message: `${error.body?.messages[0].message}`,
            })
          : changeCreateDeploymentPackageMessage({
              type: "error",
              message: "An error ocurred when creating the deployment package",
            });
      });
  };

  //DeploymentPackage Parameters functions
  const addDeploymentPackageParameter = (name, type, isRequired, enumValues) => {
    let _deploymentPackageParameters = [...deploymentPackageParameters];
    !deploymentPackageParameters.find((item) => item.name === name)
      ? (_deploymentPackageParameters = [
          ...deploymentPackageParameters,
          {
            name: name,
            type: type,
            enumValues: enumValues,
            isRequired: isRequired,
          },
        ])
      : changeCreateDeploymentPackageMessage({
          type: "error",
          message: "A deployment package parameter with the same name already exists",
        });
    changeDeploymentPackageParameters([..._deploymentPackageParameters]);
    toggleAddDeploymentPackageParamVisible();
  };

  const editDeploymentPackageParameter = (oldName, name, type, isRequired, enumValues = "") => {
    let index = deploymentPackageParameters.findIndex((item) => item.name === oldName);
    index !== -1 &&
    (oldName === name || !deploymentPackageParameters.find((item) => item.name === name))
      ? (deploymentPackageParameters[index] = {
          name: name,
          type: type,
          enumValues: enumValues,
          isRequired: isRequired,
        })
      : changeCreateDeploymentPackageMessage({
          type: "error",
          message: "A deployment package parameter with the same name already exists",
        });
    changeDeploymentPackageParameters([...deploymentPackageParameters]);
    toggleEditDeploymentPackageParamVisible();
  };

  const removeDeploymentPackageParameter = (name) => {
    changeDeploymentPackageParameters(
      deploymentPackageParameters.filter((item) => item.name !== name)
    );
    toggleEditDeploymentPackageParamVisible();
  };

  const deploymentPackageParametersList = useMemo(() => {
    return deploymentPackageParameters.map((deploymentPackageParam) => ({
      name: deploymentPackageParam.name,
      type: deploymentPackageParam.type,
      enumValues: deploymentPackageParam.enumValues,
      required: deploymentPackageParam.isRequired,
      onEditOpenClick: () => {
        dismissMessage();
        toggleEditDeploymentPackageParamVisible(deploymentPackageParam);
      },
    }));
  }, [deploymentPackageParameters, toggleEditDeploymentPackageParamVisible]);

  //DeploymentPackage Dependencies functions
  const toggleDeploymentPackageDependency = (name, type) => {
    !isSelectedDependency(name)
      ? changeDeploymentPackageDependencies([
          ...deploymentPackageDependencies,
          { name: name, type: type },
        ])
      : changeDeploymentPackageDependencies(
          deploymentPackageDependencies.filter((item) => item.name !== name)
        );
  };

  const deploymentPackageDependenciesList = useMemo(() => {
    return deploymentPackageDependencies.map((deploymentPackageDependency) => ({
      name: deploymentPackageDependency.name,
      type: deploymentPackageDependency.type,
    }));
  }, [deploymentPackageDependencies]);

  const isSelectedDependency = useCallback(
    (name) => {
      return deploymentPackageDependenciesList.some((dependency) => dependency.name === name);
    },
    [deploymentPackageDependenciesList]
  );

  const availableDeploymentPackagesList = useMemo(() => {
    const _deploymentPackagesList =
      (deploymentPackagesResource && deploymentPackagesResource.getItems()) || [];

    return _deploymentPackagesList
      .map((deploymentPackage) => {
        return {
          deploymentPackageName: deploymentPackage.name,
          deploymentPackageType: deploymentPackage.summary.service_type,
          isSelected: isSelectedDependency(
            deploymentPackage.name,
            deploymentPackage.summary.service_type
          ),
          isDeprecated: deploymentPackage.summary.deprecated,
        };
      })
      .filter((deploymentPackage) => {
        const byTypes = deploymentPackageTypeFilter.length
          ? deploymentPackageTypeFilter.includes(deploymentPackage.deploymentPackageType)
            ? deploymentPackage
            : null
          : deploymentPackage;
        const byDeprecated = !deprecatedFilter
          ? (deploymentPackage.isDeprecated &&
              deploymentPackage.isDeprecated === deprecatedFilter) ||
            (!deploymentPackage.isDeprecated && !deprecatedFilter)
            ? deploymentPackage
            : null
          : deploymentPackage;
        const byTextFilter = textFilter
          ? deploymentPackage.deploymentPackageName.includes(textFilter)
            ? deploymentPackage
            : null
          : deploymentPackage;
        return byTypes ? (byDeprecated && byTextFilter ? deploymentPackage : null) : null;
      });
  }, [
    isSelectedDependency,
    deploymentPackageTypeFilter,
    deploymentPackagesResource,
    deprecatedFilter,
    textFilter,
  ]);

  //DeploymentPackage Conditions functions
  const addDeploymentPackageCondition = (newDeploymentPackageConditions) => {
    changeDeploymentPackageConditions(
      deploymentPackageConditions.concat(
        newDeploymentPackageConditions.filter(
          (item, index, _self) =>
            _self.findIndex((_selfItem) => _selfItem.conditionKey === item.conditionKey) ===
              index &&
            item.conditionKey !== "" &&
            item.value !== ""
        )
      )
    );
    toggleAddDeploymentPackageConditionVisible();
  };

  const editDeploymentPackageCondition = (condition, indexCondition) => {
    deploymentPackageConditions[indexCondition] = {
      conditionKey: condition.conditionKey,
      value: condition.value,
      assert: condition.assert,
    };

    changeDeploymentPackageConditions([...deploymentPackageConditions]);
    toggleEditDeploymentPackageConditionVisible();
  };

  const removeDeploymentPackageCondition = useCallback(
    (deploymentPackageConditionIndex) => {
      changeDeploymentPackageConditions(
        deploymentPackageConditions.filter((item, indexFilter) => {
          return deploymentPackageConditionIndex !== indexFilter;
        })
      );

      toggleEditDeploymentPackageParamVisible();
    },
    [deploymentPackageConditions, toggleEditDeploymentPackageParamVisible]
  );

  const deploymentPackageConditionsList = useMemo(() => {
    return deploymentPackageConditions.map((deploymentPackageCondition, index) => ({
      conditionKey: deploymentPackageCondition.conditionKey,
      value: deploymentPackageCondition.value,
      assert: deploymentPackageCondition.assert,
      onEditOpenClick: () => {
        dismissMessage();
        toggleEditDeploymentPackageConditionVisible({
          ...deploymentPackageCondition,
          indexCondition: index,
        });
      },
      onRemoveClick: () => removeDeploymentPackageCondition(index),
    }));
  }, [
    removeDeploymentPackageCondition,
    deploymentPackageConditions,
    toggleEditDeploymentPackageConditionVisible,
  ]);

  const deploymentPackageArtefactsList = useMemo(() => {
    return deploymentPackageArtefacts.map((deploymentPackageArtefact) => ({
      name: deploymentPackageArtefact.name,
      variable: deploymentPackageArtefact.variable,
    }));
  }, [deploymentPackageArtefacts]);

  //grouped deploymentPackage main data info
  const deploymentPackageDataInfo = {
    deploymentPackageSource,
    deploymentPackageName,
    deploymentPackageType,
    onChangeDeploymentPackageSource: (newDeploymentPackageSource) =>
      changeDeploymentPackageSource(newDeploymentPackageSource),
    onChangeDeploymentPackageName: (newDeploymentPackageName) =>
      changeDeploymentPackageName(newDeploymentPackageName),
    onChangeDeploymentPackageType: (newDeploymentPackageType) =>
      changeDeploymentPackageType(newDeploymentPackageType),
  };

  //grouped add DeploymentPackage parameter dialog state
  const deploymentPackageParametersInfo = {
    deploymentPackageParametersList: deploymentPackageParametersList,
    isAddDialogOpen: isAddDeploymentPackageParamVisible,
    isEditDialogOpen: isEditDeploymentPackageParamVisible,
    onAddOpenClick: () => {
      dismissMessage();
      toggleAddDeploymentPackageParamVisible();
    },
    onAddCancelClick: toggleAddDeploymentPackageParamVisible,
    onAddSubmitClick: addDeploymentPackageParameter,
    onEditCancelClick: toggleEditDeploymentPackageParamVisible,
    onEditSubmitClick: editDeploymentPackageParameter,
    onEditRemoveClick: removeDeploymentPackageParameter,
  };

  const deploymentPackageConditionsInfo = {
    deploymentPackageConditionsList: deploymentPackageConditionsList,
    isAddDialogOpen: isAddDeploymentPackageConditionVisible,
    isEditDialogOpen: isEditDeploymentPackageConditionVisible,
    onAddOpenClick: () => {
      dismissMessage();
      toggleAddDeploymentPackageConditionVisible();
    },
    onAddCancelClick: toggleAddDeploymentPackageConditionVisible,
    onAddSubmitClick: addDeploymentPackageCondition,
    onEditCancelClick: toggleEditDeploymentPackageConditionVisible,
    onEditSubmitClick: editDeploymentPackageCondition,
  };

  //grouped add DeploymentPackage parameter dialog state
  const availableDeploymentPackagesInfo = {
    deploymentPackageDependenciesList: deploymentPackageDependenciesList,
    availableDeploymentPackagesList: availableDeploymentPackagesList,
    textFilter: textFilter,
    deploymentPackageTypeFilter: deploymentPackageTypeFilter,
    deprecatedFilter: deprecatedFilter,
    onChangeTextFilter: changeTextFilter,
    onChangeDeploymentPackageTypeFilter: changeDeploymentPackageTypeFilter,
    onChangeDeprecatedFilter: changeDeprecatedFilter,
    onClickSelected: toggleDeploymentPackageDependency,
  };

  const deploymentPackageArtefactsInfo = {
    deploymentPackageArtefactsList: deploymentPackageArtefactsList,
  };

  //navigate function
  const navigateDeploymentPackages = () => {
    history.push(`/deployment-packages`);
  };

  return [
    createDeploymentPackageStatus,
    createDeploymentPackageMessage,
    deploymentPackageDataInfo,
    deploymentPackageParametersInfo,
    deploymentPackageConditionsInfo,
    availableDeploymentPackagesInfo,
    deploymentPackageArtefactsInfo,
    dismissMessage,
    onSaveChanges,
    navigateDeploymentPackages,
    saveDone,
    error,
  ];
};

export default useDeploymentPackageCreate;
