import React, { useState } from "react";
import styled from "styled-components";
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcButton,
  DxcDialog,
  DxcTextInput,
  DxcParagraph,
  DxcFlex,
  DxcInset,
  DxcTypography,
} from "@dxc-technology/halstack-react";

function DeleteCustomerDialog({
  customerName,
  customerStatus,
  customerErrorMessage,
  onDeleteClick,
  onCancelClick,
}) {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const nameHandler = ({ value, error }) => {
    setName(value);
    setNameError(error);
  };

  return (
    <DxcDialog isCloseVisible onCloseClick={() => onCancelClick()}>
      <DialogHeader>
        <DxcHeading level={2} text={`Delete customer `} margin={{ bottom: "xsmall" }} />
        {customerStatus === "interaction" && (
          <DxcSpinner
            role="progressbar"
            theme="light"
            mode="small"
            margin={{ left: "xsmall", bottom: "xsmall" }}
            label="Loading..."
          />
        )}
      </DialogHeader>

      {customerErrorMessage && (
        <DxcAlert type={"error"} mode="inline" size="fillParent" margin={{ bottom: "small" }}>
          <DxcParagraph>{customerErrorMessage}</DxcParagraph>
        </DxcAlert>
      )}

      <DxcFlex direction="column">
        <DxcParagraph>
          The customer{" "}
          <DxcTypography as="span" fontWeight="600" display="inline">
            {customerName}
          </DxcTypography>{" "}
          is going to be deleted, please type the name of the customer to confirm.
        </DxcParagraph>
        <DxcTextInput
          label="Customer name"
          id="customerName"
          placeholder={customerName}
          name="customerName"
          margin={{ top: "xsmall" }}
          size="fillParent"
          value={name}
          onChange={nameHandler}
          onBlur={nameHandler}
          clearable={true}
          optional={false}
          error={nameError == null ? "" : nameError}
          pattern="^[a-z0-9-]+$"
          helperText="Only lowercase alphanumeric and hyphens accepted"
        />
      </DxcFlex>

      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={() => onCancelClick()}
            mode="text"
          ></DxcButton>
          <DxcButton
            label="Delete"
            onClick={() => onDeleteClick(name)}
            disabled={
              name !== customerName || customerStatus === "interaction" || nameError != null
            }
            mode="primary"
          ></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default DeleteCustomerDialog;
