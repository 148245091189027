import React, { useEffect, useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcAccordion,
  DxcButton,
  DxcTextInput,
  DxcSelect,
  DxcTable,
} from "@dxc-technology/halstack-react";

import { formatSelectOptions } from "./helper/DeployDeploymentPackageHelper";
//Images
import { addIcon } from "./images/addIcon";
import { deleteIcon } from "./images/deleteIcon";

//Custom components
import SectionContainer from "../components/SectionContainer";
import NonEditableField from "../components/NonEditableField";

function DeploymentPackageParameters({
  deploymentPackageParametersList,
  expertParametersList,
  onAddExpertParamClick,
}) {
  const addExpertParamHandler = () => {
    onAddExpertParamClick();
  };

  const [isAccordionOpen, changeisAccordionOpen] = useState(false);
  const toggleAccordionOpen = (newValue) => {
    changeisAccordionOpen(newValue);
  };

  useEffect(() => {
    if (expertParametersList?.length > 0) {
      changeisAccordionOpen(true);
    }
  }, [expertParametersList?.length]);

  return (
    <MainContainer>
      <SectionContainer
        title="Deployment package parameters"
        padding={{
          top: "none",
          bottom: "small",
          left: "small",
          right: "small",
        }}
      >
        {deploymentPackageParametersList.length ? (
          <DxcTable margin={{ top: "small", bottom: "medium" }}>
            <tbody>
              {deploymentPackageParametersList.map((deploymentPackageParameter, index) => (
                <tr key={`parameter_row_${index}`}>
                  <LabelCell>
                    <NonEditableField
                      label="Key"
                      value={deploymentPackageParameter.name}
                      margin={{
                        top: "xxsmall",
                        bottom: "xsmall",
                        left: "xlarge",
                        right: "medium",
                      }}
                    />
                  </LabelCell>
                  <ValueCell>
                    {deploymentPackageParameter.type === "enum" ? (
                      <DxcSelect
                        options={formatSelectOptions(deploymentPackageParameter.enumValues)}
                        onChange={({ value }) =>
                          deploymentPackageParameter.onChangeDeploymentPackageParameter(value)
                        }
                        label="Value"
                        size="fillParent"
                        value={deploymentPackageParameter.value}
                        margin={{
                          top: "xxsmall",
                          bottom: "xsmall",
                          left: "medium",
                          right: "xlarge",
                        }}
                      ></DxcSelect>
                    ) : (
                      <DxcTextInput
                        placeholder="e.g.: my-parameter-value"
                        label="Value"
                        margin={{
                          top: "xxsmall",
                          bottom: "xsmall",
                          left: "medium",
                          right: "xlarge",
                        }}
                        size="fillParent"
                        value={deploymentPackageParameter.value}
                        onChange={({ value }) =>
                          deploymentPackageParameter.onChangeDeploymentPackageParameter(value)
                        }
                        onBlur={({ value }) =>
                          deploymentPackageParameter.onChangeDeploymentPackageParameter(value)
                        }
                        clearable={true}
                      />
                    )}
                  </ValueCell>
                </tr>
              ))}
            </tbody>
          </DxcTable>
        ) : (
          <ParametersEmpty>
            <EmptyText>There are no parameters</EmptyText>
          </ParametersEmpty>
        )}

        <DxcAccordion
          label="Expert mode"
          margin={{ top: "small" }}
          padding="small"
          isExpanded={isAccordionOpen}
          onChange={toggleAccordionOpen}
        >
          <ExpertParamsHeader>
            <ExpertParamsHeaderTitle>Parameters</ExpertParamsHeaderTitle>
            <DxcButton mode="text" label="Add" icon={addIcon} onClick={addExpertParamHandler} />
          </ExpertParamsHeader>
          {expertParametersList.length ? (
            <DxcTable>
              <tbody>
                {expertParametersList.map((expertParameter, index) => (
                  <tr key={`expert_parameter_row_${index}`}>
                    <Cell>
                      <DxcTextInput
                        label="Key"
                        placeholder="e.g.: my-parameter-key"
                        margin={{
                          top: "xxsmall",
                          bottom: "xsmall",
                          left: "xlarge",
                          right: "medium",
                        }}
                        size="large"
                        value={expertParameter.name}
                        onChange={({ value }) => expertParameter.onChangeName(value)}
                        onBlur={({ value }) => expertParameter.onChangeName(value)}
                        clearable={true}
                      />
                    </Cell>
                    <Cell>
                      <DxcTextInput
                        label="Value"
                        placeholder="e.g.: my-parameter-value"
                        margin={{
                          top: "xxsmall",
                          bottom: "xsmall",
                          left: "medium",
                          right: "small",
                        }}
                        size="large"
                        value={expertParameter.value}
                        onChange={({ value }) => expertParameter.onChangeValue(value)}
                        onBlur={({ value }) => expertParameter.onChangeValue(value)}
                        clearable={true}
                      />
                    </Cell>
                    <Cell>
                      <DxcButton
                        mode="text"
                        icon={deleteIcon}
                        size="small"
                        margin={{ left: "xxsmall" }}
                        onClick={expertParameter.onClickRemove}
                      />
                    </Cell>
                  </tr>
                ))}
              </tbody>
            </DxcTable>
          ) : (
            <ParametersEmpty>
              <EmptyText>There are no expert mode parameters</EmptyText>
            </ParametersEmpty>
          )}
        </DxcAccordion>
      </SectionContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ExpertParamsHeader = styled.div`
  background-color: #ededed48;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 48px;
`;

const ExpertParamsHeaderTitle = styled.span`
  display: flex;
  align-self: center;
  font-weight: 600;
  margin-left: 24px;
`;

const Cell = styled.td`
  padding: 0px !important;
`;

const LabelCell = styled(Cell)`
  max-width: 25%;
`;

const ValueCell = styled(Cell)`
  min-width: 75%;
`;

const ParametersEmpty = styled.div`
  background-color: #eeeeee;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
`;

const EmptyText = styled.p`
  text-align: center;
  color: #666;
  letter-spacing: 0.21px;
`;

export default DeploymentPackageParameters;
