

export const AWSRegionList = [
    {
        label: "",
        value: ""
    },
    {
        label: "US East (Ohio)",
        value: "us-east-2"
    },
    {
        label: "US East (N. Virginia)",
        value: "us-east-1"
    },
    {
        label: "US West (N. California)",
        value: "us-west-1"
    },
    {
        label: "US West (Oregon)",
        value: "us-west-2"
    },
    {
        label: "Asia Pacific (Hong Kong)",
        value: "ap-east-1"
    },
    {
        label: "Asia Pacific (Mumbai)",
        value: "ap-south-1"
    },
    {
        label: "Asia Pacific (Seoul)",
        value: "ap-northeast-2"
    },
    {
        label: "Asia Pacific (Singapore)",
        value: "ap-southeast-1"
    },
    {
        label: "Asia Pacific (Sydney)",
        value: "ap-southeast-2"
    },
    {
        label: "Asia Pacific (Tokyo)",
        value: "ap-northeast-1"
    },
    {
        label: "Canada (Central)",
        value: "ca-central-1"
    },
    {
        label: "China (Beijing)",
        value: "cn-north-1"
    },
    {
        label: "China (Ningxia)",
        value: "cn-northwest-1"
    },
    {
        label: "EU (Frankfurt)",
        value: "eu-central-1"
    },
    {
        label: "EU (Ireland)",
        value: "eu-west-1"
    },
    {
        label: "EU (London)",
        value: "eu-west-2"
    },
    {
        label: "EU (Paris)",
        value: "eu-west-3"
    },
    {
        label: "EU (Stockholm)",
        value: "eu-north-1"
    },
    {
        label: "Middle East (Bahrain)",
        value: "me-south-1"
    },
    {
        label: "South America (Sao Paulo)",
        value: "sa-east-1"
    },
    {
        label: "AWS GovCloud (US-East)",
        value: "us-gov-east-1"
    },
    {
        label: "AWS GovCloud (US-West)",
        value: "us-gov-west-1"
    }
];

//clone environment dialog state management
export const cloneInitialState = { cloneStatus: "idle" };

export const cloneStateReducer = (state, action) => {
  switch (action.type) {  
    case "loadAccounts":
      return {
        ...state,
        ...{
          cloneStatus: "completed",
          accountsList: buildAccountsList(action.accountCollectionResource),
        },
      };    
    case "accountsError":
      return {
        ...state,
        ...{
          cloneStatus: "accountError",
          cloneMessage: action.message,
          accountsList: [],
        },
      };   
    default:
      throw new Error("Action not supported");
  }
};


const buildAccountsList = (accountsResource) => {
  const itemsList = (accountsResource && accountsResource.getItems()) || [];

  return itemsList.map((item) => {
    return {
      resourceName: item.summary.resource_name,
      accountTitle: item.summary.account_title,
    };
  });
};