import React, { useEffect, useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import { DxcTextInput, DxcButton, DxcAccordion, DxcTable } from "@dxc-technology/halstack-react";

//images
import { addIcon } from "./images/addIcon";
import { deleteIcon } from "./images/deleteIcon";

//Custom Components
import SectionContainer from "../components/SectionContainer";

function DeploymentPackageArtefacts({
  deploymentPackageArtefactsList,
  onAddArtefactClick,
  onRemoveArtefactClick,
  readOnly,
}) {
  const addArtefactHandler = () => {
    onAddArtefactClick();
    changeisAccordionOpen((oldIsAccordionOpen) => {
      oldIsAccordionOpen.push(true);
      return [...oldIsAccordionOpen];
    });
  };

  const removeArtefactHandler = (artefactIndex) => {
    onRemoveArtefactClick(artefactIndex);
    changeisAccordionOpen((oldIsAccordionOpen) => {
      oldIsAccordionOpen.splice(artefactIndex, 1);
      return [...oldIsAccordionOpen];
    });
  };

  const [isAccordionOpen, changeisAccordionOpen] = useState([]);
  const toggleAccordionOpen = (value, index) => {
    changeisAccordionOpen((oldIsAccordionOpen) => {
      oldIsAccordionOpen[index] = value;
      return [...oldIsAccordionOpen];
    });
  };

  //only to be executed when page loads
  useEffect(() => {
    if (deploymentPackageArtefactsList?.length > 0) {
      changeisAccordionOpen(
        deploymentPackageArtefactsList.map((_, index) => (index === 0 ? true : false))
      );
    }
  }, []);

  return (
    <MainContainer>
      <SectionContainer
        title="Deployment package artefacts"
        actionLabel={readOnly === false ? "Add artefact" : null}
        actionOnClick={readOnly === false ? addArtefactHandler : null}
        actionIcon={readOnly === false ? addIcon : null}
        padding={{ top: "none", bottom: "small", left: "small", right: "small" }}
      >
        {deploymentPackageArtefactsList.length ? (
          deploymentPackageArtefactsList.map((artefact, artefactIndex) => (
            <DxcAccordion
              key={`artefact_accordeon_${artefactIndex}`}
              label={`Artefact (${artefactIndex})`}
              margin={{ top: "small" }}
              padding="small"
              isExpanded={isAccordionOpen[artefactIndex]}
              onChange={(value) => toggleAccordionOpen(value, artefactIndex)}
            >
              <ArtefactsHeader>
                <ArtefactsHeaderTitle>Artefact properties</ArtefactsHeaderTitle>
                {readOnly === false ? (
                  <ArtefactHeaderActions>
                    <DxcButton
                      mode="text"
                      label="Delete artefact"
                      icon={deleteIcon}
                      onClick={() => removeArtefactHandler(artefactIndex)}
                      margin={{ right: "xsmall" }}
                    />
                    <DxcButton
                      mode="text"
                      label="Add property"
                      icon={addIcon}
                      onClick={() => artefact.onClickAddProperty(artefactIndex)}
                    />
                  </ArtefactHeaderActions>
                ) : null}
              </ArtefactsHeader>
              {artefact.properties.length ? (
                <DxcTable>
                  <tbody>
                    {artefact.properties.map((property, index) =>
                      readOnly === false ? (
                        <tr key={`artefact_prop_row_${index}`}>
                          <Cell>
                            <DxcTextInput
                              label="Key"
                              placeholder="e.g.: my-property-key"
                              margin={{
                                top: "xxsmall",
                                bottom: "xsmall",
                                left: "small",
                                right: "medium",
                              }}
                              size="large"
                              value={property.name}
                              onChange={
                                property.onChangeName
                                  ? ({ value }) =>
                                      property.onChangeName(value, index, artefactIndex)
                                  : null
                              }
                              onBlur={
                                property.onChangeName
                                  ? ({ value }) =>
                                      property.onChangeName(value, index, artefactIndex)
                                  : null
                              }
                              clearable={true}
                            />
                          </Cell>
                          <Cell>
                            <DxcTextInput
                              label="Value"
                              placeholder="e.g.: my-property-value"
                              margin={{
                                top: "xxsmall",
                                bottom: "xsmall",
                                left: "medium",
                                right: "small",
                              }}
                              size="large"
                              value={property.value}
                              onChange={({ value }) =>
                                property.onChangeValue(value, index, artefactIndex)
                              }
                              onBlur={({ value }) =>
                                property.onChangeValue(value, index, artefactIndex)
                              }
                              clearable={true}
                            />
                          </Cell>
                          <Cell>
                            <DxcButton
                              mode="text"
                              icon={deleteIcon}
                              size="small"
                              margin={{ left: "xxsmall" }}
                              onClick={() => property.onClickRemove(index, artefactIndex)}
                            />
                          </Cell>
                        </tr>
                      ) : (
                        <tr key={`artefact_prop_readonly_row_${index}`}>
                          <ReadOnlyCell>
                            <KeyEntry>{property.name}</KeyEntry>
                            <ValueEntry>{property.value}</ValueEntry>
                          </ReadOnlyCell>
                        </tr>
                      )
                    )}
                  </tbody>
                </DxcTable>
              ) : null}
            </DxcAccordion>
          ))
        ) : (
          <ParametersEmpty>
            <EmptyText>There are no artefacts</EmptyText>
          </ParametersEmpty>
        )}
      </SectionContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 46px;
  padding-right: 36px;
`;

const ArtefactsHeader = styled.div`
  background-color: #ededed48;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 48px;
`;
const ArtefactHeaderActions = styled.div``;

const ArtefactsHeaderTitle = styled.span`
  display: flex;
  align-self: center;
  font-weight: 600;
  margin-left: 24px;
`;

const Cell = styled.td`
  padding: 0px !important;
`;

const ReadOnlyCell = styled(Cell)`
  display: flex;
  flex-direction: row;
`;
const KeyEntry = styled.div`
  display: flex;
  align-items: center;
  min-width: 25%;
  margin-left: 24px;
`;

const ValueEntry = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: 24px;
`;

const ParametersEmpty = styled.div`
  background-color: #eeeeee;
  border-radius: 4px;
  height: 52px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const EmptyText = styled.p`
  text-align: center;
  color: #666;
  letter-spacing: 0.21px;
`;

export default DeploymentPackageArtefacts;
