//React imports
import React, { useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcButton,
  DxcDialog,
  DxcTextInput,
  DxcParagraph,
  DxcFlex,
  DxcInset,
  DxcTypography,
} from "@dxc-technology/halstack-react";

function DeleteEnvironmentType({ errorMessage, isLoading, onCancel, onDelete, environmentName }) {
  const [environmentType, changeEnvironmentType] = useState("");
  const [environmentTypeError, setEnvironmentTypeError] = useState();

  const environmentTypeHandler = ({ value, error }) => {
    changeEnvironmentType(value);
    setEnvironmentTypeError(error);
  };

  return (
    <DxcDialog isCloseVisible onCloseClick={onCancel}>
      <DialogHeader>
        <DxcHeading level={2} text={`Delete environment type`} margin={{ bottom: "xsmall" }} />
        {isLoading && (
          <DxcSpinner
            theme="light"
            mode="small"
            margin={{ left: "xsmall", bottom: "xsmall" }}
            role="progressbar"
          />
        )}
      </DialogHeader>

      {errorMessage && (
        <DxcAlert type="error" mode="inline" size="fillParent" margin={{ bottom: "small" }}>
          <DxcParagraph>{errorMessage}</DxcParagraph>
        </DxcAlert>
      )}

      <DxcFlex direction="column">
        <DxcParagraph>
          The environment type{" "}
          <DxcTypography as="span" fontWeight="600" display="inline">
            {environmentName}
          </DxcTypography>{" "}
          is going to be deleted, please type the name of the environment type to confirm.
        </DxcParagraph>

        <DxcTextInput
          label="Environment type"
          margin={{ top: "xsmall" }}
          size="fillParent"
          value={environmentType}
          placeholder="e.g.: my-environment-type"
          onChange={environmentTypeHandler}
          onBlur={environmentTypeHandler}
          clearable={true}
          optional={false}
          error={environmentTypeError == null ? "" : environmentTypeError}
          pattern="^[a-z0-9-]+$"
          helperText="Only lowercase alphanumeric and hyphens accepted"
        />
      </DxcFlex>
      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={onCancel || isLoading}
            mode="text"
          ></DxcButton>
          <DxcButton
            label="Delete"
            onClick={onDelete}
            disabled={environmentName !== environmentType || isLoading || environmentTypeError}
            mode="primary"
          ></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default DeleteEnvironmentType;
