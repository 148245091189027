import { useState, useMemo, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

//urls
import { config } from "../config";
import {
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  PROMISE_FINAL_STATUSES,
} from "../common/utils";

import { useHalResource } from "@dxc-technology/halstack-react-hal";

const DEPLOYMENT_PACKAGES_URL = `${config.environments_api_url}/deployment-packages`;

const useDeploymentPackagesDashboard = () => {
  const [textFilter, changeTextFilter] = useState(null);
  const [infrastructureCheckbox, changeInfrastructureCheckbox] = useState(true);
  const [integratedCheckbox, changeIntegratedCheckbox] = useState(true);
  const [externalCheckbox, changeExternalCheckbox] = useState(true);
  const [deprecatedCheckbox, changeDeprecatedCheckbox] = useState(false);
  const [deploymentPackagesMessage, changeDeploymentPackageMessage] = useState(null);
  const [error, setError] = useState(null);

  const history = useHistory();
  const location = useLocation();

  const [deploymentPackagesResource, deploymentPackagesStatus, deploymentPackagesError] =
    useHalResource({
      url: DEPLOYMENT_PACKAGES_URL,
      asyncHeadersHandler: getSessionHeaders,
      headers: getPlatformHeaders(),
    });

  const isLoading = useMemo(
    () => deploymentPackagesStatus === "fetching",
    [deploymentPackagesStatus]
  );

  const typesValue = useMemo(
    () =>
      [integratedCheckbox, externalCheckbox, infrastructureCheckbox].reduce(
        (acc, value, i) => (value ? acc.concat([i + 1]) : acc),
        []
      ),
    [integratedCheckbox, externalCheckbox, infrastructureCheckbox]
  );

  useEffect(() => {
    if (PROMISE_FINAL_STATUSES.includes(deploymentPackagesStatus) && deploymentPackagesError) {
      setError(buildApplicationError(deploymentPackagesError));
    }
  }, [deploymentPackagesError, deploymentPackagesStatus]);

  const deploymentPackages = useMemo(() => {
    const deploymentPackagesList =
      (deploymentPackagesResource && deploymentPackagesResource.getItems()) || [];
    return (deploymentPackagesList || [])
      .filter((serv) => {
        let byInfrastructure = null;
        let byExternal = null;
        let byIntegrated = null;

        if (infrastructureCheckbox) {
          byInfrastructure = infrastructureCheckbox
            ? serv.summary.service_type === "Infrastructure"
              ? serv
              : null
            : serv;
        }
        if (integratedCheckbox) {
          byIntegrated = integratedCheckbox
            ? serv.summary.service_type === "Integrated"
              ? serv
              : null
            : serv;
        }
        if (externalCheckbox) {
          byExternal = externalCheckbox
            ? serv.summary.service_type === "External"
              ? serv
              : null
            : serv;
        }
        const byDeprecated = !deprecatedCheckbox
          ? (serv.summary.deprecated && serv.summary.deprecated === deprecatedCheckbox) ||
            (!serv.summary.deprecated && !deprecatedCheckbox)
            ? serv
            : null
          : serv;
        const byTextFilter = textFilter
          ? serv.summary.service_name.includes(textFilter) ||
            serv.summary.source.includes(textFilter)
            ? serv
            : null
          : serv;

        if (infrastructureCheckbox || integratedCheckbox || externalCheckbox)
          return byInfrastructure || byExternal || byIntegrated
            ? byDeprecated && byTextFilter
              ? serv
              : null
            : null;
        else return byDeprecated && byTextFilter ? serv : null;
      })
      .map((deploymentPackage, index) => ({
        name: deploymentPackage.summary.service_name,
        source: deploymentPackage.summary.source,
        type: deploymentPackage.summary.service_type,
        deprecated: deploymentPackage.summary.deprecated,
        onEditClick: () => {
          history.push(`/deployment-packages/${deploymentPackage.summary.service_name}`);
        },
        onSeeDeployedEnvironmentsClick: () => {
          history.push(
            `/deployment-packages/${deploymentPackage.summary.service_name}/deployments`
          );
        },
      }));
  }, [
    deploymentPackagesResource,
    infrastructureCheckbox,
    integratedCheckbox,
    externalCheckbox,
    deprecatedCheckbox,
    textFilter,
    history,
  ]);

  const onChangeFilterText = (newValue) => {
    changeTextFilter(newValue);
  };

  const onChangeFilterType = (newValues) => {
    changeIntegratedCheckbox(newValues.includes(1));
    changeExternalCheckbox(newValues.includes(2));
    changeInfrastructureCheckbox(newValues.includes(3));
  };

  const onChangeDeprecated = (newValue) => {
    changeDeprecatedCheckbox(newValue);
  };

  const dismissMessage = () => {
    changeDeploymentPackageMessage(null);
  };

  const onCreateNewDeploymentPackage = () => {
    history.push(`/deployment-packages/new`);
  };

  //Message operations
  useEffect(() => {
    if (location?.state?.service_name)
      changeDeploymentPackageMessage({
        type: "confirm",
        message: "The deployment package was succesfully created",
      });
    changeTextFilter(location?.state?.service_name);
  }, [location]);

  return [
    isLoading,
    deploymentPackagesMessage,
    deploymentPackages,
    textFilter,
    onChangeFilterText,
    typesValue,
    deprecatedCheckbox,
    onChangeFilterType,
    onChangeDeprecated,
    dismissMessage,
    onCreateNewDeploymentPackage,
    error,
  ];
};

export default useDeploymentPackagesDashboard;
