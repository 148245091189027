//React imports
import React, { useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcButton,
  DxcDialog,
  DxcTextInput,
  DxcParagraph,
  DxcFlex,
  DxcTypography,
  DxcInset,
} from "@dxc-technology/halstack-react";

function DestroyService({ errorMessage, status, onCancel, onDestroy }) {
  const [action, changeAction] = useState("");

  return (
    <DxcDialog isCloseVisible onCloseClick={onCancel}>
      <DialogHeader>
        <DxcHeading level={2} text={`Destroy service`} margin={{ bottom: "xsmall" }} />
        {status === "interaction" && (
          <DxcSpinner theme="light" mode="small" margin={{ left: "xsmall", bottom: "xsmall" }} />
        )}
      </DialogHeader>

      {errorMessage && (
        <DxcAlert type="error" mode="inline" size="fillParent" margin={{ bottom: "small" }}>
          <DxcParagraph>{errorMessage}</DxcParagraph>
        </DxcAlert>
      )}

      <DxcFlex direction="column">
        <DxcParagraph>
          You are going to destroy the service deployments, please type{" "}
          <DxcTypography as="span" fontWeight="600" display="inline">
            destroy
          </DxcTypography>{" "}
          to confirm.
        </DxcParagraph>

        <DxcTextInput
          label="Action"
          margin={{ top: "xsmall", bottom: "xxsmall" }}
          size="fillParent"
          value={action}
          onChange={({ value }) => changeAction(value)}
          disable={status === "interaction"}
          placeholder="destroy"
          clearable={true}
        />
      </DxcFlex>
      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={onCancel || status === "interaction"}
            mode="text"
          />
          <DxcButton
            label={"Destroy"}
            onClick={onDestroy}
            disabled={action !== "destroy" || status === "interaction"}
            mode="primary"
          />
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default DestroyService;
