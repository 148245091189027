//React Imports
import { useState, useMemo, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

//configuration helpers
import { config } from "../config";
import {
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  PROMISE_FINAL_STATUSES,
} from "../common/utils";

//DXC HAL
import { useHalResource } from "@dxc-technology/halstack-react-hal";

//helper functions
import {
  buildDeploymentPackageArtefactsPayload,
  buildDeploymentPackageConditionsPayload,
  buildDeploymentPackageDependenciesPayload,
  buildDeploymentPackageParametersPayload,
} from "../deployment-package-definition/DeploymentPackageDefinitionHelper";
import { HalApiCaller } from "@dxc-technology/halstack-client";

const DEPLOY_UNITS_URL = `${config.environments_api_url}/deployment-packages`;

const useDeploymentPackageEdit = () => {
  const [error, setError] = useState(null);
  const [deploymentPackageMessage, changeDeploymentPackageMessage] = useState(null);

  //Available DeploymentPackages Filter
  const [textFilter, changeTextFilter] = useState("");
  const [deploymentPackageTypeFilter, changeDeploymentPackageTypeFilter] = useState([
    "Infrastructure",
    "External",
    "Integrated",
  ]);
  const [deprecatedFilter, changeDeprecatedFilter] = useState(true);

  //DeploymentPackage Info
  const [deploymentPackageSource, changeDeploymentPackageSource] = useState(null);
  const [deploymentPackageName, changeDeploymentPackageName] = useState(null);
  const [deploymentPackageType, changeDeploymentPackageType] = useState("");
  const [deploymentPackageParameters, changeDeploymentPackageParameters] = useState([]);
  const [deploymentPackageDependencies, changeDeploymentPackageDependencies] = useState([]);
  const [deploymentPackageConditions, changeDeploymentPackageConditions] = useState([]);
  const [deploymentPackageArtefacts, changeDeploymentPackageArtefacts] = useState([]);
  const [deploymentPackageIsDeprecated, changeDeploymentPackageIsDeprecated] = useState(false);

  const [artefactGitHub, changeArtefactGitHub] = useState(false);

  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState(null);
  const [isDeleteLoading, changeIsDeleteLoading] = useState(false);
  const [deleteErrorMessage, changeDeleteErrorMessage] = useState(null);
  const [isDeprecateLoading, changeIsDeprecateLoading] = useState(false);

  //navigation
  const history = useHistory();
  const { deploymentPackageId } = useParams();

  //target URL to edit
  const deploymentPackageUrl = `${DEPLOY_UNITS_URL}/${deploymentPackageId}`;

  /**
   * Add DeploymentPackage param dialog
   */
  const [isAddDeploymentPackageParamVisible, setIsAddDeploymentPackageParamVisible] =
    useState(false);
  const toggleAddDeploymentPackageParamVisible = () => {
    setIsAddDeploymentPackageParamVisible(!isAddDeploymentPackageParamVisible);
  };

  /**
   * Edit DeploymentPackage param dialog
   */
  const [isEditDeploymentPackageParamVisible, setIsEditDeploymentPackageParamVisible] =
    useState(null);
  const toggleEditDeploymentPackageParamVisible = (deploymentPackageParam) => {
    setIsEditDeploymentPackageParamVisible(deploymentPackageParam);
  };

  /**
   * Add DeploymentPackage condition dialog
   */
  const [isAddDeploymentPackageConditionVisible, setIsAddDeploymentPackageConditionVisible] =
    useState(false);
  const toggleAddDeploymentPackageConditionVisible = () => {
    setIsAddDeploymentPackageConditionVisible(!isAddDeploymentPackageConditionVisible);
  };

  /**
   * Edit DeploymentPackage condition dialog
   */
  const [isEditDeploymentPackageConditionVisible, setIsEditDeploymentPackageConditionVisible] =
    useState(null);
  const toggleEditDeploymentPackageConditionVisible = (deploymentPackageParam) => {
    setIsEditDeploymentPackageConditionVisible(deploymentPackageParam);
  };

  const [
    deploymentPackageResource,
    deploymentPackageStatus,
    deploymentPackageError,
    deploymentPackageHandlers,
  ] = useHalResource({
    url: deploymentPackageUrl,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const [deploymentPackagesResource, deploymentPackagesStatus, deploymentPackagesError] =
    useHalResource({
      url: DEPLOY_UNITS_URL,
      asyncHeadersHandler: getSessionHeaders,
      headers: getPlatformHeaders(),
    });

  //Message operations
  useEffect(() => {
    if (
      PROMISE_FINAL_STATUSES.includes(deploymentPackageStatus) &&
      PROMISE_FINAL_STATUSES.includes(deploymentPackagesStatus) &&
      (deploymentPackageError || deploymentPackagesError)
    ) {
      const _error = deploymentPackageError ?? deploymentPackagesError;
      setError(buildApplicationError(_error));
    }
  }, [
    deploymentPackageError,
    deploymentPackageStatus,
    deploymentPackagesError,
    deploymentPackagesStatus,
  ]);

  //HAL resources calculated status
  const updateDeploymentPackageStatus = useMemo(() => {
    if (
      deploymentPackagesStatus === "fetching" ||
      deploymentPackageStatus === "fetching" ||
      deploymentPackageStatus === "interaction" ||
      isDeprecateLoading
    ) {
      return "fetching";
    } else if (deploymentPackagesStatus === deploymentPackageStatus) {
      return deploymentPackagesStatus;
    }
    return deploymentPackageStatus;
  }, [deploymentPackageStatus, deploymentPackagesStatus, isDeprecateLoading]);

  const dismissMessage = () => {
    changeDeploymentPackageMessage(null);
  };

  const onSaveChanges = () => {
    const payload = {
      service_name: deploymentPackageName,
      service_type: deploymentPackageType,
      source: deploymentPackageSource,
      artefact_github: artefactGitHub,
      deprecated: deploymentPackageIsDeprecated,
      service_parameters: buildDeploymentPackageParametersPayload(deploymentPackageParameters),
      dependsOn: buildDeploymentPackageDependenciesPayload(deploymentPackageDependencies),
      artefacts: buildDeploymentPackageArtefactsPayload(deploymentPackageArtefacts),
      ssm_conditions: buildDeploymentPackageConditionsPayload(deploymentPackageConditions),
    };
    deploymentPackageHandlers["update-deployment-package-data"](payload)
      .then((response) => {
        changeDeploymentPackageMessage({
          type: response.halResource.resourceRepresentation.outcome_report ? "warning" : "confirm",
          message: "Deployment package successfully updated in the platform",
          info: response.halResource.resourceRepresentation.outcome_report
            ? response.halResource.resourceRepresentation.outcome_report.messages.message
            : null,
        });
      })
      .catch((error) => {
        error.body?.messages
          ? changeDeploymentPackageMessage({
              type: "error",
              message: `${error.body?.messages[0].message}`,
            })
          : changeDeploymentPackageMessage({
              type: "error",
              message: "An error ocurred when updating the deployment package",
            });
      });
  };

  //DeploymentPackage Parameters functions
  const addDeploymentPackageParameter = (name, type, isRequired, enumValues) => {
    let _deploymentPackageParameters = [...deploymentPackageParameters];
    !deploymentPackageParameters.find((item) => item.name === name)
      ? (_deploymentPackageParameters = [
          ...deploymentPackageParameters,
          {
            name: name,
            type: type,
            enumValues: enumValues,
            isRequired: isRequired,
          },
        ])
      : changeDeploymentPackageMessage({
          type: "error",
          message: "A deployment package parameter with the same name already exists",
        });
    changeDeploymentPackageParameters([..._deploymentPackageParameters]);
    toggleAddDeploymentPackageParamVisible();
  };

  const editDeploymentPackageParameter = (oldName, name, type, isRequired, enumValues = "") => {
    let index = deploymentPackageParameters.findIndex((item) => item.name === oldName);
    index !== -1 &&
    (oldName === name || !deploymentPackageParameters.find((item) => item.name === name))
      ? (deploymentPackageParameters[index] = {
          name: name,
          type: type,
          enumValues: enumValues,
          isRequired: isRequired,
        })
      : changeDeploymentPackageMessage({
          type: "error",
          message: "A deployment package parameter with the same name already exists",
        });
    changeDeploymentPackageParameters([...deploymentPackageParameters]);
    toggleEditDeploymentPackageParamVisible();
  };

  const removeDeploymentPackageParameter = (name) => {
    changeDeploymentPackageParameters(
      deploymentPackageParameters.filter((item) => item.name !== name)
    );
    toggleEditDeploymentPackageParamVisible();
  };

  const deploymentPackageParametersList = useMemo(() => {
    return deploymentPackageParameters.map((deploymentPackageParam) => ({
      name: deploymentPackageParam.name,
      type: deploymentPackageParam.type,
      enumValues: deploymentPackageParam.enumValues,
      required: deploymentPackageParam.isRequired,
      onEditOpenClick: () => {
        dismissMessage();
        toggleEditDeploymentPackageParamVisible(deploymentPackageParam);
      },
    }));
  }, [deploymentPackageParameters]);

  //DeploymentPackage Dependencies functions
  const toggleDeploymentPackageDependency = (name, type) => {
    !isSelectedDependency(name, type)
      ? changeDeploymentPackageDependencies([
          ...deploymentPackageDependencies,
          { name: name, type: type },
        ])
      : changeDeploymentPackageDependencies(
          deploymentPackageDependencies.filter((item) => item.name !== name && item.name !== type)
        );
  };

  const deploymentPackageDependenciesList = useMemo(() => {
    return deploymentPackageDependencies.map((deploymentPackageDependency) => ({
      name: deploymentPackageDependency.name,
      type: deploymentPackageDependency.type,
    }));
  }, [deploymentPackageDependencies]);

  const isSelectedDependency = useCallback(
    (name) => {
      return deploymentPackageDependenciesList.some((dependency) => dependency.name === name);
    },
    [deploymentPackageDependenciesList]
  );

  const availableDeploymentPackagesList = useMemo(() => {
    const _deploymentPackagesList = (
      (deploymentPackagesResource && deploymentPackagesResource.getItems()) ||
      []
    ).filter((serv) => serv.name !== deploymentPackageName);

    return _deploymentPackagesList
      .map((deploymentPackage) => {
        return {
          deploymentPackageName: deploymentPackage.name,
          deploymentPackageType: deploymentPackage.summary.service_type,
          isSelected: isSelectedDependency(
            deploymentPackage.name,
            deploymentPackage.summary.deploymentPackage_type
          ),
          isDeprecated: deploymentPackage.summary.deprecated,
        };
      })
      .filter((deploymentPackage) => {
        const byDeploymentPackageTypes = deploymentPackageTypeFilter.length
          ? deploymentPackageTypeFilter.includes(deploymentPackage.deploymentPackageType)
            ? deploymentPackage
            : null
          : deploymentPackage;
        const byDeprecated = !deprecatedFilter
          ? (deploymentPackage.isDeprecated &&
              deploymentPackage.isDeprecated === deprecatedFilter) ||
            (!deploymentPackage.isDeprecated && !deprecatedFilter)
            ? deploymentPackage
            : null
          : deploymentPackage;
        const byTextFilter = textFilter
          ? deploymentPackage.deploymentPackageName.includes(textFilter)
            ? deploymentPackage
            : null
          : deploymentPackage;

        return byDeploymentPackageTypes
          ? byDeprecated && byTextFilter
            ? deploymentPackage
            : null
          : null;
      })
      .sort(function (a, b) {
        return b.isSelected - a.isSelected;
      });
  }, [
    isSelectedDependency,
    deploymentPackageName,
    deploymentPackageTypeFilter,
    deploymentPackagesResource,
    deprecatedFilter,
    textFilter,
  ]);

  //DeploymentPackage Conditions functions
  const addDeploymentPackageCondition = (newDeploymentPackageConditions) => {
    changeDeploymentPackageConditions(
      deploymentPackageConditions.concat(
        newDeploymentPackageConditions.filter(
          (item, index, _self) =>
            _self.findIndex((_selfItem) => _selfItem.conditionKey === item.conditionKey) ===
              index &&
            item.conditionKey !== "" &&
            item.value !== ""
        )
      )
    );
    toggleAddDeploymentPackageConditionVisible();
  };

  const editDeploymentPackageCondition = (condition, indexCondition) => {
    deploymentPackageConditions[indexCondition] = {
      conditionKey: condition.conditionKey,
      value: condition.value,
      assert: condition.assert,
    };

    changeDeploymentPackageConditions([...deploymentPackageConditions]);
    toggleEditDeploymentPackageConditionVisible();
  };

  const removeDeploymentPackageCondition = useCallback(
    (deploymentPackageConditionIndex) => {
      changeDeploymentPackageConditions(
        deploymentPackageConditions.filter((item, indexFilter) => {
          return deploymentPackageConditionIndex !== indexFilter;
        })
      );
      toggleEditDeploymentPackageParamVisible();
    },
    [deploymentPackageConditions]
  );

  const deploymentPackageConditionsList = useMemo(() => {
    return deploymentPackageConditions.map((deploymentPackageCondition, index) => ({
      conditionKey: deploymentPackageCondition.conditionKey,
      value: deploymentPackageCondition.value,
      assert: deploymentPackageCondition.assert,
      onEditOpenClick: () => {
        dismissMessage();
        toggleEditDeploymentPackageConditionVisible({
          ...deploymentPackageCondition,
          indexCondition: index,
        });
      },
      onRemoveClick: () => removeDeploymentPackageCondition(index),
    }));
  }, [removeDeploymentPackageCondition, deploymentPackageConditions]);

  const setDeploymentPackageArtefactProperty = (index, artefactIndex, value) => {
    changeDeploymentPackageArtefacts((oldDeploymentPackageArtefacts) => {
      let propertyValue =
        value != null
          ? value
          : oldDeploymentPackageArtefacts[artefactIndex].properties[index].value;
      let property = {
        ...oldDeploymentPackageArtefacts[artefactIndex].properties[index],
        value: propertyValue,
      };
      oldDeploymentPackageArtefacts[artefactIndex].properties[index] = {
        ...property,
      };
      return [...oldDeploymentPackageArtefacts];
    });
  };

  const addArtefactProperty = useCallback((artefactIndex, propertyName, propertyValue) => {
    changeDeploymentPackageArtefacts((oldDeploymentPackageArtefacts) => {
      let property = {
        name: propertyName ? propertyName : "",
        value: propertyValue ? propertyValue : "",
        onChangeValue: (value, index, _artefactIndex) =>
          setDeploymentPackageArtefactProperty(index, _artefactIndex, value),
      };
      oldDeploymentPackageArtefacts[artefactIndex].properties = [
        ...oldDeploymentPackageArtefacts[artefactIndex].properties,
        property,
      ];
      return [...oldDeploymentPackageArtefacts];
    });
  }, []);

  const initializeArtefact = (artefact, index) => {
    changeDeploymentPackageArtefacts((oldDeploymentPackageArtefacts) => {
      oldDeploymentPackageArtefacts[index] = {
        name: artefact.name,
        variable: artefact.variable,
        properties: [],
      };
      return [...oldDeploymentPackageArtefacts];
    });
  };

  const deploymentPackageArtefactsList = useMemo(() => {
    return deploymentPackageArtefacts.map((artefact) => ({
      properties: artefact.properties,
      name: artefact.name,
    }));
  }, [deploymentPackageArtefacts]);

  //grouped deploymentPackage main data info
  const deploymentPackageDataInfo = {
    deploymentPackageSource,
    deploymentPackageName,
    deploymentPackageType,
    deploymentPackageIsDeprecated,
    onChangeDeploymentPackageSource: (newDeploymentPackageSource) =>
      changeDeploymentPackageSource(newDeploymentPackageSource),
    onChangeDeploymentPackageName: (newDeploymentPackageName) =>
      changeDeploymentPackageName(newDeploymentPackageName),
    onChangeDeploymentPackageType: (newDeploymentPackageType) =>
      changeDeploymentPackageType(newDeploymentPackageType),
    onDeleteClick: () => {
      setDeleteDialogVisible(true);
    },
    onDeleteCancel: () => {
      setDeleteDialogVisible(false);
      changeDeleteErrorMessage(null);
    },
    onDeleteConfirm: async () => {
      changeIsDeleteLoading(true);
      changeDeleteErrorMessage(null);
      const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
      await HalApiCaller.del({
        url: `${DEPLOY_UNITS_URL}/${deploymentPackageName}`,
        headers: { ...asyncHeaders, ...getPlatformHeaders() },
      })
        .then(() => {
          changeIsDeleteLoading(false);
          setDeleteDialogVisible(false);
          navigateDeploymentPackages();
        })
        .catch((error) => {
          changeIsDeleteLoading(false);
          error.response?.data?.messages
            ? changeDeleteErrorMessage(`${error.response.data.messages[0].message}`)
            : changeDeleteErrorMessage(`${error.message}`);
        });
    },
    onDeprecateClick: async () => {
      changeIsDeprecateLoading(true);
      const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
      await HalApiCaller.patch({
        url: `${DEPLOY_UNITS_URL}/${deploymentPackageName}`,
        headers: { ...asyncHeaders, ...getPlatformHeaders() },
        body: { deprecated: !deploymentPackageIsDeprecated },
      })
        .then(() => {
          changeDeploymentPackageIsDeprecated(!deploymentPackageIsDeprecated);
          changeIsDeprecateLoading(false);
        })
        .catch((error) => {
          changeIsDeprecateLoading(false);
          changeDeploymentPackageMessage({
            type: "error",
            message: error.response?.data?.messages
              ? `${error.response.data.messages[0].message}`
              : `${error.message}`,
          });
        });
    },
  };

  //grouped add deploymentPackage parameter dialog state
  const deploymentPackageParametersInfo = {
    deploymentPackageParametersList: deploymentPackageParametersList,
    isAddDialogOpen: isAddDeploymentPackageParamVisible,
    isEditDialogOpen: isEditDeploymentPackageParamVisible,
    onAddOpenClick: () => {
      dismissMessage();
      toggleAddDeploymentPackageParamVisible();
    },
    onAddCancelClick: toggleAddDeploymentPackageParamVisible,
    onAddSubmitClick: addDeploymentPackageParameter,
    onEditCancelClick: toggleEditDeploymentPackageParamVisible,
    onEditSubmitClick: editDeploymentPackageParameter,
    onEditRemoveClick: removeDeploymentPackageParameter,
  };

  const deploymentPackageConditionsInfo = {
    deploymentPackageConditionsList: deploymentPackageConditionsList,
    isAddDialogOpen: isAddDeploymentPackageConditionVisible,
    isEditDialogOpen: isEditDeploymentPackageConditionVisible,
    onAddOpenClick: () => {
      dismissMessage();
      toggleAddDeploymentPackageConditionVisible();
    },
    onAddCancelClick: toggleAddDeploymentPackageConditionVisible,
    onAddSubmitClick: addDeploymentPackageCondition,
    onEditCancelClick: toggleEditDeploymentPackageConditionVisible,
    onEditSubmitClick: editDeploymentPackageCondition,
  };

  //grouped add deploymentPackage parameter dialog state
  const availableDeploymentPackagesInfo = {
    deploymentPackageDependenciesList: deploymentPackageDependenciesList,
    availableDeploymentPackagesList: availableDeploymentPackagesList,
    textFilter: textFilter,
    deploymentPackageTypeFilter: deploymentPackageTypeFilter,
    deprecatedFilter: deprecatedFilter,
    onChangeTextFilter: changeTextFilter,
    onChangeDeploymentPackageTypeFilter: changeDeploymentPackageTypeFilter,
    onChangeDeprecatedFilter: changeDeprecatedFilter,
    onClickSelected: toggleDeploymentPackageDependency,
  };

  const deploymentPackageArtefactsInfo = {
    deploymentPackageArtefactsList: deploymentPackageArtefactsList,
    artefactGitHub: artefactGitHub,
    onChangeArtefactGitHub: (newValue) => changeArtefactGitHub(newValue),
  };

  //navigate function
  const navigateDeploymentPackages = () => {
    history.push(`/deployment-packages`);
  };

  const resetStateProperties = () => {
    changeDeploymentPackageSource(null);
    changeDeploymentPackageName(null);
    changeDeploymentPackageType("");
    changeDeploymentPackageIsDeprecated(false);
    changeDeploymentPackageParameters([]);
    changeDeploymentPackageDependencies([]);
    changeDeploymentPackageConditions([]);
    changeDeploymentPackageArtefacts([]);
    changeArtefactGitHub(false);
  };

  //Retrieve DeploymentPackage Info
  useEffect(() => {
    if (deploymentPackageResource !== null) {
      resetStateProperties();
      const _properties = deploymentPackageResource.getProperties();

      //basic data
      changeDeploymentPackageName(_properties.find((prop) => prop.key === "service_name").value);
      changeDeploymentPackageSource(_properties.find((prop) => prop.key === "source").value);
      changeDeploymentPackageType(_properties.find((prop) => prop.key === "service_type").value);
      changeDeploymentPackageIsDeprecated(
        _properties.find((prop) => prop.key === "deprecated")?.value || false
      );

      //deploymentPackageparameters
      const deploymentPackageParam = _properties.filter(
        (prop) => prop.key === "service_parameters"
      );
      if (deploymentPackageParam?.length > 0 && deploymentPackageParam[0].value) {
        const parameters = deploymentPackageParam[0].value.map((param) =>
          Object.keys(param).map((key) => ({
            name: key,
            type: Object.values(param)[0].type,
            enumValues: Object.values(param)[0].enumValues,
            isRequired: Object.values(param)[0].required,
          }))
        );
        const loadedDeploymentPackageParams = parameters.map((item) => {
          return item[0];
        });
        changeDeploymentPackageParameters(loadedDeploymentPackageParams);
      }

      //deploymentPackageDependencies
      const dependencies = _properties.filter((prop) => prop.key === "dependsOn");
      if (dependencies.length > 0 && dependencies[0].value) {
        const loadedDeploymentPackageDependencies = dependencies[0].value.map((item) => ({
          name: item.service_name,
          type: item.service_type,
        }));
        changeDeploymentPackageDependencies(loadedDeploymentPackageDependencies);
      }

      //deploymentPackageArtefacts
      const artefacts = _properties.filter((prop) => prop.key === "artefacts");
      if (artefacts?.length > 0 && artefacts[0].value && artefacts[0].value.length > 0) {
        artefacts[0].value.forEach((artefact, index) => {
          initializeArtefact(artefact, index);
          if (artefact.tf_properties) {
            Object.keys(artefact.tf_properties).forEach((key) => {
              addArtefactProperty(index, key, artefact.tf_properties[key]);
            });
          }
        });
      }

      //deploymentPackageConditions
      const conditions = _properties.filter((prop) => prop.key === "ssm_conditions");
      if (conditions.length > 0 && conditions[0].value) {
        const loadedDeploymentPackageConditions = conditions[0].value.map((item) => ({
          conditionKey: item.key,
          value: item.value,
          assert: item.equality,
        }));
        changeDeploymentPackageConditions(loadedDeploymentPackageConditions);
      }
    }
  }, [addArtefactProperty, deploymentPackageResource]);

  return [
    updateDeploymentPackageStatus,
    deploymentPackageMessage,
    deploymentPackageDataInfo,
    deploymentPackageParametersInfo,
    deploymentPackageConditionsInfo,
    availableDeploymentPackagesInfo,
    deploymentPackageArtefactsInfo,
    dismissMessage,
    onSaveChanges,
    navigateDeploymentPackages,
    isDeleteDialogVisible,
    deleteErrorMessage,
    isDeleteLoading,
    error,
  ];
};

export default useDeploymentPackageEdit;
