import React, { useState } from "react";
import styled from "styled-components";
import {
  DxcHeading,
  DxcCheckbox,
  DxcSelect,
  DxcButton,
  DxcDialog,
  DxcTextInput,
  DxcParagraph,
  DxcInset,
  DxcFlex,
} from "@dxc-technology/halstack-react";

import { parameterTypes } from "../DeploymentPackageDefinitionHelper";

function AddDeploymentPackageParameter({ onSaveClick, onCancelClick }) {
  const [deploymentPackageParameterName, setDeploymentPackageParameterName] = useState("");
  const [deploymentPackageParameterType, setDeploymentPackageParameterType] = useState("");
  const [enumValues, setEnumValues] = useState("");
  const [isRequired, setIsRequired] = useState(false);

  const dpParameterNameHandler = ({ value }) => {
    setDeploymentPackageParameterName(value);
  };

  const dpParameterTypeHandler = ({ value }) => {
    setDeploymentPackageParameterType(value);
  };

  const enumHandler = ({ value }) => {
    setEnumValues(value);
  };

  return (
    <DxcDialog isCloseVisible onCloseClick={() => onCancelClick()}>
      <DialogHeader>
        <DxcHeading
          level={2}
          text="Create deployment package parameter"
          margin={{ bottom: "xsmall" }}
        />
      </DialogHeader>
      <DxcFlex direction="column">
        <DxcParagraph>Add a parameter associated to your deployment package.</DxcParagraph>

        <DxcTextInput
          label="Parameter name"
          id="deploymentPackageParameterName"
          placeholder="e.g.: my-parameter-name"
          name="deploymentPackageParameterName"
          margin={{ top: "xsmall", bottom: "xsmall" }}
          size="fillParent"
          value={deploymentPackageParameterName}
          onChange={dpParameterNameHandler}
          clearable={true}
        />
        <DxcSelect
          label="Type"
          id="type"
          options={parameterTypes}
          name="type"
          size={"fillParent"}
          margin={{ bottom: "xsmall" }}
          value={deploymentPackageParameterType}
          onChange={dpParameterTypeHandler}
        ></DxcSelect>

        {deploymentPackageParameterType === "enum" ? (
          <DxcTextInput
            label="Enum the values"
            id="enumValues"
            placeholder="e.g.: firstValue, secondValue, thirdValue"
            name="enumValues"
            margin={{ bottom: "xxsmall" }}
            size="fillParent"
            helperText="Separate each value by a comma"
            value={enumValues}
            onChange={enumHandler}
            clearable={true}
            optional={true}
          />
        ) : null}

        <DxcCheckbox
          labelPosition="after"
          label="The parameter is required"
          size={"large"}
          id="isRequired"
          name="isRequired"
          onChange={setIsRequired}
          checked={isRequired}
        />
      </DxcFlex>
      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={() => onCancelClick()}
            mode="text"
          ></DxcButton>
          <DxcButton
            label="Save"
            onClick={() =>
              onSaveClick(
                deploymentPackageParameterName,
                deploymentPackageParameterType,
                isRequired,
                enumValues
              )
            }
            disabled={
              deploymentPackageParameterName === "" ||
              deploymentPackageParameterType === "" ||
              (deploymentPackageParameterType === "enum" && enumValues === "")
            }
            mode="primary"
          ></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default AddDeploymentPackageParameter;
