import React, { useMemo, useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcSelect,
  DxcTextInput,
  DxcTextarea,
  DxcCheckbox,
  DxcSwitch,
  DxcHeading,
} from "@dxc-technology/halstack-react";

import { AWSRegionList } from "./EnvironmentDefitionHelper";
import NonEditableField from "../components/NonEditableField";

function EnvironmentData({
  description,
  isLocked,
  isPrivate,
  envType,
  envTypesList,
  number,
  accountZoneName,
  AWSRegion,
  owner,
  notifications,
  onChangeDescription,
  onChangeIsLocked,
  onChangeIsPrivate,
  onChangeType,
  onChangeNumber,
  onChangeAccountZoneName,
  onChangeAWSRegion,
  onChangeOwner,
  onChangeNotifications,
}) {
  const [envTypeError, setEnvTypeError] = useState();
  const [envNumberError, setEnvNumberError] = useState();
  const [awsRegionError, setAWSRegionError] = useState();
  const [ownerError, setOwnerError] = useState();

  const envTypeHandler = ({ value, error }) => {
    onChangeType(value);
    setEnvTypeError(error);
  };

  const envNumberHandler = ({ value, error }) => {
    onChangeNumber(value);
    setEnvNumberError(error);
  };

  const awsRegionHandler = ({ value, error }) => {
    onChangeAWSRegion(value);
    setAWSRegionError(error);
  };

  const ownerHandler = ({ value, error }) => {
    onChangeOwner(value);
    setOwnerError(error);
  };

  const envTypesOptions = useMemo(() => {
    const _envTypesOptions = envTypesList ? envTypesList : [];

    return _envTypesOptions.map((item) => {
      return {
        label: item.envType,
        value: item.envType,
      };
    });
  }, [envTypesList]);

  return (
    <EnvironmentDataContainer>
      <DxcTextarea
        label="Description"
        id="description"
        placeholder="Description"
        name="description"
        value={description}
        onChange={({ value }) => onChangeDescription(value)}
        onBlur={({ value }) => onChangeDescription(value)}
        size={"fillParent"}
        numRows={3}
        margin={{ bottom: "medium" }}
      />
      {(onChangeType && (
        <DxcSelect
          options={envTypesOptions}
          onChange={envTypeHandler}
          onBlur={envTypeHandler}
          label="Environment type"
          id="envType"
          name="envType"
          multiple={false}
          value={envType}
          size={"fillParent"}
          margin={{ bottom: "medium" }}
          optional={false}
          error={envTypeError == null ? "" : envTypeError}
        ></DxcSelect>
      )) || <NonEditableField label="Environment type" value={envType} />}
      {onChangeNumber ? (
        <DxcTextInput
          label="Environment number"
          id="environmentNumber"
          placeholder="e.g.: 2048"
          helperText="Numbers only"
          name="environmentNumber"
          value={number}
          margin={{ bottom: "medium" }}
          size={"fillParent"}
          onChange={onChangeNumber ? envNumberHandler : null}
          onBlur={onChangeNumber ? envNumberHandler : null}
          clearable={onChangeNumber ? true : false}
          optional={false}
          pattern="^[0-9-]+$"
          error={envNumberError == null ? "" : envNumberError}
        />
      ) : (
        <NonEditableField label="Environment number" value={number} />
      )}
      <DxcHeading text="Configuration options" level={3} />
      <OptionsContainer>
        <DxcCheckbox
          checked={isLocked}
          id="lockEnvironment"
          name="lockEnvironment"
          label="Lock the environment"
          labelPosition={"after"}
          onChange={onChangeIsLocked}
          margin={{ bottom: "medium" }}
        />
        <DxcSwitch
          checked={isPrivate}
          id="makePrivate"
          name="makePrivate"
          label="Make private"
          labelPosition={"after"}
          onChange={(value) => onChangeIsPrivate(value)}
          margin={{ bottom: "medium" }}
        />
      </OptionsContainer>
      <DxcTextInput
        label="Environment account zone name"
        id="accountZoneName"
        placeholder="e.g.: sandbox.assure.dxc.com"
        assistiveText="Environment AWS account zone name"
        name="accountZoneName"
        value={accountZoneName}
        margin={{ bottom: "medium" }}
        size={"fillParent"}
        onChange={onChangeAccountZoneName ? ({ value }) => onChangeAccountZoneName(value) : null}
        onBlur={onChangeAccountZoneName ? ({ value }) => onChangeAccountZoneName(value) : null}
        clearable={true}
      />
      <DxcSelect
        options={AWSRegionList}
        onChange={awsRegionHandler}
        onBlur={awsRegionHandler}
        label="AWS region"
        id="awsRegion"
        name="awsRegion"
        multiple={false}
        value={AWSRegion}
        size={"fillParent"}
        margin={{ bottom: "medium" }}
        optional={false}
        error={awsRegionError == null ? "" : awsRegionError}
      ></DxcSelect>
      <DxcHeading text="Ownership & notifications" level={3} />
      <DxcTextInput
        label="Owner"
        id="owner"
        placeholder="e.g.: owner@dxc.com"
        helperText="An email address"
        name="owner"
        value={owner}
        margin={{ bottom: "medium" }}
        size={"fillParent"}
        onChange={onChangeOwner ? ownerHandler : null}
        onBlur={onChangeOwner ? ownerHandler : null}
        clearable={onChangeOwner ? true : false}
        optional={false}
        pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
        error={ownerError == null ? "" : ownerError}
      />
      <DxcTextarea
        label="Notifications"
        id="notifications"
        placeholder="e.g.: example1@dxc.com, example2@dxc.com"
        assistiveText="Email addresses separated by comma"
        name="notifications"
        value={notifications}
        onChange={({ value }) => onChangeNotifications(value)}
        size={"fillParent"}
        numRows={2}
        margin={{ bottom: "medium" }}
      />
    </EnvironmentDataContainer>
  );
}

const EnvironmentDataContainer = styled.div``;

const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default EnvironmentData;
