import React from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcButton,
  DxcParagraph,
  DxcFlex,
} from "@dxc-technology/halstack-react";

//custom hook
import useDeploymentPackageEdit from "./useDeploymentPackageEdit";

import AvailableDeploymentPackages from "../deployment-package-definition/AvailableDeploymentPackages";
import DeploymentPackageData from "../deployment-package-definition/DeploymentPackageData";
import DeploymentPackageParameters from "../deployment-package-definition/DeploymentPackageParameters";
import DeploymentPackageConditions from "../deployment-package-definition/DeploymentPackageConditions";
import DeploymentPackageArtefacts from "../deployment-package-definition/DeploymentPackageArtefacts";
import SectionContainer from "../components/SectionContainer";
import NavigationContainer from "../components/NavigationContainer";
import DeleteDeploymentPackage from "./DeleteDeploymentPackage";

//search image
import backImage from "../deployment-package-definition/images/arrow_back_ios-24px.svg";
import { plusIcon } from "../deployment-package-definition/images/plusIcon.js";
import DeploymentPackageActions from "../deployment-package-definition/DeploymentPackageActions";
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";

function DeploymentPackageEdit() {
  const [
    deploymentPackagesStatus,
    deploymentPackagesMessage,
    deploymentPackageDataInfo,
    deploymentPackageParametersInfo,
    deploymentPackageConditionsInfo,
    availableDeploymentPackagesInfo,
    deploymentPackageArtefactsInfo,
    dismissMessage,
    onSaveChanges,
    navigateDeploymentPackages,
    isDeleteDialogVisible,
    deleteErrorMessage,
    isDeleteLoading,
    error,
  ] = useDeploymentPackageEdit();
  useErrorHandler(error);

  const sections = [
    {
      label: "Information",
      section: (
        <MainContainer>
          <SectionContainer title="Information">
            <DeploymentPackageData
              source={deploymentPackageDataInfo.deploymentPackageSource}
              name={deploymentPackageDataInfo.deploymentPackageName}
              type={deploymentPackageDataInfo.deploymentPackageType}
              onChangeSource={deploymentPackageDataInfo.onChangeDeploymentPackageSource}
              onChangeType={deploymentPackageDataInfo.onChangeDeploymentPackageType}
            />
          </SectionContainer>
        </MainContainer>
      ),
    },
    {
      label: "Parameters",
      section: (
        <MainContainer>
          <SectionContainer
            title="Parameters"
            actionLabel="Add parameter"
            actionOnClick={deploymentPackageParametersInfo.onAddOpenClick}
            actionIcon={plusIcon}
            padding={{ left: "small" }}
          >
            <DeploymentPackageParameters
              deploymentPackageParameters={
                deploymentPackageParametersInfo.deploymentPackageParametersList
              }
              isAddDialogVisible={deploymentPackageParametersInfo.isAddDialogOpen}
              isEditDialogVisible={deploymentPackageParametersInfo.isEditDialogOpen}
              onAddParamClick={deploymentPackageParametersInfo.onAddOpenClick}
              onAddSaveClick={deploymentPackageParametersInfo.onAddSubmitClick}
              onAddCancelClick={deploymentPackageParametersInfo.onAddCancelClick}
              onEditSaveClick={deploymentPackageParametersInfo.onEditSubmitClick}
              onEditCancelClick={deploymentPackageParametersInfo.onEditCancelClick}
              onEditRemoveClick={deploymentPackageParametersInfo.onEditRemoveClick}
            />
          </SectionContainer>
        </MainContainer>
      ),
    },
    {
      label: "Artefacts",
      section: (
        <MainContainer>
          <SectionContainer
            title="Artefacts"
            switchLabel="Reload from GitHub"
            switchAction={deploymentPackageArtefactsInfo.onChangeArtefactGitHub}
            checked={deploymentPackageArtefactsInfo.artefactGitHub}
          >
            <DeploymentPackageArtefacts
              deploymentPackageArtefacts={
                deploymentPackageArtefactsInfo.deploymentPackageArtefactsList
              }
              disabledAccordion={deploymentPackageArtefactsInfo.artefactGitHub}
            />
          </SectionContainer>
        </MainContainer>
      ),
    },
    {
      label: "Deployment dependencies",
      section: (
        <MainContainer>
          <SectionContainer title="Deployment dependencies">
            <AvailableDeploymentPackages
              availableDeploymentPackages={
                availableDeploymentPackagesInfo.availableDeploymentPackagesList
              }
              textFilter={availableDeploymentPackagesInfo.textFilter}
              deploymentPackageTypeFilter={
                availableDeploymentPackagesInfo.deploymentPackageTypeFilter
              }
              checkedDeprecated={availableDeploymentPackagesInfo.deprecatedFilter}
              onChangeTextFilter={availableDeploymentPackagesInfo.onChangeTextFilter}
              onChangeDeploymentPackageTypeFilter={
                availableDeploymentPackagesInfo.onChangeDeploymentPackageTypeFilter
              }
              onChangeDeprecated={availableDeploymentPackagesInfo.onChangeDeprecatedFilter}
              onClickSelected={availableDeploymentPackagesInfo.onClickSelected}
              isDependency
            ></AvailableDeploymentPackages>
          </SectionContainer>
        </MainContainer>
      ),
    },
    {
      label: "Conditions",
      section: (
        <MainContainer>
          <SectionContainer
            title="Conditions"
            actionOnClick={deploymentPackageConditionsInfo.onAddOpenClick}
            actionLabel="Add condition"
            actionIcon={plusIcon}
          >
            <DeploymentPackageConditions
              deploymentPackageConditions={
                deploymentPackageConditionsInfo.deploymentPackageConditionsList
              }
              isAddDialogVisible={deploymentPackageConditionsInfo.isAddDialogOpen}
              isEditDialogVisible={deploymentPackageConditionsInfo.isEditDialogOpen}
              onAddSaveClick={deploymentPackageConditionsInfo.onAddSubmitClick}
              onAddCancelClick={deploymentPackageConditionsInfo.onAddCancelClick}
              onEditSaveClick={deploymentPackageConditionsInfo.onEditSubmitClick}
              onEditCancelClick={deploymentPackageConditionsInfo.onEditCancelClick}
            />
          </SectionContainer>
          <DxcFlex>
            <DxcButton mode="primary" label="Save changes" onClick={onSaveChanges} />
          </DxcFlex>
        </MainContainer>
      ),
    },
    {
      label: "Deprecate",
      section: (
        <MainContainer>
          <DxcHeading level={3} text={`Actions`} margin={{ top: "medium", bottom: "small" }} />
          <SectionContainer
            title="Deprecate"
            customTitleContent={
              deploymentPackageDataInfo.deploymentPackageIsDeprecated && (
                <Tag>
                  <TagLabel>Deprecated</TagLabel>
                </Tag>
              )
            }
          >
            <DeploymentPackageActions
              message={
                deploymentPackageDataInfo.deploymentPackageIsDeprecated
                  ? "This deployment package is currently deprecated."
                  : "This deployment package will not appear by default in the deploy packages list."
              }
              buttonLabel={
                deploymentPackageDataInfo.deploymentPackageIsDeprecated ? "Restore" : "Deprecate"
              }
              onClickButton={deploymentPackageDataInfo.onDeprecateClick}
            />
          </SectionContainer>
        </MainContainer>
      ),
    },
    {
      label: "Delete",
      section: (
        <MainContainer>
          <SectionContainer title="Delete">
            <DeploymentPackageActions
              message="Delete deployment package. A dialog will prompt you to confirm the deletion."
              buttonLabel="Delete"
              onClickButton={deploymentPackageDataInfo.onDeleteClick}
            />
          </SectionContainer>
        </MainContainer>
      ),
    },
  ];

  return (
    <GlobalContent>
      <Content>
        <StyledLink onClick={navigateDeploymentPackages}>
          <BackImg src={backImage} />
          Back to Deployment Packages
        </StyledLink>

        <ComponentHeaderContainer>
          <Title>
            <DxcHeading
              level={1}
              weight="normal"
              text={`Edit deployment package`}
              margin={{ top: "small", bottom: "small" }}
            />
          </Title>

          {deploymentPackagesMessage ? (
            deploymentPackagesMessage.info ? (
              <DxcAlert
                type={deploymentPackagesMessage.type}
                mode="inline"
                inlineText={deploymentPackagesMessage.message}
                size="fillParent"
                onClose={dismissMessage}
                margin={{ bottom: "small" }}
              >
                <DxcParagraph>{deploymentPackagesMessage.info}</DxcParagraph>
              </DxcAlert>
            ) : (
              <DxcAlert
                type={deploymentPackagesMessage.type}
                mode="inline"
                inlineText={deploymentPackagesMessage.message}
                size="fillParent"
                onClose={dismissMessage}
                margin={{ bottom: "small" }}
              />
            )
          ) : null}

          {deploymentPackageDataInfo.deploymentPackageIsDeprecated && (
            <DxcAlert
              type="warning"
              mode="inline"
              inlineText="This deployment package is deprecated. It can be restored from the actions section."
              margin={{ bottom: "small" }}
              size="fillParent"
            />
          )}
        </ComponentHeaderContainer>
        {deploymentPackagesStatus === "fetching" && (
          <DxcSpinner theme="light" label="Loading..." mode="overlay" />
        )}
      </Content>

      <NavigationContainer sections={sections} title="Sections" />

      {isDeleteDialogVisible && (
        <DeleteDeploymentPackage
          errorMessage={deleteErrorMessage}
          isLoading={isDeleteLoading}
          onCancel={deploymentPackageDataInfo.onDeleteCancel}
          onDelete={deploymentPackageDataInfo.onDeleteConfirm}
          deploymentPackage={deploymentPackageDataInfo.deploymentPackageName}
        ></DeleteDeploymentPackage>
      )}
    </GlobalContent>
  );
}

const GlobalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Title = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
  margin-bottom: 32px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled.div`
  display: flex;
  align-self: flex-start;
  width: fit-content;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  top: 16px;
  :hover {
    cursor: pointer;
  }
`;

const BackImg = styled.img`
  width: 20px;
  height: 20px;
`;

const Tag = styled.div`
  margin-left: 10px;
  height: calc(1.5rem - 2 * 1px);
  padding: 0 1rem;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  background-color: #fef9e6;
  border: 1px solid #c59f07;
  color: #947705;
`;

const TagLabel = styled.p`
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;
`;

const ComponentHeaderContainer = styled.div`
  width: 80%;
  align-self: flex-start;
`;

export default withErrorBoundary(DeploymentPackageEdit, { FallbackComponent: ApplicationError });
