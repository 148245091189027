import React from "react";
import styled from "styled-components";
import { DxcBox } from "@dxc-technology/halstack-react";
import useScrollSpy from "react-use-scrollspy";

function NavigationContainer({ sections = [] , title}) {

  const refs = sections.map(() => React.createRef());

  const activeSection = useScrollSpy({
    defaultValue: 0,
    sectionElementRefs: refs,
    offsetPx: -104,
  });

  const softScroll = (index) => {
    window.scroll({
      top: refs[index]?.current.offsetTop,
      behavior: 'smooth'
    });
  } 

  return (           
    <Container>
      <Content>
      {sections.map((section, i) => (
          <div ref={refs[i]}>
           {section.section}
          </div>
      ))}
      </Content>
      <NavigationContent> 
        <DxcBox shadowDepth={0} margin={{ left: "medium", bottom: "medium" }}>
              <BoxContent>       
                {title && (
                    <HeadingContainer>{title}</HeadingContainer>
                  )}  
                {sections.map((section, index) => (
                      <SubMenu isActive={index === activeSection} onClick={() => softScroll(index)}>
                        {section.label}
                      </SubMenu>
                    ))}
              </BoxContent> 
        </DxcBox>   
      </NavigationContent>
  </Container>
  )}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`;

const NavigationContent = styled.div` 
  position: sticky;
  top: 70px;
  max-height: calc(100vh - 210px);
  width:20%
`;


const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 2px solid #BFBFBF;  
  overflow: auto;

`;

const HeadingContainer = styled.div`
  align-items: first baseline;
  padding: 18px 24px;
  font: 16px semibold;
`;

const SubMenu = styled.span`
    padding: 0px 0px 0px 24px;
    font: 14px regular;
    margin-bottom: 10px;
    :hover {
      cursor: pointer;
      color: #AB63CF;
    }
    color: ${(props)=> props.isActive ? "#6F2C91" : "#666666"}
`;


export default NavigationContainer;
