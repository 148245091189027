//React hooks imports
import { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";

//urls
import { config } from "../config";

//Utils
import {
  retrieveEnvironmentsByCustomer,
  getCustomerLastDeploymentDate,
  goToTop,
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  PROMISE_FINAL_STATUSES,
} from "../common/utils";

//DXC hook
import { useHalResource } from "@dxc-technology/halstack-react-hal";

const CUSTOMERS_URL = `${config.environments_api_url}/customers`;
const ENVIRONMENTS_URL = `${config.environments_api_url}/environments`;

const useCustomerDashboard = () => {
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [customerMessage, changeCustomerMessage] = useState(null);
  const [error, setError] = useState(null);

  const history = useHistory();

  const [environmentsResource, environmentsStatus, environmentsError] = useHalResource({
    url: ENVIRONMENTS_URL,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const [customersResource, customersStatus, customersError, customersHandlers] = useHalResource({
    url: CUSTOMERS_URL,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const fetchStatus = useMemo(() => {
    if (!isCreateDialogOpen) {
      if (
        customersStatus === "fetching" ||
        customersStatus === "interaction" ||
        environmentsStatus === "fetching"
      ) {
        return "fetching";
      } else if (customersStatus === environmentsStatus) {
        return customersStatus;
      }
    } else {
      return customersStatus;
    }
  }, [customersStatus, environmentsStatus, isCreateDialogOpen]);

  useEffect(() => {
    if (
      PROMISE_FINAL_STATUSES.includes(customersStatus) &&
      PROMISE_FINAL_STATUSES.includes(environmentsStatus) &&
      (customersError || environmentsError)
    ) {
      const _error = customersError ?? environmentsError;
      setError(buildApplicationError(_error));
    }
  }, [customersError, customersStatus, environmentsError, environmentsStatus]);

  const customersList = useMemo(() => {
    const customersItemsList = (customersResource && customersResource.getItems()) || [];
    const environmentsItemsList = (environmentsResource && environmentsResource.getItems()) || [];

    return (
      fetchStatus === "resolved" &&
      customersItemsList.map((customer) => ({
        customerName: customer.summary.customer_name,
        accountsNumber: customer.summary.number_accounts,
        environmentsNumber: retrieveEnvironmentsByCustomer(
          environmentsItemsList,
          customer.summary.customer_name
        ).length,
        lastDeploymentDate: getCustomerLastDeploymentDate(
          environmentsItemsList,
          customer.summary.customer_name
        ),
        navigateCustomerDetails: () => {
          history.push(`/customers/${customer.summary.customer_name}`);
        },
      }))
    );
  }, [customersResource, environmentsResource, fetchStatus, history]);

  const dismissMessage = () => {
    changeCustomerMessage(null);
  };

  const toggleCreateDialogVisibility = () => {
    dismissMessage();
    setCreateDialogOpen(!isCreateDialogOpen);
  };

  const saveCustomer = (customerName, customerId) => {
    dismissMessage();
    let payload = {
      customer_name: customerId,
      client_short_name: customerName,
    };
    customersHandlers["create"](payload)
      .then(() => {
        toggleCreateDialogVisibility();
        changeCustomerMessage({
          type: "confirm",
          message: "Your customer was successfully created in the platform",
        });
        customersHandlers["list-customers"]()
          .then(() => {})
          .catch((error) => {
            changeCustomerMessage({
              type: "error",
              message: "An error ocurred getting customers",
            });
          })
          .finally(goToTop());
      })
      .catch((error) => {
        error.body?.messages
          ? changeCustomerMessage({
              type: "error",
              message: `${error.body.messages[0].message}`,
            })
          : changeCustomerMessage({
              type: "error",
              message: "An error ocurred when creating the customer",
            });
      });
  };

  return {
    fetchStatus,
    customerMessage,
    customersList,
    isCreateDialogOpen,
    dismissMessage,
    saveCustomer,
    toggleCreateDialogVisibility,
    error,
  };
};

export default useCustomerDashboard;
