import React from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcBox,
  DxcHeading,
  DxcLink,
  DxcRadioGroup,
  DxcResultsetTable,
  DxcSelect,
} from "@dxc-technology/halstack-react";
import EmptySection from "../components/EmptySection";
import { openInNewTabIcon } from "./img/open_in_new_tab";

function DeployInstallationStep({ deployInstallationInfo }) {
  const rows = deployInstallationInfo.serviceDeploymentPackages.map((item) => [
    { displayValue: item.version },
    { displayValue: item.name, sortValue: item.name },
  ]);

  const tfActionOptions = [
    { label: "Plan", value: "plan" },
    { label: "Apply", value: "apply" },
  ];

  return (
    <MainContainer>
      {deployInstallationInfo.serviceVersions.length > 0 ? (
        <>
          <DxcBox display="column-flex">
            <Title>
              <DxcHeading level={4} text={`Version`} margin="small" />
            </Title>
            <InfoFields>
              <DxcSelect
                options={deployInstallationInfo.serviceVersions}
                value={deployInstallationInfo.selectedVersion}
                label="Release"
                iconPosition="after"
                onChange={({ value }) => deployInstallationInfo.onVersionChange(value)}
                margin={{ bottom: "large" }}
              />
              <DxcLink
                icon={openInNewTabIcon}
                iconPosition="before"
                href={deployInstallationInfo.releaseServiceHref}
              >
                See deployment packages information in Release Service
              </DxcLink>
            </InfoFields>
          </DxcBox>
          <DxcBox display="column-flex" margin={{ top: "medium", bottom: "medium" }}>
            <Title>
              <DxcHeading level={4} text={`Deployment package versions`} margin="small" />
            </Title>
            <InfoFields>
              {deployInstallationInfo.serviceDeploymentPackages &&
              deployInstallationInfo.serviceDeploymentPackages.length > 0 ? (
                <DxcResultsetTable
                  columns={[
                    { displayValue: "Version", isSortable: false },
                    { displayValue: "Name", isSortable: true },
                  ]}
                  rows={rows}
                />
              ) : (
                <EmptySectionContainer>
                  <EmptySection text="No deployment packages found..." />
                </EmptySectionContainer>
              )}
            </InfoFields>
          </DxcBox>
          <DxcBox display="column-flex">
            <Title>
              <DxcHeading level={4} text={`Deploy actions`} margin="small" />
            </Title>
            <DeployActions>
              <DxcRadioGroup
                options={tfActionOptions}
                value={deployInstallationInfo.action}
                onChange={deployInstallationInfo.setAction}
              />
            </DeployActions>
          </DxcBox>
        </>
      ) : (
        <MainContainer>
          <DxcBox size="fillParent" margin={{ bottom: "xxsmall" }}>
            <EmptySectionContainer>
              <EmptySection text="No releases available..." />
            </EmptySectionContainer>
          </DxcBox>
        </MainContainer>
      )}
    </MainContainer>
  );
}

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 46px;
  padding-right: 36px;
  margin-top: 36px;
`;

const InfoFields = styled.div`
  display: flex;
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 46px;
  flex-direction: column;
`;

const DeployActions = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 46px;
`;

const EmptySectionContainer = styled.div`
  width: 100%;
  margin: 24px;
`;

export default DeployInstallationStep;
