import React, { useState } from "react";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";

const CSAMRadio = ({
  checked = false,
  value,
  label = "",
  labelPosition = "before",
  name,
  disabled = false,
  onClick,
  margin,
  size = "fitContent",
}) => {
  const [innerChecked, setInnerChecked] = useState(false);

  const handlerRadioChange = () => {
    if (checked == null) {
      setInnerChecked(true);
    }
    if (typeof onClick === "function") {
      onClick(true);
    }
  };

  const labelComponent = (
    <LabelContainer
      checked={checked || innerChecked}
      disabled={disabled}
      onClick={(!disabled && handlerRadioChange) || null}
    >
      {label}
    </LabelContainer>
  );

  return (
      <RadioContainer
        id={name}
        labelPosition={labelPosition}
        disabled={disabled}
        margin={margin}
        size={size}
      >
        {labelPosition === "before" && labelComponent}
        <Radio
          checked={(checked != null && checked) || innerChecked}
          name={name}
          onClick={handlerRadioChange}
          value={value}
          disabled={disabled}
          disableRipple
        />
        {labelPosition === "after" && labelComponent}
      </RadioContainer>
  );
};

const sizes = {
  small: "120px",
  medium: "240px",
  large: "480px",
  fillParent: "100%",
  fitContent: "unset",
};

const spaces = {
  xxsmall: "6px",
  xsmall: "16px",
  small: "24px",
  medium: "36px",
  large: "48px",
  xlarge: "64px",
  xxlarge: "100px",
};

const getMargin = (marginProp, side) => {
  if (marginProp && typeof marginProp === "object") {
    return (marginProp[side] && spaces[marginProp[side]]) || "0px";
  } else {
    return (marginProp && spaces[marginProp]) || "0px";
  }
};

const calculateWidth = (margin, size) => {
  if (size === "fillParent") {
    return `calc(${sizes[size]} - ${getMargin(margin, "left")} - ${getMargin(margin, "right")})`;
  }
  return sizes[size];
};

const RadioContainer = styled.span`
  width: ${(props) => calculateWidth(props.margin, props.size)};

  display: inline-flex;
  align-items: center;
  max-height: 42px;
  position: relative;
  vertical-align: top;
  margin: ${(props) => (props.margin && typeof props.margin !== "object" ? spaces[props.margin] : "0px")};
  margin-top: ${(props) =>
    props.margin && typeof props.margin === "object" && props.margin.top ? spaces[props.margin.top] : ""};
  margin-right: ${(props) =>
    props.margin && typeof props.margin === "object" && props.margin.right ? spaces[props.margin.right] : ""};
  margin-bottom: ${(props) =>
    props.margin && typeof props.margin === "object" && props.margin.bottom ? spaces[props.margin.bottom] : ""};
  margin-left: ${(props) =>
    props.margin && typeof props.margin === "object" && props.margin.left ? spaces[props.margin.left] : ""};
  cursor: ${(props) => (props.disabled === true ? "not-allowed" : "default")};
  .MuiButtonBase-root {
    width: auto;
    height: auto;
    padding: 10px;
    margin: 2px;
    ${(props) => (props.labelPosition === "after" ? "padding-right" : "padding-left")}: ${(props) =>
      props.theme.circleLabelSpacing};
    padding-left: ${(props) => (props.labelPosition === "after" ? "0px" : "")};
    padding-right: ${(props) => (props.labelPosition === "before" ? "0px" : "")};
    margin-left: ${(props) => (props.labelPosition === "after" ? "0px" : "")};
    margin-right: ${(props) => (props.labelPosition === "before" ? "0px" : "")};
    .MuiIconButton-label {
      .MuiSvgIcon-root {
        height: 18px;
        width: 18px;
      }
      color: #0067b3;

      > div > :nth-child(2) path {
        color: #0067b3;
      }
    }

    &.Mui-focusVisible {
      background-color: transparent;
      .MuiSvgIcon-root {
        outline: #0095ff auto 1px;
      }
    }
    :hover {
      background-color: transparent;
    }
    .MuiTouchRipple-ripple {
      height: 40px !important;
      width: 40px !important;
      top: 0px !important;
      left: 0px !important;
    }
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #0067b3;
    :hover {
      background-color: transparent;
    }
  }
`;

const LabelContainer = styled.span`
  font-family: "Open Sans, sans-serif",;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: inherit;
  cursor: ${(props) => (props.disabled === true ? "not-allowed" : "pointer")};
`;

export default CSAMRadio;
