import styled from "styled-components";

const Tag = ({ type, text }) => {
  return (
    <DivTag type={type}>
      <Label>{text}</Label>
    </DivTag>
  );
};

const Label = styled.p`
  font-family: var(--font-family-sans);
  font-size: var(--font-scale-01);
  font-weight: 600;
  margin: 0;
`;

const DivTag = styled.div`
  --color-white: #ffffff;
  --color-black: #000000;

  --color-grey-100: #f2f2f2;
  --color-grey-700: #666666;
  --color-grey-800: #4d4d4d;

  --color-green-100: #eafaef;
  --color-green-700: #24a148;
  --color-green-800: #1c7d38;

  --color-yellow-100: #fef9e6;
  --color-yellow-700: #c59f07;
  --color-yellow-800: #947705;

  --color-blue-100: #e6f4ff;
  --color-blue-700: #0086e6;
  --color-blue-800: #0067b3;

  --color-red-100: #ffe6e9;
  --color-red-700: #d0011b;
  --color-red-800: #980115;

  --font-scale-01: 0.75rem;
  --font-family-sans: "Open Sans", sans-serif;

  --border-width-1: 1px;

  height: calc(1.5rem - 2 * 1px);
  padding: 0 1rem;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;

  background-color: ${(props) => {
    switch (props.type) {
      case "success":
        return "var(--color-green-100)";
      case "warning":
        return "var(--color-yellow-100)";
      case "info":
        return "var(--color-blue-100)";
      case "error":
        return "var(--color-red-100)";
      default:
        return "var(--color-grey-100)";
    }
  }};

  border: ${(props) => {
    switch (props.type) {
      case "success":
        return "var(--border-width-1) solid var(--color-green-700)";
      case "warning":
        return "var(--border-width-1) solid var(--color-yellow-700)";
      case "info":
        return "var(--border-width-1) solid var(--color-blue-700)";
      case "error":
        return "var(--border-width-1) solid var(--color-red-700)";
      default:
        return "var(--border-width-1) solid var(--color-grey-700)";
    }
  }};

  color: ${(props) => {
    switch (props.type) {
      case "success":
        return "var(--color-green-800)";
      case "warning":
        return "var(--color-yellow-800)";
      case "info":
        return "var(--color-blue-800)";
      case "error":
        return "var(--color-red-800)";
      default:
        return "var(--color-grey-800)";
    }
  }};
`;

export default Tag;
