//React hooks imports
import { useState, useMemo, useEffect } from "react";

//configuation helpers imports
import { config } from "../config";
import {
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  PROMISE_FINAL_STATUSES,
} from "../common/utils";

//DXC HAL
import { HalApiCaller } from "@dxc-technology/halstack-client";
import { useHalResource } from "@dxc-technology/halstack-react-hal";

import { useParams } from "react-router-dom";

const useEnvironmentMigration = () => {
  const [error, setError] = useState(null);
  const { customerId, accountId, environmentId } = useParams();
  const [deploymentPackagesToMigrate, setDeploymentPackagesToMigrate] = useState([{}]);
  const [migrateActive, setIsMigrateActive] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [environmentResource, environmentStatus, environmentError, environmentHandlers] =
    useHalResource({
      url: `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}/deployment-packages`,
      asyncHeadersHandler: getSessionHeaders,
      headers: getPlatformHeaders(),
    });

  const [
    deploymentPackagesResource,
    deploymentPackagesStatus,
    deploymentPackagesError,
    deploymentPackagesHandlers,
  ] = useHalResource({
    url: `${config.environments_api_url}/deployment-packages`,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const envDeploymentPackagesList = useMemo(() => {
    const _envDeploymentPackagesList =
      (environmentResource && environmentResource.getItems()) || [];
    return _envDeploymentPackagesList.map((deploymentPackage) => ({
      value: deploymentPackage.summary.service_name,
      label: deploymentPackage.summary.service_name,
    }));
  }, [environmentResource]);

  const deploymentPackagesList = useMemo(() => {
    const _deploymentPackagesList =
      (deploymentPackagesResource && deploymentPackagesResource.getItems()) || [];
    return _deploymentPackagesList.map(
      (deploymentPackage) => deploymentPackage.summary.service_name
    );
  }, [deploymentPackagesResource]);

  const migrateEnvironmentStatus = useMemo(
    () =>
      (environmentStatus === "fetching" ||
        environmentStatus === "interaction" ||
        deploymentPackagesStatus === "fetching" ||
        deploymentPackagesStatus === "interaction") &&
      "fetching",
    [environmentStatus, deploymentPackagesStatus]
  );

  //Error messages
  useEffect(() => {
    if (
      PROMISE_FINAL_STATUSES.includes(environmentStatus) &&
      PROMISE_FINAL_STATUSES.includes(deploymentPackagesStatus) &&
      (environmentError || deploymentPackagesError)
    ) {
      const _error = environmentError ?? deploymentPackagesError;
      setError(buildApplicationError(_error));
    }
  }, [environmentError, deploymentPackagesError, environmentStatus, deploymentPackagesStatus]);

  useEffect(() => {
    setIsMigrateActive(
      deploymentPackagesToMigrate.some(
        (deploymentPackage) => deploymentPackage.source && deploymentPackage.target
      )
    );
  }, [deploymentPackagesToMigrate]);

  const onChangeDeploymentPackage = (newValue, index) => {
    const newArray = [...deploymentPackagesToMigrate];
    newArray.splice(index, 1, newValue);
    setDeploymentPackagesToMigrate(newArray);
  };

  const addDeploymentPackage = () => {
    setDeploymentPackagesToMigrate(deploymentPackagesToMigrate.concat([{}]));
  };

  const deleteDeploymentPackageToMigrate = (index) => {
    const newArray = [...deploymentPackagesToMigrate];
    newArray.splice(index, 1);
    setDeploymentPackagesToMigrate(newArray);
  };

  const dismissMessage = () => {
    setErrorMessage(null);
  };

  const onMigrate = async () => {
    dismissMessage();
    const payload = {
      migratedDeploymentPackages: deploymentPackagesToMigrate.filter(
        (deploymentPackage) => deploymentPackage.source && deploymentPackage.target
      ),
    };
    const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
    await HalApiCaller.patch({
      url: `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}`,
      headers: { ...asyncHeaders, ...getPlatformHeaders() },
      body: payload,
    }).then(async () => {
        setDeploymentPackagesToMigrate([{}]);

        await Promise.all([
          environmentHandlers["list-deployment-package"](),
          deploymentPackagesHandlers["list-deployment-package"](),
        ]);

        setErrorMessage({
          type: "confirm",
          message: "Your environment was successfully migrated in the platform",
        });
      })
      .catch((error) => {
        error.body?.messages
          ? setErrorMessage({
              type: "error",
              message: `${error.body.messages[0].message}`,
            })
          : setErrorMessage({
              type: "error",
              message: "An error ocurred when migrating the environment",
            });
      });
  };

  return {
    customerId,
    accountId,
    environmentId,
    migrateActive,
    migrateEnvironmentStatus,
    errorMessage,
    envDeploymentPackagesList,
    deploymentPackagesList,
    deploymentPackagesToMigrate,
    onChangeDeploymentPackage,
    addDeploymentPackage,
    deleteDeploymentPackageToMigrate,
    dismissMessage,
    onMigrate,
    error,
  };
};

export default useEnvironmentMigration;
