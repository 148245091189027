import React from "react";
import styled from "styled-components";

//DXC CDK Components
import { DxcHeading } from "@dxc-technology/halstack-react";

//search image
import CollapsableLink from "../deploy-deployment-package-steps/helper/CollapsableLink";
import EmptySection from "../components/EmptySection";
import KeyValuePair from "../components/KeyValuePair";

function DeploymentHistoryData({
  sourceURL,
  sourceBranch,
  artefactList,
  duParameterList: deploymentPackageParameterList,
  isLinkCollapsed,
  onChangeIsLinkCollapsed,
}) {
  const toggleArtefactCollapsedLink = (value, index) => {
    onChangeIsLinkCollapsed(
      isLinkCollapsed.map((item, itemIndex) => (itemIndex === index ? (item = value) : item))
    );
  };

  return (
    <MainContainer>
      <SectionContainer>
        <SectionHeader>
          <DxcHeading
            level={3}
            text="Deployment package information"
            margin={{ bottom: "xsmall" }}
          />
        </SectionHeader>
        <KeyValuePair label="Source URL" value={sourceURL} />
        <KeyValuePair label="Source branch/tag" value={sourceBranch} />
      </SectionContainer>
      <SectionContainer>
        <SectionHeader>
          <DxcHeading level={3} text="Artefact information" margin={{ bottom: "xsmall" }} />
        </SectionHeader>
        {artefactList.length ? (
          artefactList.map((artefact, artefactIndex) => (
            <ArtefactContainer>
              <CollapsableLinkContainer>
                <CollapsableLink
                  text={`Artefact (${artefactIndex})`}
                  isCollapsed={isLinkCollapsed[artefactIndex]}
                  onClick={(value) => toggleArtefactCollapsedLink(value, artefactIndex)}
                ></CollapsableLink>
              </CollapsableLinkContainer>
              {!isLinkCollapsed[artefactIndex] && artefact.properties.length > 0
                ? artefact.properties.map((property) => (
                    <KeyValuePair label={property.name} value={property.value} />
                  ))
                : null}
            </ArtefactContainer>
          ))
        ) : (
          <EmptySectionContainer>
            <EmptySection text="There are no deployment package artefacts defined" />
          </EmptySectionContainer>
        )}
      </SectionContainer>
      <SectionContainer>
        <SectionHeader>
          <DxcHeading
            level={3}
            text="Deployment package parameters information"
            margin={{ bottom: "xsmall" }}
          />
        </SectionHeader>
        {deploymentPackageParameterList.length > 0 ? (
          deploymentPackageParameterList.map((deploymentPackage) => (
            <KeyValuePair label={deploymentPackage.name} value={deploymentPackage.value} />
          ))
        ) : (
          <EmptySectionContainer>
            <EmptySection text="There are no parameters defined" />
          </EmptySectionContainer>
        )}
      </SectionContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SectionHeader = styled.div`
  display: flex;
  margin-bottom: 12px;
  width: 100%;
  border-bottom: 2px solid #6f2c91;

  margin-bottom: 32px;
`;

const SectionContainer = styled.div`
  margin-top: 32px;
`;

const CollapsableLinkContainer = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  min-height: 48px;
`;

const ArtefactContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmptySectionContainer = styled.div`
  width: 100%;
`;

export default DeploymentHistoryData;
