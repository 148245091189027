import React from "react";
import styled from "styled-components";

//DXC CDK Components
import { DxcResultsetTable } from "@dxc-technology/halstack-react";
import { useMemo } from "react";

import AddDeploymentPackageParameter from "./dialog/AddDeploymentPackageParameter";
import EditDeploymentPackageParameter from "./dialog/EditDeploymentPackageParameter";
import { deploymentPackageParametersColumns } from "./DeploymentPackageDefinitionHelper";
import binImage from "./images/delete-24px.svg";
import editImage from "./images/create-24px.svg";

function DeploymentPackageParameters({
  deploymentPackageParameters,
  isAddDialogVisible,
  isEditDialogVisible,
  onAddParamClick,
  onAddSaveClick,
  onAddCancelClick,
  onEditSaveClick,
  onEditCancelClick,
  onEditRemoveClick,
}) {
  const deploymentPackageParametersList = useMemo(() => {
    const _deploymentPackageParametersList = deploymentPackageParameters
      ? deploymentPackageParameters
      : [];

    return _deploymentPackageParametersList.map((deploymentPackage) => [
      { displayValue: deploymentPackage.name },
      { displayValue: deploymentPackage.type },
      { displayValue: deploymentPackage.required ? "Yes" : "No" },
      {
        displayValue: (
          <ImageContainer>
            <Image
              src={editImage}
              alt={`edit ${deploymentPackage.name}`}
              onClick={deploymentPackage.onEditOpenClick}
            ></Image>
            <Image
              src={binImage}
              alt={`delete ${deploymentPackage.name}`}
              onClick={() => onEditRemoveClick(deploymentPackage.name)}
            ></Image>
          </ImageContainer>
        ),
      },
    ]);
  }, [onEditRemoveClick, deploymentPackageParameters]);

  return (
    <DeploymentPackageParametersContainer>
      {deploymentPackageParametersList.length ? (
        <DxcResultsetTable
          columns={deploymentPackageParametersColumns}
          rows={deploymentPackageParametersList}
          itemsPerPage={10}
          margin={0}
        ></DxcResultsetTable>
      ) : (
        <ParametersEmpty>
          <EmptyText>There are no parameters</EmptyText>
        </ParametersEmpty>
      )}

      {!!isAddDialogVisible ? (
        <AddDeploymentPackageParameter
          role="dialog"
          onSaveClick={onAddSaveClick}
          onCancelClick={onAddCancelClick}
        />
      ) : null}

      {isEditDialogVisible ? (
        <EditDeploymentPackageParameter
          role="dialog"
          deploymentPackageParameterName={isEditDialogVisible.name}
          deploymentPackageParameterType={isEditDialogVisible.type}
          enumValues={isEditDialogVisible.enumValues}
          isRequired={isEditDialogVisible.isRequired}
          onSaveClick={onEditSaveClick}
          onCancelClick={onEditCancelClick}
        />
      ) : null}
    </DeploymentPackageParametersContainer>
  );
}

const DeploymentPackageParametersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ParametersEmpty = styled.div`
  background-color: #eeeeee;
  border-radius: 4px;
  width: 100%;
`;

const EmptyText = styled.p`
  text-align: center;
  color: #666;
  letter-spacing: 0.21px;
`;

const ImageContainer = styled.div`
  display: flex;
`;

const Image = styled.img`
  cursor: pointer;
  height: 24px;
  width: 24px;
`;

export default DeploymentPackageParameters;
