export const deploySteps = [
  {
    label: "Deployment specification",
    id: "deploy_info",
    order: 0,
  },
  {
    label: "Deployment package parameters",
    id: "parameters",
    order: 1,
  },
  {
    label: "Deployment package artefacts",
    id: "artifacts",
    order: 2,
  },
  {
    label: "Deployment package dependencies",
    id: "linked_deploy_units",
    order: 3,
  },
  {
    label: "Deployment review",
    id: "review",
    order: 4,
  },
];

export const deployServiceSteps = [
  {
    label: "Deployment installation",
    id: "deploy_installation",
    order: 0,
  },
  {
    label: "Deployment packages parameters",
    id: "parameters",
    order: 1,
  },
];

export const refreshSteps = (withDependants = false, tfAction = "apply") => [
  {
    label: "Deployment specification",
    id: "deploy_info",
    order: 0,
  },
  {
    label: "Deployment package parameters",
    id: "parameters",
    order: 1,
  },
  {
    label: "Deployment package artefacts",
    id: "artifacts",
    order: 2,
  },
  {
    label: "Deployment package dependants",
    id: "linked_deploy_units",
    order: 3,
    disabled: tfAction === "upgrade_state" ? true : withDependants ? false : true,
  },
  {
    label: "Deployment review",
    id: "review",
    order: 4,
  },
];

export const getRecursiveDependants = (dependantList, deploymentPackagesList, deployments) => {
  const _dependantNames = dependantList.map((item) => item.service_name);
  const _dependantsToAdd = deploymentPackagesList.filter(
    (item) =>
      !_dependantNames.includes(item.service_name) &&
      item.dependsOn?.find((item) => _dependantNames.includes(item.service_name))
  );
  if (_dependantsToAdd?.length > 0) {
    dependantList.push(..._dependantsToAdd);
    getRecursiveDependants(dependantList, deploymentPackagesList, deployments);
  }
};

export const getDeploymentPackageData = (deploymentPackageID, deploymentPackagesList) =>
  deploymentPackagesList.find((item) => item.service_name === deploymentPackageID);

export const getActiveDeployment = (deploymentList, deploymentPackage) =>
  deploymentList.find((_deployment) => {
    return (
      _deployment.service_name === deploymentPackage.service_name &&
      !(
        _deployment.terraform_action === "destroy" &&
        _deployment.deployment_status === "successful"
      )
    );
  });

export const isDeploymentPackageInstalled = (deploymentList, deploymentPackage) =>
  deploymentList.find((_deployment) => {
    return (
      _deployment.service_name === deploymentPackage.service_name &&
      _deployment.deployment_status === "successful" &&
      (_deployment.terraform_action === "apply" ||
        _deployment.terraform_action === "upgrade_state")
    );
  });

export const getExistingDeployment = (deploymentList, deploymentPackage) =>
  deploymentList.find((_deployment) => {
    return _deployment.summary.service_name === deploymentPackage.service_name;
  });

export const formatDeploymentPackageDependencyParams = (deploymentPackageDependency, targetDeployment) => {
  if (deploymentPackageDependency) {
    const _lastDeploymentParameters = targetDeployment ? targetDeployment.parameters : {};
    let _lastDeploymentParameterKeys = _lastDeploymentParameters
      ? Object.keys(_lastDeploymentParameters)
      : [];
    deploymentPackageDependency.service_parameters = deploymentPackageDependency.service_parameters
      ? deploymentPackageDependency.service_parameters
      : [];
    deploymentPackageDependency.service_parameters = deploymentPackageDependency.service_parameters
      .map((param) =>
        Object.keys(param).map((key) => {
          if (_lastDeploymentParameterKeys.some((depKey) => depKey === key)) {
            _lastDeploymentParameterKeys = _lastDeploymentParameterKeys.filter(
              (depKey) => depKey !== key
            );
          }
          return {
            name: key,
            type: Object.values(param)[0].type,
            enumValues: Object.values(param)[0].enumValues,
            isRequired: Object.values(param)[0].required,
            value: targetDeployment ? getDeploymentPackageParamValueFromLastDeployment(targetDeployment, key) : "",
          };
        })
      )
      .map((item) => item[0]);
    const _expertParameters = _lastDeploymentParameterKeys.map((key) => ({
      name: key,
      value: _lastDeploymentParameters[key],
    }));
    deploymentPackageDependency.service_parameters = _expertParameters.length
      ? [...deploymentPackageDependency.service_parameters, ..._expertParameters]
      : deploymentPackageDependency.service_parameters;
  }
};

export const formatDeploymentPackageDependency = (deploymentPackageDependency, targetDeployment) => {
  const _deploymentPackageDependency = { ...deploymentPackageDependency };
  formatDeploymentPackageDependencyParams(_deploymentPackageDependency, targetDeployment);
  if (targetDeployment){
  _deploymentPackageDependency.source = targetDeployment?.deploy_info?.git_url
    ? targetDeployment.deploy_info.git_url
    : _deploymentPackageDependency.source;
  _deploymentPackageDependency.tag = targetDeployment?.deploy_info?.tag
    ? targetDeployment.deploy_info.tag
    : _deploymentPackageDependency.tag;
  _deploymentPackageDependency.artefacts = targetDeployment?.artifacts?.length
    ? targetDeployment.artifacts
    : [];
  _deploymentPackageDependency.ssm_conditions = targetDeployment?.ssm_conditions?.length
    ? targetDeployment.ssm_conditions
    : [];
  _deploymentPackageDependency.upgraded_to_version = targetDeployment?.upgraded_to_version
    ? targetDeployment.upgraded_to_version
    : null;
  }
  return _deploymentPackageDependency;
};

export const getDeploymentPackageDependencies = (deploymentPackageDependencies, deployments) => {
  let _deploymentPackageDependencies = { ...deploymentPackageDependencies };
  _deploymentPackageDependencies.installed = isDeploymentPackageInstalled(deployments, _deploymentPackageDependencies) ? true : false;
  const _formattedDepDu = formatDeploymentPackageDependency(_deploymentPackageDependencies, getActiveDeployment(deployments, _deploymentPackageDependencies));
  return _formattedDepDu;
};

export const getRecursiveDependencies = (
  item,
  _this,
  deploymentPackagesList,
  deployments
) => {
  const dependencyCandidatesList = item.dependsOn?.length
    ? item.dependsOn.filter(
        (dependency) =>
          !_this.some((deploymentPackage) => deploymentPackage.service_name === dependency.service_name)
      )
    : [];
  dependencyCandidatesList.forEach((dependencyCandidate) => {
    if (!_this.find((item) => item.service_name === dependencyCandidate.service_name)) {
      let deploymentPackageDependency = dependencyCandidate
        ? deploymentPackagesList.find(
            (deploymentPackage) => deploymentPackage.service_name === dependencyCandidate.service_name
          )
        : null;
      if (deploymentPackageDependency) {
        let _deploymentPackageDependency = { ...deploymentPackageDependency };
        _deploymentPackageDependency.installed = isDeploymentPackageInstalled(deployments, _deploymentPackageDependency)
          ? true
          : false;
        const _formattedDeploymentPackageDependency = formatDeploymentPackageDependency(
          _deploymentPackageDependency,
          getActiveDeployment(deployments, _deploymentPackageDependency)
        );
        _this.push(_formattedDeploymentPackageDependency);
        getRecursiveDependencies(
          _formattedDeploymentPackageDependency,
          _this,
          deploymentPackagesList,
          deployments
        );
      }
    }
  });
};

export const formatSelectOptions = (options) => {
  return options
    ? options.split(",").map((item) => ({
        value: item,
        label: item,
      }))
    : [];
};

export const getDeploymentPackageParamValueFromLastDeployment = (deployment, propertyName) => {
  return propertyName && deployment?.parameters
    ? deployment.parameters[propertyName]
    : "";
};

export const getDeploymentPackageParamsFromEnvironment = (deploymentPackageParams) => {
  const parameters = deploymentPackageParams.map((param) =>
    Object.keys(param).map((key) => ({
      name: key,
      type: Object.values(param)[0].type,
      enumValues: Object.values(param)[0].enumValues,
      isRequired: Object.values(param)[0].required,
      value: "",
    }))
  );

  return parameters.map((item) => {
    return item[0];
  });
};

const buildParametersPayload = (deploymentPackageInfo, deploymentPackageParameters, expertParameters) => {
  let _deploymentPackagePayload = { ...deploymentPackageInfo, parameters: {} };

  // required deployment package params
  if (deploymentPackageParameters && deploymentPackageParameters.length) {
    deploymentPackageParameters.forEach((sp) => {
      if (sp.value) {
        _deploymentPackagePayload.parameters[sp.name] = sp.value;
      }
    });
  }

  // expert mode params
  if (expertParameters && expertParameters.length) {
    expertParameters.forEach((sp) => {
      if (sp.value && sp.name) {
        _deploymentPackagePayload.parameters[sp.name] = sp.value;
      }
    });
  }

  return _deploymentPackagePayload;
};

const buildArtefactsPayload = (deploymentPackageInfo, deploymentPackagertefactsList) => {
  let _deploymentPackagePayload = { ...deploymentPackageInfo };
  if (deploymentPackagertefactsList && deploymentPackagertefactsList.length) {
    _deploymentPackagePayload.artifacts = deploymentPackagertefactsList.map((_artefact) => {
      return {
        uniquename: _artefact.uniquename,
        tf_properties: _artefact.properties.reduce((tf_props, prop) => {
          if (prop.value && prop.name) {
            tf_props[prop.name] = prop.value;
          }
          return tf_props;
        }, {}),
      };
    });
  } else {
    _deploymentPackagePayload.artifacts = [];
  }
  return _deploymentPackagePayload;
};

export const buildDeploymentPackagePayload = (
  deploymentPackageInfo,
  deploymentPackageParameters = [],
  expertParameters = [],
  deploymentPackageArtefactsList
) => {
  let _deploymentPackagePayload = { ...deploymentPackageInfo };
  _deploymentPackagePayload = buildParametersPayload(_deploymentPackagePayload, deploymentPackageParameters, expertParameters);
  if (deploymentPackageArtefactsList) _deploymentPackagePayload = buildArtefactsPayload(_deploymentPackagePayload, deploymentPackageArtefactsList);
  return _deploymentPackagePayload;
};

export const getDeploymentsFromEmbeddedObject = (embeddedObject) => {
  const _deploymentsCollection = embeddedObject._links.item.reduce((result, dp) => {
    const _deployments = dp._embedded?.item?._embedded?._links?.item.map((item) => ({
      resource_name: item._embedded?.item?.resource_name,
      service_name: item._embedded?.item?.service_name,
      version: item._embedded?.item?.version,
      resource_subtype: item._embedded?.item?.resource_subtype,
      aws_region: item._embedded?.item?.aws_region,
      locationURL: item._embedded?.item?.locationURL,
      deployment_date: item._embedded?.item?.deployment_date,
      deployment_status: item._embedded?.item?.deployment_status,
      tf_request_date: item._embedded?.item?.tf_request_date,
      tf_request_status: item._embedded?.item?.tf_request_status,
      creator: item._embedded?.item?.creator,
      terraform_action: item._embedded?.item?.terraform_action,
      deployment_packages: item._embedded?.item?.deployment_packages,
      artifacts: item._embedded?.item?.artifacts,
      dependsOn: item._embedded?.item?.dependsOn,
      deploy_info: item._embedded?.item?.deploy_info,
      parameters: item._embedded?.item?.parameters,
      ssm_conditions: item._embedded?.item?.ssm_conditions,
      skip_on_destroy: item._embedded?.item?.skip_on_destroy,
      upgraded_to_version: item._embedded?.item?.upgraded_to_version,
    }));
    return _deployments ? result.concat(_deployments) : result;
  }, []);
  return _deploymentsCollection.filter(
    (deployment, index, self) =>
      index ===
      self.findIndex(
        (existingDeployment) => existingDeployment.resource_name === deployment.resource_name
      )
  );
};