import React, { useMemo } from "react";
import styled from "styled-components";

import { DxcAccordion, DxcTable } from "@dxc-technology/halstack-react";

//Custom components
import SectionContainer from "../components/SectionContainer";

import { readyIcon } from "./images/readyIcon";
import { pendingIcon } from "./images/pendingIcon";

function ReviewDeployRequest({
  operation,
  reviewDeploymentPackageDeployDataInfo,
  reviewDeploymentPackageParametersInfo,
  reviewDeploymentPackageArtefactsInfo,
  reviewLinkedDeploymentPackageInfo,
}) {
  const showLinkedDeploymentPackage = reviewDeploymentPackageDeployDataInfo?.mode === "direct-refresh" ? false : true;

  const deploymentMode = useMemo(() => {
    switch (operation) {
      case "install": return reviewDeploymentPackageDeployDataInfo.mode === "all" ? "Deploy all dependencies" : "Deploy only uninstalled dependencies";
      case "refresh": return reviewDeploymentPackageDeployDataInfo.mode === "propagated" ? "Deploy with dependants" : "Deploy alone";
      default: return "";
    }
  },[operation, reviewDeploymentPackageDeployDataInfo.mode]);

  return (
    <MainContainer>
      <SectionContainer
        title="Deployment script specifications"
        padding={{ top: "none", bottom: "small", left: "small", right: "small" }}
      >
        <DxcTable margin={{ top: "xsmall" }}>
          <tbody>
            <tr>
              <Cell>
                <KeyEntry>Script source URL</KeyEntry>
                <ValueEntry>{reviewDeploymentPackageDeployDataInfo.source}</ValueEntry>
              </Cell>
            </tr>
            <tr>
              <Cell>
                <KeyEntry>Script source branch/tag</KeyEntry>
                <ValueEntry>{reviewDeploymentPackageDeployDataInfo.tag}</ValueEntry>
              </Cell>
            </tr>
          </tbody>
        </DxcTable>
      </SectionContainer>

      <SectionContainer
        title="Deployment options"
        padding={{ top: "none", bottom: "small", left: "small", right: "small" }}
      >
        <DxcTable margin={{ top: "xsmall" }}>
          <tbody>
            <tr>
              <Cell>
                <KeyEntry>Action</KeyEntry>
                <ValueEntry>
                  {reviewDeploymentPackageDeployDataInfo.tfAction === "plan"
                    ? "Plan"
                    : reviewDeploymentPackageDeployDataInfo.tfAction === "apply"
                    ? "Apply"
                    : "Upgrade"}
                </ValueEntry>
              </Cell>
            </tr>
            <tr>
              <Cell>
                <KeyEntry>Mode</KeyEntry>
                <ValueEntry>{deploymentMode}</ValueEntry>
              </Cell>
            </tr>
          </tbody>
        </DxcTable>
      </SectionContainer>

      <SectionContainer
        title="Deployment package parameters"
        padding={{ top: "none", bottom: "small", left: "small", right: "small" }}
      >
        {reviewDeploymentPackageParametersInfo.deploymentPackageParametersList.length ? (
          <DxcTable margin={{ top: "xsmall" }}>
            <tbody>
              {reviewDeploymentPackageParametersInfo.deploymentPackageParametersList.map((parameter, index) => (
                <tr key={`parameter_row_${index}`}>
                  <Cell>
                    <KeyEntry>{parameter.name}</KeyEntry>
                    <ValueEntry>{parameter.value}</ValueEntry>
                  </Cell>
                </tr>
              ))}
            </tbody>
          </DxcTable>
        ) : (
          <ParametersEmpty>
            <EmptyText>There are no parameters</EmptyText>
          </ParametersEmpty>
        )}
      </SectionContainer>

      <SectionContainer
        title="Deployment package artefacts"
        padding={{ top: "none", bottom: "small", left: "small", right: "small" }}
      >
        {reviewDeploymentPackageArtefactsInfo.deploymentPackageArtefactsList.length ? (
          reviewDeploymentPackageArtefactsInfo.deploymentPackageArtefactsList.map((artefact, artefactIndex) => (
            <DxcAccordion
              key={`artefact_accordion_${artefactIndex}`}
              label={`Artefact (${artefactIndex})`}
              margin={{ top: "small" }}
              padding="small"
            >
              {artefact.properties.length ? (
                <DxcTable>
                  <tbody>
                    {artefact.properties.map((property, index) => (
                      <tr key={`artefact_row_${index}`}>
                        <Cell>
                          <KeyEntry>{property.name}</KeyEntry>
                          <ValueEntry>{property.value}</ValueEntry>
                        </Cell>
                      </tr>
                    ))}
                  </tbody>
                </DxcTable>
              ) : null}
            </DxcAccordion>
          ))
        ) : (
          <ParametersEmpty>
            <EmptyText>There are no artefacts</EmptyText>
          </ParametersEmpty>
        )}
      </SectionContainer>

      {showLinkedDeploymentPackage ? (
        <SectionContainer
          title="Linked deployment packages"
          padding={{ top: "none", bottom: "small", left: "small", right: "small" }}
        >
          {reviewLinkedDeploymentPackageInfo.linkedDeploymentPackagesList.length ? (
            reviewLinkedDeploymentPackageInfo.linkedDeploymentPackagesList.map((deploymentPackage, index) => (
              <DxcAccordion
                key={`linked_dus_accordeon_${index}`}
                label={`Deployment package (${index}): ${deploymentPackage.service_name}`}
                margin={{ top: "small" }}
                padding={{ left: "small", right: "small", bottom: "small" }}
                icon={deploymentPackage.installed ? readyIcon : pendingIcon}
                assistiveText={deploymentPackage.installed ? "Installed" : ""}
              >
                {deploymentPackage.service_parameters.length ? (
                  <DxcTable margin={{ top: "small" }}>
                    <tbody>
                      {deploymentPackage.service_parameters.map((parameter, index) => (
                        <tr key={`linked_dus_parameter_row_${index}`}>
                          <Cell>
                            <KeyEntry>{parameter.name}</KeyEntry>
                            <ValueEntry>{parameter.value}</ValueEntry>
                          </Cell>
                        </tr>
                      ))}
                    </tbody>
                  </DxcTable>
                ) : (
                  <ParametersEmpty>
                    <EmptyText>There are no parameters</EmptyText>
                  </ParametersEmpty>
                )}
              </DxcAccordion>
            ))
          ) : (
            <ParametersEmpty>
              <EmptyText>There are no deployment packages</EmptyText>
            </ParametersEmpty>
          )}
        </SectionContainer>
      ) : null}
    </MainContainer>
  );
}
const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 46px;
  padding-right: 36px;
`;

const KeyEntry = styled.div`
  display: flex;
  align-items: center;
  min-width: 25%;
  margin-left: 24px;
`;

const ValueEntry = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: 24px;
`;

const ParametersEmpty = styled.div`
  background-color: #eeeeee;
  border-radius: 4px;
  height: 52px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
`;

const EmptyText = styled.p`
  text-align: center;
  color: #666;
  letter-spacing: 0.21px;
`;

const Cell = styled.td`
  padding: 3px 0px !important;
  display: flex;
  flex-direction: row;
`;

export default ReviewDeployRequest;
