//React imports
import React, { useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import { DxcBox } from "@dxc-technology/halstack-react";

//CardImages
import plusImage from "./Path_2839.svg";

function CreateCustomerCard({ onClick }) {
  const [isHovered, onHoverChanged] = useState(false);

  return (
    <DxcBox
      margin={{
        top: "small",
        bottom: "small",
        right: "xsmall",
        left: "xsmall",
      }}
      shadowDepth={isHovered ? 1 : 0}
    >
      <CardInfo
        onClick={() => onClick()}
        onMouseEnter={() => onHoverChanged(true)}
        onMouseLeave={() => onHoverChanged(false)}
      >
        <CardInfoContent>
          <PlusImg src={plusImage}></PlusImg> Create new customer
        </CardInfoContent>
      </CardInfo>
    </DxcBox>
  );
}

const CardInfo = styled.div`
  height: 229px;
  width: 405px;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

const CardInfoContent = styled.p`
  background: #d9d9d933;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlusImg = styled.img`
  width: 24px;
  height: 24px;
  margin: 12px;
 `;

export default CreateCustomerCard;
