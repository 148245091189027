import React, { useMemo } from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcResultsetTable,
  DxcSelect,
  DxcTextInput,
  DxcDropdown,
  DxcFlex,
} from "@dxc-technology/halstack-react";

//custom hook
import useDeploymentPackageDeploymentsDashboard from "./useDeploymentPackageDeploymentsDashboard";

//images
import more_horiz from "./images/more_horiz-24px.svg";
import backImage from "../deployment-package-definition/images/arrow_back_ios-24px.svg";
import tfWarningImage from "./images/tf-warning-24px.svg";

//constant objects
import { columns, options } from "./DeploymentPackageDeploymentsDashboardHelper";
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";
import { formatDate } from "../common/utils";

function DeploymentPackageDeploymentsDashboard() {
  const [
    isLoading,
    dashboardMessage,
    rows,
    deploymentsStatusSelected,
    deploymentsStatus,
    textFilter,
    onChangeFilterDeploymentStatus,
    onChangeFilterText,
    dismissMessage,
    navigateDeploymentPackages,
    deploymentPackageName,
    error,
  ] = useDeploymentPackageDeploymentsDashboard();
  useErrorHandler(error);

  const textFilterHandler = ({ value }) => {
    onChangeFilterText(value);
  };

  const statusFilterHandler = ({ value }) => {
    onChangeFilterDeploymentStatus(value);
  };

  const deploymentsDataList = useMemo(() => {
    const _envDataList = rows ? rows : [];

    return _envDataList.map((item) => [
      { displayValue: item.customerName },
      { displayValue: item.accountName },
      { displayValue: item.environmentName },
      {
        displayValue:
          item.status !== "successful" && item.status !== "failed" ? (
            <StatusContainer>
              <span className="es-capitalized-text">{item.terraformAction}</span>
              <span data-title={item.status.replace(/-/g, " ")}>
                <Image src={tfWarningImage} />
              </span>
            </StatusContainer>
          ) : (
            <StatusContainer>
              <span className="es-capitalized-text">{`${item.terraformAction} ${item.status}`}</span>{" "}
            </StatusContainer>
          ),
        sortValue: `${item.terraformAction} ${item.status}`,
      },
      {
        displayValue: formatDate(item.deploymentDate),
        sortValue: item.deploymentDate,
      },
      {
        displayValue: item.version ? item.version : "Custom",
      },
      {
        displayValue: (
          <DxcDropdown
            options={options}
            onSelectOption={(option) => item.onClickOption(option)}
            icon={more_horiz}
            caretHidden={true}
            theme="dark"
          ></DxcDropdown>
        ),
      },
    ]);
  }, [rows]);

  const optionsDeploymentStatus = useMemo(() => {
    return deploymentsStatus.map((status) => {
      const statusLabel = status.replace(/-/g, " ");
      return {
        label: statusLabel[0].toUpperCase() + statusLabel.substr(1),
        value: status,
      };
    });
  }, [deploymentsStatus]);

  return (
    <Content>
      <StyledLink onClick={navigateDeploymentPackages}>
        <BackImg src={backImage} />
        Back to deployment packages
      </StyledLink>

      <DxcFlex alignItems="baseline">
        <DxcHeading
          level={2}
          weight="normal"
          text="Deployments for:"
          margin={{ top: "medium", bottom: "medium" }}
        />
        <DxcHeading
          level={2}
          text={deploymentPackageName}
          margin={{ top: "medium", left: "xxsmall", bottom: "medium" }}
        />
      </DxcFlex>

      {dashboardMessage && (
        <DxcAlert
          type={dashboardMessage.type}
          mode="inline"
          inlineText={dashboardMessage.message}
          onClose={dismissMessage}
          size="fillParent"
          margin={{ bottom: "small" }}
        />
      )}

      <div>
        <FilterWrapper>
          <InputWrapper75>
            <DxcTextInput
              label="Search by customer, account or environment"
              value={textFilter}
              onChange={textFilterHandler}
              onBlur={textFilterHandler}
              margin={{ bottom: "xsmall" }}
              size={"fillParent"}
              placeholder="e.g.: my-environment"
              clearable={true}
            />
          </InputWrapper75>

          <SelectWrapper25>
            <DxcSelect
              options={optionsDeploymentStatus}
              onChange={statusFilterHandler}
              label="Select deployment status"
              multiple={true}
              value={deploymentsStatusSelected}
              size={"fillParent"}
              margin={{ left: "medium" }}
            ></DxcSelect>
          </SelectWrapper25>
        </FilterWrapper>

        {deploymentsDataList && (
          <DxcResultsetTable
            columns={columns}
            rows={deploymentsDataList}
            itemsPerPage={5}
            margin={{ top: "medium" }}
          ></DxcResultsetTable>
        )}
      </div>

      {isLoading ? <DxcSpinner theme="light" label="Loading..." mode="overlay" /> : null}
    </Content>
  );
}

const Content = styled.div``;

const StatusContainer = styled.div`
  display: flex;
`;

const Image = styled.img`
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-right: 6px;
`;

const StyledLink = styled.div`
  display: flex;
  width: fit-content;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  top: 16px;
  :hover {
    cursor: pointer;
  }
`;

const BackImg = styled.img`
  width: 20px;
  height: 20px;
`;

const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const InputWrapper75 = styled.div`
  width: 66%;
`;

const SelectWrapper25 = styled.div`
  width: 34%;
`;

export default withErrorBoundary(DeploymentPackageDeploymentsDashboard, {
  FallbackComponent: ApplicationError,
});
