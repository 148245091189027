import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useMemo } from "react";

//DXC CDK Components
import {
  DxcSelect,
  DxcResultsetTable,
  DxcTextInput,
  DxcCheckbox,
  DxcParagraph,
  DxcInset,
  DxcTypography,
  DxcFlex,
} from "@dxc-technology/halstack-react";

import { servicesDeploymentPackageColumns } from "./DeploymentPackageDefinitionHelper";

import LinkDeprecatedDeploymentPackage from "./dialog/LinkDeprecatedDeploymentPackage";

function AvailableServices({
  availableDeploymentPackages,
  textFilter,
  deploymentPackageServiceFilter,
  checkedDeprecated,
  onChangeTextFilter,
  onChangeDeploymentPackageServiceFilter,
  onChangeDeprecated,
  onClickSelected,
  onChangeServiceFilter,
  services,
  serviceFilter,
}) {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showDeprecatedDialog, setShowDeprecatedDialog] = useState(false);
  const [currentDeploymentPackage, setCurrentDeploymentPackage] = useState(null);

  const itemsPerPageClick = (value) => {
    setItemsPerPage(value);
  };

  const clickHandler = useCallback(
    (deploymentPackageName, deploymentPackageType) => {
      if (onClickSelected) {
        onClickSelected(deploymentPackageName, deploymentPackageType);
      }
      setShowDeprecatedDialog(false);
      setCurrentDeploymentPackage(null);
      return false;
    },
    [onClickSelected]
  );

  const deprecatedClickHandler = (deploymentPackageName, deploymentPackageType) => {
    setShowDeprecatedDialog(true);
    setCurrentDeploymentPackage({
      deploymentPackageName,
      deploymentPackageType,
    });
  };

  const availableDeploymentPackagesList = useMemo(() => {
    const _deploymentPackagesList = availableDeploymentPackages ? availableDeploymentPackages : [];

    return _deploymentPackagesList.map((deploymentPackage) => [
      {
        displayValue: (
          <DeploymentPackageNameContainer>
            {deploymentPackage.deploymentPackageName}{" "}
            {deploymentPackage.isDeprecated && (
              <Tag>
                <TagLabel>Deprecated</TagLabel>
              </Tag>
            )}
          </DeploymentPackageNameContainer>
        ),
      },
      { displayValue: deploymentPackage.deploymentPackageService },
      {
        displayValue: (
          <DxcCheckbox
            checked={deploymentPackage.isSelected}
            onChange={() =>
              deploymentPackage.isDeprecated && !deploymentPackage.isSelected
                ? deprecatedClickHandler(
                    deploymentPackage.deploymentPackageName,
                    deploymentPackage.deploymentPackageService
                  )
                : clickHandler(
                    deploymentPackage.deploymentPackageName,
                    deploymentPackage.deploymentPackageService
                  )
            }
          />
        ),
        sortValue: deploymentPackage.isSelected,
      },
    ]);
  }, [availableDeploymentPackages, clickHandler]);

  const servicesFilter = (services) => {
    return (
      services &&
      services.map((service) => ({
        value: service.name,
        label: service.name,
      }))
    );
  };

  return (
    <Content>
      <SearchContainers>
        <ColumnLeft>
          <HeadingColumn>Choose a service</HeadingColumn>
          <ServiceContainer>
            <DxcTextInput
              label="Search for a service"
              placeholder="e.g.: my-service-name"
              id="serviceNameFilter"
              name="serviceNameFilter"
              value={serviceFilter}
              onChange={({ value }) => onChangeServiceFilter(value)}
              size={"fillParent"}
              clearable={true}
            />
            <ServicesListContainer>
              {services && services.length ? (
                <ul>
                  {services.map((service) => (
                    <li>
                      <DxcCheckbox
                        checked={service.selected}
                        labelPosition="after"
                        label={service.name}
                        onChange={() => service.onChangeSelected(service.name)}
                      />
                    </li>
                  ))}
                </ul>
              ) : (
                <ServicesEmpty>
                  <EmptyText>There are no services</EmptyText>
                </ServicesEmpty>
              )}
            </ServicesListContainer>
          </ServiceContainer>
        </ColumnLeft>

        <ColumnRight>
          <HeadingColumn>Deployment packages</HeadingColumn>
          <DxcFlex direction="column">
            <DxcTextInput
              label="Search for a deployment package"
              placeholder="e.g.: my-deployment-package-name"
              id="deploymentPackageNameFilter"
              name="deploymentPackageNameFilter"
              value={textFilter}
              onChange={({ value }) => onChangeTextFilter(value)}
              size={"fillParent"}
              clearable={true}
              margin={{ bottom: "small" }}
            />

            <DxcFlex justifyContent="space-between" alignItems="center">
              <DxcSelect
                options={servicesFilter(services)}
                onChange={({ value }) => onChangeDeploymentPackageServiceFilter(value)}
                label="Service"
                id="deploymentPackageServiceFilter"
                name="deploymentPackageServiceFilter"
                multiple={true}
                value={deploymentPackageServiceFilter}
                size={"fillParent"}
                margin={{ right: "small" }}
              ></DxcSelect>

              <DxcCheckbox
                checked={checkedDeprecated}
                labelPosition="after"
                label="Show deprecated"
                onChange={onChangeDeprecated}
                size="fitContent"
                margin={{ top: "small" }}
              />
            </DxcFlex>
          </DxcFlex>

          <DxcResultsetTable
            columns={servicesDeploymentPackageColumns}
            rows={availableDeploymentPackagesList}
            itemsPerPage={itemsPerPage}
            itemsPerPageOptions={[10, 15]}
            itemsPerPageFunction={itemsPerPageClick}
            margin={{ top: "small" }}
          ></DxcResultsetTable>
        </ColumnRight>
      </SearchContainers>
      <DxcInset top="0.75rem">
        <DxcParagraph>
          <DxcTypography fontStyle="italic" fontSize="0.875rem">
            * Checking a service will check all the associated deployment packages in the
            "Deployment packages" section. Uncheck a DP will uncheck the associated service.
          </DxcTypography>
        </DxcParagraph>
      </DxcInset>

      {showDeprecatedDialog && (
        <LinkDeprecatedDeploymentPackage
          onCancel={() => setShowDeprecatedDialog(false)}
          onAdd={() =>
            clickHandler(
              currentDeploymentPackage.deploymentPackageName,
              currentDeploymentPackage.deploymentPackageService
            )
          }
          deploymentPackageName={currentDeploymentPackage.deploymentPackageName}
        />
      )}
    </Content>
  );
}

const Content = styled.div`
  display: flex;
  height: 100%;
  margin: 0px auto;
  flex-direction: column;
`;

const SearchContainers = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColumnLeft = styled.div`
  width: 30%;
  display: flex;
  flex-grow: 1;
  height: 100%;
  margin: 0px auto;
  flex-direction: column;
  border-right: 1px solid #cccccc;
  padding-right: 26px;
`;

const ColumnRight = styled.div`
  width: 70%;
  display: flex;
  flex-grow: 1;
  height: 100%;
  margin-left: 20px;
  flex-direction: column;
`;

const HeadingColumn = styled.div`
  font-size: 18px;
  font-family: "Open Sans, sans-serif";
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 2px;
`;

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ServicesEmpty = styled.div`
  background-color: #eeeeee;
  border-radius: 4px;
  width: 80%;
`;

const EmptyText = styled.p`
  text-align: center;
  color: #666;
  letter-spacing: 0.21px;
`;

const ServicesListContainer = styled.div`
  max-height: 1000px;
  overflow-y: auto;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(102, 102, 102);
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background-color: rgb(217, 217, 217);
    border-radius: 3px;
  }
  ul {
    padding-left: 0px;
    li {
      list-style-type: none;
      margin-bottom: 8px;
    }
  }
`;

const DeploymentPackageNameContainer = styled.div`
  display: flex;
`;

const Tag = styled.div`
  margin-left: 10px;
  height: calc(1.5rem - 2 * 1px);
  padding: 0 1rem;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  background-color: #fef9e6;
  border: 1px solid #c59f07;
  color: #947705;
`;

const TagLabel = styled.p`
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;
`;

export default AvailableServices;
