//React hooks imports
import { useState, useMemo, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

//configuation helpers imports
import { config } from "../config";
import {
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  PROMISE_FINAL_STATUSES,
} from "../common/utils";

//DXC HAL
import { useHalResource } from "@dxc-technology/halstack-react-hal";

import { buildDeploymentPackageDependenciesPayload } from "../deployment-package-definition/DeploymentPackageDefinitionHelper";

const DEPLOY_UNITS_URL = `${config.environments_api_url}/deployment-packages`;
const ENVIRONMENT_TYPES_URL = `${config.environments_api_url}/environment-types`;

const useEnvironmentEdit = () => {
  const [error, setError] = useState(null);
  const [editEnvironmentMessage, changeEditEnvironmentMessage] = useState(null);

  //Available Deployment Packages Filter
  const [deploymentPackageTextFilter, changeDeploymentPackageTextFilter] = useState("");
  const [deploymentPackageServiceFilter, changeDeploymentPackageServiceFilter] = useState([]);
  const [deploymentPackageIsDeprecated, changeDeploymentPackageIsDeprecated] = useState(true);
  const [serviceFilter, changeServiceFilter] = useState("");

  //Environment Info
  const [envDescription, changeEnvDescription] = useState(null);
  const [isEnvLocked, setIsEnvLocked] = useState(false);
  const [isEnvPrivate, setIsEnvPrivate] = useState(false);
  const [envType, changeEnvType] = useState("");
  const [envNumber, changeEnvNumber] = useState(null);
  const [envAccountZoneName, changeEnvAccountZoneName] = useState("");
  const [envAWSRegion, changeEnvAWSRegion] = useState("");
  const [envOwner, changeEnvOwner] = useState(null);
  const [envNotifications, changeEnvNotifications] = useState(null);
  const [deploymentPackageDependencies, changeDeploymentPackageDependencies] = useState([]);

  //navigation
  const history = useHistory();
  const location = useLocation();

  const environmentUrl = useMemo(() => {
      return `${config.environments_api_url}${location.pathname}?embedded=[dps]`;
  }, [location.pathname]);

  const [deploymentPackagesResource, deploymentPackagesStatus, deploymentPackagesError] =
    useHalResource({
      url: DEPLOY_UNITS_URL,
      asyncHeadersHandler: getSessionHeaders,
      headers: getPlatformHeaders(),
    });

  const [envTypesResource, envTypesStatus, envTypesError] = useHalResource({
    url: ENVIRONMENT_TYPES_URL,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const [environmentResource, environmentStatus, environmentError, environmentHandlers] =
    useHalResource({
      url: environmentUrl,
      asyncHeadersHandler: getSessionHeaders,
      headers: getPlatformHeaders(),
    });

  //Message operations
  useEffect(() => {
    if (
      PROMISE_FINAL_STATUSES.includes(envTypesStatus) &&
      PROMISE_FINAL_STATUSES.includes(environmentStatus) &&
      PROMISE_FINAL_STATUSES.includes(deploymentPackagesStatus) &&
      (deploymentPackagesError || environmentError || envTypesError)
    ) {
      const _error = deploymentPackagesError ?? environmentError ?? envTypesError;
      setError(buildApplicationError(_error));
    }
  }, [
    deploymentPackagesError,
    deploymentPackagesStatus,
    envTypesError,
    envTypesStatus,
    environmentError,
    environmentStatus,
  ]);

  //HAL resources calculated status
  const editEnvironmentStatus = useMemo(() => {
    if (
      envTypesStatus === "fetching" ||
      deploymentPackagesStatus === "fetching" ||
      environmentStatus === "fetching" ||
      environmentStatus === "interaction"
    ) {
      return "fetching";
    }
  }, [envTypesStatus, deploymentPackagesStatus, environmentStatus]);

  const dismissMessage = () => {
    changeEditEnvironmentMessage(null);
  };

  const toggleIsEnvLocked = () => {
    setIsEnvLocked(!isEnvLocked);
  };

  const toggleIsEnvPrivate = () => {
    setIsEnvPrivate(!isEnvPrivate);
  };

  const deploymentPackageDependenciesList = useMemo(() => {
    return deploymentPackageDependencies.map((deploymentPackageDependency) => ({
      name: deploymentPackageDependency.name,
      service: deploymentPackageDependency.service,
    }));
  }, [deploymentPackageDependencies]);

  const isSelectedDependency = useCallback(
    (name) => {
      return deploymentPackageDependenciesList.some((dependency) => dependency.name === name);
    },
    [deploymentPackageDependenciesList]
  );

  const availableDeploymentPackagesList = useMemo(() => {
    const _deploymentPackagesList =
      (deploymentPackagesResource && deploymentPackagesResource.getItems()) || [];

    return _deploymentPackagesList
      .map((deploymentPackage) => {
        return {
          deploymentPackageName: deploymentPackage.name,
          deploymentPackageService:
            deploymentPackage.summary?.tags?.find((tag) => tag.type === "service")?.type ===
            "service"
              ? deploymentPackage.summary.tags.find((tag) => tag.type === "service")?.title
              : "",
          isSelected: isSelectedDependency(
            deploymentPackage.name,
            deploymentPackage.summary.tags ? deploymentPackage.summary.tags[0]?.title : ""
          ),
          isDeprecated: deploymentPackage.summary.deprecated,
        };
      })
      .filter((deploymentPackage) => {
        const byDeploymentPackageTypes = deploymentPackageServiceFilter.length
          ? deploymentPackageServiceFilter.includes(deploymentPackage.deploymentPackageService)
            ? deploymentPackage
            : null
          : deploymentPackage;
        const byDeprecated = !deploymentPackageIsDeprecated
          ? (deploymentPackage.isDeprecated &&
              deploymentPackage.isDeprecated === deploymentPackageIsDeprecated) ||
            (!deploymentPackage.isDeprecated && !deploymentPackageIsDeprecated)
            ? deploymentPackage
            : null
          : deploymentPackage;
        const byTextFilter = deploymentPackageTextFilter
          ? deploymentPackage.deploymentPackageName.includes(deploymentPackageTextFilter)
            ? deploymentPackage
            : null
          : deploymentPackage;
        return byDeploymentPackageTypes
          ? byDeprecated && byTextFilter
            ? deploymentPackage
            : null
          : null;
      })
      .sort(function (a, b) {
        return b.isSelected - a.isSelected;
      });
  }, [
    isSelectedDependency,
    deploymentPackagesResource,
    deploymentPackageIsDeprecated,
    deploymentPackageServiceFilter,
    deploymentPackageTextFilter,
  ]);

  const totalDeploymentPackageList = useMemo(() => {
    const _deploymentPackagesList =
      (deploymentPackagesResource && deploymentPackagesResource.getItems()) || [];

    return _deploymentPackagesList.map((deploymentPackage) => {
      return {
        deploymentPackageName: deploymentPackage.name,
        deploymentPackageService:
          deploymentPackage.summary?.tags?.find((tag) => tag.type === "service")?.type === "service"
            ? deploymentPackage.summary.tags.find((tag) => tag.type === "service")?.title
            : "",
        isSelected: isSelectedDependency(
          deploymentPackage.name,
          deploymentPackage.summary.tags ? deploymentPackage.summary.tags[0]?.title : ""
        ),
        isDeprecated: deploymentPackage.summary.deprecated,
      };
    });
  }, [isSelectedDependency, deploymentPackagesResource]);

  const envTypesList = useMemo(() => {
    const _envTypesList = (envTypesResource && envTypesResource.getItems()) || [];

    return _envTypesList.map((item) => {
      return {
        envType: item.summary.environment_type,
      };
    });
  }, [envTypesResource]);

  const onSaveChanges = async (customerName, accountName) => {
    dismissMessage();
    const payload = {
      customer_name: customerName,
      account_name: accountName,
      description: envDescription,
      environment_type: envType,
      env_account_zone_name: envAccountZoneName,
      aws_region: envAWSRegion,
      environment_id: envNumber,
      is_locked: isEnvLocked,
      is_private: isEnvPrivate,
      owner: envOwner,
      notifications:
        envNotifications && envNotifications.length > 0
          ? envNotifications.split(",").map((email) => email.trim())
          : [],
      services: buildDeploymentPackageDependenciesPayload(deploymentPackageDependencies),
    };

    environmentHandlers["patch-environment"](payload)
      .then(() => {
        changeEditEnvironmentMessage({
          type: "confirm",
          message: "Your environment was successfully updated in the platform",
        });
      })
      .catch((error) => {
        error.body?.messages
          ? changeEditEnvironmentMessage({
              type: "error",
              message: `${error.body.messages[0].message}`,
            })
          : changeEditEnvironmentMessage({
              type: "error",
              message: "An error ocurred when updating the environment",
            });
      });
  };

  const compareStringsForSorting = (value1, value2) => {
    return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
  };

  const isSelectedService = useCallback(
    (service) => {
      let dpFilteredByService = totalDeploymentPackageList.filter(
        (deploymentP) => deploymentP.deploymentPackageService === service
      );
      const emptyService = dpFilteredByService.length === 0 ? true : false;

      !emptyService &&
        deploymentPackageDependencies.forEach((dp) => {
          dpFilteredByService = dpFilteredByService.filter(
            (item) => item.deploymentPackageName !== dp.name
          );
        });

      return !emptyService && dpFilteredByService.length === 0 ? true : false;
    },
    [deploymentPackageDependencies, totalDeploymentPackageList]
  );

  const toggleService = useCallback(
    (service) => {
      let dPackageDependencies = deploymentPackageDependencies;
      let dpFilteredByService = totalDeploymentPackageList.filter(
        (deploymentP) => deploymentP.deploymentPackageService === service
      );

      if (!isSelectedService(service)) {
        dpFilteredByService.forEach((dp) => {
          if (!isSelectedDependency(dp.deploymentPackageName))
            dPackageDependencies = [
              ...dPackageDependencies,
              {
                name: dp.deploymentPackageName,
                service: dp.deploymentPackageService,
              },
            ];
        });
      } else {
        dpFilteredByService.forEach((dp) => {
          dPackageDependencies = dPackageDependencies.filter(
            (item) => item.name !== dp.deploymentPackageName
          );
        });
      }
      changeDeploymentPackageDependencies(dPackageDependencies);
    },
    [
      deploymentPackageDependencies,
      isSelectedDependency,
      isSelectedService,
      totalDeploymentPackageList,
    ]
  );

  const services = useMemo(() => {
    const allDUServices = deploymentPackagesResource
      ? [].concat(deploymentPackagesResource.getItems())
      : [];
    const allServices = allDUServices
      .filter((item) => {
        return item.summary?.tags?.length > 0;
      })
      .reduce((acc, du) => {
        const serviceTags = du.summary?.tags?.filter((tag) => tag.type === "service");
        return acc.concat(serviceTags);
      }, [])
      .filter((value, index, self) => {
        return self.findIndex((service) => service.id === value.id) === index;
      })
      .sort((compare1, compare2) => {
        return compareStringsForSorting(compare1.title.toLowerCase(), compare2.title.toLowerCase());
      })
      .map((service) => ({
        name: service.title,
        selected: isSelectedService(service.title),
        onChangeSelected: (service) => {
          toggleService(service);
        },
      }));

    const servicesFiltered = serviceFilter
      ? allServices.filter((service) =>
          service.name.toLowerCase().includes(serviceFilter.toLowerCase())
        )
      : allServices;

    return servicesFiltered;
  }, [deploymentPackagesResource, serviceFilter, isSelectedService, toggleService]);

  //DeploymentPackage Dependencies functions
  const toggleDeploymentPackageDependency = (name, service) => {
    !isSelectedDependency(name)
      ? changeDeploymentPackageDependencies([
          ...deploymentPackageDependencies,
          { name: name, service: service },
        ])
      : changeDeploymentPackageDependencies(
          deploymentPackageDependencies.filter((item) => item.name !== name)
        );
  };

  //Retrieve Environment Info
  useEffect(() => {
    if (environmentResource !== null) {
      const _properties = environmentResource.getProperties();

      //basic data
      changeEnvDescription(_properties.find((prop) => prop.key === "description")?.value ?? null);
      setIsEnvLocked(_properties.find((prop) => prop.key === "is_locked")?.value ?? false);
      setIsEnvPrivate(_properties.find((prop) => prop.key === "is_private")?.value ?? false); // this is temporary
      changeEnvType(_properties.find((prop) => prop.key === "environment_type").value);
      changeEnvNumber(_properties.find((prop) => prop.key === "environment_id").value);
      changeEnvAWSRegion(_properties.find((prop) => prop.key === "aws_region").value);
      changeEnvAccountZoneName(_properties.find((prop) => prop.key === "env_account_zone_name")?.value);
      changeEnvOwner(_properties.find((prop) => prop.key === "owner")?.value ?? null);
      changeEnvNotifications(
        notificationsToString(_properties.find((prop) => prop.key === "notifications")?.value)
      );
      //linked deploymentPackages
      let loadedlinkedDeploymentPackages = [];
      const linkedDeploymentPackages = environmentResource.resourceRepresentation?._embedded?._links?.item;
      
      if (
        linkedDeploymentPackages?.length > 0 
      ) {
        loadedlinkedDeploymentPackages = linkedDeploymentPackages.map(
          (item) => ({
            name: item.name,
            service:
              item.summary.tags &&
              item.summary.tags.find((tag) => tag.type === "service")?.type ===
                "service"
                ? item.summary.tags.find((tag) => tag.type === "service").title
                : "",
          })
        );
        changeDeploymentPackageDependencies(loadedlinkedDeploymentPackages);
      }
    }
  }, [environmentResource]);

  const breadcrumbs = sessionStorage.getItem("origin") ? sessionStorage.getItem("origin") : "";
  let breadcrumbsArray = breadcrumbs.split(",");
  const navigateBackOrigin = breadcrumbsArray[breadcrumbsArray.length - 1];
  const navigateBackText = navigateBackOrigin.includes("environments")
    ? "Back to Environments"
    : navigateBackOrigin.includes("deployments")
    ? "Back to Deployments"
    : "Back to Accounts";

  const navigateBack = (customer) => {
    breadcrumbsArray.pop();
    sessionStorage.setItem("origin", breadcrumbsArray);
    navigateBackOrigin.includes("environments")
      ? history.push(`/environment-types`)
      : navigateBackOrigin.includes("deployments")
      ? history.push(`${location.pathname}/deployments`)
      : history.push(`/customers/${customer}`);
  };

  //grouped environment data info
  const envDataInfo = {
    envDescription: envDescription,
    isEnvLocked: isEnvLocked,
    isEnvPrivate: isEnvPrivate,
    envType: envType,
    envTypesList: envTypesList,
    envNumber: envNumber,
    envAccountZoneName: envAccountZoneName,
    envAWSRegion: envAWSRegion,
    envOwner: envOwner,
    envNotifications: envNotifications,
    onChangeDescription: (newEnvDescription) => changeEnvDescription(newEnvDescription),
    onChangeIsLocked: toggleIsEnvLocked,
    onChangeIsPrivate: toggleIsEnvPrivate,
    onChangeType: (newEnvType) => changeEnvType(newEnvType),
    onChangeNumber: (newEnvNumber) => changeEnvNumber(newEnvNumber),
    onChangeAccountZoneName: changeEnvAccountZoneName,
    onChangeAWSRegion: (newEnvAWSRegion) => changeEnvAWSRegion(newEnvAWSRegion),
    onChangeOwner: (newEnvOwner) => changeEnvOwner(newEnvOwner),
    onChangeNotifications: (newEnvNotifications) => changeEnvNotifications(newEnvNotifications),
  };

  //grouped add deploymentPackage parameter dialog state
  const availableDeploymentPackagesInfo = {
    availableDeploymentPackagesList: availableDeploymentPackagesList,
    textFilter: deploymentPackageTextFilter,
    deploymentPackageServiceFilter: deploymentPackageServiceFilter,
    services: services,
    serviceFilter: serviceFilter,
    deprecatedFilter: deploymentPackageIsDeprecated,
    onChangeServiceFilter: changeServiceFilter,
    onChangeTextFilter: changeDeploymentPackageTextFilter,
    onChangeDeploymentPackageServiceFilter: changeDeploymentPackageServiceFilter,
    onChangeDeprecatedFilter: changeDeploymentPackageIsDeprecated,
    onClickSelected: toggleDeploymentPackageDependency,
  };

  return [
    editEnvironmentStatus,
    editEnvironmentMessage,
    envDataInfo,
    availableDeploymentPackagesInfo,
    dismissMessage,
    onSaveChanges,
    navigateBack,
    navigateBackText,
    error,
  ];
};

const notificationsToString = (notificationsArray) =>
  notificationsArray && notificationsArray.length > 0 ? notificationsArray.join(", ") : "";

export default useEnvironmentEdit;
