//React hooks imports
import { useMemo, useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PAGES } from "../utils";

//configuation helpers imports
import { config } from "../config";

import {
  getAuthenticatedUser,
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  PROMISE_FINAL_STATUSES,
} from "../common/utils";

//DXC HAL
import { useHalResource } from "@dxc-technology/halstack-react-hal";
import { HalApiCaller } from "@dxc-technology/halstack-client";

//helper functions
import {
  getActiveDeployment,
  formatDeploymentPackageDependency,
  getRecursiveDependants,
  buildDeploymentPackagePayload,
  getDeploymentPackageData,
  isDeploymentPackageInstalled,
} from "../deploy-deployment-package-steps/helper/DeployDeploymentPackageHelper";

const getDeploymentsFromEmbeddedObject = (embeddedObject) => {
  const _deploymentsCollection = embeddedObject._links.item.reduce((result, dp) => {
    const _deployments = dp._embedded?.item?._embedded?._links?.item.map((item) => ({
      resource_name: item._embedded?.item?.resource_name,
      service_name: item._embedded?.item?.service_name,
      title: item._embedded?.item?.title,
      version: item._embedded?.item?.version,
      resource_subtype: item._embedded?.item?.resource_subtype,
      aws_region: item._embedded?.item?.aws_region,
      locationURL: item._embedded?.item?.locationURL,
      deployment_date: item._embedded?.item?.deployment_date,
      deployment_status: item._embedded?.item?.deployment_status,
      tf_request_date: item._embedded?.item?.tf_request_date,
      tf_request_status: item._embedded?.item?.tf_request_status,
      creator: item._embedded?.item?.creator,
      terraform_action: item._embedded?.item?.terraform_action,
      deployment_packages: item._embedded?.item?.deployment_packages,
      artifacts: item._embedded?.item?.artifacts,
      dependsOn: item._embedded?.item?.dependsOn,
      deploy_info: item._embedded?.item?.deploy_info,
      parameters: item._embedded?.item?.parameters,
      ssm_conditions: item._embedded?.item?.ssm_conditions,
      skip_on_destroy: dp._embedded?.item?.skip_on_destroy,
      upgraded_to_version: item._embedded?.item?.upgraded_to_version,
    }));
    return _deployments ? result.concat(_deployments) : result;
  }, []);
  return _deploymentsCollection.filter(
    (deployment, index, self) =>
      index ===
      self.findIndex(
        (existingDeployment) => existingDeployment.resource_name === deployment.resource_name
      )
  );
};

const getServiceDPDeployments = (deploymentPackages = [], deploymentPackageDeployments = []) => {
  return deploymentPackages.reduce((result, item) => {
    const _deployment = deploymentPackageDeployments.find(
      (deployment) => deployment.service_name === item.service_name
    );
    return _deployment ? [...result, _deployment] : [...result, item];
  }, []);
};

export const useDeploymentDashboard = (customerId, accountId, environmentId) => {
  const history = useHistory();

  const [environmentName, setEnvironmentName] = useState("");
  const [isDestroying, setIsDestroying] = useState(false);
  const [deployDashboardMessage, setDeployDashboardMessage] = useState(null);
  const [isDestroyServiceDialogVisible, setIsDestroyServiceDialogVisible] = useState(null);
  const [services, setServices] = useState([]);

  const [textFilter, changeTextFilter] = useState("");
  const [deploymentStatusFilter, changeStatusFilter] = useState("");

  const [error, setError] = useState(null);

  //urls
  const ENVIRONMENTS_URL = `/customers/${customerId}/accounts/${accountId}/environments/${environmentId}`;

  const environmentURI =
    `${config.environments_api_url}/` +
    `customers/${customerId}/` +
    `accounts/${accountId}/` +
    `environments/${environmentId}`;
  /*
    environment deployment packages data is retrieved by API embedded resource pattern
  */
  const [environmentResource, environmentStatus, environmentError] = useHalResource({
    url: `${environmentURI}?embedded=[deployments.item]&type=service`,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  /**
   * Destroy Service dialog
   */
  const toggleDestroyServiceDialogVisible = (serviceData) => {
    setDeployDashboardMessage(null);
    setIsDestroyServiceDialogVisible(serviceData);
  };

  /**
   * Destroy Deployment Package dialog
   */
  const [isDestroyDeploymentPackageDialogVisible, setIsDestroyDeploymentPackageDialogVisible] =
    useState(null);
  const toggleDestroyDeploymentPackageDialogVisible = (deploymentData) => {
    setIsDestroyDeploymentPackageDialogVisible(deploymentData);
  };

  const breadcrumbs = sessionStorage.getItem("origin") ? sessionStorage.getItem("origin") : "";
  const breadcrumbsArray = useMemo(() => breadcrumbs.split(","), [breadcrumbs]);
  const navigateBackOrigin = breadcrumbsArray[breadcrumbsArray.length - 1];
  const navigateBackText = navigateBackOrigin.includes("environments")
    ? "Back to Environments"
    : "Back to Accounts";

  //navigate functions
  const navigateBack = (customer) => {
    breadcrumbsArray.pop();
    sessionStorage.setItem("origin", breadcrumbsArray);
    navigateBackOrigin.includes("environments")
      ? history.push(`/environment-types`)
      : history.push(`/customers/${customer}`);
  };

  const navigateToEditEnvironmentScreen = () => {
    breadcrumbsArray.push(PAGES.DEPLOYMENTS_DASHBOARD);
    sessionStorage.setItem("origin", breadcrumbsArray);
    history.push(`/customers/${customerId}/accounts/${accountId}/environments/${environmentId}`, {
      origin: "deployments",
    });
  };

  // Service Actions
  const navigateToServiceLogs = (serviceId) => {
    const selectedService = servicesList.find((service) => service.id === serviceId);
    if (selectedService && selectedService.locationURL) {
      viewLogsHandler(
        selectedService.locationURL,
        selectedService.resource_name,
        selectedService.id
      );
    } else {
      setDeployDashboardMessage({
        type: "error",
        message:
          "Logs are not available for this service, please check the specific deployment package",
      });
    }
  };

  const destroyServiceHandler = (serviceId) => {
    toggleDestroyServiceDialogVisible({
      serviceId: serviceId,
      destroyClick: () => bulkDestroyHandler(serviceId),
      cancelClick: toggleDestroyServiceDialogVisible,
    });
  };

  const bulkDestroyHandler = async (serviceId) => {
    toggleDestroyServiceDialogVisible();
    setIsDestroying(true);
    const _selectedService = services.find((service) => service.id === serviceId);
    const _deploymentPackages = _selectedService.deploymentPackages.map((deploymentPackage) => ({
      name: deploymentPackage.service_name,
      version: deploymentPackage.version,
      parameters: deploymentPackage.parameters,
      artifacts: deploymentPackage.artifacts,
      ssm_conditions: deploymentPackage.ssm_conditions,
      dependsOn: deploymentPackage.dependsOn,
      deploy_info: deploymentPackage.deploy_info,
      skip_on_destroy: deploymentPackage.skip_on_destroy,
      upgraded_to_version: deploymentPackage.upgraded_to_version,
      type: "deployment-package",
    }));
    const payload = {
      customer: customerId,
      account: accountId,
      environment: environmentId,
      name: _selectedService.id,
      type: "service",
      version: _selectedService.version,
      terraform_action: "destroy",
      username: await getAuthenticatedUser(),
      deploymentPackages: _deploymentPackages,
    };
    const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
    await HalApiCaller.post({
      url: `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}/deployment-packages:destroy`,
      headers: { ...asyncHeaders, ...getPlatformHeaders() },
      body: payload,
    })
      .then((response) => {
        toggleDestroyServiceDialogVisible();
        const logUrl = response.body.tf_log_url;
        setIsDestroying(false);
        viewLogsHandler(logUrl, `${_selectedService.id}-${environmentId}`, _selectedService.id);
      })
      .catch((error) => {
        setIsDestroying(false);
        error.response?.data?.messages
          ? setDeployDashboardMessage({
              type: "error",
              message: error.response.data.messages[0].message,
            })
          : setDeployDashboardMessage({
              type: "error",
              message: "There was an error destroying the service",
            });
      });
  };

  const bulkActionSelected = (bulkService, option) => {
    switch (option) {
      case "bulkInstall":
        history.push(
          `/customers/${customerId}/accounts/${accountId}/environments/${environmentId}/service/${bulkService}/install`
        );
        break;
      case "viewLogs":
        navigateToServiceLogs(bulkService);
        break;
      case "bulkDestroy":
        destroyServiceHandler(bulkService);
        break;
      default:
        break;
    }
  };

  //Dashboard loading indicator
  const isLoading = useMemo(
    () => environmentStatus === "fetching" || isDestroying,
    [environmentStatus, isDestroying]
  );

  const dismissMessage = () => {
    setDeployDashboardMessage(null);
  };

  //Message operations
  useEffect(() => {
    if (PROMISE_FINAL_STATUSES.includes(environmentStatus) && environmentError) {
      setError(buildApplicationError(environmentError));
    }
  }, [environmentError, environmentStatus]);

  //DP Actions
  const viewLogsHandler = useCallback(
    (logURL, deploymentId, deploymentPackageId) => {
      const logId = logURL.split("/logs/")[1];
      breadcrumbsArray.push(PAGES.DEPLOYMENTS_DASHBOARD);
      sessionStorage.setItem("origin", breadcrumbsArray);
      history.push(
        `${ENVIRONMENTS_URL}/deployment-packages/${deploymentPackageId}/deployments/${deploymentId}/logs/${logId}`
      );
    },
    [ENVIRONMENTS_URL, breadcrumbsArray, history]
  );

  const viewDeploymentDetailsHandler = useCallback(
    (deploymentId, deploymentPackageId) => {
      breadcrumbsArray.push(PAGES.DEPLOYMENTS_DASHBOARD);
      sessionStorage.setItem("origin", breadcrumbsArray);
      history.push(
        `${ENVIRONMENTS_URL}/deployment-packages/${deploymentPackageId}/deployments/${deploymentId}/history`
      );
    },
    [ENVIRONMENTS_URL, breadcrumbsArray, history]
  );

  const installDeploymentPackageHandler = useCallback(
    (deploymentPackage) => {
      const deployDeploymentPackageRoute =
        `/customers/${customerId}` +
        `/accounts/${accountId}` +
        `/environments/${environmentId}` +
        `/deployment-packages/${deploymentPackage.name}/install`;
      history.push(deployDeploymentPackageRoute);
    },
    [history, customerId, accountId, environmentId]
  );

  const refreshDeploymentPackageHandler = useCallback(
    (deploymentPackage) => {
      let refreshDeploymentPackageRoute =
        `/customers/${customerId}` +
        `/accounts/${accountId}` +
        `/environments/${environmentId}` +
        `/deployment-packages/${deploymentPackage.name}` +
        `/deployments/${deploymentPackage.deployment.name}/refresh`;
      history.push(refreshDeploymentPackageRoute);
    },
    [history, customerId, accountId, environmentId]
  );

  const destroyHandler = useCallback(
    async (serviceId, deploymentPackageInfo, dependantsList) => {
      toggleDestroyDeploymentPackageDialogVisible();
      setIsDestroying(true);
      const payload = {
        customer: customerId,
        account: accountId,
        environment: environmentId,
        terraform_action: "destroy",
        username: await getAuthenticatedUser(),
      };

      // add deployment package to deploy to payload
      const _deploymentPackage = {
        name: deploymentPackageInfo.service_name,
        deploy_info: deploymentPackageInfo.deploy_info,
        // TO_BE_UPDATED: ssm_conditions will be taken from last deployment when they were made configurable on deployment steps
        // ssm_conditions: deploymentPackage.deployment.ssmConditions
        //   ? deploymentPackage.deployment.ssmConditions
        //   : [],
        ssm_conditions: deploymentPackageInfo.ssmConditions
          ? deploymentPackageInfo.ssmConditions
          : [],
        artifacts: deploymentPackageInfo.artifacts ? deploymentPackageInfo.artifacts : [],
        parameters: deploymentPackageInfo.parameters ? deploymentPackageInfo.parameters : {},
        dependsOn: deploymentPackageInfo.dependsOn,
        // TODO: Review this
        // upgraded_to_version: deploymentPackage.deployment.upgraded_to_version,
      };

      payload.deploymentPackages = [_deploymentPackage];

      dependantsList.forEach((dependant) => {
        const _dependantInfo = {
          name: dependant.service_name,
          deploy_info: {
            git_url: dependant.source,
            tag: dependant.tag,
          },
          // TO_BE_UPDATED: ssm_conditions will be taken from last deployment when they were made configurable on deployment steps
          // ssm_conditions: dependant.ssm_conditions ? dependant.ssm_conditions : [],
          ssm_conditions: getDeploymentPackageData(dependant.service_name, dependantsList)
            ?.ssm_conditions,
          artifacts: dependant.artefacts ? dependant.artefacts : [],
          dependsOn: getDeploymentPackageData(dependant.service_name, dependantsList)?.dependsOn,
          skip_on_destroy: getDeploymentPackageData(dependant.service_name, dependantsList)
            ?.skip_on_destroy,
          upgraded_to_version: dependant.upgraded_to_version,
        };
        payload.deploymentPackages.push(
          buildDeploymentPackagePayload(_dependantInfo, dependant.service_parameters)
        );
      });
      //RBAC - nueva URL con DP
      const url = `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}/deployment-packages/${deploymentPackageInfo.service_name}/deployments/${deploymentPackageInfo.resource_name}:destroy`;
      const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
      await HalApiCaller.post({
        url: url,
        headers: { ...asyncHeaders, ...getPlatformHeaders() },
        body: payload,
      })
        .then((destroyResponse) => {
          setIsDestroying(false);
          const logUrl = destroyResponse.body.tf_log_url;
          viewLogsHandler(
            logUrl,
            deploymentPackageInfo.resource_name,
            deploymentPackageInfo.service_name
          );
        })
        .catch((error) => {
          setIsDestroying(false);
          error.response?.data?.messages
            ? setDeployDashboardMessage({
                type: "error",
                message: `${error.response.data.messages[0].message}`,
              })
            : setDeployDashboardMessage({
                type: "error",
                message: `Error destroying the installation of the deployment package`,
              });
        });
    },
    [accountId, customerId, environmentId, viewLogsHandler]
  );

  const buildDependantsList = useCallback(
    (deploymentPackageName) => {
      let installedDependants = [];
      if (environmentResource) {
        const _environmentDeploymentPackages =
          environmentResource.resourceRepresentation?._embedded?._links?.item?.map((item) => ({
            dependsOn: item._embedded?.item.dependsOn,
            service_name: item._embedded?.item.service_name,
            service_type: item._embedded?.item.service_type,
            source: item._embedded?.item.source,
            tags: item._embedded?.item.tags,
            skip_on_destroy: item._embedded?.item.skip_on_destroy,
          }));

        // 2 - get deployments from both service and deployment package
        const _deployments = environmentResource.resourceRepresentation._embedded
          ? getDeploymentsFromEmbeddedObject(environmentResource.resourceRepresentation._embedded)
          : [];

        //deploymentPackageDependants
        const _deploymentPackageDependants = _environmentDeploymentPackages.filter((serv) => {
          if (serv.dependsOn) {
            return serv.dependsOn.some((key) => key.service_name === deploymentPackageName);
          } else {
            return false;
          }
        });

        let _dependantsList = [..._deploymentPackageDependants];
        getRecursiveDependants(_dependantsList, _environmentDeploymentPackages, _deployments);

        installedDependants.push(
          ..._dependantsList.reduce((result, dependant, index) => {
            let _dependant = { ...dependant };
            _dependant.installed = isDeploymentPackageInstalled(_deployments, _dependant)
              ? true
              : false;
            let _targetDeployment = getActiveDeployment(_deployments, _dependant);
            if (_targetDeployment) {
              const _formattedDependant = formatDeploymentPackageDependency(
                _dependant,
                getActiveDeployment(_deployments, dependant)
              );
              return [...result, _formattedDependant];
            } else return result;
          }, [])
        );
      }

      return installedDependants;
    },
    [environmentResource]
  );

  const buildDestroyDialogInfo = useCallback(
    (serviceId, deploymentPackage) => {
      const _dependantList = buildDependantsList(deploymentPackage.service_name);
      return {
        deploymentPackageName: deploymentPackage.service_name,
        hasDependants: _dependantList.length ? true : false,
        destroyClick: () => destroyHandler(serviceId, deploymentPackage, _dependantList),
        cancelClick: toggleDestroyDeploymentPackageDialogVisible,
      };
    },
    [buildDependantsList, destroyHandler]
  );

  //Load DB Data
  useEffect(() => {
    if (environmentResource) {
      setEnvironmentName(environmentResource.getProperty("environment_name")?.value);

      // 1 - get environment definition deployment packages
      const _environmentDeploymentPackages =
        environmentResource.resourceRepresentation?._embedded?._links?.item?.map(
          (item) => item.summary
        );

      // 2 - get deployments from both service and deployment package
      const _deployments = environmentResource.resourceRepresentation._embedded
        ? getDeploymentsFromEmbeddedObject(environmentResource.resourceRepresentation._embedded)
        : [];

      // 3 - filter deployment package deployments only
      const _deploymentPackageDeployments = _deployments.filter((item) => !item.resource_subtype);

      // 4 - extract environment services list
      const _environmentServicesList = _environmentDeploymentPackages
        ?.reduce(
          (_tags, _deploymentPackage) =>
            _deploymentPackage.tags?.length > 0
              ? [..._tags, ..._deploymentPackage.tags?.filter((_tag) => "service" === _tag.type)]
              : _tags,
          []
        )
        .filter(
          (tag, index, self) => index === self.findIndex((existingTag) => existingTag.id === tag.id)
        );

      // 5 - set services deployment information with their own deployment packages deployemnt information
      const _servicesDeploymentInfo = _deployments
        .filter((item) => "service" === item.resource_subtype)
        .reduce((result, item) => {
          const _serviceInfo = {
            id: item.service_name,
            name:
              item.title ??
              _environmentServicesList.find((_tag) => _tag.id === item.service_name)?.title ??
              item.service_name,
            resource_name: item.resource_name,
            version: item.version,
            locationURL: item.locationURL,
            deploymentPackages: getServiceDPDeployments(
              item.deployment_packages,
              _deploymentPackageDeployments
            ),
          };
          return [...result, _serviceInfo];
        }, []);

      // 6 - get and populate the remaining undeployed services with their deployment packages deployment information
      const _servicesInfo = _environmentServicesList
        .filter((item) => !_servicesDeploymentInfo.find((service) => service.id === item.id))
        .map((item) => ({ id: item.id, name: item.title, deploymentPackages: [] }));

      const _remainingDPDeployments = _deploymentPackageDeployments.filter(
        (item) =>
          !_servicesDeploymentInfo
            .reduce((result, service) => result.concat(service.deploymentPackages), [])
            .find((service) => service.service_name === item.service_name)
      );

      _servicesInfo.forEach((service) => {
        service.deploymentPackages = _remainingDPDeployments.filter((item) =>
          _environmentDeploymentPackages
            .filter((deploymentPackage) =>
              deploymentPackage.tags?.find((tag) => "service" === tag.type && tag.id === service.id)
            )
            .find((deploymentPackage) => deploymentPackage.service_name === item.service_name)
        );
      });

      // 7 - defined unassigned service with the unassigned deployment packages deployment information
      // and deployed DPs info which are not part of its service deployed version
      const _unassigned = {
        id: "unassigned",
        name: "Unassigned",
        deploymentPackages: _remainingDPDeployments.filter((item) =>
          _environmentDeploymentPackages
            .filter(
              (deploymentPackage) =>
                !deploymentPackage.tags?.find((tag) => "service" === tag.type) ||
                deploymentPackage.tags?.find(
                  (tag) =>
                    tag.type === "service" &&
                    _servicesDeploymentInfo.find((service) => service.id === tag.id)
                )
            )
            .find((deploymentPackage) => deploymentPackage.service_name === item.service_name)
        ),
      };

      const _services = [].concat(_servicesDeploymentInfo).concat(_servicesInfo);

      // 8 - populate remaining service's undeployed deployment packages information
      const _notDeployedDPs = _environmentDeploymentPackages.filter(
        (item) =>
          !_deploymentPackageDeployments.find(
            (deployment) => deployment.service_name === item.service_name
          )
      );
      _services.forEach((service) => {
        if (!service.version) {
          service.deploymentPackages = service.deploymentPackages.concat(
            _notDeployedDPs
              .filter((item) =>
                item.tags?.find((tag) => "service" === tag.type && tag.id === service.id)
              )
              .filter(
                (item) =>
                  !service.deploymentPackages?.find((dp) => dp.service_name === item.service_name)
              )
          );
        }
      });

      // 9 - populate remaining unassigned deployment packages information and add unsassigned "service" to the final list
      _unassigned.deploymentPackages = _unassigned.deploymentPackages.concat(
        _notDeployedDPs
          .filter(
            (item) =>
              !item.tags?.find((tag) => "service" === tag.type) ||
              item.tags?.find(
                (tag) =>
                  tag.type === "service" &&
                  _servicesDeploymentInfo.find((service) => service.id === tag.id)
              )
          )
          .filter(
            (item) =>
              !_unassigned.deploymentPackages?.find((dp) => dp.service_name === item.service_name)
          )
      );

      if (_unassigned.deploymentPackages.length > 0) _services.push(_unassigned);

      setServices(
        _services.sort((item1, item2) =>
          item1.name?.toLowerCase() < item2.name?.toLowerCase()
            ? -1
            : item1.name?.toLowerCase() > item2.name?.toLowerCase()
            ? 1
            : 0
        )
      );
    }
  }, [environmentResource]);

  //Populate dashboard data
  const servicesList = useMemo(() => {
    if (services.length > 0) {
      const _servicesList = services.map((service) => {
        const _service = { ...service };
        const _deploymentPackages = _service.deploymentPackages
          .map((deploymentPackage) => {
            const item = {
              name: deploymentPackage.service_name,
              onInstallClick: installDeploymentPackageHandler,
              onRefreshClick: refreshDeploymentPackageHandler,
              onViewLogClick: (logURL) => {
                viewLogsHandler(
                  logURL,
                  deploymentPackage.resource_name,
                  deploymentPackage.service_name
                );
              },
              onDestroyClick: () =>
                toggleDestroyDeploymentPackageDialogVisible(
                  buildDestroyDialogInfo(_service.id, deploymentPackage)
                ),
              onViewInfoClick: () =>
                viewDeploymentDetailsHandler(
                  deploymentPackage.resource_name,
                  deploymentPackage.service_name
                ),
            };

            if (deploymentPackage?.terraform_action) {
              item.deployment = {
                name: deploymentPackage.resource_name,
                version: deploymentPackage.version,
                action: deploymentPackage.terraform_action,
                date: deploymentPackage.tf_request_date,
                process: deploymentPackage.tf_request_status,
                status: deploymentPackage.deployment_status,
                is_warning_deployment:
                  deploymentPackage.tf_request_status !== "completed" &&
                  deploymentPackage.tf_request_status !== "failure",
                locationURL: deploymentPackage.locationURL,
              };
            }
            return item;
          })
          .sort((item1, item2) => (item1.name < item2.name ? -1 : item1.name > item2.name ? 1 : 0));
        _service.deploymentPackages = _deploymentPackages;
        return _service;
      });
      return _servicesList;
    }
  }, [
    services,
    installDeploymentPackageHandler,
    refreshDeploymentPackageHandler,
    viewLogsHandler,
    buildDestroyDialogInfo,
    viewDeploymentDetailsHandler,
  ]);

  const deploymentInfo = {
    services: servicesList,
    textFilter: textFilter,
    onChangeTextFilter: changeTextFilter,
    deploymentStatusFilter: deploymentStatusFilter,
    onChangeStatusFilter: changeStatusFilter,
  };

  return {
    environmentName: environmentName,
    isLoading: isLoading,
    deploymentDashboardMessage: deployDashboardMessage,
    isDestroyDeploymentPackageDialogVisible: isDestroyDeploymentPackageDialogVisible,
    environmentStatus: environmentStatus,
    isDestroyServiceDialogVisible: isDestroyServiceDialogVisible,
    navigateBackText: navigateBackText,
    deploymentInfo: deploymentInfo,
    error: error,
    navigateBack: navigateBack,
    navigateToEditEnvironmentScreen: navigateToEditEnvironmentScreen,
    bulkActionSelected: bulkActionSelected,
    dismissMessage: dismissMessage,
  };
};
