import React, { useState, useEffect, useCallback } from "react";
import { config } from "../config";
import { useHistory, useParams } from "react-router";

import { buildApplicationError, getPlatformHeaders, getSessionHeaders } from "../common/utils";
import { DxcSpinner } from "@dxc-technology/halstack-react";

const useLog = () => {
  const [error, setError] = useState(null);
  const [log, setLog] = useState("");
  const [loading, setLoading] = useState(true);
  const [isFinished, setIsFinished] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [requestError, setRequestError] = useState(null);

  const { logId, environmentId, customerId, accountId, deploymentPackageId, deploymentId } =
    useParams();

  const history = useHistory();

  const logUrl = `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}/deployment-packages/${deploymentPackageId}/deployments/${deploymentId}/logs/${logId}`;

  const environmentUIRoute = `/customers/${customerId}/accounts/${accountId}/environments/${environmentId}`;

  //breadcrumbs implementation for 3rd level navigation
  const breadcrumbs = sessionStorage.getItem("origin") ? sessionStorage.getItem("origin") : "";
  let breadcrumbsArray = breadcrumbs.split(",");
  const navigateBackOrigin = breadcrumbsArray[breadcrumbsArray.length - 1];
  const navigateBackText = navigateBackOrigin.includes("historical")
    ? "Back to History"
    : "Back to Deployments";

  const getValidDeploymentId = (deploymentId) => {
    return deploymentId.includes(accountId + "-")
      ? deploymentId.substring(0, deploymentId.lastIndexOf("-"))
      : deploymentId;
  };

  const navigateBack = () => {
    breadcrumbsArray.pop();
    sessionStorage.setItem("origin", breadcrumbsArray);

    const urlBack = navigateBackOrigin.includes("historical")
      ? `${environmentUIRoute}/deployment-packages/${deploymentPackageId}/deployments/${getValidDeploymentId(deploymentId)}/history`
      : `${environmentUIRoute}/deployments`;
    history.push(urlBack);
  };

  const refreshLog = useCallback(async () => {
    if (logUrl && !isLocked) {
      setIsLocked(true);
      const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
      const headers = new Headers({ ...asyncHeaders, ...getPlatformHeaders() });
      return window
        .fetch(logUrl, { headers })
        .then(async (response) => {
          if (response.ok) {
            const responseText = await response.text();
            if (responseText.includes("Running terraform")) setLog(responseText);
            if (hasFinished(responseText)) {
              setIsFinished(true);
            }
            setLoading(false);
            setIsLocked(false);
          } else {
            setIsFinished(true);
            const errorMessage = response.body ? await response.json() : null;

            const _structuredError = errorMessage
              ? {
                  status: response.status,
                  message: response.statusText,
                  body: errorMessage,
                  toString: () => errorMessage,
                }
              : {
                  status: response.status,
                  message: response.statusText,
                  toString: () => response.statusText,
                };
            setLoading(false);
            setIsLocked(false);
            setRequestError(_structuredError);
          }
        })
        .catch((error) => {
          setIsFinished(true);
          const _structuredError = {
            status: 400,
            message: error.message,
            toString: () => error.message,
          };
          setLoading(false);
          setIsLocked(false);
          setRequestError(_structuredError);
        });
    }
  }, [isLocked, logUrl]);

  useEffect(() => {
    if (requestError) {
      setError(buildApplicationError(requestError));
    }
  }, [requestError]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      refreshLog();
    }, 5000);
    if (isFinished) {
      clearInterval(refreshInterval);
    }
    return () => clearInterval(refreshInterval);
  }, [isFinished, refreshLog]);

  const stages = log ? getStages(log) : [];

  const titleAction = getTitleAction(log);

  const stageError = stages.length > 0 ? getErrorStage(stages) : "";

  const logStateIcon = getLogStatus(stages);

  const logStateMessage = stages.length > 0 ? getLogStatusMessage(stages, titleAction) : "";

  const navigationInfo = {
    goBackCaption: navigateBackText,
    goBack: navigateBack,
  };

  const copyLog = () => {
    var aux = document.createElement("textarea");
    aux.innerHTML = log;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
  };

  return {
    stages,
    loading,
    stageError,
    environmentId,
    customerId,
    titleAction,
    logStateIcon,
    logStateMessage,
    navigationInfo,
    copyLog,
    error,
  };
};

const getOutcome = (log) => {
  const mark1 = "###OUTCOME_URL ";
  const mark2 = "###";
  const _regexRes = log.match(new RegExp(mark1 + "(.*)" + mark2));
  const _csv = _regexRes ? _regexRes[1] : undefined;
  return _csv;
};

const getLogEnd = (log) => {
  return log.match(new RegExp("END_STAGES"));
};


const getStages = (log) => {
  return getStagesArray(log).map((stage) => {
    const stageStatus = getStatus(stage, log);
    return {
      name: stage,
      status: stageStatus,
      body: getStageBody(stage, stageStatus, log),
      icon: getIcon(stageStatus),
    };
  });
};

const getStageEnding = (stage, status) => {
  switch (status) {
    case "SUCCESS": {
      return `###END_STAGE_OK ${stage} ###`;
    }
    case "ERROR": {
      return `###END_STAGE_KO ${stage} ###`;
    }
    case "SKIPPED": {
      return `###END_STAGE_SKIPPED ${stage} ###`;
    }
    case "IN PROGRESS": {
      return `$`;
    }
    default: {
      return "$";
    }
  }
};

const getTitleAction = (log) => {
  const _regexRes = log?.match(new RegExp("terraform (.*) with AWS"));
  const _csv = _regexRes ? _regexRes[1] : "";
  return _csv;
};

const getStageBody = (stage, status, log) => {
  const _mark1 = `###START_STAGE ${stage} ###`;
  const _mark2 = getStageEnding(stage, status);
  const _regexRes = log?.includes(_mark1) ? log?.match(new RegExp(_mark1 + "\n([\\s\\S]*?)" + _mark2)) : null;
  const _csv = _regexRes ? _regexRes[1] : status === "ERROR" ? "Unable to load STAGE info, please check full log" : "Pending...";
  return _csv;
};

const getStagesArray = (log) => {
  const _mark1 = "###STAGES ";
  const _mark2 = "###";
  const _regexRes = log?.match(new RegExp(_mark1 + "(.*)" + _mark2));
  const _csv = _regexRes ? _regexRes[1] : "";
  return _csv
    .split(";")
    .map((stage) => stage.trim())
    .reverse();
};

const isIncompleteStatus = (log) => {
  return ((log.includes("###END_STAGES ###") && !log.includes("###START_STAGE ")) || log.includes(`###END_STAGE_KO ###`));
};

const getStatus = (stage, log) => {
  return (
    (isIncompleteStatus(log) && "ERROR") ||
    (log.includes(`###END_STAGE_OK ${stage} ###`) && "SUCCESS") ||
    (log.includes(`###END_STAGE_KO ${stage} ###`) && "ERROR") ||
    (log.includes(`###END_STAGE_SKIPPED ${stage} ###`) && "SKIPPED") ||
    (log.includes(`###START_STAGE ${stage} ###`) && "IN PROGRESS") ||
    "PENDING"
  );
};

const hasFinished = (log) => {
  return (
    getOutcome(log) ||
    getStages(log).some((stage) => stage.status === "ERROR") ||
    getStages(log).every((stage) => stage.status === "SUCCESS" || stage.status === "SKIPPED") || 
    getLogEnd(log)
  );
};

const getLogStatus = (stages) => {
  return stages.some((stage) => stage.status === "ERROR") ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="42px"
      viewBox="0 0 24 24"
      width="42px"
      fill="#EE2222"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
    </svg>
  ) : stages.every((stage) => stage.status === "SUCCESS" || stage.status === "SKIPPED") ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="42px"
      viewBox="0 0 24 24"
      width="42px"
      fill="#7ED321"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  ) : (
    <DxcSpinner mode="small" margin="small"></DxcSpinner>
  );
};

const getLogStatusMessage = (stages, titleAction) => {
  if (stages.find((stage) => stage.status === "ERROR")) {
    return `Error in ${stages.find((stage) => stage.status === "ERROR").name}`;
  } else if (stages.find((stage) => stage.status === "IN PROGRESS")) {
    return `${stages.find((stage) => stage.status === "IN PROGRESS").name} is in progress`;
  } else if (stages.every((stage) => stage.status === "SUCCESS" || stage.status === "SKIPPED")) {
    return `${titleAction} has been successfully completed`;
  } else return `${stages.find((stage) => stage.status === "PENDING").name} is pending`;
};

const getErrorStage = (stages) => {
  const errorStage = stages.find((stage) => stage.status === "ERROR");
  if (errorStage) {
    return `An error has occurred during the process`;
  }
};

const getIcon = (status) => {
  return (
    (status === "SUCCESS" && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#7ED321"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
      </svg>
    )) ||
    (status === "ERROR" && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#EE2222"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
      </svg>
    )) ||
    (status === "SKIPPED" && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#FFD806"
      >
        <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
      </svg>
    )) ||
    (status === "IN PROGRESS" && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#0067b3"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z" />
      </svg>
    )) ||
    (status === "PENDING" && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        enable-background="new 0 0 24 24"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#D9D9D9"
      >
        <g>
          <rect fill="none" height="24" width="24" x="0" />
        </g>
        <g>
          <g>
            <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8 S16.41,20,12,20z M12.5,7H11v6l5.2,3.2l0.8-1.3l-4.5-2.7V7z" />
          </g>
        </g>
      </svg>
    ))
  );
};

export default useLog;
