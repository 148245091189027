import React from "react";
import { useMemo, useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcSelect,
  DxcResultsetTable,
  DxcChip,
  DxcButton,
  DxcTextInput,
  DxcHeading,
  DxcFlex,
} from "@dxc-technology/halstack-react";
import VerticalMenu from "../components/VerticalMenu";

// All images
import installImage from "./images/install-24px.svg";
import destroyImage from "./images/destroy-24px.svg";
import reDeployImage from "./images/re-deploy-24px.svg";
import logsImage from "./images/log-24px.svg";
import infoImage from "./images/info-24px.svg";
import tfWarningImage from "./images/tf-warning-24px.svg";
import { formatDate } from "../common/utils";
import EmptySection from "../components/EmptySection";

function DeploymentList({
  servicesList,
  textFilter,
  deploymentStatusFilter,
  onChangeTextFilter,
  onChangeDeploymentStatusFilter,
  bulkActionSelected,
}) {
  const showLogClickHandler = (deploymentPackage) => {
    const logUrl = deploymentPackage.deployment.locationURL;
    deploymentPackage.onViewLogClick(logUrl);
  };
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const itemsPerPageClick = (value) => {
    setItemsPerPage(value);
  };
  const [selectedService, setSelectedService] = useState(null);
  const menuItems = useMemo(() => {
    const _items =
      servicesList && servicesList.length > 0
        ? servicesList
            .map((s) => ({
              label: s.name,
              value: s.id,
            }))
            .sort((item1, item2) =>
              item1.value < item2.value ? -1 : item1.value > item2.value ? 1 : 0
            )
        : [];
    setSelectedService(_items[0]);
    return _items;
  }, [servicesList]);
  const onMenuItemClick = (value) => {
    setSelectedService(menuItems.find((i) => i.value === value));
  };

  const statusFilterValues = useMemo(
    () =>
      servicesList
        .find((s) => s.id === selectedService?.value)
        ?.deploymentPackages.map((deploymentPackage) => {
          const status = deploymentPackage.deployment
            ? !deploymentPackage.deployment.is_warning_deployment
              ? `${deploymentPackage.deployment.action} ${deploymentPackage.deployment.status}`.trim()
              : deploymentPackage.deployment.action
            : "Not installed";
          return status;
        })
        .reduce(
          (uniqueStatus, status) =>
            uniqueStatus.includes(status) ? uniqueStatus : [...uniqueStatus, status],
          []
        )
        .map((item) => ({
          label: item,
          value: item,
        })),
    [selectedService?.value, servicesList]
  );

  const serviceDeploymentInfo = useMemo(() => {
    const service = servicesList.find((s) => s.id === selectedService?.value);
    return { version: service?.version, locationURL: service?.locationURL };
  }, [servicesList, selectedService]);

  const globalStatus = useMemo(() => {
    const service = servicesList.find((s) => s.id === selectedService?.value);
    const firstDeploymentStatus = service?.deploymentPackages[0]?.deployment
      ? !service.deploymentPackages[0].deployment.is_warning_deployment
        ? `${service.deploymentPackages[0].deployment.action} ${service.deploymentPackages[0].deployment.status}`
        : service.deploymentPackages[0].deployment.action
      : "not installed";
    const isGlobalStatus = service?.deploymentPackages.every((deploymentPackage) => {
      const status = deploymentPackage.deployment
        ? !deploymentPackage.deployment.is_warning_deployment
          ? `${deploymentPackage.deployment.action} ${deploymentPackage.deployment.status}`
          : deploymentPackage.deployment.action
        : "not installed";
      return status === firstDeploymentStatus;
    });
    return isGlobalStatus ? firstDeploymentStatus : null;
  }, [selectedService, servicesList]);

  const rows = useMemo(() => {
    return servicesList
      .find((s) => s.id === selectedService?.value)
      ?.deploymentPackages.filter((deploymentPackage) =>
        deploymentPackage?.name?.includes(textFilter)
      )
      .map((deploymentPackage) => {
        const deploymentStatus = deploymentPackage.deployment
          ? deploymentPackage.deployment.status &&
            !deploymentPackage.deployment.is_warning_deployment
            ? `${deploymentPackage.deployment.action} ${deploymentPackage.deployment.status}`
            : deploymentPackage.deployment.action
          : "Not installed";

        return [
          { displayValue: deploymentPackage.name },
          {
            displayValue: (
              <StatusContainer>
                <span className="es-capitalized-text">{deploymentStatus}</span>
                {deploymentPackage.deployment?.process &&
                deploymentPackage.deployment?.is_warning_deployment ? (
                  <span data-title={deploymentPackage.deployment.process}>
                    <Image
                      alt={`view Terraform action status ${deploymentPackage.name}`}
                      src={tfWarningImage}
                    ></Image>
                  </span>
                ) : null}
              </StatusContainer>
            ),
            sortValue: deploymentStatus,
          },
          {
            displayValue: deploymentPackage.deployment?.date
              ? Number(deploymentPackage.deployment.date)
                ? formatDate(deploymentPackage.deployment.date)
                : deploymentPackage.deployment.date
              : "-",
            sortValue: deploymentPackage.deployment?.date,
          },
          {
            displayValue: deploymentPackage.deployment
              ? deploymentPackage.deployment.version && deploymentPackage.deployment.version !== ""
                ? deploymentPackage.deployment.version
                : "Custom"
              : "-",
          },
          {
            displayValue: (
              <ImageContainer>
                {!deploymentPackage.deployment && (
                  <span data-title={`Install ${deploymentPackage.name}`}>
                    <Image
                      src={installImage}
                      alt={`Install ${deploymentPackage.name}`}
                      onClick={() => deploymentPackage.onInstallClick(deploymentPackage)}
                    ></Image>
                  </span>
                )}
                {deploymentPackage.deployment && (
                  <>
                    {deploymentPackage.deployment.action === "destroy" &&
                    deploymentPackage.deployment.status === "successful" &&
                    deploymentPackage.deployment.process === "completed" ? (
                      <span data-title={`Install ${deploymentPackage.name}`}>
                        <Image
                          src={installImage}
                          alt={`Install ${deploymentPackage.name}`}
                          onClick={() => deploymentPackage.onInstallClick(deploymentPackage)}
                        ></Image>
                      </span>
                    ) : (
                      <span data-title={`Refresh ${deploymentPackage.name}`}>
                        <Image
                          src={reDeployImage}
                          alt={`Refresh ${deploymentPackage.name}`}
                          onClick={() => deploymentPackage.onRefreshClick(deploymentPackage)}
                        ></Image>
                      </span>
                    )}
                    <span data-title={`View logs for ${deploymentPackage.name}`}>
                      <Image
                        src={logsImage}
                        alt={`View logs for ${deploymentPackage.name}`}
                        onClick={() => showLogClickHandler(deploymentPackage)}
                      ></Image>
                    </span>
                    {deploymentPackage.deployment.action === "destroy" &&
                    deploymentPackage.deployment.status === "successful" &&
                    deploymentPackage.deployment.process === "completed" ? null : (
                      <span data-title={`Destroy ${deploymentPackage.name}`}>
                        <Image
                          src={destroyImage}
                          alt={`Destroy ${deploymentPackage.name}`}
                          onClick={() => deploymentPackage.onDestroyClick(deploymentPackage)}
                        ></Image>
                      </span>
                    )}
                    {deploymentPackage.deployment ? (
                      <span data-title={`See ${deploymentPackage.name} deployment details`}>
                        <Image
                          src={infoImage}
                          alt={`See ${deploymentPackage.name} deployment details`}
                          onClick={deploymentPackage.onViewInfoClick}
                        ></Image>
                      </span>
                    ) : null}
                  </>
                )}
              </ImageContainer>
            ),
          },
        ];
      })
      .filter((row) => {
        if (deploymentStatusFilter !== "") {
          return row[1].displayValue.props
            ? row[1].displayValue.props.children[0].props.children.trim() === deploymentStatusFilter
            : row[1].displayValue.trim() === deploymentStatusFilter;
        }
        return true;
      });
  }, [servicesList, selectedService, textFilter, deploymentStatusFilter]);

  return (
    <Content>
      <ServicesList>
        <VerticalMenu
          title="Services"
          items={menuItems}
          selectedValue={selectedService?.value}
          onSelectItem={onMenuItemClick}
          size="fillParent"
          margin={{
            bottom: "small",
            right: "large",
          }}
        />
      </ServicesList>
      <ServicesArea>
        <VerticalMenuContent>
          <ContentHeader>
            <DxcFlex justifyContent="space-between">
              <Title>
                <DxcHeading level={3} text={selectedService?.label}></DxcHeading>
                <ChipContainer>
                  {selectedService?.value !== "unassigned" && serviceDeploymentInfo?.version && (
                    <DxcChip label={serviceDeploymentInfo.version} margin="xxsmall" />
                  )}
                  {globalStatus && <DxcChip label={globalStatus} margin="xxsmall" />}
                </ChipContainer>
              </Title>
            </DxcFlex>
            {selectedService?.value !== "unassigned" && (
              <ButtonsContainer>
                <DxcButton
                  mode="primary"
                  label="Install"
                  margin="xxsmall"
                  onClick={() => bulkActionSelected(selectedService?.value, "bulkInstall")}
                />
                <DxcButton
                  disabled={!serviceDeploymentInfo.locationURL}
                  mode="secondary"
                  label="See logs"
                  margin="xxsmall"
                  onClick={() => bulkActionSelected(selectedService?.value, "viewLogs")}
                />
                <DxcButton
                  disabled={
                    !serviceDeploymentInfo.version || serviceDeploymentInfo.version === "custom"
                  }
                  mode="secondary"
                  margin="xxsmall"
                  label="Destroy"
                  onClick={() => bulkActionSelected(selectedService?.value, "bulkDestroy")}
                />
              </ButtonsContainer>
            )}
          </ContentHeader>
          <FiltersContainer>
            <DxcTextInput
              label="Search by name"
              value={textFilter}
              onChange={({ value }) => onChangeTextFilter(value)}
              onBlur={({ value }) => onChangeTextFilter(value)}
              size={"large"}
              margin={{ right: "medium" }}
              placeholder="e.g.: my-deployment-package-name"
              clearable={true}
            />
            <DxcSelect
              label="Status"
              options={[{ label: "--None--", value: "" }, ...(statusFilterValues ?? [])]}
              size={"medium"}
              value={deploymentStatusFilter}
              onChange={({ value }) => onChangeDeploymentStatusFilter(value)}
            />
          </FiltersContainer>
          {rows?.length > 0 ? (
            <DxcResultsetTable
              columns={[
                { displayValue: "Name", isSortable: true },
                { displayValue: "Status", isSortable: true },
                { displayValue: "Date", isSortable: true },
                { displayValue: "Version", isSortable: false },
                { displayValue: "Actions", isSortable: false },
              ]}
              rows={rows}
              itemsPerPage={itemsPerPage}
              itemsPerPageOptions={[5, 10, 15]}
              itemsPerPageFunction={itemsPerPageClick}
            />
          ) : (
            <EmptySectionContainer>
              <EmptySection text="Deployments not found..." />
            </EmptySectionContainer>
          )}
        </VerticalMenuContent>
      </ServicesArea>
    </Content>
  );
}

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
`;

const ServicesArea = styled.div`
  width: 70%;
  display: flex;
`;

const ServicesList = styled.div`
  width: 30%;
  display: flex;
`;

const VerticalMenuContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;
  width: 200px;
  text-transform: capitalize;
`;

const ContentHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 52px;
`;

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 1rem 0 2rem 0;
  > div {
    text-transform: capitalize;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  align-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StatusContainer = styled.div`
  display: flex;
`;

const Image = styled.img`
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-right: 6px;
`;

const EmptySectionContainer = styled.div`
  width: 100%;
`;

export default DeploymentList;
