//React imports
import React, { useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcButton,
  DxcDialog,
  DxcTextInput,
  DxcParagraph,
  DxcFlex,
  DxcInset,
  DxcTypography,
} from "@dxc-technology/halstack-react";

function DeleteDeploymentPackage({
  errorMessage,
  isLoading,
  onCancel,
  onDelete,
  deploymentPackage,
}) {
  const [deploymentPackageName, changeDeploymentPackageName] = useState("");

  const [nameError, setNameError] = useState("");

  const nameHandler = ({ value, error }) => {
    changeDeploymentPackageName(value);
    setNameError(error);
  };

  return (
    <DxcDialog isCloseVisible onCloseClick={onCancel}>
      <DialogHeader>
        <DxcHeading level={2} text={`Delete deployment package`} margin={{ bottom: "xsmall" }} />
        {isLoading && (
          <DxcSpinner theme="light" mode="small" margin={{ left: "xsmall", bottom: "xsmall" }} />
        )}
      </DialogHeader>

      {!errorMessage ? (
        <DxcAlert type="info" mode="inline" size="fillParent" margin={{ bottom: "small" }}>
          <DxcParagraph>
            This deployment package and its related deployments will be removed if it is not active
            in any environment.
          </DxcParagraph>
        </DxcAlert>
      ) : (
        <DxcAlert type="error" mode="inline" size="fillParent" margin={{ bottom: "small" }}>
          <DxcParagraph>{errorMessage}</DxcParagraph>
        </DxcAlert>
      )}

      <DxcFlex direction="column">
        <DxcParagraph>
          The deployment package{" "}
          <DxcTypography as="span" fontWeight="600" display="inline">
            {deploymentPackage}
          </DxcTypography>{" "}
          is going to be deleted, please type the name of the deployment package to confirm.
        </DxcParagraph>

        <DxcTextInput
          label="Deployment package name"
          margin={{ top: "xsmall" }}
          size="fillParent"
          value={deploymentPackageName}
          onChange={nameHandler}
          onBlur={nameHandler}
          disable={isLoading}
          placeholder="e.g.: my-deployment-package-name"
          optional={false}
          error={nameError == null ? "" : nameError}
          clearable={true}
        />
      </DxcFlex>
      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={onCancel || isLoading}
            mode="text"
          ></DxcButton>
          <DxcButton
            label="Delete"
            onClick={onDelete}
            disabled={deploymentPackageName !== deploymentPackage || isLoading || nameError != null}
            mode="primary"
          ></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default DeleteDeploymentPackage;
