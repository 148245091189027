export const columns = [
  { displayValue: "Customer", isSortable: true },
  { displayValue: "Account", isSortable: true },
  { displayValue: "Environment name", isSortable: true },
  { displayValue: "Type", isSortable: true },
  { displayValue: "Locked", isSortable: true },
  { displayValue: "Domain", isSortable: true },
  { displayValue: "Actions", isSortable: false },
];

export const options = [
  { value: 1, label: "Edit environment" },
  { value: 2, label: "See deployments" },
  { value: 3, label: "Platform console" },
  { value: 4, label: "Delete environment" },
  // TODO: remove clone environment option
  // { value: 5, label: "Clone environment" },
];