import React, { useMemo, useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcWizard,
  DxcButton,
  DxcAlert,
} from "@dxc-technology/halstack-react";

//custom hook
import { useInstallService } from "./useInstallService";

import { deployServiceSteps } from "../deploy-deployment-package-steps/helper/DeployDeploymentPackageHelper";

import DeployInstallationStep from "../deploy-service-steps/DeployInstallationStep";
import DeploymentPackageParametersStep from "../deploy-service-steps/DeploymentPackageParametersStep";
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";
import { goToTop } from "../common/utils";

function InstallService() {
  const {
    navigateToDeploymentDashboard,
    deployInstallationInfo,
    installService,
    installServiceMessage,
    dismissMessage,
    installStatus,
    error,
  } = useInstallService();
  useErrorHandler(error);
  const [currentStep, setCurrentStep] = useState(0);

  const onStepClick = (i) => {
    setCurrentStep(i);
  };

  const moveNext = () => {
    onStepClick(currentStep + 1);
    goToTop();
  };
  const movePrev = () => {
    onStepClick(currentStep - 1);
    goToTop();
  };

  const disableToInstall = useMemo(() => {
    return (
      deployInstallationInfo.serviceDeploymentPackages.length === 0 ||
      deployInstallationInfo.serviceDeploymentPackages.some((deploymentPackage) =>
        [...deploymentPackage.expertParameters, ...deploymentPackage.defaultParameters].some(
          (parameter) => parameter.invalid === true
        )
      )
    );
  }, [deployInstallationInfo.serviceDeploymentPackages]);

  return (
    <Content>
      <Title>
        <DxcHeading
          level={2}
          weight="normal"
          text="Install service:"
          margin={{ bottom: "medium" }}
        />
        {deployInstallationInfo.serviceId ? (
          <DxcHeading
            level={2}
            text={deployInstallationInfo.serviceId}
            margin={{ bottom: "medium", left: "xsmall" }}
          />
        ) : null}
      </Title>
      {installServiceMessage ? (
        <DxcAlert
          type={installServiceMessage.type}
          mode="inline"
          inlineText={installServiceMessage.message}
          size="fillParent"
          onClose={dismissMessage}
          margin={{ bottom: "small" }}
        />
      ) : null}
      <WizardContainer>
        <DxcWizard currentStep={currentStep} onStepClick={onStepClick} steps={deployServiceSteps} />
      </WizardContainer>

      <Title>
        <DxcHeading
          level={3}
          text={currentStep === 0 ? "Deployment installation" : "Deployment packages parameters"}
        />
      </Title>
      <MainContainer>
        {currentStep === 0 && (
          <DeployInstallationStep deployInstallationInfo={deployInstallationInfo} />
        )}

        {currentStep === 1 && (
          <DeploymentPackageParametersStep
            deployInstallationInfo={deployInstallationInfo}
          ></DeploymentPackageParametersStep>
        )}
      </MainContainer>

      <Navigation firstPage={currentStep === 0}>
        <DxcButton
          mode="text"
          label="Cancel"
          onClick={navigateToDeploymentDashboard}
          margin={"xxsmall"}
        />
        {currentStep !== 0 ? (
          <DxcButton
            disabled={currentStep < 1}
            mode="secondary"
            label="Previous"
            onClick={movePrev}
            margin={"xxsmall"}
          />
        ) : null}
        {currentStep !== 1 && (
          <DxcButton mode="primary" label="Next" onClick={moveNext} margin={"xxsmall"} />
        )}
        {currentStep === 1 && (
          <DxcButton
            disabled={disableToInstall}
            mode="primary"
            label="Install"
            onClick={installService}
            margin={"xxsmall"}
          />
        )}
      </Navigation>
      {installStatus === "loading" ? (
        <DxcSpinner theme="light" label="Loading..." mode="overlay" />
      ) : null}
    </Content>
  );
}

const Content = styled.div`
  margin-bottom: 48px;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const WizardContainer = styled.div`
  width: 50%;
  margin-bottom: 24px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;
`;

export default withErrorBoundary(InstallService, { FallbackComponent: ApplicationError });
