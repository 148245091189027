import React, { useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import { DxcSelect, DxcTextInput } from "@dxc-technology/halstack-react";
import { deploymentPackageTypes } from "./DeploymentPackageDefinitionHelper";
import NonEditableField from "../components/NonEditableField";

function DeploymentPackageData({ source, name, type, onChangeSource, onChangeName, onChangeType }) {
  const [dpNameError, setDpNameError] = useState();
  const [dpSourceError, setDpSourceError] = useState();
  const [dpTypeError, setDpTypeError] = useState();

  const dpNameHandler = ({ value, error }) => {
    onChangeName(value);
    setDpNameError(error);
  };

  const dpSourceHandler = ({ value, error }) => {
    onChangeSource(value);
    setDpSourceError(error);
  };

  const dpTypeHandler = ({ value, error }) => {
    onChangeType(value);
    setDpTypeError(error);
  };

  return (
    <DeploymentPackageDataContainer>
      {onChangeName ? (
        <DxcTextInput
          label="Name"
          id="deploymentPackageName"
          placeholder="e.g.: my-deployment-package-name"
          name="deploymentPackageName"
          value={name}
          size={"fillParent"}
          onChange={dpNameHandler}
          onBlur={dpNameHandler}
          clearable={true}
          optional={false}
          error={dpNameError == null ? "" : dpNameError}
          pattern="^[a-z0-9-]+$"
          helperText="Only lowercase alphanumeric and hyphens accepted"
        />
      ) : (
        <NonEditableField label="Name" value={name} margin={{bottom: "small"}}/>
      )}

      <DxcTextInput
        label="Source"
        id="source"
        placeholder="e.g.: git::https://github.dxc.com/my-organization/my-repository-name.git//code"
        name="source"
        value={source}
        size={"fillParent"}
        onChange={dpSourceHandler}
        onBlur={dpSourceHandler}
        clearable={true}
        optional={false}
        error={dpSourceError == null ? "" : dpSourceError}
      />

      <DxcSelect
        id="deploymentPackageType"
        name="deploymentPackageType"
        options={deploymentPackageTypes}
        onChange={dpTypeHandler}
        onBlur={dpTypeHandler}
        label="Type"
        multiple={false}
        value={type}
        size={"fillParent"}
        optional={false}
        error={dpTypeError == null ? "" : dpTypeError}
      ></DxcSelect>
    </DeploymentPackageDataContainer>
  );
}

const DeploymentPackageDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default DeploymentPackageData;
