const hubList = [
  {
    aws_region: "us-east-1",
    diaas_hub: "hub-1"
  },
  {
    aws_region: "us-east-2",
    diaas_hub: "hub-2"
  },
  {
    aws_region: "us-west-1",
    diaas_hub: "hub-51"
  },
  {
    aws_region: "us-west-2",
    diaas_hub: "hub-52"
  },
  {
    aws_region: "ca-central-1",
    diaas_hub: "hub-61"
  },
  {
    aws_region: "eu-west-1",
    diaas_hub: "hub-81"
  },
  {
    aws_region: "eu-west-2",
    diaas_hub: "hub-82"
  },
  {
    aws_region: "eu-west-3",
    diaas_hub: "hub-83"
  },
  {
    aws_region: "eu-central-1",
    diaas_hub: "hub-91"
  },
  {
    aws_region: "eu-north-1",
    diaas_hub: "hub-71"
  },
  {
    aws_region: "ap-south-1",
    diaas_hub: "hub-21"
  },
  {
    aws_region: "ap-northeast-1",
    diaas_hub: "hub-31"
  },
  {
    aws_region: "ap-northeast-2",
    diaas_hub: "hub-32"
  },
  {
    aws_region: "ap-southeast-1",
    diaas_hub: "hub-41"
  },
  {
    aws_region: "ap-southeast-2",
    diaas_hub: "hub-42"
  }
];

export const environmentsTableColumns = [
  { displayValue: "Name", isSortable: true },
  { displayValue: "AWS region", isSortable: true },
  { displayValue: "Locked", isSortable: true },
  { displayValue: "Domain", isSortable: true },
  { displayValue: "Creator", isSortable: true },
  { displayValue: "Last deployment", isSortable: false },
  { displayValue: "Actions", isSortable: false },
];

export const environmentItemActionOptions = [
  {
    value: 1,
    label: "See deployments",
  },
  {
    value: 2,
    label: "Edit environment",
  },
  // {
  //   value: 3,
  //   label: "Configure Solution",
  // },
  {
    value: 4,
    label: "Platform console",
  },
  {
    value: 5,
    label: "Delete environment",
  },
  // TODO: remove clone option
  // {
  //   value: 6,
  //   label: "Clone environment",
  // },
];

export const getBusinessConsoleURL = (environmentName, awsRegion, accountZoneName) => {
  const hubName = hubList.find(hub => hub.aws_region === awsRegion);
  return `https://www.${environmentName}.${hubName.diaas_hub}.${accountZoneName}`;
};