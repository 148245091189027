import { DxcButton, DxcTextInput } from "@dxc-technology/halstack-react";
import styled from "styled-components";
import { deleteIcon } from "./delete_outline_24dp";
import { addIcon } from "./add_circle_24dp";
import EmptySection from "../EmptySection";
import Tag from "../Tag";
import React from "react";

function ParameterManager({ defaultParameters, expertParameters, addExpertParameter }) {
  return (
    <React.Fragment>
      <ParametersContainer>
        {defaultParameters?.length > 0
          ? defaultParameters.map((defaultParameter) => {
              return (
                <ParameterContainer>
                  <ParameterDefinition>
                    <TagContainer>
                      <Tag text="Default" />
                    </TagContainer>
                    <PairContainer>
                      <Key><span>{defaultParameter.key}</span></Key>
                      <DxcTextInput
                        label="Value"
                        size="fillParent"
                        margin={{
                          left: "medium",
                        }}
                        value={defaultParameter.value}
                        placeholder="e.g.: my-parameter-value"
                        name={defaultParameter.key}
                        onChange={({ value }) => defaultParameter.setNewValue(value)}
                      />
                    </PairContainer>
                  </ParameterDefinition>
                </ParameterContainer>
              );
            })
          : null}

        {expertParameters?.length > 0
          ? expertParameters.map((expertParameter) => {
              return (
                <ParameterContainer>
                  <ParameterDefinition>
                    <TagContainer>
                      <Tag type="warning" text="Expert" />
                    </TagContainer>
                    <PairContainer>
                      <DxcTextInput
                        label="Key"
                        size="fillParent"
                        value={expertParameter.key}
                        invalid={expertParameter.invalid}
                        helperText={expertParameter.assistiveText}
                        placeholder="e.g.: my-parameter-key"
                        onChange={({value}) => expertParameter.setNewKey(value)}
                      />
                      <DxcTextInput
                        label="Value"
                        size="fillParent"
                        margin={{
                          left: "medium",
                        }}
                        value={expertParameter.value}
                        placeholder="e.g.: my-parameter-value"
                        name={expertParameter.key}
                        onChange={({value}) => expertParameter.setNewValue(value)}
                      />
                    </PairContainer>
                  </ParameterDefinition>
                  <ButtonContainer>
                    <DxcButton
                      mode="text"
                      label="Delete"
                      margin={{ left: "xsmall" }}
                      icon={deleteIcon}
                      iconPosition="before"
                      onClick={expertParameter.deleteParameter}
                    />
                  </ButtonContainer>
                </ParameterContainer>
              );
            })
          : null}

        {(!defaultParameters || defaultParameters.length === 0) &&
        (!expertParameters || expertParameters.length === 0) ? (
          <EmptySectionContainer>
            <EmptySection text="No parameters found..." />
          </EmptySectionContainer>
        ) : null}
      </ParametersContainer>

      <BottomContainer>
        <DxcButton
          mode="text"
          label="Add parameter"
          icon={addIcon}
          iconPosition="before"
          margin={{ left: "xxsmall", top: "xsmall" }}
          onClick={addExpertParameter}
        />
      </BottomContainer>
    </React.Fragment>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  margin-top: 16px;
`;
const Key = styled.p`
  font: 16px regular;
  color: black;
  width: 100%;
  margin-bottom: 12px;
  align-self: flex-end;
`;

const TagContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: stretch;
  margin-top: 24px;
  margin-bottom: 6px;
  margin-right: 36px;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
`;

const ParametersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
`;

const ParameterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 16px;
  width: 100%;
`;

const ParameterDefinition = styled.div`
  display: flex;
  align-items: stretch;
  width: 70%;
`;
const PairContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
  justify-content: space-evenly;
  flex-warp: wrap;
`;

const EmptySectionContainer = styled.div`
  width: 100%;
`;

export default ParameterManager;
