import React, { useState } from "react";
import styled from "styled-components";

import {
  DxcHeading,
  DxcButton,
  DxcDialog,
  DxcAlert,
  DxcSpinner,
  DxcTextInput,
  DxcParagraph,
  DxcFlex,
  DxcInset,
  DxcTypography,
} from "@dxc-technology/halstack-react";

function UnregisterAccountDialog({
  accountName,
  unregisterStatus,
  unregisterErrorMessage,
  onUnregisterClick,
  onCancelClick,
}) {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const nameHandler = ({ value, error }) => {
    setName(value);
    setNameError(error);
  };

  return (
    <DxcDialog isCloseVisible onCloseClick={() => onCancelClick()}>
      <DialogHeader>
        <DxcHeading level={2} text="Delete account" margin={{ bottom: "xsmall" }} />
        {unregisterStatus === "interaction" && (
          <DxcSpinner
            role="progressbar"
            theme="light"
            mode="small"
            margin={{ left: "xsmall", bottom: "xsmall" }}
            label="Loading..."
          />
        )}
      </DialogHeader>

      {unregisterErrorMessage && (
        <DxcAlert type="error" mode="inline" size="fillParent" margin={{ bottom: "small" }}>
          <DxcParagraph>{unregisterErrorMessage}</DxcParagraph>
        </DxcAlert>
      )}

      <DxcFlex direction="column">
        <DxcParagraph>
          The account{" "}
          <DxcTypography as="span" fontWeight="600" display="inline">
            {accountName}
          </DxcTypography>{" "}
          is going to be deleted, please type the name of the account to confirm.
        </DxcParagraph>

        <DxcTextInput
          label="Account Name"
          id="accountName"
          placeholder={accountName}
          name="accountName"
          margin={{ top: "xsmall" }}
          size="fillParent"
          value={name}
          onChange={nameHandler}
          onBlur={nameHandler}
          clearable={true}
          optional={false}
          error={nameError == null ? "" : nameError}
          pattern="^[a-z0-9-]+$"
          helperText="Only lowercase alphanumeric and hyphens accepted"
        />
      </DxcFlex>

      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={() => onCancelClick()}
            mode="text"
          ></DxcButton>
          <DxcButton
            label="Delete"
            onClick={() => onUnregisterClick(accountName)}
            mode="primary"
            disabled={
              name !== accountName || unregisterStatus === "interaction" || nameError != null
            }
          ></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default UnregisterAccountDialog;
