import React, { useState } from "react";
import styled from "styled-components";
import {
  DxcHeading,
  DxcButton,
  DxcDialog,
  DxcAlert,
  DxcTextInput,
  DxcParagraph,
  DxcFlex,
  DxcInset,
  DxcTypography,
} from "@dxc-technology/halstack-react";

function DestroyDeploymentPackage({
  deploymentPackageName,
  hasDependants,
  onDestroyClick,
  onCancelClick,
}) {
  const [action, changeAction] = useState("");

  return (
    <DxcDialog isCloseVisible onCloseClick={onCancelClick}>
      <DialogHeader>
        <DxcHeading level={2} text="Destroy deployment package" margin={{ bottom: "xsmall" }} />
      </DialogHeader>

      {hasDependants && (
        <DxcAlert mode="inline" size="fillParent" margin={{ bottom: "small" }}>
          <DxcParagraph>This action will apply to its installed dependants too.</DxcParagraph>
        </DxcAlert>
      )}

      <DxcFlex direction="column">
        <DxcParagraph>
          The deployment package{" "}
          <DxcTypography as="span" fontWeight="600" display="inline">
            {deploymentPackageName}
          </DxcTypography>{" "}
          is going to be destroyed, please type{" "}
          <DxcTypography as="span" fontWeight="600" display="inline">
            destroy
          </DxcTypography>{" "}
          to confirm.
        </DxcParagraph>

        <DxcTextInput
          label="Action"
          size="fillParent"
          margin={{ top: "xsmall" }}
          value={action}
          onChange={({ value }) => changeAction(value)}
          placeholder="destroy"
          id="serviceAction"
          clearable={true}
        />
      </DxcFlex>

      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label={"Cancel"}
            onClick={onCancelClick}
            mode={"text"}
            margin={{ right: "xsmall" }}
          />
          <DxcButton
            label={hasDependants ? "Destroy with dependants" : "Destroy"}
            onClick={onDestroyClick}
            mode="primary"
            disabled={action !== "destroy"}
          />
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default DestroyDeploymentPackage;
