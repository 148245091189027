import { DxcAccordion, DxcBox } from "@dxc-technology/halstack-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EmptySection from "../components/EmptySection";

//DXC CDK Components
import ParameterManager from "../components/parameter-manager/ParameterManager";

function DeploymentPackageParametersStep({ deployInstallationInfo }) {
  const [isAccordionOpen, changeisAccordionOpen] = useState([]);
  const toggleAccordionOpen = (value, index) => {
    changeisAccordionOpen((oldIsAccordionOpen) => {
      oldIsAccordionOpen[index] = value;
      return [...oldIsAccordionOpen];
    });
  };

  //only to be executed when page loads
  useEffect(() => {
    if (deployInstallationInfo.serviceDeploymentPackages?.length > 0) {
      changeisAccordionOpen(
        deployInstallationInfo.serviceDeploymentPackages.map(
          (deploymentPackage) =>
            deploymentPackage.defaultParameters?.length > 0 ||
            deploymentPackage.expertParameters?.length > 0
        )
      );
    }
  }, [deployInstallationInfo.serviceDeploymentPackages]);

  return deployInstallationInfo.serviceDeploymentPackages &&
    deployInstallationInfo.serviceDeploymentPackages.length > 0 ? (
    <MainContainer>
      {deployInstallationInfo.serviceDeploymentPackages.map(
        (deploymentPackage, deploymentPackageIndex) => (
          <DxcAccordion
            label={deploymentPackage.name}
            padding={{
              top: "xsmall",
              left: "medium",
              right: "medium",
              bottom: "xsmall",
            }}
            margin={
              deploymentPackageIndex !== deployInstallationInfo.serviceDeploymentPackages.length - 1
                ? { bottom: "xxsmall" }
                : {}
            }
            isExpanded={isAccordionOpen[deploymentPackageIndex]}
            onChange={(value) => toggleAccordionOpen(value, deploymentPackageIndex)}
          >
            <ParameterManager
              defaultParameters={deploymentPackage.defaultParameters}
              expertParameters={deploymentPackage.expertParameters}
              addExpertParameter={deploymentPackage.addNewExpertParameter}
            />
          </DxcAccordion>
        )
      )}
    </MainContainer>
  ) : (
    <MainContainer>
      <DxcBox size="fillParent" margin={{ bottom: "xxsmall" }}>
        <EmptySectionContainer>
          <EmptySection text="No deployment packages found..." />
        </EmptySectionContainer>
      </DxcBox>
    </MainContainer>
  );
}
const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 46px;
  padding-right: 36px;
  margin-top: 36px;
`;

const EmptySectionContainer = styled.div`
  width: 100%;
  margin: 24px;
`;

export default DeploymentPackageParametersStep;
