import React from "react";
import styled from "styled-components";
import { useState, useMemo } from "react";

//DXC CDK Components
import { DxcAccordionGroup, DxcTextInput } from "@dxc-technology/halstack-react";

function DeploymentPackageArtefacts({ deploymentPackageArtefacts, disabledAccordion }) {
  const [indexAccordion, setIndexAccordion] = useState(0);

  const onActiveChange = (i) => {
    setIndexAccordion((prevValue) => (prevValue === i ? null : i));
  };

  const artefactsList = useMemo(() => {
    return deploymentPackageArtefacts && deploymentPackageArtefacts.length > 0
      ? deploymentPackageArtefacts
      : [];
  }, [deploymentPackageArtefacts]);

  return (
    <DeploymentPackageArtefactsContent>
      {artefactsList.length ? (
        <DxcAccordionGroup
          indexActive={disabledAccordion ? -1 : indexAccordion}
          onActiveChange={onActiveChange}
          disabled={disabledAccordion}
        >
          {artefactsList.map((artefact, index) => (
            <DxcAccordionGroup.Accordion
              label={`${artefact.name}` ? `${artefact.name}` : `Artefact ${index}`}
              padding="small"
            >
              {artefact.properties.length ? (
                artefact.properties.map((property, indexProperty) => (
                  <DxcTextInput
                    label={property.name}
                    value={property.value}
                    onChange={({value}) => property.onChangeValue(value, indexProperty, index)}
                    size="fillParent"
                    margin={{bottom: "xxsmall"}}
                  />
                ))
              ) : (
                <PropertiesContent>
                  <PropertiesEmpty>
                    <EmptyText>There are no properties</EmptyText>
                  </PropertiesEmpty>
                </PropertiesContent>
              )}
            </DxcAccordionGroup.Accordion>
          ))}
        </DxcAccordionGroup>
      ) : (
        <ArtefactsEmpty>
          <EmptyText>There are no artefacts</EmptyText>
        </ArtefactsEmpty>
      )}
    </DeploymentPackageArtefactsContent>
  );
}

const DeploymentPackageArtefactsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ArtefactsEmpty = styled.div`
  background-color: #eeeeee;
  border-radius: 4px;
  width: 100%;
`;

const PropertiesContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PropertiesEmpty = styled.div`
  width: 100%;
  background-color: #eeeeee;
  border-radius: 4px;
`;

const EmptyText = styled.p`
  text-align: center;
  color: #666;
  letter-spacing: 0.21px;
`;

export default DeploymentPackageArtefacts;
