export const deleteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    enable-background="new 0 0 24 24"
    fill="currentColor"
  >
    <path d="M6.033,19.531A2.072,2.072,0,0,0,8.1,21.6h8.266a2.072,2.072,0,0,0,2.066-2.066V7.133H6.033Zm13.431-15.5H15.848L14.815,3H9.649L8.616,4.033H5V6.1H19.465Z" />
    <path class="a" d="M0,0H24V24H0Z" fill="none" />
  </svg>
);
