import React, { useEffect, useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import { DxcAccordion, DxcTextInput, DxcSelect, DxcTable } from "@dxc-technology/halstack-react";

import { formatSelectOptions } from "./helper/DeployDeploymentPackageHelper";
import { readyIcon } from "./images/readyIcon";
import { pendingIcon } from "./images/pendingIcon";
import SectionContainer from "../components/SectionContainer";
import NonEditableField from "../components/NonEditableField";

function DeploymentPackageDependencyParameters({
  title,
  linkedDeploymentPackagesList,
  disableFields = false,
}) {
  const [isAccordionOpen, changeisAccordionOpen] = useState([]);
  const toggleAccordionOpen = (value, index) => {
    changeisAccordionOpen((oldIsAccordionOpen) => {
      oldIsAccordionOpen[index] = value;
      return [...oldIsAccordionOpen];
    });
  };

  //only to be executed when page loads
  useEffect(() => {
    if (linkedDeploymentPackagesList?.length > 0) {
      changeisAccordionOpen(
        linkedDeploymentPackagesList.map((item) =>
          item.deploymentPackageParameters?.length > 0 ? true : false
        )
      );
    }
  }, []);

  return (
    <MainContainer>
      <SectionContainer
        title={title}
        padding={{ top: "none", bottom: "small", left: "small", right: "small" }}
      >
        {linkedDeploymentPackagesList.length ? (
          linkedDeploymentPackagesList.map((deploymentPackage, index) => (
            // TODO: Review to change the key
            <DxcAccordion
              key={`linked_dus_accordeon_${index}`}
              label={`Deployment package (${index}): ${deploymentPackage.deploymentPackageName}`}
              margin={{ top: "small" }}
              padding={{ left: "small", right: "small", bottom: "small" }}
              icon={deploymentPackage.installed ? readyIcon : pendingIcon}
              assistiveText={deploymentPackage.installed ? "Installed" : ""}
              isExpanded={isAccordionOpen[index]}
              onChange={(value) => toggleAccordionOpen(value, index)}
            >
              {deploymentPackage.deploymentPackageParameters.length ? (
                <DxcTable margin={{ top: "small" }}>
                  <tbody>
                    {deploymentPackage.deploymentPackageParameters.map((parameter, index) => (
                      <tr key={`linked_dus_param_row_${index}`}>
                        <LabelCell>
                          <NonEditableField
                            label="Key"
                            value={parameter.name}
                            margin={{
                              top: "xxsmall",
                              bottom: "xsmall",
                              left: "xlarge",
                              right: "medium",
                            }}
                          />
                        </LabelCell>
                        <ValueCell>
                          {parameter.type === "enum" ? (
                            <DxcSelect
                              options={formatSelectOptions(parameter.enumValues)}
                              onChange={({ value }) =>
                                parameter.onChangeDeploymentPackageParameter(value)
                              }
                              label="Value"
                              size="fillParent"
                              value={parameter.value}
                              margin={{
                                top: "xxsmall",
                                bottom: "xsmall",
                                left: "medium",
                                right: "xlarge",
                              }}
                              disabled={disableFields}
                            ></DxcSelect>
                          ) : (
                            <DxcTextInput
                              label="Value"
                              placeholder="e.g.: my-property-value"
                              margin={{
                                top: "xxsmall",
                                bottom: "xsmall",
                                left: "medium",
                                right: "xlarge",
                              }}
                              size="fillParent"
                              value={parameter.value}
                              onChange={({ value }) =>
                                parameter.onChangeDeploymentPackageParameter(value)
                              }
                              onBlur={({ value }) =>
                                parameter.onChangeDeploymentPackageParameter(value)
                              }
                              clearable={true}
                              disabled={disableFields}
                            />
                          )}
                        </ValueCell>
                      </tr>
                    ))}
                  </tbody>
                </DxcTable>
              ) : (
                <ParametersEmpty>
                  <EmptyText>There are no parameters</EmptyText>
                </ParametersEmpty>
              )}
            </DxcAccordion>
          ))
        ) : (
          <ParametersEmpty>
            <EmptyText>There are no deployment packages</EmptyText>
          </ParametersEmpty>
        )}
      </SectionContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 46px;
  padding-right: 36px;
`;

const ParametersEmpty = styled.div`
  display: flex;
  justify-content: center;
  background-color: #eeeeee;
  border-radius: 4px;
  height: 52px;
  width: 100%;
  margin-top: 24px;
`;

const EmptyText = styled.p`
  text-align: center;
  color: #666;
  letter-spacing: 0.21px;
`;

const Cell = styled.td`
  padding: 0px !important;
`;

const LabelCell = styled(Cell)`
  max-width: 25%;
`;

const ValueCell = styled(Cell)`
  min-width: 75%;
`;

export default DeploymentPackageDependencyParameters;
