import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useMemo } from "react";

//DXC CDK Components
import {
  DxcSelect,
  DxcResultsetTable,
  DxcTextInput,
  DxcCheckbox,
  DxcFlex
} from "@dxc-technology/halstack-react";

import {
  deploymentPackageTypes,
  deploymentPackageColumns,
} from "./DeploymentPackageDefinitionHelper";

import LinkDeprecatedDeploymentPackage from "./dialog/LinkDeprecatedDeploymentPackage";

function AvailableDeploymentPackages({
  availableDeploymentPackages,
  textFilter,
  deploymentPackageTypeFilter,
  checkedDeprecated,
  onChangeTextFilter,
  onChangeDeploymentPackageTypeFilter,
  onChangeDeprecated,
  onClickSelected,
}) {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showDeprecatedDialog, setShowDeprecatedDialog] = useState(false);
  const [currentDeploymentPackage, setCurrentDeploymentPackage] = useState(null);

  const itemsPerPageClick = (value) => {
    setItemsPerPage(value);
  };

  const textFilterHandler = ({ value }) => {
    onChangeTextFilter(value);
  };

  const typesFilterHandler = ({ value }) => {
    onChangeDeploymentPackageTypeFilter(value);
  };

  const clickHandler = useCallback(
    (deploymentPackageName, deploymentPackageType) => {
      if (onClickSelected) {
        onClickSelected(deploymentPackageName, deploymentPackageType);
      }
      setShowDeprecatedDialog(false);
      setCurrentDeploymentPackage(null);
      return false;
    },
    [onClickSelected]
  );

  const deprecatedClickHandler = (deploymentPackageName, deploymentPackageType) => {
    setShowDeprecatedDialog(true);
    setCurrentDeploymentPackage({
      deploymentPackageName,
      deploymentPackageType,
    });
  };

  const availableDeploymentPackagesList = useMemo(() => {
    const _deploymentPackagesList = availableDeploymentPackages ? availableDeploymentPackages : [];

    return _deploymentPackagesList.map((deploymentPackage) => [
      {
        displayValue: (
          <DeploymentPackageNameContainer>
            {deploymentPackage.deploymentPackageName}{" "}
            {deploymentPackage.isDeprecated && (
              <Tag>
                <TagLabel>Deprecated</TagLabel>
              </Tag>
            )}
          </DeploymentPackageNameContainer>
        ),
      },
      { displayValue: deploymentPackage.deploymentPackageType },
      {
        displayValue: (
          <DxcCheckbox
            checked={deploymentPackage.isSelected}
            onChange={() =>
              deploymentPackage.isDeprecated && !deploymentPackage.isSelected
                ? deprecatedClickHandler(
                    deploymentPackage.deploymentPackageName,
                    deploymentPackage.deploymentPackageType
                  )
                : clickHandler(
                    deploymentPackage.deploymentPackageName,
                    deploymentPackage.deploymentPackageType
                  )
            }
          />
        ),
        sortValue: deploymentPackage.isSelected,
      },
    ]);
  }, [availableDeploymentPackages, clickHandler]);

  return (
    <Content>
      {/* <FilterContainer> */}
      <DxcFlex justifyContent="space-between">
        <DxcTextInput
          label="Search for a deployment package"
          placeholder="e.g.: my-deployment-package-name"
          id="deploymentPackageNameFilter"
          name="deploymentPackageNameFilter"
          value={textFilter}
          onChange={textFilterHandler}
          margin={{ bottom: "small", right: "small" }}
          size={"large"}
          clearable={true}
        />

        <DxcSelect
          options={deploymentPackageTypes}
          onChange={typesFilterHandler}
          label="Type"
          id="deploymentPackageTypeFilter"
          name="deploymentPackageTypeFilter"
          multiple={true}
          value={deploymentPackageTypeFilter}
          margin={{ right: "small" }}
          size="large"
        ></DxcSelect>

        <DxcCheckbox
          checked={checkedDeprecated}
          labelPosition="after"
          label="Show deprecated"
          onChange={onChangeDeprecated}
          size="small"
        />
        </DxcFlex>
      {/* </FilterContainer> */}

      <DxcResultsetTable
        columns={deploymentPackageColumns}
        rows={availableDeploymentPackagesList}
        itemsPerPage={itemsPerPage}
        itemsPerPageOptions={[10, 15]}
        itemsPerPageFunction={itemsPerPageClick}
        margin={{ top: "medium" }}
      ></DxcResultsetTable>

      {showDeprecatedDialog && (
        <LinkDeprecatedDeploymentPackage
          onCancel={() => setShowDeprecatedDialog(false)}
          onAdd={() =>
            clickHandler(
              currentDeploymentPackage.deploymentPackageName,
              currentDeploymentPackage.deploymentPackageType
            )
          }
          deploymentPackageName={currentDeploymentPackage.deploymentPackageName}
          isDependency
        />
      )}
    </Content>
  );
}

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  margin: 0px auto;
  flex-direction: column;
`;

const DeploymentPackageNameContainer = styled.div`
  display: flex;
`;

const Tag = styled.div`
  margin-left: 10px;
  height: calc(1.5rem - 2 * 1px);
  padding: 0 1rem;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  background-color: #fef9e6;
  border: 1px solid #c59f07;
  color: #947705;
`;

const TagLabel = styled.p`
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;
`;

export default AvailableDeploymentPackages;
