//React hooks imports
import { useState, useMemo, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

//configuation helpers imports
import { config } from "../config";
import {
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  PROMISE_FINAL_STATUSES,
} from "../common/utils";

//DXC HAL
import { useHalResource } from "@dxc-technology/halstack-react-hal";

//helper functions
import { buildDeploymentPackageDependenciesPayload } from "../deployment-package-definition/DeploymentPackageDefinitionHelper";
import { HalApiCaller } from "@dxc-technology/halstack-client";

const DEPLOY_UNTIS_URL = `${config.environments_api_url}/deployment-packages`;
const ENVIRONMENT_TYPES_URL = `${config.environments_api_url}/environment-types`;

const useEnvironmentCreate = () => {
  const [createEnvironmentMessage, changeCreateEnvironmentMessage] = useState(null);
  const [createEnvironmentStatus, changeCreateEnvironmentStatus] = useState("idle");
  const [defaultLoaded, setDefaultLoaded] = useState(false);
  const [error, setError] = useState(null);

  //Available DeploymentPackages Filter
  const [deploymentPackageTextFilter, changeDeploymentPackageTextFilter] = useState("");
  const [deploymentPackageServiceFilter, changeDeploymentPackageServiceFilter] = useState([]);
  const [deploymentPackageIsDeprecated, changeDeploymentPackageIsDeprecated] = useState(false);
  const [serviceFilter, changeServiceFilter] = useState("");

  //Environment Info
  const [envDescription, changeEnvDescription] = useState(null);
  const [isEnvLocked, setIsEnvLocked] = useState(false);
  const [isEnvPrivate, setIsEnvPrivate] = useState(false);
  const [envType, changeEnvType] = useState("");
  const [envNumber, changeEnvNumber] = useState(null);
  const [envAccountZoneName, changeEnvAccountZoneName] = useState("");
  const [envAWSRegion, changeEnvAWSRegion] = useState("");
  const [envOwner, changeEnvOwner] = useState(null);
  const [envNotifications, changeEnvNotifications] = useState(null);
  const [deploymentPackageDependencies, changeDeploymentPackageDependencies] = useState([]);

  //navigation
  const history = useHistory();
  
  const [deploymentPackagesResource, deploymentPackagesStatus, deploymentPackagesError] =
    useHalResource({
      url: DEPLOY_UNTIS_URL,
      asyncHeadersHandler: getSessionHeaders,
      headers: getPlatformHeaders(),
    });

  const [envTypesResource, envTypesStatus, envTypesError] = useHalResource({
    url: ENVIRONMENT_TYPES_URL,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  //Message operations
  useEffect(() => {
    if (
      PROMISE_FINAL_STATUSES.includes(deploymentPackagesStatus) &&
      PROMISE_FINAL_STATUSES.includes(envTypesStatus) &&
      (deploymentPackagesError || envTypesError)
    ) {
      const _error = deploymentPackagesError ?? envTypesError;
      setError(buildApplicationError(_error));
    }
  }, [deploymentPackagesError, deploymentPackagesStatus, envTypesError, envTypesStatus]);

  //HAL resources calculated status
  useEffect(() => {
    if (envTypesStatus === "fetching" || deploymentPackagesStatus === "fetching") {
      changeCreateEnvironmentStatus("loading");
    } else {
      changeCreateEnvironmentStatus("idle");
    }
  }, [envTypesStatus, deploymentPackagesStatus]);

  const dismissMessage = () => {
    changeCreateEnvironmentMessage(null);
  };

  const toggleIsEnvLocked = () => {
    setIsEnvLocked(!isEnvLocked);
  };

  const toggleIsEnvPrivate = () => {
    setIsEnvPrivate(!isEnvPrivate);
  };

  //DeploymentPackage Dependencies functions
  const toggleDeploymentPackageDependency = (name, service) => {
    !isSelectedDependency(name)
      ? changeDeploymentPackageDependencies([
          ...deploymentPackageDependencies,
          { name: name, service: service },
        ])
      : changeDeploymentPackageDependencies(
          deploymentPackageDependencies.filter((item) => item.name !== name)
        );
  };

  const deploymentPackageDependenciesList = useMemo(() => {
    return deploymentPackageDependencies.map((deploymentPackageDependency) => ({
      name: deploymentPackageDependency.name,
      service: deploymentPackageDependency.service,
    }));
  }, [deploymentPackageDependencies]);

  const isSelectedDependency = useCallback(
    (name) => {
      return deploymentPackageDependenciesList.some((dependency) => dependency.name === name);
    },
    [deploymentPackageDependenciesList]
  );

  const availableDeploymentPackagesList = useMemo(() => {
    const _deploymentPackagesList =
      (deploymentPackagesResource && deploymentPackagesResource.getItems()) || [];

    return _deploymentPackagesList
      .map((deploymentPackage) => {
        return {
          deploymentPackageName: deploymentPackage.name,
          deploymentPackageService:
            deploymentPackage.summary?.tags?.find((tag) => tag.type === "service")?.type ===
            "service"
              ? deploymentPackage.summary.tags.find((tag) => tag.type === "service")?.title
              : "",
          isSelected: isSelectedDependency(
            deploymentPackage.name,
            deploymentPackage.summary.tags ? deploymentPackage.summary.tags[0]?.title : ""
          ),
          isDeprecated: deploymentPackage.summary.deprecated,
        };
      })
      .filter((deploymentPackage) => {
        const byDeploymentPackageTypes = deploymentPackageServiceFilter.length
          ? deploymentPackageServiceFilter.includes(deploymentPackage.deploymentPackageService)
            ? deploymentPackage
            : null
          : deploymentPackage;
        const byDeprecated = !deploymentPackageIsDeprecated
          ? (deploymentPackage.isDeprecated &&
              deploymentPackage.isDeprecated === deploymentPackageIsDeprecated) ||
            (!deploymentPackage.isDeprecated && !deploymentPackageIsDeprecated)
            ? deploymentPackage
            : null
          : deploymentPackage;
        const byTextFilter = deploymentPackageTextFilter
          ? deploymentPackage.deploymentPackageName.includes(deploymentPackageTextFilter)
            ? deploymentPackage
            : null
          : deploymentPackage;
        return byDeploymentPackageTypes
          ? byDeprecated && byTextFilter
            ? deploymentPackage
            : null
          : null;
      })
      .sort(function (a, b) {
        return b.isSelected - a.isSelected;
      });
  }, [
    isSelectedDependency,
    deploymentPackageServiceFilter,
    deploymentPackagesResource,
    deploymentPackageIsDeprecated,
    deploymentPackageTextFilter,
  ]);

  const totalDeploymentPackageList = useMemo(() => {
    const _deploymentPackagesList =
      (deploymentPackagesResource && deploymentPackagesResource.getItems()) || [];

    return _deploymentPackagesList.map((deploymentPackage) => {
      return {
        deploymentPackageName: deploymentPackage.name,
        deploymentPackageService:
          deploymentPackage.summary?.tags?.find((tag) => tag.type === "service")?.type === "service"
            ? deploymentPackage.summary.tags.find((tag) => tag.type === "service")?.title
            : "",
        isSelected: isSelectedDependency(
          deploymentPackage.name,
          deploymentPackage.summary.tags ? deploymentPackage.summary.tags[0]?.title : ""
        ),
        isDeprecated: deploymentPackage.summary.deprecated,
      };
    });
  }, [isSelectedDependency, deploymentPackagesResource]);

  const envTypesList = useMemo(() => {
    const _envTypesList = (envTypesResource && envTypesResource.getItems()) || [];

    return _envTypesList.map((item) => {
      return {
        envType: item.summary.environment_type,
      };
    });
  }, [envTypesResource]);

  const onSaveChanges = async (customerName, accountName) => {
    dismissMessage();
    changeCreateEnvironmentStatus("loading");
    const payload = {
      customer_name: customerName,
      account_name: accountName,
      description: envDescription,
      environment_type: envType,
      aws_region: envAWSRegion,
      env_account_zone_name: envAccountZoneName,
      environment_id: envNumber,
      is_locked: isEnvLocked,
      is_private: isEnvPrivate,
      owner: envOwner,
      notifications:
        envNotifications && envNotifications.length > 0
          ? envNotifications.split(",").map((email) => email.trim())
          : [],
      location_url: "",
      username: "",
      services: buildDeploymentPackageDependenciesPayload(deploymentPackageDependencies),
    };

    try {
      const environmentUrl = `${config.environments_api_url}/customers/${customerName}/accounts/${accountName}/environments`;
      const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
      const response = await HalApiCaller.post({
        url: environmentUrl,
        body: payload,
        headers: { ...asyncHeaders, ...getPlatformHeaders() },
      });
      changeCreateEnvironmentStatus("completed");
      const environment_resource_name =
        response?.halResource?.getProperty("resource_name")?.value;
      history.push(
        `/customers/${customerName}/accounts/${accountName}/environments/${environment_resource_name}/deployments`
      );
    } catch (error) {
      changeCreateEnvironmentStatus("rejected");
      error.response?.data?.messages
        ? changeCreateEnvironmentMessage({
            type: "error",
            message: `${error.response.data.messages[0].message}`,
          })
        : changeCreateEnvironmentMessage({
            type: "error",
            message: "An error ocurred when creating the environment",
          });
    }
  };

  const compareStringsForSorting = (value1, value2) => {
    return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
  };

  const isSelectedService = useCallback(
    (service) => {
      let dpFilteredByService = totalDeploymentPackageList.filter(
        (deploymentP) => deploymentP.deploymentPackageService === service
      );
      const emptyService = dpFilteredByService.length === 0 ? true : false;

      !emptyService &&
        deploymentPackageDependencies.forEach((dp) => {
          dpFilteredByService = dpFilteredByService.filter(
            (item) => item.deploymentPackageName !== dp.name
          );
        });

      return !emptyService && dpFilteredByService.length === 0 ? true : false;
    },
    [deploymentPackageDependencies, totalDeploymentPackageList]
  );

  const toggleService = useCallback(
    (service) => {
      let dPackageDependencies = deploymentPackageDependencies;
      let dpFilteredByService = totalDeploymentPackageList.filter(
        (deploymentP) => deploymentP.deploymentPackageService === service
      );

      if (!isSelectedService(service)) {
        dpFilteredByService.forEach((dp) => {
          if (!isSelectedDependency(dp.deploymentPackageName))
            dPackageDependencies = [
              ...dPackageDependencies,
              {
                name: dp.deploymentPackageName,
                service: dp.deploymentPackageService,
              },
            ];
        });
      } else {
        dpFilteredByService.forEach((dp) => {
          dPackageDependencies = dPackageDependencies.filter(
            (item) => item.name !== dp.deploymentPackageName
          );
        });
      }
      changeDeploymentPackageDependencies(dPackageDependencies);
    },
    [
      deploymentPackageDependencies,
      isSelectedDependency,
      isSelectedService,
      totalDeploymentPackageList,
    ]
  );

  const services = useMemo(() => {
    const allDUServices = deploymentPackagesResource
      ? [].concat(deploymentPackagesResource.getItems())
      : [];
    const allServices = allDUServices
      .filter((item) => {
        return item.summary?.tags?.length > 0;
      })
      .reduce((acc, du) => {
        const serviceTags = du.summary?.tags?.filter((tag) => tag.type === "service");
        return acc.concat(serviceTags);
      }, [])
      .filter((value, index, self) => {
        return self.findIndex((service) => service.id === value.id) === index;
      })
      .sort((compare1, compare2) => {
        return compareStringsForSorting(compare1.title.toLowerCase(), compare2.title.toLowerCase());
      })
      .map((service) => ({
        name: service.title,
        selected: isSelectedService(service.title),
        onChangeSelected: (service) => {
          toggleService(service);
        },
      }));

    const servicesFiltered = serviceFilter
      ? allServices.filter((service) =>
          service.name.toLowerCase().includes(serviceFilter.toLowerCase())
        )
      : allServices;

    return servicesFiltered;
  }, [deploymentPackagesResource, serviceFilter, isSelectedService, toggleService]);

  //default values load
  useEffect(() => {
    if (!defaultLoaded && availableDeploymentPackagesList.length > 0) {
      changeDeploymentPackageDependencies(
        availableDeploymentPackagesList
          .filter((item) => item.deploymentPackageType === "Infrastructure")
          .map((deploymentPackageDependency) => ({
            name: deploymentPackageDependency.deploymentPackageName,
            type: deploymentPackageDependency.deploymentPackageType,
          }))
      );
      setDefaultLoaded(true);
    }
  }, [availableDeploymentPackagesList, defaultLoaded]);

  //navigate function
  const navigateAccounts = (customer) => {
    history.push(`/customers/${customer}`);
  };

  //grouped environment data info
  const envDataInfo = {
    envDescription: envDescription,
    isEnvLocked: isEnvLocked,
    isEnvPrivate: isEnvPrivate,
    envType: envType,
    envTypesList: envTypesList,
    envNumber: envNumber,
    envAccountZoneName: envAccountZoneName,
    envAwsRegion: envAWSRegion,
    envOwner: envOwner,
    envNotifications: envNotifications,
    onChangeDescription: (newEnvDescription) => changeEnvDescription(newEnvDescription),
    onChangeIsLocked: toggleIsEnvLocked,
    onChangeIsPrivate: toggleIsEnvPrivate,
    onChangeType: (newEnvType) => changeEnvType(newEnvType),
    onChangeNumber: (newEnvNumber) => changeEnvNumber(newEnvNumber),
    onChangeAccountZoneName: changeEnvAccountZoneName,
    onChangeAWSRegion: (newEnvAWSRegion) => changeEnvAWSRegion(newEnvAWSRegion),
    onChangeOwner: (newEnvOwner) => changeEnvOwner(newEnvOwner),
    onChangeNotifications: (newEnvNotifications) => changeEnvNotifications(newEnvNotifications),
  };

  //grouped available deploymentPackages info
  const availableDeploymentPackagesInfo = {
    availableDeploymentPackagesList: availableDeploymentPackagesList,
    textFilter: deploymentPackageTextFilter,
    deploymentPackageServiceFilter: deploymentPackageServiceFilter,
    services: services,
    serviceFilter: serviceFilter,
    deprecatedFilter: deploymentPackageIsDeprecated,
    onChangeServiceFilter: changeServiceFilter,
    onChangeTextFilter: changeDeploymentPackageTextFilter,
    onChangeDeploymentPackageServiceFilter: changeDeploymentPackageServiceFilter,
    onChangeDeprecatedFilter: changeDeploymentPackageIsDeprecated,
    onClickSelected: toggleDeploymentPackageDependency,
  };

  return [
    createEnvironmentStatus,
    createEnvironmentMessage,
    envDataInfo,
    availableDeploymentPackagesInfo,
    dismissMessage,
    onSaveChanges,
    navigateAccounts,
    error,
  ];
};

export default useEnvironmentCreate;
