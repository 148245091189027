import React from "react";
import styled from "styled-components";

const EmptySection = ({ text }) => {
  return <Container>{text}</Container>;
};

const Container = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
  background-color: #fafafa;
  font-size: 0.875rem;
  color: #666666;
`;

export default EmptySection;
