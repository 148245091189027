import React from "react";
import styled from "styled-components";

//DXC CDK Components
import { DxcTable } from "@dxc-technology/halstack-react";

import AddDeploymentPackageCondition from "./dialog/AddDeploymentPackageCondition";
import EditDeploymentPackageCondition from "./dialog/EditDeploymentPackageCondition";

import binImage from "./images/delete-24px.svg";
import editImage from "./images/edit-24px.svg";

function DeploymentPackageConditions({
  deploymentPackageConditions,
  isAddDialogVisible,
  isEditDialogVisible,
  onAddSaveClick,
  onAddCancelClick,
  onEditSaveClick,
  onEditCancelClick,
}) {
  return (
    <DeploymentPackageConditionsContainer>
      {deploymentPackageConditions.length ? (
        <DxcTable>
          <tbody>
            {deploymentPackageConditions.map((condition) => (
              <tr>
                <td>
                  {condition.conditionKey} {condition.assert ? "=" : "≠"} {condition.value}
                </td>
                <td width="15%">
                  <ImageContainer>
                    <Image
                      src={editImage}
                      alt={`edit ${condition.conditionKey}`}
                      onClick={condition.onEditOpenClick}
                    ></Image>
                    <Image
                      src={binImage}
                      alt={`delete ${condition.conditionKey}`}
                      onClick={condition.onRemoveClick}
                    ></Image>
                  </ImageContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </DxcTable>
      ) : (
        <ConditionsEmpty>
          <EmptyText>There are no conditions</EmptyText>
        </ConditionsEmpty>
      )}

      {!!isAddDialogVisible ? (
        <AddDeploymentPackageCondition
          onSaveClick={onAddSaveClick}
          onCancelClick={onAddCancelClick}
        />
      ) : null}

      {isEditDialogVisible ? (
        <EditDeploymentPackageCondition
          oldConditionKey={isEditDialogVisible.conditionKey}
          oldValue={isEditDialogVisible.value}
          oldAssert={isEditDialogVisible.assert}
          onSaveClick={onEditSaveClick}
          onCancelClick={onEditCancelClick}
          indexCondition={isEditDialogVisible.indexCondition}
        />
      ) : null}
    </DeploymentPackageConditionsContainer>
  );
}

const DeploymentPackageConditionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ImageContainer = styled.div`
  display: flex;
`;

const Image = styled.img`
  cursor: pointer;
  height: 24px;
  width: 24px;
`;

const ConditionsEmpty = styled.div`
  background-color: #eeeeee;
  border-radius: 4px;
  width: 100%;
`;

const EmptyText = styled.p`
  text-align: center;
  color: #666;
  letter-spacing: 0.21px;
`;

export default DeploymentPackageConditions;
