import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcButton,
  DxcInset,
  DxcFlex,
} from "@dxc-technology/halstack-react";

//custom hook
import useEnvironmentEdit from "./useEnvironmentEdit";

import AvailableServices from "../deployment-package-definition/AvailableServices";
import EnvironmentData from "../environment-definition/EnvironmentData";
import SectionContainer from "../components/SectionContainer";
import NavigationContainer from "../components/NavigationContainer";

//search image
import backImage from "../deployment-package-definition/images/arrow_back_ios-24px.svg";
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";

function EnvironmentEdit() {
  const [
    environmentStatus,
    environmentMessage,
    envDataInfo,
    availableDeploymentPackagesInfo,
    dismissMessage,
    onSaveChanges,
    navigateBack,
    navigateBackText,
    error,
  ] = useEnvironmentEdit();
  useErrorHandler(error);

  //retrieve location parameters
  const { customerId, accountId } = useParams();
  const sections = [
    {
      label: "Information",
      section: (
        <MainContainer>
          <SectionContainer title="Information">
            <EnvironmentData
              description={envDataInfo.envDescription}
              isLocked={envDataInfo.isEnvLocked}
              isPrivate={envDataInfo.isEnvPrivate}
              envType={envDataInfo.envType}
              envTypesList={envDataInfo.envTypesList}
              number={envDataInfo.envNumber}
              accountZoneName={envDataInfo.envAccountZoneName}
              AWSRegion={envDataInfo.envAWSRegion}
              owner={envDataInfo.envOwner}
              notifications={envDataInfo.envNotifications}
              onChangeDescription={envDataInfo.onChangeDescription}
              onChangeIsLocked={envDataInfo.onChangeIsLocked}
              onChangeIsPrivate={envDataInfo.onChangeIsPrivate}
              onChangeAccountZoneName={envDataInfo.onChangeAccountZoneName}
              onChangeAWSRegion={envDataInfo.onChangeAWSRegion}
              onChangeOwner={envDataInfo.onChangeOwner}
              onChangeNotifications={envDataInfo.onChangeNotifications}
            />
          </SectionContainer>
        </MainContainer>
      ),
    },
    {
      label: "Available deployment packages",
      section: (
        <MainContainer>
          <SectionContainer title="Available deployment packages">
            <AvailableServices
              availableDeploymentPackages={
                availableDeploymentPackagesInfo.availableDeploymentPackagesList
              }
              textFilter={availableDeploymentPackagesInfo.textFilter}
              deploymentPackageServiceFilter={
                availableDeploymentPackagesInfo.deploymentPackageServiceFilter
              }
              checkedDeprecated={availableDeploymentPackagesInfo.deprecatedFilter}
              onChangeTextFilter={availableDeploymentPackagesInfo.onChangeTextFilter}
              onChangeDeploymentPackageServiceFilter={
                availableDeploymentPackagesInfo.onChangeDeploymentPackageServiceFilter
              }
              onChangeDeprecated={availableDeploymentPackagesInfo.onChangeDeprecatedFilter}
              onClickSelected={availableDeploymentPackagesInfo.onClickSelected}
              onChangeServiceFilter={availableDeploymentPackagesInfo.onChangeServiceFilter}
              services={availableDeploymentPackagesInfo.services}
              serviceFilter={availableDeploymentPackagesInfo.serviceFilter}
            ></AvailableServices>
          </SectionContainer>
        </MainContainer>
      ),
    },
  ];

  return (
    <GlobalContent>
      <Content>
        <StyledLink onClick={() => navigateBack(customerId)}>
          <BackImg src={backImage} />
          {navigateBackText}
        </StyledLink>
        <ComponentHeaderContainer>
          <DxcInset top="2rem" bottom="1rem">
            <DxcFlex alignItems="center" justifyContent="space-between">
              <DxcHeading level={1} weight="normal" text={`Edit environment`} />
              <DxcButton
                mode="primary"
                label="Save changes"
                onClick={() => onSaveChanges(customerId, accountId)}
              />
            </DxcFlex>
          </DxcInset>
          <DxcFlex direction="column" gap={"0.1rem"}>
            <DxcFlex>
              <SubtitleLabel>Customer:</SubtitleLabel>
              <SubtitleValue>{customerId}</SubtitleValue>
            </DxcFlex>
            <DxcFlex>
              <SubtitleLabel>Account:</SubtitleLabel>
              <SubtitleValue>{accountId}</SubtitleValue>
            </DxcFlex>
          </DxcFlex>
          <DxcInset top="1rem" />
          {environmentMessage ? (
            <DxcAlert
              type={environmentMessage.type}
              mode="inline"
              inlineText={environmentMessage.message}
              size="fillParent"
              onClose={dismissMessage}
              margin={{ bottom: "small" }}
            />
          ) : null}
        </ComponentHeaderContainer>
        <DxcInset top="1rem" />
        {environmentStatus === "fetching" ? (
          <DxcSpinner theme="light" label="Loading..." mode="overlay" />
        ) : null}
      </Content>

      <NavigationContainer sections={sections} title="Sections" />
    </GlobalContent>
  );
}

const GlobalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubtitleLabel = styled.span`
  color: #0009;
`;

const SubtitleValue = styled.span`
  font-weight: 600;
  margin-left: 4px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled.div`
  display: flex;
  align-self: flex-start;
  width: fit-content;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  top: 16px;
  :hover {
    cursor: pointer;
  }
`;

const BackImg = styled.img`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-self: center;
`;

const ComponentHeaderContainer = styled.div`
  width: 80%;
  align-self: flex-start;
`;

export default withErrorBoundary(EnvironmentEdit, { FallbackComponent: ApplicationError });
