
import React from "react";
import styled from "styled-components";

const KeyValuePair = ({ label, value }) => {
  return (
    <PairContaianer>
      <KeyEntry title={label}>{label}</KeyEntry>
      <ValueEntry title={value}>{value}</ValueEntry>
    </PairContaianer>
  );
};

const PairContaianer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 20%) minmax(240px, 80%));
  border-bottom: 1px solid #eeeeee;
  padding: 4px;
`;

const KeyEntry = styled.div`
  place-self: center stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
`;

const ValueEntry = styled.div`
  font-weight: 600;
  place-self: center stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 4px;
`;

export default KeyValuePair;
