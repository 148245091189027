export const editImage = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    enable-background="new 0 0 24 24"
    fill="currentColor"
  >
    <path d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM20.71,7.04a1,1,0,0,0,0-1.41L18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75,1.83-1.83Z" />
    <path class="a" d="M0,0H24V24H0Z" fill="none" />
  </svg>
);
