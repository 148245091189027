//React hooks imports
import { useState, useMemo, useEffect, useCallback, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";

//configuation helpers imports
import { config } from "../config";
import {
  getAuthenticatedUser,
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  isEmptyObject,
  HTTP_ERROR_CODES,
  PROMISE_FINAL_STATUSES,
  sortReleaseByVersionDesc,
} from "../common/utils";

//DXC HAL
import { useHalResource } from "@dxc-technology/halstack-react-hal";

import {
  buildDeploymentPackagePayload,
  getRecursiveDependencies,
  getDeploymentPackageDependencies,
  getDeploymentPackageParamsFromEnvironment,
  getDeploymentPackageData,
  getDeploymentsFromEmbeddedObject,
} from "../deploy-deployment-package-steps/helper/DeployDeploymentPackageHelper";
import { HalApiCaller } from "@dxc-technology/halstack-client";

export const useInstallDeploymentPackage = () => {
  const [error, setError] = useState(null);

  //url management hooks
  const history = useHistory();
  const { customerId, accountId, environmentId, deploymentPackageId } = useParams();

  //UI routes
  const environmentUIRoute = `/customers/${customerId}/accounts/${accountId}/environments/${environmentId}`;
  const environmentURL = `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}`;
  const releaseURL = `${config.release_api_url}/releases/${deploymentPackageId}/versions?deprecated=false`;
  const deploymentPackageURL = `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}/deployment-packages/${deploymentPackageId}`;

  //HAL stack hooks
  const [environmentResource, environmentStatus, environmentError] = useHalResource({
    url: `${environmentURL}?embedded=[deployments.item]`,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const [releasesResource, releasesStatus, releasesError] = useHalResource({
    url: releaseURL,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const [isMessageDismissed, changeIsMessageDismissed] = useState(false);
  const [installDeploymentPackageMessage, changeInstallDeploymentPackageMessage] = useState(null);
  const [installDeploymentPackageStatus, changeInstallDeploymentPackageStatus] = useState("idle");
  //deploymentInfo
  const [source, setSource] = useState(null);
  const [tag, setTag] = useState(null);
  const [invalidTag, setInvalidTag] = useState(false);
  const [tfAction, setTfAction] = useState("apply");
  const [deployDependencies, setDeployDependencies] = useState("only-uninstalled");
  const [deploymentPackageParameters, setDeploymentPackageParameters] = useState([]);
  const [expertParameters, setExpertParameters] = useState([]);
  const [deploymentPackageDependencies, setDeploymentPackageDependencies] = useState([]);
  const [deploymentPackageArtefacts, setDeploymentPackageArtefacts] = useState([]);
  const [deploymentType, setDeploymentType] = useState("default");
  const [resourceVersion, setResourceVersion] = useState("");
  const [version, setVersion] = useState("");

  const resetStateProperties = () => {
    setSource(null);
    setTag(null);
    setDeploymentPackageParameters([]);
    setExpertParameters([]);
    setDeploymentPackageDependencies([]);
    setDeploymentPackageArtefacts([]);
  };

  const dismissMessage = () => {
    changeIsMessageDismissed(true);
    changeInstallDeploymentPackageMessage(null);
  };

  //Release Info
  const releasesVersionList = useMemo(() => {
    const _releasesCollection = releasesResource ? releasesResource.getItems() : [];
    return _releasesCollection.map((release) => ({
      version: release.summary.version,
      resource_version: release.summary.resource_version,
      href: release.href,
    })).sort((a, b) => sortReleaseByVersionDesc(a.version, b.version));
  }, [releasesResource]);

  const fetchDeploymentDataByResourceVersion = useCallback(
    async (version) => {
      try {
        const _versionHref = releasesVersionList.find(
          (item) => item.resource_version === version
        )?.href;
        if (_versionHref) {
          const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
          const response = await HalApiCaller.get({
            url: _versionHref,
            headers: { ...asyncHeaders, ...getPlatformHeaders() },
          });
          response.containsHalResource
            ? dispatch({
                type: "success",
                resource: response.halResource,
              })
            : dispatch({ type: "error" });
        } else {
          dispatch({type: "error"});
        }
      } catch (error) {
        dispatch({ type: "error", error: error });
      }
    },
    [releasesVersionList]
  );

  const getDeploymentPackageDeploymentDataFromEnvironment = (targetDP = {}) => ({
    source: targetDP.source,
    tag: targetDP.tag,
    service_parameters: targetDP.service_parameters,
    dependsOn: targetDP.dependsOn,
    artefacts: targetDP.artefacts,
    ssm_conditions: targetDP.ssm_conditions,
  });

  const getDeploymentPackageDeploymentDataFromVersion = (descriptor = {}, resourceVersion) => ({
    source: descriptor.deploy_info?.git_url,
    tag: descriptor.deploy_info?.tag,
    service_parameters: descriptor.parameters,
    dependsOn: descriptor.dependsOn,
    artefacts: descriptor.artifacts,
    ssm_conditions: descriptor.ssm_conditions,
    isLatest: resourceVersion === "latest" ? true : false,
  });

  const changeVersion = (resource_version, version) => {
    setResourceVersion(resource_version);
    setVersion(version);
    if (resource_version === "") {
      dispatch({ type: "default" });
    } else {
      dispatch({ type: "version" });
      fetchDeploymentDataByResourceVersion(resource_version);
    }
  };

  const verifyTag = (newTag) => {
    newTag?.includes("+") ? setInvalidTag(true) : setInvalidTag(false);
    setTag(newTag);
  };

  const initialDeploymentState = {
    deploymentPackageToInstall: {},
    loadingStatus: "idle",
  };

  function deploymentStateReducer(state, action) {
    switch (action.type) {
      case "default":
        resetStateProperties();
        return {
          deploymentPackageToInstall: getDeploymentPackageDeploymentDataFromEnvironment(
            environmentResource.resourceRepresentation?._embedded?._links?.item?.find(
              (dp) => dp.name === deploymentPackageId
            )?._embedded?.item
          ),
          loadingStatus: "idle",
        };
      case "version":
        resetStateProperties();
        dismissMessage();
        return {
          deploymentPackageToInstall: {},
          loadingStatus: "fetching",
        };
      case "success":
        return {
          deploymentPackageToInstall: getDeploymentPackageDeploymentDataFromVersion(
            action.resource.getProperty("descriptor")?.value,
            action.resource.resource_version
          ),
          loadingStatus: "successful",
        };
      case "error":
        setDeploymentType("default");
        setVersion("");
        action.error?.response?.data?.messages
          ? changeInstallDeploymentPackageMessage({
              type: "error",
              message: `${action.error?.response.data.messages[0].message}, default info will be loaded`,
            })
          : changeInstallDeploymentPackageMessage({
              type: "error",
              message: `Error fetching version data, default info will be loaded`,
            });
        return {
          deploymentPackageToInstall: getDeploymentPackageDeploymentDataFromEnvironment(
            environmentResource.resourceRepresentation._embedded?._links?.item
          ),
          loadingStatus: "rejected",
        };
      default:
        throw new Error();
    }
  }

  const [deploymentState, dispatch] = useReducer(deploymentStateReducer, initialDeploymentState);

  //HAL resources calculated status
  useEffect(() => {
    if (
      releasesStatus === "fetching" ||
      environmentStatus === "fetching" ||
      deploymentState.loadingStatus === "fetching"
    ) {
      changeInstallDeploymentPackageStatus("loading");
    } else {
      changeInstallDeploymentPackageStatus("idle");
    }
  }, [releasesStatus, environmentStatus, deploymentState.loadingStatus]);

  useEffect(() => {
    if (
      PROMISE_FINAL_STATUSES.includes(environmentStatus) &&
      PROMISE_FINAL_STATUSES.includes(releasesStatus) &&
      (environmentError || releasesError)
    ) {
      if (!isMessageDismissed && releasesError) {
        const _releaseError = buildApplicationError(releasesError);
        _releaseError.status === HTTP_ERROR_CODES.NOT_FOUND
          ? changeInstallDeploymentPackageMessage({
              type: "info",
              message: "There are no releases/versions available for the deployment package",
            })
          : changeInstallDeploymentPackageMessage({
              type: "warning",
              message: `Unable to get releases information`,
            });
      }
      if (environmentError) setError(buildApplicationError(environmentError));
    }
  }, [environmentError, environmentStatus, isMessageDismissed, releasesError, releasesStatus]);

  //Deployment Package Parameters functions
  const addExpertParameter = () => {
    setExpertParameters([...expertParameters, { name: "", value: "" }]);
  };

  const setDeploymentPackageParameter = useCallback(
    (index, newValue) => {
      deploymentPackageParameters[index] = {
        ...deploymentPackageParameters[index],
        value: newValue,
      };
      setDeploymentPackageParameters([...deploymentPackageParameters]);
    },
    [deploymentPackageParameters]
  );

  const setExpertParameter = useCallback(
    (index, newName, newValue) => {
      expertParameters[index] = {
        ...expertParameters[index],
        name: newName,
        value: newValue,
      };
      setExpertParameters([...expertParameters]);
    },
    [expertParameters]
  );

  const removeExpertParameter = useCallback(
    (index) => {
      let _expertParameters = [...expertParameters];
      _expertParameters.splice(index, 1);
      setExpertParameters(_expertParameters);
    },
    [expertParameters]
  );

  const deploymentPackageParametersList = useMemo(() => {
    return deploymentPackageParameters.map((deploymentPackageParam, index) => ({
      name: deploymentPackageParam.name,
      type: deploymentPackageParam.type,
      enumValues: deploymentPackageParam.enumValues,
      required: deploymentPackageParam.isRequired,
      value: deploymentPackageParam.value,
      onChangeDeploymentPackageParameter: (value) => setDeploymentPackageParameter(index, value),
    }));
  }, [deploymentPackageParameters, setDeploymentPackageParameter]);

  const expertParametersList = useMemo(() => {
    return expertParameters.map((expertParam, index) => ({
      name: expertParam.name,
      value: expertParam.value,
      onChangeName: (name) => setExpertParameter(index, name, expertParam.value),
      onChangeValue: (value) => setExpertParameter(index, expertParam.name, value),
      onClickRemove: () => removeExpertParameter(index),
    }));
  }, [expertParameters, removeExpertParameter, setExpertParameter]);

  //Deployment Package Artefacts functions
  const buildEmptyArtefact = () => {
    return {
      properties: [
        {
          name: "bundle_name",
          value: "",
          onChangeName: (name, index, _artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, _artefactIndex, name, null),
          onChangeValue: (value, index, artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, artefactIndex, null, value),
          onClickRemove: (index, artefactIndex) =>
            removeDeploymentPackageArtefactProperty(index, artefactIndex),
        },
        {
          name: "bundle_path",
          value: "",
          onChangeName: (name, index, _artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, _artefactIndex, name, null),
          onChangeValue: (value, index, artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, artefactIndex, null, value),
          onClickRemove: (index, artefactIndex) =>
            removeDeploymentPackageArtefactProperty(index, artefactIndex),
        },
        {
          name: "bundle_type",
          value: "",
          onChangeName: (name, index, _artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, _artefactIndex, name, null),
          onChangeValue: (value, index, artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, artefactIndex, null, value),
          onClickRemove: (index, artefactIndex) =>
            removeDeploymentPackageArtefactProperty(index, artefactIndex),
        },
        {
          name: "bundle_version",
          value: "",
          onChangeName: (name, index, _artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, _artefactIndex, name, null),
          onChangeValue: (value, index, artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, artefactIndex, null, value),
          onClickRemove: (index, artefactIndex) =>
            removeDeploymentPackageArtefactProperty(index, artefactIndex),
        },
        {
          name: "artifact_branch_name",
          value: "",
          onChangeName: (name, index, _artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, _artefactIndex, name, null),
          onChangeValue: (value, index, artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, artefactIndex, null, value),
          onClickRemove: (index, artefactIndex) =>
            removeDeploymentPackageArtefactProperty(index, artefactIndex),
        },
      ],
    };
  };

  const addArtefact = () => {
    setDeploymentPackageArtefacts([...deploymentPackageArtefacts, buildEmptyArtefact()]);
  };

  const removeArtefact = (index) => {
    let _deploymentPackageArtefacts = [...deploymentPackageArtefacts];
    _deploymentPackageArtefacts.splice(index, 1);
    setDeploymentPackageArtefacts(_deploymentPackageArtefacts);
  };

  const removeDeploymentPackageArtefactProperty = (index, artefactIndex) => {
    setDeploymentPackageArtefacts((oldDeploymentPackageArtefacts) => {
      oldDeploymentPackageArtefacts[artefactIndex].properties.splice(index, 1);
      if (oldDeploymentPackageArtefacts[artefactIndex].properties.length === 0) {
        oldDeploymentPackageArtefacts.splice(artefactIndex, 1);
      }
      return [...oldDeploymentPackageArtefacts];
    });
  };

  const setDeploymentPackageArtefactProperty = (index, artefactIndex, name, value) => {
    setDeploymentPackageArtefacts((oldDeploymentPackageArtefacts) => {
      let propertyName =
        name != null ? name : oldDeploymentPackageArtefacts[artefactIndex].properties[index].name;
      let propertyValue =
        value != null
          ? value
          : oldDeploymentPackageArtefacts[artefactIndex].properties[index].value;
      let property = {
        ...oldDeploymentPackageArtefacts[artefactIndex].properties[index],
        name: propertyName,
        value: propertyValue,
      };
      oldDeploymentPackageArtefacts[artefactIndex].properties[index] = {
        ...property,
      };
      return [...oldDeploymentPackageArtefacts];
    });
  };

  const addArtefactProperty = useCallback((artefactIndex, propertyName, propertyValue) => {
    setDeploymentPackageArtefacts((oldDeploymentPackageArtefacts) => {
      oldDeploymentPackageArtefacts[artefactIndex] = !oldDeploymentPackageArtefacts[artefactIndex]
        ? { properties: [] }
        : oldDeploymentPackageArtefacts[artefactIndex];
      let property = {
        name: propertyName ? propertyName : "",
        value: propertyValue ? propertyValue : "",
        onChangeName: (name, index, _artefactIndex) =>
          setDeploymentPackageArtefactProperty(index, _artefactIndex, name, null),
        onChangeValue: (value, index, _artefactIndex) =>
          setDeploymentPackageArtefactProperty(index, _artefactIndex, null, value),
        onClickRemove: removeDeploymentPackageArtefactProperty,
      };
      oldDeploymentPackageArtefacts[artefactIndex].properties = [
        ...oldDeploymentPackageArtefacts[artefactIndex].properties,
        property,
      ];
      return [...oldDeploymentPackageArtefacts];
    });
  }, []);

  const deploymentPackageArtefactsList = useMemo(() => {
    return deploymentPackageArtefacts.map((artefact) => ({
      properties: artefact.properties,
      onClickAddProperty: (artefactIndex) => addArtefactProperty(artefactIndex),
    }));
  }, [addArtefactProperty, deploymentPackageArtefacts]);

  //Deployment Package Dependencies functions
  const setDependencyDeploymentPackageParameter = (dependencyIndex, index, value) => {
    setDeploymentPackageDependencies((oldDeploymentPackageDependencies) => {
      let parameter = {
        ...oldDeploymentPackageDependencies[dependencyIndex].service_parameters[index],
        value: value,
      };
      oldDeploymentPackageDependencies[dependencyIndex].service_parameters[index] = {
        ...parameter,
      };
      return [...oldDeploymentPackageDependencies];
    });
  };

  const deploymentPackageDependenciesList = useMemo(() => {
    return deploymentPackageDependencies.map((dependency, dependencyIndex) => ({
      deploymentPackageName: dependency.service_name,
      installed: dependency.installed,
      deploymentPackageParameters: dependency.service_parameters
        ? dependency.service_parameters.map((parameter, index) => ({
            name: parameter.name,
            value: parameter.value,
            type: parameter.type,
            isRequired: parameter.isRequired,
            enumValues: parameter.enumValues,
            onChangeDeploymentPackageParameter: (value) =>
              setDependencyDeploymentPackageParameter(dependencyIndex, index, value),
          }))
        : [],
    }));
  }, [deploymentPackageDependencies]);

  //Install Deployment Package
  const installDeploymentPackageHandler = async () => {
    changeInstallDeploymentPackageStatus("loading");

    var payload = {
      customer: customerId,
      account: accountId,
      environment: environmentId,
      terraform_action: tfAction,
      username: await getAuthenticatedUser(),
    };

    const _deploymentPackagesList =
      environmentResource.resourceRepresentation?._embedded?._links?.item?.map(
        (item) => item._embedded?.item
      );
    // add Deployment Package info to payload
    const _deploymentPackage = {
      name: deploymentPackageId,
      deploy_info: {
        git_url: source,
        tag: tag,
      },
      ssm_conditions: deploymentState.deploymentPackageToInstall.ssm_conditions
        ? deploymentState.deploymentPackageToInstall.ssm_conditions
        : [],
      dependsOn: deploymentState.deploymentPackageToInstall.dependsOn
        ? deploymentState.deploymentPackageToInstall.dependsOn
        : [],
      version: version,
    };

    payload.deploymentPackages = [
      buildDeploymentPackagePayload(
        _deploymentPackage,
        deploymentPackageParameters,
        expertParameters,
        deploymentPackageArtefactsList
      ),
    ];

    // add Deployment Package dependencies info to payload
    if (deploymentPackageDependencies.length) {
      const _dependenciesToInstall =
        deployDependencies === "only-uninstalled"
          ? deploymentPackageDependencies.filter((item) => item.installed !== true)
          : deploymentPackageDependencies;
      _dependenciesToInstall.forEach((dependency) => {
        const _dependencyInfo = {
          name: dependency.service_name,
          deploy_info: {
            git_url: dependency.source,
            tag: dependency.tag,
          },
          ssm_conditions: getDeploymentPackageData(dependency.service_name, _deploymentPackagesList)
            ?.ssm_conditions,
          artifacts: dependency.artefacts ? dependency.artefacts : [],
          dependsOn: getDeploymentPackageData(dependency.service_name, _deploymentPackagesList)
            ?.dependsOn,
          upgraded_to_version: dependency.upgraded_to_version,
        };
        payload.deploymentPackages.push(
          buildDeploymentPackagePayload(_dependencyInfo, dependency.service_parameters)
        );
      });
    }

    const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
    await HalApiCaller.post({
      url: `${deploymentPackageURL}:deploy`,
      headers: { ...asyncHeaders, ...getPlatformHeaders() },
      body: payload,
    })
      .then((response) => {
        changeInstallDeploymentPackageStatus("completed");
        const logURL = response.body.tf_log_url;
        if (logURL) {
          const logId = logURL.split("/logs/")[1];
          history.push(
            `${environmentUIRoute}/deployment-packages/${deploymentPackageId}/deployments/${deploymentPackageId}-${environmentId}/logs/${logId}`
          );
        }
      })
      .catch((error) => {
        changeInstallDeploymentPackageStatus("rejected");
        error.response?.data?.messages
          ? changeInstallDeploymentPackageMessage({
              type: "error",
              message: `${error.response.data.messages[0].message}`,
            })
          : changeInstallDeploymentPackageMessage({
              type: "error",
              message: `Error installing the deployment package`,
            });
      });
  };

  //load info to be installed
  useEffect(() => {
    if (environmentResource) dispatch({ type: "default" });
  }, [environmentResource]);

  useEffect(() => {
    if (!isEmptyObject(deploymentState?.deploymentPackageToInstall) && environmentResource) {
      const _deploymentPackageList =
        environmentResource.resourceRepresentation?._embedded?._links?.item?.map(
          (item) => item._embedded?.item
        );
      const _deployments = getDeploymentsFromEmbeddedObject(
        environmentResource.resourceRepresentation?._embedded
      );

      setSource(deploymentState.deploymentPackageToInstall.source);
      setTag(deploymentState.deploymentPackageToInstall.tag ?? null);

      //DeploymentPackageParameters
      if (deploymentState.deploymentPackageToInstall.service_parameters?.length) {
        setDeploymentPackageParameters(
          getDeploymentPackageParamsFromEnvironment(
            deploymentState.deploymentPackageToInstall.service_parameters
          )
        );
      }
      //dependencies
      if (deploymentState.deploymentPackageToInstall.dependsOn?.length) {
        const dependencyList = deploymentState.deploymentPackageToInstall.dependsOn
          .map((dep) =>
            _deploymentPackageList.find((catalog) => dep.service_name === catalog.service_name)
          )
          .filter((item) => item != null);

        let _depParamList = dependencyList.map((dep_deploymentPackage) =>
          getDeploymentPackageDependencies(dep_deploymentPackage, _deployments)
        );
        _depParamList.forEach((item, _, _this) => {
          getRecursiveDependencies(item, _this, _deploymentPackageList, _deployments);
        });
        setDeploymentPackageDependencies(_depParamList);
      }
      //artefacts
      if (deploymentState?.deploymentPackageToInstall?.artefacts?.length) {
        deploymentState.deploymentPackageToInstall.artefacts.forEach((artefact, index) => {
          artefact.tf_properties = artefact.tf_properties ? artefact.tf_properties : {};
          Object.keys(artefact.tf_properties)
            .sort()
            .forEach((key) => {
              addArtefactProperty(index, key, artefact.tf_properties[key]);
            });
        });
      }
    }
  }, [addArtefactProperty, deploymentState, environmentResource, deploymentPackageId]);

  //build review info
  const buildDeploymentPackageDeployDataInfo = useMemo(() => {
    return {
      deploymentPackageId: deploymentPackageId,
      source,
      tag,
      tfAction,
      mode: deployDependencies,
    };
  }, [deployDependencies, deploymentPackageId, source, tag, tfAction]);

  const buildDeploymentPackageParametersInfo = useMemo(() => {
    return {
      deploymentPackageParametersList: [...deploymentPackageParameters, ...expertParameters],
    };
  }, [expertParameters, deploymentPackageParameters]);

  const buildDeploymentPackageArtefactsInfo = useMemo(() => {
    return {
      deploymentPackageArtefactsList: deploymentPackageArtefacts,
    };
  }, [deploymentPackageArtefacts]);

  const buildDeploymentPackageDependenciesInfo = useMemo(() => {
    return {
      linkedDeploymentPackagesList:
        deployDependencies === "only-uninstalled"
          ? deploymentPackageDependencies.filter((dependency) => dependency.installed === false)
          : deploymentPackageDependencies,
    };
  }, [deployDependencies, deploymentPackageDependencies]);

  //grouped Deployment Package main data info
  const deploymentPackageDeployDataInfo = {
    deploymentPackageId: deploymentPackageId,
    source,
    tag,
    invalidTag,
    tfAction,
    deploymentType,
    resourceVersion,
    deployDependencies,
    version,
    releasesVersionList,
    onChangeSource: setSource,
    onChangeTag: verifyTag,
    onChangeTfAction: setTfAction,
    onChangeDeploymentType: setDeploymentType,
    onChangeDeployDependencies: (value) => setDeployDependencies(value),
    onChangeVersion: (resourceVersion, version) => changeVersion(resourceVersion, version),
  };

  //grouped add Deployment Package parameter state
  const deploymentPackageParametersInfo = {
    deploymentPackageParametersList: deploymentPackageParametersList,
    expertParametersList: expertParametersList,
    onAddClick: addExpertParameter,
  };

  //grouped add Deployment Package parameter state
  const deploymentPackageArtefactInfo = {
    deploymentPackageArtefactsList: deploymentPackageArtefactsList,
    onAddArtefactClick: addArtefact,
    onRemoveArtefactClick: removeArtefact,
  };

  //grouped add Deployment Package dependencies parameters state
  const deploymentPackageDependenciesInfo = {
    deploymentPackageDependenciesList:
      deployDependencies === "only-uninstalled"
        ? deploymentPackageDependenciesList.filter((dependency) => dependency.installed === false)
        : deploymentPackageDependenciesList,
  };

  //grouped add Deployment Package dependencies parameters state
  const reviewDeploymentInfo = useMemo(() => {
    return {
      reviewDeploymentPackageDeployDataInfo: buildDeploymentPackageDeployDataInfo,
      reviewDeploymentPackageParametersInfo: buildDeploymentPackageParametersInfo,
      reviewDeploymentPackageArtefactsInfo: buildDeploymentPackageArtefactsInfo,
      reviewLinkedDeploymentPackageInfo: buildDeploymentPackageDependenciesInfo,
    };
  }, [
    buildDeploymentPackageArtefactsInfo,
    buildDeploymentPackageDependenciesInfo,
    buildDeploymentPackageDeployDataInfo,
    buildDeploymentPackageParametersInfo,
  ]);

  const navigateToDeploymentDashboard = () => {
    history.push(`${environmentUIRoute}/deployments`);
  };

  return [
    installDeploymentPackageStatus,
    installDeploymentPackageMessage,
    deploymentPackageDeployDataInfo,
    deploymentPackageParametersInfo,
    deploymentPackageArtefactInfo,
    deploymentPackageDependenciesInfo,
    reviewDeploymentInfo,
    error,
    navigateToDeploymentDashboard,
    installDeploymentPackageHandler,
    dismissMessage,
  ];
};
