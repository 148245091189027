//React hooks imports
import { useState, useMemo, useEffect, useCallback, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";

//configuation helpers imports
import { config } from "../config";
import {
  getAuthenticatedUser,
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  isEmptyObject,
  HTTP_ERROR_CODES,
  PROMISE_FINAL_STATUSES,
  sortReleaseByVersionDesc,
} from "../common/utils";

//DXC HAL
import { useHalResource } from "@dxc-technology/halstack-react-hal";

import {
  getActiveDeployment,
  formatDeploymentPackageDependency,
  buildDeploymentPackagePayload,
  getRecursiveDependants,
  getDeploymentPackageData,
  isDeploymentPackageInstalled,
  getDeploymentsFromEmbeddedObject,
} from "../deploy-deployment-package-steps/helper/DeployDeploymentPackageHelper";
import { HalApiCaller } from "@dxc-technology/halstack-client";

const extractDefaultParameters = (
  parameters,
  depDeploymentPackageParamKeys,
  _depDeploymentPackageParameters
) => {
  const defaultParameters = parameters.map((parameter) =>
    Object.keys(parameter).map((key) => {
      if (depDeploymentPackageParamKeys.some((depKey) => depKey === key)) {
        depDeploymentPackageParamKeys = depDeploymentPackageParamKeys.filter(
          (depKey) => depKey !== key
        );
      }

      return {
        name: key,
        type: Object.values(parameter)[0].type,
        enumValues: Object.values(parameter)[0].enumValues,
        isRequired: Object.values(parameter)[0].required,
        value: _depDeploymentPackageParameters[key],
      };
    })
  );
  return [defaultParameters, depDeploymentPackageParamKeys];
};

const createParameterStructure = (parameters) =>
  parameters.map((param) => {
    return {
      [Object.keys(param)[0]]: { type: "string", enumValues: "", isRequired: "" },
    };
  });

export const useRefreshDeploymentPackage = () => {
  const [error, setError] = useState(null);

  //url management hooks
  const history = useHistory();
  const { customerId, accountId, environmentId, deploymentPackageId, deploymentId } = useParams();

  //UI routes
  const environmentUIRoute = `/customers/${customerId}/accounts/${accountId}/environments/${environmentId}`;

  // API urls
  const environmentURL = `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}`;
  const releaseURL = `${config.release_api_url}/releases/${deploymentPackageId}/versions?deprecated=false`;
  const deploymentPackageURL = `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}/deployment-packages/${deploymentPackageId}`;

  const [environmentResource, environmentStatus, environmentError] = useHalResource({
    url: `${environmentURL}?embedded=[deployments.item]`,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const [releasesResource, releasesStatus, releasesError] = useHalResource({
    url: releaseURL,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const [isMessageDismissed, changeIsMessageDismissed] = useState(false);
  const [refreshDeploymentPackageMessage, changeRefreshDeploymentPackageMessage] = useState(null);
  const [refreshDeploymentPackageStatus, changeRefreshDeploymentPackageStatus] = useState("idle");

  //deploymentInfo
  const [source, setSource] = useState(null);
  const [tag, setTag] = useState(null);
  const [invalidTag, setInvalidTag] = useState(false);
  const [tfAction, setTfAction] = useState("apply");
  const [deployDependants, setDeployDependants] = useState("direct-refresh");
  const [deploymentPackageParameters, setDeploymentPackageParameters] = useState([]);
  const [expertParameters, setExpertParameters] = useState([]);
  const [deploymentPackageArtefacts, setDeploymentPackageArtefacts] = useState([]);
  const [deploymentPackageDependants, setDeploymentPackageDependants] = useState([]);
  // const [ssmConditions, setSSMConditions] = useState([]);
  const [upgradedToVersion, setUpgradedToVersion] = useState(null);
  const [deploymentType, setDeploymentType] = useState("default");
  const [resourceVersion, setResourceVersion] = useState("");
  const [version, setVersion] = useState("");

  const resetStateProperties = () => {
    setSource(null);
    setTag(null);
    setDeploymentPackageParameters([]);
    setExpertParameters([]);
    setDeploymentPackageDependants([]);
    setDeploymentPackageArtefacts([]);
  };

  const dismissMessage = () => {
    changeIsMessageDismissed(true);
    changeRefreshDeploymentPackageMessage(null);
  };

  //Release Info
  const releasesVersionList = useMemo(() => {
    const _releasesCollection = releasesResource ? releasesResource.getItems() : [];
    return _releasesCollection.map((release) => ({
      version: release.summary.version,
      resource_version: release.summary.resource_version,
      href: release.href,
    })).sort((a, b) => sortReleaseByVersionDesc(a.version, b.version));
  }, [releasesResource]);

  const fetchDeploymentDataByResourceVersion = useCallback(
    async (version) => {
      try {
        const _versionHref = releasesVersionList.find(
          (item) => item.resource_version === version
        )?.href;
        if (_versionHref) {
          const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
          const response = await HalApiCaller.get({
            url: _versionHref,
            headers: { ...asyncHeaders, ...getPlatformHeaders() },
          });
          response?.containsHalResource
            ? dispatch({
                type: "success",
                resource: response.halResource,
              })
            : dispatch({ type: "error" });
        } else {
          dispatch({ type: "error" });
        }
      } catch (error) {
        dispatch({ type: "error", error: error });
      }
    },
    [releasesVersionList]
  );

  const getDeploymentDataFromEnvironment = (targetDP = {}) => {
    const _targetDeployment = targetDP._embedded?._links?.item.find(
      (item) => !item.summary.resource_subtype
    );
    if (!_targetDeployment) {
      setError(
        buildApplicationError({ status: 400, message: "There's no previous deployment to refresh" })
      );
    }
    return {
      source: _targetDeployment._embedded?.item?.deploy_info?.git_url,
      tag: _targetDeployment._embedded?.item?.deploy_info?.tag,
      upgraded_to_version: _targetDeployment._embedded?.item?.upgraded_to_version,
      service_parameters: _targetDeployment._embedded?.item?.parameters,
      dependsOn: _targetDeployment._embedded?.item?.dependsOn,
      artefacts: _targetDeployment._embedded?.item?.artifacts,
      ssm_conditions: _targetDeployment._embedded?.item?.ssm_conditions,
    };
  };

  const extractParametersFromVersion = (versionParameters) => {
    const _parameters = {};
    const _deploymentParams =
      environmentResource.resourceRepresentation?._embedded?._links?.item?.find(
        (dp) => dp.name === deploymentPackageId
      )?._embedded?.item?._embedded?._links?.item[0]?._embedded?.item?.parameters ?? [];

    const allParameters = versionParameters.concat(
      Object.keys(_deploymentParams)
        .filter(
          (element) => !versionParameters.map((item) => Object.keys(item)[0]).includes(element)
        )
        .map((item) => ({ [item]: "" }))
    );

    allParameters?.forEach((item) => {
      const _objectKey = Object.keys(item)[0];
      _parameters[_objectKey] = _deploymentParams[_objectKey];
    });

    return _parameters;
  };

  const getDeploymentDataFromVersion = (descriptor = {}, resourceVersion) => ({
    source: descriptor.deploy_info?.git_url,
    tag: descriptor.deploy_info?.tag,
    service_parameters: extractParametersFromVersion(descriptor.parameters),
    dependsOn: descriptor.dependsOn,
    // artefacts: [
    //   {
    //     value: descriptor.artifacts?.map((item) => {
    //       return { tf_properties: item.tf_properties };
    //     }),
    //   },
    // ],
    artefacts: [].concat(
      descriptor.artifacts?.map((item) => {
        return { tf_properties: item.tf_properties };
      })
    ),
    ssm_conditions: descriptor.ssm_conditions,
    isLatest: resourceVersion === "latest" ? true : false,
    isVersion: true,
    version_parameters: createParameterStructure(descriptor.parameters),
  });

  const changeVersion = useCallback(
    (resource_version, version) => {
      setResourceVersion(resource_version);
      setVersion(version);
      if (resource_version === "") {
        dispatch({ type: "default" });
      } else {
        dispatch({ type: "version" });
        fetchDeploymentDataByResourceVersion(resource_version);
      }
    },
    [fetchDeploymentDataByResourceVersion]
  );

  const verifyTag = (newTag) => {
    newTag?.includes("+") ? setInvalidTag(true) : setInvalidTag(false);
    setTag(newTag);
  };

  const initialDeploymentState = {
    deploymentPackageToInstall: {},
    loadingStatus: "idle",
  };

  function deploymentStateReducer(state, action) {
    switch (action.type) {
      case "default":
        resetStateProperties();
        return {
          deploymentPackageToInstall: getDeploymentDataFromEnvironment(
            environmentResource.resourceRepresentation?._embedded?._links?.item?.find(
              (dp) => dp.name === deploymentPackageId
            )?._embedded?.item
          ),
          loadingStatus: "idle",
        };
      case "version":
        resetStateProperties();
        dismissMessage();
        return {
          deploymentPackageToInstall: {},
          loadingStatus: "fetching",
        };
      case "success":
        return {
          deploymentPackageToInstall: getDeploymentDataFromVersion(
            action.resource.getProperty("descriptor")?.value,
            action.resource.resource_version
          ),
          loadingStatus: "successful",
        };
      case "error":
        setDeploymentType("default");
        setResourceVersion("");
        action.error?.response?.data?.messages
          ? changeRefreshDeploymentPackageMessage({
              type: "error",
              message: `${action.error?.response.data.messages[0].message}, default info will be loaded`,
            })
          : changeRefreshDeploymentPackageMessage({
              type: "error",
              message: `Error fetching version data, default info will be loaded`,
            });
        return {
          deploymentPackageToInstall: getDeploymentDataFromEnvironment(
            environmentResource.resourceRepresentation?._embedded?._links?.item?.find(
              (dp) => dp.name === deploymentPackageId
            )?._embedded?.item
          ),
          loadingStatus: "rejected",
        };
      default:
        throw new Error();
    }
  }

  const [deploymentState, dispatch] = useReducer(deploymentStateReducer, initialDeploymentState);

  //HAL resources calculated status
  useEffect(() => {
    if (
      releasesStatus === "fetching" ||
      environmentStatus === "fetching" ||
      deploymentState.loadingStatus === "fetching"
    ) {
      changeRefreshDeploymentPackageStatus("loading");
    } else {
      changeRefreshDeploymentPackageStatus("idle");
    }
  }, [releasesStatus, environmentStatus, deploymentState.loadingStatus]);

  //Message operations
  useEffect(() => {
    if (
      PROMISE_FINAL_STATUSES.includes(environmentStatus) &&
      PROMISE_FINAL_STATUSES.includes(releasesStatus) &&
      (environmentError || releasesError)
    ) {
      if (!isMessageDismissed && releasesError) {
        const _releaseError = buildApplicationError(releasesError);
        _releaseError.status === HTTP_ERROR_CODES.NOT_FOUND
          ? changeRefreshDeploymentPackageMessage({
              type: "info",
              message: "There are no releases/versions available for the deployment package",
            })
          : changeRefreshDeploymentPackageMessage({
              type: "warning",
              message: `Unable to get releases information`,
            });
      }
      if (environmentError) setError(buildApplicationError(environmentError));
    }
  }, [environmentError, environmentStatus, isMessageDismissed, releasesError, releasesStatus]);

  //Deployment Package Parameters functions
  const addExpertParameter = () => {
    setExpertParameters([...expertParameters, { name: "", value: "" }]);
  };

  const setDeploymentPackageParameter = useCallback(
    (index, newValue) => {
      deploymentPackageParameters[index] = {
        ...deploymentPackageParameters[index],
        value: newValue,
      };
      setDeploymentPackageParameters([...deploymentPackageParameters]);
    },
    [deploymentPackageParameters]
  );

  const setExpertParameter = useCallback(
    (index, newName, newValue) => {
      expertParameters[index] = {
        ...expertParameters[index],
        name: newName,
        value: newValue,
      };
      setExpertParameters([...expertParameters]);
    },
    [expertParameters]
  );

  const removeExpertParameter = useCallback(
    (index) => {
      let _expertParameters = [...expertParameters];
      _expertParameters.splice(index, 1);
      setExpertParameters(_expertParameters);
    },
    [expertParameters]
  );

  const deploymentPackageParametersList = useMemo(() => {
    return deploymentPackageParameters.map((deploymentPackageParam, index) => ({
      name: deploymentPackageParam.name,
      type: deploymentPackageParam.type,
      enumValues: deploymentPackageParam.enumValues,
      required: deploymentPackageParam.isRequired,
      value: deploymentPackageParam.value,
      onChangeDeploymentPackageParameter: (value) => setDeploymentPackageParameter(index, value),
    }));
  }, [deploymentPackageParameters, setDeploymentPackageParameter]);

  const expertParametersList = useMemo(() => {
    return expertParameters.map((expertParam, index) => ({
      name: expertParam.name,
      value: expertParam.value,
      onChangeName: (name) => setExpertParameter(index, name, expertParam.value),
      onChangeValue: (value) => setExpertParameter(index, expertParam.name, value),
      onClickRemove: () => removeExpertParameter(index),
    }));
  }, [expertParameters, removeExpertParameter, setExpertParameter]);

  //Deployment Package Artefacts functions
  const buildEmptyArtefact = () => {
    return {
      properties: [
        {
          name: "bundle_name",
          value: "",
          onChangeName: (name, index, _artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, _artefactIndex, name, null),
          onChangeValue: (value, index, artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, artefactIndex, null, value),
          onClickRemove: (index, artefactIndex) =>
            removeDeploymentPackageArtefactProperty(index, artefactIndex),
        },
        {
          name: "bundle_path",
          value: "",
          onChangeName: (name, index, _artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, _artefactIndex, name, null),
          onChangeValue: (value, index, artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, artefactIndex, null, value),
          onClickRemove: (index, artefactIndex) =>
            removeDeploymentPackageArtefactProperty(index, artefactIndex),
        },
        {
          name: "bundle_type",
          value: "",
          onChangeName: (name, index, _artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, _artefactIndex, name, null),
          onChangeValue: (value, index, artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, artefactIndex, null, value),
          onClickRemove: (index, artefactIndex) =>
            removeDeploymentPackageArtefactProperty(index, artefactIndex),
        },
        {
          name: "bundle_version",
          value: "",
          onChangeName: (name, index, _artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, _artefactIndex, name, null),
          onChangeValue: (value, index, artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, artefactIndex, null, value),
          onClickRemove: (index, artefactIndex) =>
            removeDeploymentPackageArtefactProperty(index, artefactIndex),
        },
        {
          name: "artifact_branch_name",
          value: "",
          onChangeName: (name, index, _artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, _artefactIndex, name, null),
          onChangeValue: (value, index, artefactIndex) =>
            setDeploymentPackageArtefactProperty(index, artefactIndex, null, value),
          onClickRemove: (index, artefactIndex) =>
            removeDeploymentPackageArtefactProperty(index, artefactIndex),
        },
      ],
    };
  };

  const addArtefact = () => {
    setDeploymentPackageArtefacts([...deploymentPackageArtefacts, buildEmptyArtefact()]);
  };

  const removeArtefact = (index) => {
    let _deploymentPackageArtefacts = [...deploymentPackageArtefacts];
    _deploymentPackageArtefacts.splice(index, 1);
    setDeploymentPackageArtefacts(_deploymentPackageArtefacts);
  };

  const addArtefactProperty = useCallback((artefactIndex, propertyName, propertyValue) => {
    setDeploymentPackageArtefacts((oldDeploymentPackageArtefacts) => {
      oldDeploymentPackageArtefacts[artefactIndex] = !oldDeploymentPackageArtefacts[artefactIndex]
        ? { properties: [] }
        : oldDeploymentPackageArtefacts[artefactIndex];
      let property = {
        name: propertyName ? propertyName : "",
        value: propertyValue ? propertyValue : "",
        onChangeName: (name, index, _artefactIndex) =>
          setDeploymentPackageArtefactProperty(index, _artefactIndex, name, null),
        onChangeValue: (value, index, _artefactIndex) =>
          setDeploymentPackageArtefactProperty(index, _artefactIndex, null, value),
        onClickRemove: removeDeploymentPackageArtefactProperty,
      };
      oldDeploymentPackageArtefacts[artefactIndex].properties = [
        ...oldDeploymentPackageArtefacts[artefactIndex].properties,
        property,
      ];
      return [...oldDeploymentPackageArtefacts];
    });
  }, []);

  const removeDeploymentPackageArtefactProperty = (index, artefactIndex) => {
    setDeploymentPackageArtefacts((oldDeploymentPackageArtefacts) => {
      oldDeploymentPackageArtefacts[artefactIndex].properties.splice(index, 1);
      if (oldDeploymentPackageArtefacts[artefactIndex].properties.length === 0) {
        oldDeploymentPackageArtefacts.splice(artefactIndex, 1);
      }
      return [...oldDeploymentPackageArtefacts];
    });
  };

  const setDeploymentPackageArtefactProperty = (index, artefactIndex, name, value) => {
    setDeploymentPackageArtefacts((oldDeploymentPackageArtefacts) => {
      let propertyName =
        name != null ? name : oldDeploymentPackageArtefacts[artefactIndex].properties[index].name;
      let propertyValue =
        value != null
          ? value
          : oldDeploymentPackageArtefacts[artefactIndex].properties[index].value;
      let property = {
        ...oldDeploymentPackageArtefacts[artefactIndex].properties[index],
        name: propertyName,
        value: propertyValue,
      };
      oldDeploymentPackageArtefacts[artefactIndex].properties[index] = {
        ...property,
      };
      return [...oldDeploymentPackageArtefacts];
    });
  };

  const deploymentPackageArtefactsList = useMemo(() => {
    return deploymentPackageArtefacts.map((artefact) => ({
      properties: artefact.properties,
      onClickAddProperty: (artefactIndex) => addArtefactProperty(artefactIndex),
    }));
  }, [addArtefactProperty, deploymentPackageArtefacts]);

  //Deployment Package Dependendants functions
  const setDependantDeploymentPackageParameter = (dependantIndex, index, value) => {
    setDeploymentPackageDependants((oldDeploymentPackageDependants) => {
      let parameter = {
        ...oldDeploymentPackageDependants[dependantIndex].service_parameters[index],
        value: value,
      };
      oldDeploymentPackageDependants[dependantIndex].service_parameters[index] = {
        ...parameter,
      };
      return [...oldDeploymentPackageDependants];
    });
  };

  const deploymentPackageDependantsList = useMemo(() => {
    return deploymentPackageDependants.map((dependant, dependantIndex) => ({
      deploymentPackageName: dependant.service_name,
      installed: dependant.installed,
      deploymentPackageParameters: dependant.service_parameters
        ? dependant.service_parameters.map((parameter, index) => ({
            name: parameter.name,
            value: parameter.value,
            type: parameter.type,
            isRequired: parameter.isRequired,
            enumValues: parameter.enumValues,
            onChangeDeploymentPackageParameter: (value) =>
              setDependantDeploymentPackageParameter(dependantIndex, index, value),
          }))
        : [],
    }));
  }, [deploymentPackageDependants]);

  const refreshDeploymentPackageHandler = async () => {
    changeRefreshDeploymentPackageStatus("loading");

    var payload = {
      customer: customerId,
      account: accountId,
      environment: environmentId,
      terraform_action: tfAction,
      username: await getAuthenticatedUser(),
    };

    const _deploymentPackagesList =
      environmentResource.resourceRepresentation?._embedded?._links?.item?.map(
        (item) => item._embedded?.item
      );

    // add Deployment Package info to payload
    const _deploymentPackage = {
      name: deploymentPackageId,
      deploy_info: {
        git_url: source,
        tag: tag,
      },
      // ssm_conditions: ssmConditions ? ssmConditions : [],
      ssm_conditions: deploymentState?.deploymentPackageToInstall.ssm_conditions
        ? deploymentState?.deploymentPackageToInstall.ssm_conditions
        : getDeploymentPackageData(deploymentPackageId, _deploymentPackagesList)?.ssm_conditions,
      dependsOn: deploymentState?.deploymentPackageToInstall.dependsOn
        ? deploymentState?.deploymentPackageToInstall.dependsOn
        : getDeploymentPackageData(deploymentPackageId, _deploymentPackagesList)?.dependsOn,
      upgraded_to_version: upgradedToVersion,
      version: version,
    };

    payload.deploymentPackages = [
      buildDeploymentPackagePayload(
        _deploymentPackage,
        deploymentPackageParameters,
        expertParameters,
        deploymentPackageArtefactsList
      ),
    ];

    //dependants Deployment Package parameters
    if (deployDependants === "propagated" && deploymentPackageDependants.length) {
      deploymentPackageDependants.forEach((dependant) => {
        const _dependantInfo = {
          name: dependant.service_name,
          deploy_info: {
            git_url: dependant.source,
            tag: dependant.tag,
          },
          ssm_conditions: getDeploymentPackageData(dependant.service_name, _deploymentPackagesList)
            ?.ssm_conditions,
          artifacts: dependant.artefacts ? dependant.artefacts : [],
          dependsOn: getDeploymentPackageData(dependant.service_name, _deploymentPackagesList)
            ?.dependsOn,
          upgraded_to_version: dependant.upgraded_to_version,
        };
        payload.deploymentPackages.push(
          buildDeploymentPackagePayload(_dependantInfo, dependant.service_parameters)
        );
      });
    }

    const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
    await HalApiCaller.post({
      url: `${deploymentPackageURL}:refresh`,
      headers: { ...asyncHeaders, ...getPlatformHeaders() },
      body: payload,
    })
      .then((response) => {
        changeRefreshDeploymentPackageStatus("completed");
        const logURL = response.body.tf_log_url;
        if (logURL) {
          const logId = logURL.split("/logs/")[1];
          history.push(
            `${environmentUIRoute}/deployment-packages/${deploymentPackageId}/deployments/${deploymentId}/logs/${logId}`
          );
        }
      })
      .catch((error) => {
        changeRefreshDeploymentPackageStatus("rejected");
        error.response?.data?.messages
          ? changeRefreshDeploymentPackageMessage({
              type: "error",
              message: `${error.response.data.messages[0].message}`,
            })
          : changeRefreshDeploymentPackageMessage({
              type: "error",
              message: `Error installing the deployment package`,
            });
      });
  };

  //load info to be installed
  useEffect(() => {
    if (environmentResource) {
      const _deploymentPackage =
        environmentResource.resourceRepresentation?._embedded?._links?.item?.find(
          (dp) => dp.summary.service_name === deploymentPackageId
        );
      const _lastDeployment = _deploymentPackage?._embedded?.item?._embedded?._links?.item?.find(
        (dp) => dp.summary.service_name === deploymentPackageId
      );
      const _lastDeploymentVersion = _lastDeployment?.summary?.version;
      if (!_lastDeploymentVersion) {
        dispatch({ type: "default" });
      } else {
        const isLatest = releasesVersionList.find(
          (item) => item.resource_version === "latest" && item.version === _lastDeploymentVersion
        );
        changeVersion(isLatest ? "latest" : _lastDeploymentVersion, _lastDeploymentVersion);
        setDeploymentType("version");
      }
    }
  }, [changeVersion, deploymentPackageId, environmentResource, releasesVersionList]);

  useEffect(() => {
    if (!isEmptyObject(deploymentState?.deploymentPackageToInstall) && environmentResource) {
      const _deploymentPackageList =
        environmentResource.resourceRepresentation?._embedded?._links?.item?.map(
          (item) => item._embedded?.item
        );
      const _deployments = getDeploymentsFromEmbeddedObject(
        environmentResource.resourceRepresentation?._embedded
      );

      //pending to get ssm_conditions from last deployment when its enabled
      setSource(deploymentState?.deploymentPackageToInstall.source);
      setTag(deploymentState?.deploymentPackageToInstall.tag);

      //upgraded_to_version
      setUpgradedToVersion(deploymentState?.deploymentPackageToInstall.upgraded_to_version);

      //deploymentPackageParameters
      const _deploymentPackageData = _deploymentPackageList.find(
        (item) => item.service_name === deploymentPackageId
      );

      // initializing parameters with empty list
      _deploymentPackageData.service_parameters = Array.isArray(
        _deploymentPackageData.service_parameters
      )
        ? _deploymentPackageData.service_parameters
        : [];

      const _depDeploymentPackageParameters =
        deploymentState?.deploymentPackageToInstall.service_parameters;

      const depDeploymentPackageParamKeys = !isEmptyObject(_depDeploymentPackageParameters)
        ? Object.keys(_depDeploymentPackageParameters)
        : [];

      const [_deploymentPackageParameters, expertParamsKeys] =
        deploymentState.deploymentPackageToInstall.isVersion === true
          ? extractDefaultParameters(
              deploymentState.deploymentPackageToInstall.version_parameters,
              depDeploymentPackageParamKeys,
              _depDeploymentPackageParameters
            )
          : extractDefaultParameters(
              _deploymentPackageData.service_parameters,
              depDeploymentPackageParamKeys,
              _depDeploymentPackageParameters
            );

      const loadedDeploymentPackageParams = _deploymentPackageParameters.map((item) => {
        return item[0];
      });

      const loadedExpertParams = expertParamsKeys.map((key) => ({
        name: key,
        value: _depDeploymentPackageParameters[key],
      }));

      setDeploymentPackageParameters(loadedDeploymentPackageParams);
      setExpertParameters(loadedExpertParams);

      //deploymentPackageDependants
      const _deploymentPackageDependants = _deploymentPackageList.filter((serv) => {
        if (serv.dependsOn) {
          return serv.dependsOn.some((key) => key.service_name === deploymentPackageId);
        } else {
          return false;
        }
      });
      if (_deploymentPackageDependants.length) {
        let _isDependantLinkCollapsed = [];
        let _depParamList = [..._deploymentPackageDependants];
        getRecursiveDependants(_depParamList, _deploymentPackageList, _deployments);

        setDeploymentPackageDependants(
          _depParamList.reduce((result, dependant, index) => {
            index === 0
              ? _isDependantLinkCollapsed.push(false)
              : _isDependantLinkCollapsed.push(true);
            let _dependant = { ...dependant };
            _dependant.installed = isDeploymentPackageInstalled(_deployments, _dependant)
              ? true
              : false;
            let _targetDeployment = getActiveDeployment(_deployments, _dependant);
            if (_targetDeployment) {
              const _formattedDependant = formatDeploymentPackageDependency(
                _dependant,
                _targetDeployment
              );
              return [...result, _formattedDependant];
            } else return result;
          }, [])
        );
      }
      // load db artefacts
      const _deploymentPackageArtefactsList = deploymentState?.deploymentPackageToInstall.artefacts;
      if (_deploymentPackageArtefactsList?.length > 0) {
        _deploymentPackageArtefactsList.forEach((artefact, index) => {
          if (artefact.tf_properties) {
            Object.keys(artefact.tf_properties)
              .sort()
              .forEach((key) => {
                addArtefactProperty(index, key, artefact.tf_properties[key]);
              });
          }
        });
      }
    }
  }, [addArtefactProperty, deploymentState, environmentResource, deploymentPackageId]);

  //build review info
  const buildDeploymentPackageDeployDataInfo = useMemo(() => {
    return {
      deploymentPackageId: deploymentPackageId,
      source,
      tag,
      tfAction,
      mode: deployDependants,
    };
  }, [deployDependants, deploymentPackageId, source, tag, tfAction]);

  const buildDeploymentPackageParametersInfo = useMemo(() => {
    return {
      deploymentPackageParametersList: [...deploymentPackageParameters, ...expertParameters],
    };
  }, [expertParameters, deploymentPackageParameters]);

  const buildDeploymentPackageArtefactsInfo = useMemo(() => {
    return {
      deploymentPackageArtefactsList: deploymentPackageArtefacts,
    };
  }, [deploymentPackageArtefacts]);

  const buildDeploymentPackageDependantsInfo = useMemo(() => {
    return {
      linkedDeploymentPackagesList: deploymentPackageDependants,
    };
  }, [deploymentPackageDependants]);

  //grouped Deployment Package main data info
  const deploymentPackageDeployDataInfo = {
    deploymentPackageId: deploymentPackageId,
    source,
    tag,
    invalidTag,
    tfAction,
    deployDependants,
    deploymentType,
    resourceVersion,
    version,
    releasesVersionList,
    onChangeSource: setSource,
    onChangeTag: verifyTag,
    onChangeTfAction: setTfAction,
    onChangeDeployDependants: setDeployDependants,
    onChangeDeploymentType: setDeploymentType,
    onChangeVersion: (resourceVersion, version) => changeVersion(resourceVersion, version),
  };

  //grouped add Deployment Package parameter state
  const deploymentPackageParametersInfo = {
    deploymentPackageParametersList: deploymentPackageParametersList,
    expertParametersList: expertParametersList,
    onAddClick: addExpertParameter,
  };

  //grouped add Deployment Package parameter state
  const deploymentPackageArtefactInfo = {
    deploymentPackageArtefactsList: deploymentPackageArtefactsList,
    onAddArtefactClick: addArtefact,
    onRemoveArtefactClick: removeArtefact,
  };

  //grouped add Deployment Package dependencies parameters state
  const deploymentPackageDependantsInfo = {
    deploymentPackageDependantsList: deploymentPackageDependantsList,
  };

  //grouped add Deployment Package dependencies parameters state
  const reviewDeploymentInfo = useMemo(() => {
    return {
      reviewDeploymentPackageDeployDataInfo: buildDeploymentPackageDeployDataInfo,
      reviewDeploymentPackageParametersInfo: buildDeploymentPackageParametersInfo,
      reviewDeploymentPackageArtefactsInfo: buildDeploymentPackageArtefactsInfo,
      reviewLinkedDeploymentPackageInfo: buildDeploymentPackageDependantsInfo,
    };
  }, [
    buildDeploymentPackageArtefactsInfo,
    buildDeploymentPackageDependantsInfo,
    buildDeploymentPackageDeployDataInfo,
    buildDeploymentPackageParametersInfo,
  ]);

  const navigateBack = () => {
    history.push(`${environmentUIRoute}/deployments`);
  };

  return [
    refreshDeploymentPackageStatus,
    refreshDeploymentPackageMessage,
    deploymentPackageDeployDataInfo,
    deploymentPackageParametersInfo,
    deploymentPackageArtefactInfo,
    deploymentPackageDependantsInfo,
    reviewDeploymentInfo,
    error,
    navigateBack,
    refreshDeploymentPackageHandler,
    dismissMessage,
  ];
};
