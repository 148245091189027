import React, { useMemo } from "react";
import styled from "styled-components";

//DXC CDK Components
import { DxcHeading, DxcResultsetTable } from "@dxc-technology/halstack-react";

//common functions
import { formatDate } from "../common/utils";

//images
import logsImage from "./images/log-24px.svg";
import CSAMRadio from "../components/CSAMRadio";

function DeploymentHistoryList({ deploymentHistoryList, onClickRow, onClickLog }) {
  const historyList = useMemo(() => {
    const _historyList = deploymentHistoryList ? deploymentHistoryList : [];

    return _historyList.map((entry) => [
      {
        displayValue: (
          <CSAMRadio
            checked={entry.isSelected}
            name="deploymentHistory"
            onClick={() => onClickRow(entry.deploymentResourceId)}
          />
        ),
      },
      {
        displayValue: entry.deploymentDate ? formatDate(entry.deploymentDate) : "-",
        sortValue: entry.deploymentDate,
      },
      {
        displayValue: entry.version || "-",
      },
      {
        displayValue: <span className="es-capitalized-text">{entry.deploymentStatus}</span>,
      },
      {
        displayValue: (
          <span className="es-capitalized-text">{entry?.testingStatus ?? "Not tested"}</span>
        ),
      },
      { displayValue: entry.creator },
      {
        displayValue: (
          <span data-title={`View logs for ${entry.deploymentResourceId}`}>
            <Image
              src={logsImage}
              alt={`View logs for ${entry.deploymentResourceId}`}
              onClick={() => onClickLog(entry.logUrl, entry.deploymentResourceId)}
            ></Image>
          </span>
        ),
      },
    ]);
  }, [deploymentHistoryList, onClickLog, onClickRow]);

  return (
    <MainContainer>
      <DxcHeading
        level={3}
        text={"Deployment history list"}
        margin={{ top: "small", bottom: "medium" }}
      />
      <DxcResultsetTable
        columns={[
          { displayValue: "", isSortable: false },
          { displayValue: "Deployment date", isSortable: true },
          { displayValue: "Version", isSortable: true },
          { displayValue: "Deployment status", isSortable: true },
          { displayValue: "Testing status", isSortable: true },
          { displayValue: "Requested by", isSortable: true },
          { displayValue: "Actions", isSortable: false },
        ]}
        rows={historyList}
        itemsPerPage={5}
        margin={{ bottom: "small" }}
      ></DxcResultsetTable>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin-right: 6px;
`;

export default DeploymentHistoryList;
