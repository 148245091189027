import { useState, useMemo, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PAGES } from "../utils";

//urls
import { config } from "../config";

// Account dashboard helper for some re-usable environment related stuff
import { getBusinessConsoleURL } from "../account-dashboard/AccountDashboardHelper";

//DXC HAL
import { useHalResource } from "@dxc-technology/halstack-react-hal";
import { HalApiCaller } from "@dxc-technology/halstack-client";

//common utils
import {
  goToTop,
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  PROMISE_FINAL_STATUSES,
} from "../common/utils";

const ENVIRONMENT_TYPES_URL = `${config.environments_api_url}/environment-types`;
const ENVIRONMENTS_URL = `${config.environments_api_url}/environments`;

const useEnvironmentTypeDashboard = () => {
  const [environmentTypesSelected, changeEnvironmentTypesSelected] = useState([]);
  const [textFilter, changeTextFilter] = useState(null);
  const [isCreateDialogVisible, setCreateDialogVisible] = useState(false);
  const [environmentTypeMessage, changeEnvironmentTypeMessage] = useState(null);
  const [isDeleteLoading, changeIsDeleteLoading] = useState(false);
  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState(null);
  const [deleteErrorMessage, changeDeleteErrorMessage] = useState(null);
  const [isDeletingEnvironment, changeIsDeletingEnvironment] = useState("idle");
  const [dashboardStatus, changeDashboardStatus] = useState("idle");
  const [isCreating, changeIsCreating] = useState(false);
  const [error, setError] = useState(null);

  const [isDeleteEnvironmentDialogVisible, setIsDeleteEnvironmentDialogVisible] = useState(null);
  const [errorMessageDeleteEnvironment, changeErrorMessageDeleteEnvironment] = useState(null);
  const toggleDeleteEnvironmentDialogVisible = (environmentData) => {
    setIsDeleteEnvironmentDialogVisible(environmentData);
    changeErrorMessageDeleteEnvironment(false);
  };

  // navigation
  const history = useHistory();

  //halresource hooks
  const [environmentsResource, environmentsStatus, environmentsError, environmentsHandlers] =
    useHalResource({
      url: ENVIRONMENTS_URL,
      asyncHeadersHandler: getSessionHeaders,
      headers: getPlatformHeaders(),
    });

  const [
    environmentTypesResource,
    environmentTypesStatus,
    environmentTypesError,
    environmentTypesHandlers,
  ] = useHalResource({
    url: ENVIRONMENT_TYPES_URL,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  // status and messaging functions
  const isLoading = useMemo(() => {
    if (!isCreateDialogVisible && !isDeleteDialogVisible && !isDeleteEnvironmentDialogVisible) {
      if (
        environmentsStatus === "fetching" ||
        environmentsStatus === "interaction" ||
        environmentTypesStatus === "fetching" ||
        environmentTypesStatus === "interaction" ||
        dashboardStatus === "interacting"
      ) {
        return "fetching";
      } else if (environmentsStatus === environmentTypesStatus) {
        return environmentTypesStatus;
      }
    } else {
      return environmentsStatus;
    }
  }, [
    isCreateDialogVisible,
    isDeleteDialogVisible,
    isDeleteEnvironmentDialogVisible,
    environmentsStatus,
    environmentTypesStatus,
    dashboardStatus,
  ]);

  useEffect(() => {
    if (
      PROMISE_FINAL_STATUSES.includes(environmentTypesStatus) &&
      PROMISE_FINAL_STATUSES.includes(environmentsStatus) &&
      (environmentTypesError || environmentsError)
    ) {
      const _error = environmentTypesError ?? environmentsError;
      setError(buildApplicationError(_error));
    }
  }, [environmentTypesError, environmentTypesStatus, environmentsError, environmentsStatus]);

  const dismissMessage = () => {
    changeEnvironmentTypeMessage(null);
  };

  //Delete environment functions
  const deleteEnvironmentHandler = useCallback(
    async (customerId, accountId, environmentId) => {
      changeIsDeletingEnvironment("interacting");
      changeErrorMessageDeleteEnvironment(null);
      const environmentURI = `${config.environments_api_url}/customers/${customerId}/accounts/${accountId}/environments/${environmentId}`;
      try {
        const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
        await HalApiCaller.del({
          url: environmentURI,
          headers: { ...asyncHeaders, ...getPlatformHeaders() },
        });

        changeErrorMessageDeleteEnvironment(false);
        changeEnvironmentTypeMessage({
          type: "confirm",
          message: "The environment has been successfully deleted",
        });
        changeIsDeletingEnvironment("resolved");
        toggleDeleteEnvironmentDialogVisible();
      } catch (error) {
        changeIsDeletingEnvironment("rejected");
        error.response?.data?.messages
          ? changeErrorMessageDeleteEnvironment(`${error.response.data.messages[0].message}`)
          : changeErrorMessageDeleteEnvironment(
              "There was an error while deleting the environment"
            );
      } finally {
        environmentsHandlers["list-environments"]()
          .then(() => {})
          .catch((error) => {
            error.response?.data?.messages
              ? changeEnvironmentTypeMessage(`${error.response.data.messages[0].message}`)
              : changeEnvironmentTypeMessage("There was an error getting the environments");
          });
        goToTop();
      }
    },
    [environmentsHandlers]
  );

  const deleteEnvironment = useCallback(
    (customer, account, environment) => {
      toggleDeleteEnvironmentDialogVisible({
        enviromentName: environment,
        deleteClick: () => deleteEnvironmentHandler(customer, account, environment),
        cancelClick: toggleDeleteEnvironmentDialogVisible,
      });
    },
    [deleteEnvironmentHandler]
  );

  //environment functions
  const clickOption = useCallback(
    (
      option,
      customer,
      account,
      environment,
      awsRegion,
      domain,
      environmentName,
      envAccountZoneName
    ) => {
      sessionStorage.setItem("origin", PAGES.ENVIRONMENTS_DASHBOARD);
      switch (option) {
        case 1:
          history.push(`/customers/${customer}/accounts/${account}/environments/${environment}`);
          break;
        case 2:
          history.push(
            `/customers/${customer}/accounts/${account}/environments/${environment}/deployments`
          );
          break;
        case 3:
          buildBusinessConsoleURL(awsRegion, domain, environmentName, envAccountZoneName);
          break;
        case 4:
          deleteEnvironment(customer, account, environment);
          break;
        // TODO: remove clone environment case
        // case 5:
        //   history.push(
        //     `/customers/${customer}/accounts/${account}/environments/${environment}/clone`
        //   );
        //   break;
        default:
          console.log("option to be implemented");
      }
    },
    [deleteEnvironment, history]
  );

  const environments = useMemo(() => {
    const environmentsList = (environmentsResource && environmentsResource.getItems()) || [];
    return environmentsList
      .map((item) => {
        return {
          customerName: item.summary.customer_name,
          accountName: item.summary.account_name,
          environmentType: item.summary.environment_type,
          environmentName: item.summary.environment_name,
          awsRegion: item.summary.aws_region,
          isLocked: item.summary.is_locked ? "Yes" : "No",
          domain: item.summary.is_private ? "Private" : "Public",
          resource_name: item.summary.resource_name,
          onClickOption: (option) =>
            clickOption(
              option,
              item.summary.customer_name,
              item.summary.account_name,
              item.summary.resource_name,
              item.summary.aws_region,
              item.summary.is_private ? "Private" : "Public",
              item.summary.environment_name,
              item.summary.env_account_zone_name
            ),
        };
      })
      .filter((env) => {
        const byEnvTypes =
          environmentTypesSelected.length > 0
            ? environmentTypesSelected.includes(env.environmentType)
              ? env
              : null
            : env;
        const byTextFilter = textFilter
          ? env.customerName.includes(textFilter) ||
            env.accountName.includes(textFilter) ||
            env.resource_name.includes(textFilter)
            ? env
            : null
          : env;
        return byEnvTypes ? (byTextFilter ? env : null) : null;
      });
  }, [environmentsResource, clickOption, environmentTypesSelected, textFilter]);

  const buildBusinessConsoleURL = (awsRegion, domain, environmentName, envAccountZoneName) => {
    if (domain === "Private" || !envAccountZoneName) {
      changeEnvironmentTypeMessage({
        type: "error",
        message: "Platform Console is not available",
      });
      goToTop();
    } else {
      window.open(getBusinessConsoleURL(environmentName, awsRegion, envAccountZoneName), "_blank");
    }
  };

  const optionsEnvironmentTypes = useMemo(() => {
    const _environmentTypesList =
      (environmentTypesResource && environmentTypesResource.getItems()) || [];
    const _environmentsList = (environmentsResource && environmentsResource.getItems()) || [];
    const _optionsFromTypes = _environmentTypesList.map((environmentType) => ({
      label: environmentType.summary.environment_type,
      value: environmentType.summary.environment_type,
    }));
    const _optionsFromEnvironments = _environmentsList.map((environment) => ({
      label: environment.summary.environment_type,
      value: environment.summary.environment_type,
    }));
    const _allOptions = _optionsFromTypes.concat(_optionsFromEnvironments);
    const _uniqueOptions = _allOptions.filter(
      (option, index) => _allOptions.map((_option) => _option.value).indexOf(option.value) === index
    );
    return _uniqueOptions;
  }, [environmentTypesResource, environmentsResource]);

  const onChangeFilterEnvironmentTypes = (newValue) => {
    changeEnvironmentTypesSelected(newValue);
  };

  const onChangeFilterText = (newValue) => {
    changeTextFilter(newValue);
  };

  //environment types functions
  const deleteEnvType = useCallback(
    async (url) => {
      changeIsDeleteLoading(true);
      changeDeleteErrorMessage(null);
      try {
        const asyncHeaders = getSessionHeaders ? await getSessionHeaders() : {};
        await HalApiCaller.del({
          url: `${url}`,
          headers: { ...asyncHeaders, ...getPlatformHeaders() },
        });
        changeIsDeleteLoading(false);
        setDeleteDialogVisible(null);
        changeEnvironmentTypeMessage({
          type: "confirm",
          message: "The environment type has been successfully deleted",
        });
        changeDashboardStatus("interacting");
        environmentTypesHandlers["list-environment-type"]()
          .then(() => {
            changeDashboardStatus("completed");
          })
          .catch((error) => {
            changeDashboardStatus("rejected");
            error.response?.data?.messages
              ? changeEnvironmentTypeMessage({
                  type: "error",
                  message: `${error.response.data.messages[0].message}`,
                })
              : changeEnvironmentTypeMessage({
                  type: "error",
                  message: "Error getting environment types",
                });
          })
          .finally(goToTop());
      } catch (error) {
        changeIsDeleteLoading(false);
        error.response?.data?.messages
          ? changeDeleteErrorMessage(`${error.response.data.messages[0].message}`)
          : changeDeleteErrorMessage(`Error deleting the environment type`);
      }
    },
    [environmentTypesHandlers]
  );

  const tagsEnvironmentTypes = useMemo(() => {
    const environmentTypesList =
      (environmentTypesResource && environmentTypesResource.getItems()) || [];
    return environmentTypesList.map((environmentType, index) => ({
      name: environmentType.summary.environment_type,
      onTagClick: () => {
        setDeleteDialogVisible(index);
      },
      onDeleteCancel: () => {
        setDeleteDialogVisible(null);
      },
      onDeleteConfirm: () => deleteEnvType(environmentType.href),
    }));
  }, [environmentTypesResource, deleteEnvType]);

  const toggleCreateDialogVisible = () => {
    dismissMessage();
    setCreateDialogVisible(!isCreateDialogVisible);
  };

  const saveEnvironmentType = (environmentTypeName) => {
    changeIsCreating(true);
    changeEnvironmentTypeMessage(null);
    let payload = {
      environment_type: environmentTypeName,
    };
    environmentTypesHandlers["create"](payload)
      .then(() => {
        changeIsCreating(false);
        toggleCreateDialogVisible();
        changeEnvironmentTypeMessage({
          type: "confirm",
          message: "The environment type has been successfully created",
        });
        changeDashboardStatus("interacting");
        environmentTypesHandlers["list-environment-type"]()
          .then(() => {
            changeDashboardStatus("completed");
          })
          .catch((error) => {
            changeDashboardStatus("rejected");
            error.response?.data?.messages
              ? changeEnvironmentTypeMessage({
                  type: "error",
                  message: `${error.response.data.messages[0].message}`,
                })
              : changeEnvironmentTypeMessage({
                  type: "error",
                  message: "Error getting environment types",
                });
          })
          .finally(goToTop());
      })
      .catch((error) => {
        changeIsCreating(false);
        changeDashboardStatus("rejected");
        error.body?.messages
          ? changeEnvironmentTypeMessage({
              type: "error",
              message: `${error.body.messages[0].message}`,
            })
          : changeEnvironmentTypeMessage({
              type: "error",
              message: `Error creating the environment type`,
            });
      });
  };

  return [
    isLoading,
    environmentTypeMessage,
    environments,
    environmentTypesSelected,
    tagsEnvironmentTypes,
    optionsEnvironmentTypes,
    textFilter,
    isCreateDialogVisible,
    onChangeFilterEnvironmentTypes,
    onChangeFilterText,
    dismissMessage,
    toggleCreateDialogVisible,
    saveEnvironmentType,
    deleteErrorMessage,
    isDeleteDialogVisible,
    isDeleteLoading,
    isCreating,
    isDeleteEnvironmentDialogVisible,
    errorMessageDeleteEnvironment,
    isDeletingEnvironment,
    error,
  ];
};

export default useEnvironmentTypeDashboard;
