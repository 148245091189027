import React from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcButton,
  DxcAlert,
  DxcFlex,
} from "@dxc-technology/halstack-react";

//custom hook
import { useDeploymentDashboard } from "./useDeploymentDashboard";
import DeploymentList from "./DeploymentList";
import DestroyService from "./dialog/DestroyService";
//search image
import backImage from "./images/arrow_back_ios-24px.svg";
import { useParams } from "react-router-dom";
import DestroyDeploymentPackage from "./dialog/DestroyDeploymentPackage";
import EmptySection from "../components/EmptySection";
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";

function DeploymentDashboard() {
  const { customerId, accountId, environmentId } = useParams();

  const {
    environmentName,
    isLoading,
    deploymentDashboardMessage,
    isDestroyDeploymentPackageDialogVisible,
    environmentStatus,
    isDestroyServiceDialogVisible,
    errorMessageDestroyEnvironment,
    navigateBackText,
    deploymentInfo,
    error,
    navigateBack,
    navigateToEditEnvironmentScreen,
    bulkActionSelected,
    dismissMessage,
  } = useDeploymentDashboard(customerId, accountId, environmentId);

  useErrorHandler(error);

  return (
    <Content>
      <StyledLink onClick={() => navigateBack(customerId)}>
        <BackImg src={backImage} />
        {navigateBackText}
      </StyledLink>

      {!isLoading && (
        <Title>
          <HeadingContainer>
            <DxcHeading level={2} weight="normal" text="Deploy to environment:" />
            <DxcHeading
              level={2}
              text={environmentName}
              margin={{ left: "xxsmall" }}
              weight="bold"
            />
          </HeadingContainer>
          {{ environmentName } ? (
            <DxcButton
              mode="secondary"
              label="Edit environment"
              onClick={navigateToEditEnvironmentScreen}
              margin="xxsmall"
            />
          ) : null}
        </Title>
      )}
      {deploymentDashboardMessage ? (
        <DxcAlert
          type={deploymentDashboardMessage.type}
          mode="inline"
          inlineText={deploymentDashboardMessage.message}
          onClose={dismissMessage}
          margin={{ bottom: "small" }}
          size="fillParent"
        />
      ) : null}
      <DxcFlex>
        {deploymentInfo.services && deploymentInfo.services.length > 0 ? (
          <DeploymentList
            servicesList={deploymentInfo.services}
            textFilter={deploymentInfo.textFilter}
            onChangeTextFilter={deploymentInfo.onChangeTextFilter}
            deploymentStatusFilter={deploymentInfo.deploymentStatusFilter}
            onChangeDeploymentStatusFilter={deploymentInfo.onChangeStatusFilter}
            bulkActionSelected={bulkActionSelected}
          />
        ) : (
          <EmptySectionContainer>
            <EmptySection text="The environment has no deployment packages" />
          </EmptySectionContainer>
        )}
      </DxcFlex>
      {isDestroyDeploymentPackageDialogVisible ? (
        <DestroyDeploymentPackage
          role="dialog"
          deploymentPackageName={isDestroyDeploymentPackageDialogVisible.deploymentPackageName}
          hasDependants={isDestroyDeploymentPackageDialogVisible.hasDependants}
          onDestroyClick={isDestroyDeploymentPackageDialogVisible.destroyClick}
          onCancelClick={isDestroyDeploymentPackageDialogVisible.cancelClick}
        />
      ) : null}

      {isDestroyServiceDialogVisible ? (
        <DestroyService
          errorMessage={errorMessageDestroyEnvironment}
          status={environmentStatus}
          onCancel={isDestroyServiceDialogVisible.cancelClick}
          onDestroy={isDestroyServiceDialogVisible.destroyClick}
        />
      ) : null}

      {isLoading && <DxcSpinner theme="light" label="Loading..." mode="overlay" />}
    </Content>
  );
}

const Content = styled.div``;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0 2rem 0;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledLink = styled.div`
  display: flex;
  width: fit-content;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  top: 16px;
  :hover {
    cursor: pointer;
  }
`;

const BackImg = styled.img`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-self: center;
`;

const EmptySectionContainer = styled.div`
  width: 100%;
  margin-top: 48px;
  margin-bottom: 48px;
`;

export default withErrorBoundary(DeploymentDashboard, { FallbackComponent: ApplicationError });
