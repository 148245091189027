import React, { useState } from "react";
import styled from "styled-components";

//images
import arrowRight from "../images/arrow_right-24px.svg";
import arrowDown from "../images/arrow_down-24px.svg";

function CollapsableLink({ text, isCollapsed, onClick }) {
  const [innerIsCollapsed, setInnerIsCollapsed] = useState(isCollapsed);

  const collapseHandler = () => {
    setInnerIsCollapsed(!innerIsCollapsed);
    if (isCollapsed == null && typeof onClick === "function") {
      onClick(isCollapsed);
    }
    if (typeof onClick === "function") {
      onClick(isCollapsed == null ? !innerIsCollapsed : !isCollapsed);
    }
  };

  return (
    <CollapsableLinkContainer onClick={collapseHandler}>
      <CollapseImg src={innerIsCollapsed ? arrowRight : arrowDown} />
      {text}
    </CollapsableLinkContainer>
  );
}

const CollapsableLinkContainer = styled.div`
  color: #006bf6;
  cursor: pointer;
  letter-spacing: 0.49px;
  display: flex;
  align-items: center;
  width: max-content;
`;

const CollapseImg = styled.img`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-self: center;
`;

export default CollapsableLink;
