import React, { useState } from "react";
import styled from "styled-components";
import {
  DxcHeading,
  DxcButton,
  DxcDialog,
  DxcParagraph,
  DxcFlex,
  DxcInset,
  DxcTypography,
} from "@dxc-technology/halstack-react";

import { ReactComponent as PlusImage } from "../images/add_circle_border.svg";
import DeploymentPackageCondition from "./DeploymentPackageCondition";

function AddDeploymentPackageCondition({ onSaveClick, onCancelClick }) {
  const [conditionsList, changeConditionsList] = useState([
    { conditionKey: "", value: "", assert: true },
  ]);

  const addCondition = () => {
    changeConditionsList([...conditionsList, { conditionKey: "", value: "", assert: true }]);
  };

  const updateCondition = (conditionKey, value, assert, index) => {
    if (index !== -1)
      conditionsList[index] = {
        conditionKey: conditionKey,
        value: value,
        assert: assert,
      };
    changeConditionsList([...conditionsList]);
  };

  return (
    <DxcDialog isCloseVisible onCloseClick={() => onCancelClick()}>
      <DialogHeader>
        <DxcHeading level={2} text="Add condition" margin={{ bottom: "xsmall" }} />
      </DialogHeader>
      <DxcFlex direction="column">
        <DxcParagraph>
          Available placeholders for parameterized key:{" "}
          <DxcTypography as="span" fontStyle="italic" display="inline">
            {" "}
            {"{customer}"}, {"{account}"}, {"{environmentId}"} and {"{environmentType}"}
          </DxcTypography>
        </DxcParagraph>
        <DxcHeading text="Deployment package conditions" level={4} margin={{ top: "small" }} />
        <ConditionsListContainer>
          {conditionsList.length
            ? conditionsList.map((condition, index) => (
                <DeploymentPackageCondition
                  key={`${condition.key}_${index}`}
                  conditionKey={condition.conditionKey}
                  value={condition.value}
                  assert={condition.assert}
                  onChangeElement={(conditionKey, value, assert) => {
                    updateCondition(conditionKey, value, assert, index);
                  }}
                ></DeploymentPackageCondition>
              ))
            : null}
        </ConditionsListContainer>
      </DxcFlex>

      <DxcInset top="1rem">
        <DxcFlex justifyContent="space-between">
          <DxcButton
            label="Add condition"
            icon={<PlusImage />}
            onClick={addCondition}
            mode="text"
          ></DxcButton>
          <DxcFlex justifyContent="flex-end">
            <DxcButton
              label="Cancel"
              margin={{ right: "xsmall" }}
              onClick={() => onCancelClick()}
              mode="text"
            ></DxcButton>
            <DxcButton
              label="Save"
              onClick={() => onSaveClick(conditionsList)}
              mode="primary"
            ></DxcButton>
          </DxcFlex>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const ConditionsListContainer = styled.div`
  max-height: 240px;
  overflow-y: auto;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(102, 102, 102);
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background-color: rgb(217, 217, 217);
    border-radius: 3px;
  }
`;

export default AddDeploymentPackageCondition;
