import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcButton,
  DxcDialog,
  DxcTextInput,
  DxcParagraph,
  DxcInset,
  DxcFlex,
} from "@dxc-technology/halstack-react";

function CreateAccountDialog({
  creationStatus,
  creationErrorMessage,
  onRegisterClick,
  onCancelClick,
}) {
  const [accountName, setAccountName] = useState("");
  const [accountNameError, setAccountNameError] = useState();
  const [accountRole, setAccountRole] = useState("");
  const [accountRoleError, setAccountRoleError] = useState();
  const [accountId, setAccountId] = useState("");
  const [accountIdError, setAccountIdError] = useState();

  const accountNameHandler = ({ value, error }) => {
    setAccountName(value);
    setAccountNameError(error);
  };

  const accountRoleHandler = ({ value, error }) => {
    setAccountRole(value);
    setAccountRoleError(error);
  };

  const accountIdHandler = ({ value, error }) => {
    setAccountId(value);
    setAccountIdError(error);
  };

  const isDisabled = useMemo(() => {
    return (
      accountIdError != null ||
      accountRoleError != null ||
      accountNameError != null ||
      !accountName ||
      !accountId ||
      !accountRole
    );
  }, [accountId, accountIdError, accountName, accountNameError, accountRole, accountRoleError]);

  return (
    <DxcDialog isCloseVisible onCloseClick={() => onCancelClick()}>
      <DialogHeader>
        <DxcHeading
          level={2}
          text="Register new DXC Assure account"
          margin={{ bottom: "xsmall" }}
        />
        {creationStatus === "interaction" && (
          <DxcSpinner
            role="progressbar"
            theme="light"
            mode="small"
            margin={{ left: "xsmall", bottom: "xsmall" }}
            label="Creating..."
          />
        )}
      </DialogHeader>

      {creationErrorMessage && (
        <DxcAlert type={"error"} mode="inline" size="fillParent" margin={{ bottom: "small" }}>
          <DxcParagraph>{creationErrorMessage}</DxcParagraph>
        </DxcAlert>
      )}

      <DxcFlex direction="column">
        <DxcTextInput
          label="AWS account ID"
          id="awsAccountId"
          placeholder="e.g.: 2048"
          name="awsAccountId"
          size="fillParent"
          value={accountId}
          onChange={accountIdHandler}
          onBlur={accountIdHandler}
          clearable={true}
          optional={false}
          error={accountIdError == null ? "" : accountIdError}
          pattern="^[0-9]+$"
          helperText="Only numbers accepted"
        />
        <DxcTextInput
          label="Account role"
          id="assureAccountRole"
          placeholder="e.g.: arn:aws:iam::XXXXXXXXXXXXXX:role/my-aws-role"
          name="assureAccountRole"
          size="fillParent"
          value={accountRole}
          onChange={accountRoleHandler}
          onBlur={accountRoleHandler}
          clearable={true}
          optional={false}
          error={accountRoleError == null ? "" : accountRoleError}
        />
        <DxcTextInput
          label="Assure account"
          id="assureAccount"
          placeholder="e.g.: my-assure-account"
          name="assureAccount"
          size="fillParent"
          value={accountName}
          onChange={accountNameHandler}
          onBlur={accountNameHandler}
          clearable={true}
          optional={false}
          error={accountNameError == null ? "" : accountNameError}
          pattern="^[a-z0-9-]+$"
          helperText="Only lowercase alphanumeric and hyphens accepted"
        />
      </DxcFlex>
      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={() => onCancelClick()}
            mode="text"
          ></DxcButton>
          <DxcButton
            label="Create"
            onClick={() => onRegisterClick(accountName, accountRole, accountId)}
            mode="primary"
            disabled={isDisabled || creationStatus === "interaction"}
          ></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default CreateAccountDialog;
