import { Auth } from "aws-amplify";

//User/Authentication functions
export const getPlatformHeaders = () => {
  return window.assureConfig?.apiHeaders ? window.assureConfig.apiHeaders : null;
};

export const getSessionHeaders = async () => {
  if (window?.assureConfig?.loginPath) {
    if (window?.assureConfig?.dev) return {};
    try {
      const response = await Auth.currentSession();
      const token = response?.accessToken?.jwtToken;
      if (!token) {
        const locationPath = window.location.href;
        window.location.href = `${window.location.origin}${window.assureConfig.loginPath}?redirectPath=${locationPath}`;
      }
      return { Authorization: `Bearer ${token}` };
    } catch (err) {
      const locationPath = window.location.href;
      window.location.href = `${window.location.origin}${window.assureConfig.loginPath}?redirectPath=${locationPath}`;
    }
  } else {
    const cognitoUserSession = await Auth.currentSession();
    const cognitoToken = cognitoUserSession.idToken.jwtToken;
    return { authorization: `Bearer ${cognitoToken}` };
  }
};

export const getAuthenticatedUser = () =>
  Auth.currentAuthenticatedUser().then((userObject) => {
    return userObject.username;
  });

//common utils functions
const dateFormat = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  timeZoneName: "short",
};

export const formatDate = (deploymentDate) => {
  let formattedDate;
  if (deploymentDate) {
    formattedDate = new Date(
      Number(deploymentDate) || deploymentDate.replace(/[^a-zA-Z0-9 :]/g, "").replace(/  +/g, " ")
    ).toLocaleString(undefined, dateFormat);
  }

  return formattedDate === "Invalid Date" && typeof deploymentDate === "string"
    ? deploymentDate
    : formattedDate;
};

export const goToTop = (behavior = "smooth") => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: behavior,
  });
};

export const isEmptyObject = (item = {}) => Object.keys(item).length === 0;

//customers utils functions
export const retrieveEnvironmentsByCustomer = (environmentsList, customerName) => {
  return environmentsList.filter((item) => item.summary.customer_name === customerName);
};

export const getCustomerLastDeploymentDate = (environmentsList, customerName) => {
  return formatDate(
    retrieveEnvironmentsByCustomer(environmentsList, customerName)
      .map((item) => item.summary.last_deployment_date)
      .sort(function (a, b) {
        return b - a;
      })[0]
  );
};

export const softScroll = (identifier) => {
  window.scroll({
    top: document.getElementById(identifier).offsetTop,
    behavior: "smooth",
  });
};

export const buildApplicationError = (error) => {
  return {
    toString: () => error.toString(),
    status: error.status,
    message: error.body
      ? error.body.messages
        ? error.body.messages[0].message
        : error.body.message
      : error.message,
  };
};

export const HTTP_ERROR_CODES = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

export const PROMISE_FINAL_STATUSES = ["resolved", "rejected"];

/**
 * default element order from array sort params to get descendent order
 * for ascendent, invert the params
 * 
 * @param {versionA} versionA 
 * @param {versionB} versionB 
 * @returns 
 */
export const sortReleaseByVersionDesc = (versionA, versionB) => {
  const fa = getReleaseSortValue(versionA);
  const fb = getReleaseSortValue(versionB);

  if (fa > fb) return -1;
  else if (fa < fb) return 1;
  else return 0;
};

const getReleaseSortValue = (value) => {
  const versionSortedArrayValue = value.split(/[-+.]/g).map((n) => +n + 100000000);
  return versionSortedArrayValue.length < 4
    ? [...versionSortedArrayValue, 100000000].join(".")
    : versionSortedArrayValue.join(".");
};
