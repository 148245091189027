import { useState, useMemo, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

//urls
import { config } from "../config";

//DXC HAL
import { useHalResource } from "@dxc-technology/halstack-react-hal";

//common utils
import {
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  PROMISE_FINAL_STATUSES,
} from "../common/utils";

const getDeploymentsFromEmbeddedObject = (deploymentsResource) => {
  const _deploymentsCollection = deploymentsResource.map((deployment) => {
    return {
      resourceName: deployment._embedded?.item?.resource_name,
      customerName: deployment._embedded?.item?.customer_name,
      accountName: deployment._embedded?.item?.account_name,
      environmentName: deployment._embedded?.item?.environment_name,
      environmentResourceName: deployment._embedded?.item?.environment_resource_id,
      deploymentPackage: deployment._embedded?.item?.service_name,
      version: deployment._embedded?.item?.version,
      deploymentDate: deployment._embedded?.item?.tf_request_date,
      status: deployment._embedded?.item?.deployment_status
        ? deployment._embedded?.item.deployment_status
        : deployment._embedded?.item?.tf_request_status,
      creator: deployment._embedded?.item?.creator,
      terraformAction: deployment._embedded?.item?.terraform_action,
    };
  });

  return _deploymentsCollection.filter(
    (deployment, index, self) =>
      index ===
      self.findIndex(
        (existingDeployment) => existingDeployment.resourceName === deployment.resourceName
      )
  );
};
const useDeploymentPackageEnvironmentsDashboard = () => {
  const [error, setError] = useState(null);

  //url management hooks
  const history = useHistory();

  //target URL to edit
  const { deploymentPackageName } = useParams();

  //navigate function
  const navigateDeploymentPackages = () => {
    history.push(`/deployment-packages`);
  };

  // filters hooks
  const [deploymentsStatusesSelected, changeDeploymentsStatusesSelected] = useState([]);
  const [textFilter, changeTextFilter] = useState(null);

  // use state
  const [dashboardMessage, changeDeploymentPackageEnvironmentsMessage] = useState(null);

  //HAL stack hooks

  const [deploymentsResource, deploymentsStatus, deploymentsError] = useHalResource({
    url: `${config.environments_api_url}/deployment-packages/${deploymentPackageName}/deployments?embedded=[item]`,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  // status and messaging functions
  const isLoading = useMemo(() => deploymentsStatus === "fetching", [deploymentsStatus]);

  useEffect(() => {
    if (PROMISE_FINAL_STATUSES.includes(deploymentsStatus) && deploymentsError) {
      setError(buildApplicationError(deploymentsError));
    }
  }, [deploymentsError, deploymentsStatus]);

  const dismissMessage = () => {
    changeDeploymentPackageEnvironmentsMessage(null);
  };

  //environment functions
  const clickOption = useCallback(
    (option, customer, account, environment) => {
      switch (option) {
        case 1:
          history.push(`/customers/${customer}/accounts/${account}/environments/${environment}`);
          break;
        case 2:
          history.push(
            `/customers/${customer}/accounts/${account}/environments/${environment}/deployments`
          );
          break;
        default:
          console.log("option to be implemented");
      }
    },
    [history]
  );

  const deploymentsStatuses = useMemo(() => {
    const _statuses = getDeploymentsFromEmbeddedObject(deploymentsResource?.getItems() ?? []).map(
      (deployment) => {
        return `${deployment.terraformAction} ${deployment.status}`;
      }
    );
    return [...new Set(_statuses)];
  }, [deploymentsResource]);

  const deployments = useMemo(() => {
    // Map function
    const _getCustomizedDeploymentList = (deployment) => {
      return {
        ...deployment,
        onClickOption: (option) =>
          clickOption(
            option,
            deployment.customerName,
            deployment.accountName,
            deployment.environmentResourceName
          ),
      };
    };
    // Filter functions
    const _filterByStatus = (deployment) => {
      return deploymentsStatusesSelected.length > 0
        ? deploymentsStatusesSelected.includes(`${deployment.terraformAction} ${deployment.status}`)
          ? deployment
          : null
        : deployment;
    };
    const _filterByText = (deployment) =>
      textFilter
        ? deployment.customerName.toLowerCase().includes(textFilter.toLowerCase()) ||
          deployment.accountName.toLowerCase().includes(textFilter.toLowerCase()) ||
          deployment.environmentName.toLowerCase().includes(textFilter.toLowerCase())
          ? deployment
          : null
        : deployment;

    // Build environments list
    return getDeploymentsFromEmbeddedObject(deploymentsResource?.getItems() ?? [])
      .map((deployment) => _getCustomizedDeploymentList(deployment))
      .filter((deployment) => _filterByStatus(deployment))
      .filter((deployment) => _filterByText(deployment));
  }, [deploymentsResource, clickOption, deploymentsStatusesSelected, textFilter]);

  const onChangeFilterDeploymentsStatuses = (newValue) => {
    changeDeploymentsStatusesSelected(newValue);
  };

  const onChangeFilterText = (newValue) => {
    changeTextFilter(newValue);
  };

  return [
    isLoading,
    dashboardMessage,
    deployments,
    deploymentsStatusesSelected,
    deploymentsStatuses,
    textFilter,
    onChangeFilterDeploymentsStatuses,
    onChangeFilterText,
    dismissMessage,
    navigateDeploymentPackages,
    deploymentPackageName,
    error,
  ];
};

export default useDeploymentPackageEnvironmentsDashboard;
