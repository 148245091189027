export const config = {
  environments_api_url: window.assureConfig?.environmentServiceUrl
    ? window.assureConfig?.environmentServiceUrl
    : null,
  release_api_url: window.assureConfig?.releaseServiceUrl
    ? window.assureConfig?.releaseServiceUrl
    : null,
  release_ui_url: window.assureConfig?.releaseServiceUIUrl
    ? window.assureConfig?.releaseServiceUIUrl
    : null,
};
