import React, { useEffect, useMemo } from "react";
import styled from "styled-components";

//DXC CDK Components
import { DxcTextInput, DxcRadioGroup, DxcSelect, DxcInset } from "@dxc-technology/halstack-react";
import SectionContainer from "../components/SectionContainer";
import NonEditableField from "../components/NonEditableField";

function DeployInfo({
  operation,
  source,
  setSource,
  tag,
  invalidTag,
  deploymentType,
  resourceVersion,
  version,
  releasesVersionList,
  deploymentMode,
  setTag,
  tfAction,
  setTfAction,
  setDeploymentType,
  setVersion,
  setDeploymentMode,
  readOnly,
  toggleReadOnly,
}) {
  const tfActionHandler = (value) => {
    setTfAction(value);
    if (value === "upgrade_state") setDeploymentMode("direct-refresh");
  };

  const tfActionOptions =
    operation === "refresh"
      ? [
          { label: "Plan", value: "plan" },
          { label: "Apply", value: "apply" },
          { label: "Upgrade", value: "upgrade_state" },
        ]
      : [
          { label: "Plan", value: "plan" },
          { label: "Apply", value: "apply" },
        ];

  const deploymentModeOptions =
    operation === "refresh"
      ? [
          { label: "Deploy alone", value: "direct-refresh" },
          { label: "Deploy with dependants", value: "propagated" },
        ]
      : [
          { label: "Deploy only uninstalled dependencies", value: "only-uninstalled" },
          { label: "Deploy all dependencies", value: "all" },
        ];

  useEffect(
    () => (deploymentType === "default" ? toggleReadOnly(false) : toggleReadOnly(true)),
    [toggleReadOnly, deploymentType]
  );

  const onChangeDeploymentType = (value) => {
    value === "default"
      ? setVersion("", "")
      : setVersion("latest", versionsList.find((i) => i.value === "latest").label);
    setDeploymentType(value);
  };

  const versionsList = useMemo(
    () =>
      releasesVersionList.map((release) => ({
        label: release.version,
        value: release.resource_version,
      })),

    [releasesVersionList]
  );

  return (
    <MainContainer>
      <VersionSelectorContainer>
        <SectionContainer
          title="Version"
          padding={{
            top: "small",
            bottom: "small",
            left: "none",
            right: "none",
          }}
        >
          <SourceInfoContainer>
            <DxcSelect
              options={[
                { value: "default", label: "Default" },
                { value: "version", label: "Release/Version" },
              ]}
              onChange={({ value }) => onChangeDeploymentType(value)}
              label="Choose type"
              value={deploymentType}
              size="small"
              margin={{ left: "small", right: "small", bottom: "small" }}
              disabled={releasesVersionList.length === 0}
            ></DxcSelect>
            <DxcSelect
              options={versionsList}
              onChange={({ value }) =>
                setVersion(value, versionsList.find((i) => i.value === value).label)
              }
              label="Version number"
              value={resourceVersion}
              size="small"
              margin={{ left: "small", right: "small" }}
              disabled={deploymentType === "default"}
            ></DxcSelect>
          </SourceInfoContainer>
        </SectionContainer>
      </VersionSelectorContainer>
      <DeployInfoContainer>
        <SectionContainer
          title="Deployment script specifications"
          padding={{
            top: "small",
            bottom: "small",
            left: "none",
            right: "none",
          }}
        >
          <SourceInfoContainer>
            {readOnly === false ? (
              <React.Fragment>
                <DxcTextInput
                  label="Script source URL"
                  placeholder="e.g.: git::https://github.dxc.com/my-organization/my-repository-name//code"
                  margin={{ left: "small", right: "large", bottom: "small" }}
                  size={"fillParent"}
                  name="source"
                  value={source}
                  onChange={({ value }) => setSource(value)}
                  onBlur={({ value }) => setSource(value)}
                  clearable={true}
                />
                <DxcTextInput
                  label="Script source branch/tag"
                  placeholder="e.g.: feature/my-new-feature or 1.0.0%2B"
                  margin={{ left: "small", right: "large" }}
                  size={"fillParent"}
                  name="tag"
                  value={tag}
                  onChange={({ value }) => setTag(value)}
                  onBlur={({ value }) => setTag(value)}
                  clearable={true}
                  invalid={invalidTag}
                  helperText={invalidTag ? "The special character '+' is not allowed" : ""}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <NonEditableField
                  label="Script source URL"
                  value={source}
                  margin={{ left: "small", right: "large", bottom: "small" }}
                />
                <NonEditableField
                  label="Script source branch/tag"
                  value={tag}
                  margin={{ left: "small", right: "large", bottom: "none" }}
                />
              </React.Fragment>
            )}
          </SourceInfoContainer>
        </SectionContainer>
        <SectionContainer
          title="Deployment actions"
          padding={{
            top: "small",
            bottom: "small",
            left: "xxsmall",
            right: "none",
          }}
        >
          <DxcInset horizontal="2rem">
            <DxcRadioGroup options={tfActionOptions} value={tfAction} onChange={tfActionHandler} />
          </DxcInset>
        </SectionContainer>

        <SectionContainer
          title="Deployment mode"
          padding={{
            top: "small",
            bottom: "small",
            left: "xxsmall",
            right: "none",
          }}
        >
          <DxcInset horizontal="2rem">
            <DxcRadioGroup
              options={deploymentModeOptions}
              value={deploymentMode}
              onChange={setDeploymentMode}
              disabled={tfAction === "upgrade_state" ? true : false}
            />
          </DxcInset>
        </SectionContainer>
      </DeployInfoContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const VersionSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
`;

const DeployInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SourceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export default DeployInfo;
