import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  DxcButton,
  DxcHeading,
  DxcApplicationLayout,
  DxcFlex,
  DxcInset,
  DxcParagraph,
} from "@dxc-technology/halstack-react";

//icons
import accountIcon from "./user.svg";
import { homeImage } from "./homeImage.js";
import { getAuthenticatedUser } from "./utils";

function Header() {
  const location = useLocation();
  const [userName, setUserName] = useState("");

  const navigateToIndex = () => {
    window.location.href = window.location.origin;
  };

  const signOut = () => {
    Auth.signOut();
    window.location.href = window.location.origin;
  };

  const goToConsole = () => {
    window.location.href = window.assureConfig.csamConsoleUIUrl;
  };

  useEffect(() => {
    let _headerMounted = true;
    const fetchUser = async () => {
      if (_headerMounted) {
        getAuthenticatedUser()
          .then((authUserName) => setUserName(authUserName))
          .catch(console.log("unauthenticated"));
      }
    };
    fetchUser();
    return () => (_headerMounted = false);
  }, []);

  return (
    <DxcApplicationLayout.Header
      underlined={true}
      onClick={navigateToIndex}
      padding={{ left: "medium", right: "large" }}
      content={
        <React.Fragment>
          <HeaderContentContainer>
            <DxcHeading
              level={3}
              margin={{ left: "medium", right: "medium" }}
              weight="normal"
              text="Environment Service"
            />
            <HeaderLinkContainer>
              <HeaderLink isActive={location.pathname.startsWith("/customers")}>
                <Link to="/customers">Customers</Link>
              </HeaderLink>
              <HeaderLink isActive={location.pathname.startsWith("/deployment-packages")}>
                <Link to="/deployment-packages">Deployment packages</Link>
              </HeaderLink>
              <HeaderLink isActive={location.pathname.startsWith("/environment-types")}>
                <Link to="/environment-types">Environments</Link>
              </HeaderLink>
            </HeaderLinkContainer>
            <DxcFlex>
              {window.assureConfig.csamConsoleUIUrl ? (
                <DxcButton mode="text" icon={homeImage} onClick={goToConsole} />
              ) : null}
              <DxcApplicationLayout.Header.Dropdown
                label={userName}
                options={[
                  {
                    value: 1,
                    label: "Sign out",
                  },
                ]}
                icon={accountIcon}
                iconPosition="before"
                onSelectOption={signOut}
              ></DxcApplicationLayout.Header.Dropdown>
            </DxcFlex>
          </HeaderContentContainer>
        </React.Fragment>
      }
      responsiveContent={(closeHandler) => (
        <React.Fragment>
          <HeaderContainerResponsive>
            <DxcInset top="1rem" />
            <DxcHeading level={3} weight="normal" text="Environment Service" />
            <DxcInset top="1.5rem" />
            <DxcParagraph>Navigate</DxcParagraph>
            <ul>
              <li>
                <HeaderLink isActive={location.pathname.startsWith("/customers")}>
                  <Link to="/customers" onClick={closeHandler}>
                    Customers
                  </Link>
                </HeaderLink>
              </li>
              <li>
                <HeaderLink isActive={location.pathname.startsWith("/deployment-packages")}>
                  <Link to="/deployment-packages" onClick={closeHandler}>
                    Deployment packages
                  </Link>
                </HeaderLink>
              </li>
              <li>
                <HeaderLink isActive={location.pathname.startsWith("/environment-types")}>
                  <Link to="/environment-types" onClick={closeHandler}>
                    Environments
                  </Link>
                </HeaderLink>
              </li>
            </ul>
            {userName ? (
              <DxcButton
                mode="secondary"
                label="Log out"
                onClick={() => {
                  closeHandler();
                  signOut();
                }}
              />
            ) : null}
          </HeaderContainerResponsive>
        </React.Fragment>
      )}
    ></DxcApplicationLayout.Header>
  );
}

const HeaderLink = styled.div`
  width: fit-content;
  margin-right: 48px;
  & a {
    color: ${({ isActive }) => (isActive && "6f2c91") || "black"};
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 1px;
  }
`;

const HeaderContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: relative;
  color: black;
  :before {
    width: 1.2px;
    height: 26px;
    background-color: black;
    display: block;
    content: "";
    position: absolute;
    left: 0px;
  }
`;

const HeaderLinkContainer = styled.div`
  display: flex;
`;

const HeaderContainerResponsive = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > p {
    font-weight: 600;
    margin-bottom: 0;
    &:first-child {
      margin-top: 48px;
    }
  }
  ul {
    padding-left: 20px;
    li {
      list-style: none;
      margin-bottom: 12px;
      a {
        text-decoration: none;
        color: inherit;
      }
    }
    & + a {
      font-weight: 600;
    }
  }
`;

export default Header;
