//React imports
import React, { useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import { DxcHeading, DxcBox } from "@dxc-technology/halstack-react";

//CardImages
import { ReactComponent as LastUpdateImage } from "./update-24px.svg";

function CustomerCard({
  customerName,
  accountsNumber,
  environmentsNumber,
  lastDeploymentDate,
  onClick,
}) {
  const [isHovered, onHoverChanged] = useState(false);

  return (
    <DxcBox
      shadowDepth={isHovered ? 2 : 1}
      margin={{
        top: "small",
        bottom: "small",
        right: "xsmall",
        left: "xsmall",
      }}
    >
      <CardInfo
        onClick={() => onClick()}
        onMouseEnter={() => onHoverChanged(true)}
        onMouseLeave={() => onHoverChanged(false)}
      >
        <DxcHeading level={4} text={customerName} margin={{ top: "medium", bottom: "medium" }} />

        <CardInfoContent>
          <CounterContainer number={accountsNumber}>{accountsNumber}</CounterContainer>
          {accountsNumber === 1 ? "account" : "accounts"}
        </CardInfoContent>

        <CardInfoContent>
          <CounterContainer number={environmentsNumber}>{environmentsNumber}</CounterContainer>
          {environmentsNumber === 1 ? "environment" : "environments"}
        </CardInfoContent>

        {lastDeploymentDate && (
          <CardInfoDeployment>
            <LastUpdateImage />
            <CardText>Last deployment: {lastDeploymentDate}</CardText>
          </CardInfoDeployment>
        )}
      </CardInfo>
    </DxcBox>
  );
}

const CardInfo = styled.div`
  height: 205px;
  width: 357px;
  display: row;
  align-items: center;
  background-color: white;
  padding: 0 24px 24px 24px;
  :hover {
    cursor: pointer;
  }
`;

const CardInfoContent = styled.div`
  margin-bottom: 12px;
  display: column;
`;

const CardInfoDeployment = styled.div`
  display: inline-flex;
  margin-bottom: 12px;
`;

const CounterContainer = styled.span`
  border-radius: 50%;
  opacity: 1;
  background-color: ${({ number }) => (number > 0 && "#5F249F") || "#D9D9D6"};
  color: ${({ number }) => (number > 0 && "white") || "black"};
  width: 26px;
  height: 26px;
  display: inline-flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-right: 6px;
`;

const CardText = styled.span`
  margin-left: 6px;
`;

export default CustomerCard;
