import React, { useState } from "react";
import styled from "styled-components";
import {
  DxcHeading,
  DxcCheckbox,
  DxcSelect,
  DxcButton,
  DxcDialog,
  DxcTextInput,
  DxcParagraph,
  DxcFlex,
  DxcInset,
} from "@dxc-technology/halstack-react";

import { parameterTypes } from "../DeploymentPackageDefinitionHelper";

function AddDeploymentPackageParameter({
  deploymentPackageParameterName,
  deploymentPackageParameterType,
  enumValues,
  isRequired,
  onSaveClick,
  onCancelClick,
}) {
  const [newDeploymentPackageParameterName, setNewDeploymentPackageParameterName] = useState(
    deploymentPackageParameterName
  );
  const [newDeploymentPackageParameterType, setNewDeploymentPackageParameterType] = useState(
    deploymentPackageParameterType
  );
  const [newEnumValues, setNewEnumValues] = useState(enumValues);
  const [newIsRequired, setNewIsRequired] = useState(isRequired);

  const dpParameterNameHandler = ({ value }) => {
    setNewDeploymentPackageParameterName(value);
  };

  const dpParameterTypeHandler = ({ value }) => {
    setNewDeploymentPackageParameterType(value);
  };

  const enumHandler = ({ value }) => {
    setNewEnumValues(value);
  };

  const saveParamClick = () => {
    newDeploymentPackageParameterType === "enum"
      ? onSaveClick(
          deploymentPackageParameterName,
          newDeploymentPackageParameterName,
          newDeploymentPackageParameterType,
          newIsRequired,
          newEnumValues
        )
      : onSaveClick(
          deploymentPackageParameterName,
          newDeploymentPackageParameterName,
          newDeploymentPackageParameterType,
          newIsRequired
        );
  };
  return (
    <DxcDialog isCloseVisible onCloseClick={() => onCancelClick()}>
      <DialogHeader>
        <DxcHeading level={2} text="Edit parameter" margin={{ bottom: "xsmall" }} />
      </DialogHeader>
      <DxcFlex direction="column">
        <DxcParagraph>Edit properties of the deployment package.</DxcParagraph>

        <DxcTextInput
          label="Parameter name"
          id="deploymentPackageParameterName"
          placeholder="e.g.: my-parameter-name"
          name="deploymentPackageParameterName"
          margin={{ top: "xsmall", bottom: "xsmall" }}
          size="fillParent"
          value={newDeploymentPackageParameterName}
          onChange={dpParameterNameHandler}
          clearable={true}
        />
        <DxcSelect
          label="Type"
          id="type"
          options={parameterTypes}
          name="type"
          size={"fillParent"}
          margin={{ bottom: "xsmall" }}
          value={newDeploymentPackageParameterType}
          onChange={dpParameterTypeHandler}
          clearable={true}
        ></DxcSelect>

        {newDeploymentPackageParameterType === "enum" ? (
          <DxcTextInput
            label="Enum the values"
            id="enumValues"
            placeholder="e.g.: firstValue, secondValue, thirdValue"
            name="enumValues"
            margin={{ bottom: "xxsmall" }}
            size="fillParent"
            helperText="Separate each value by a comma"
            value={newEnumValues}
            onChange={enumHandler}
            clearable={true}
          />
        ) : null}

        <DxcCheckbox
          labelPosition="after"
          label="The parameter is required"
          size={"large"}
          id="isRequired"
          name="isRequired"
          onChange={setNewIsRequired}
          checked={newIsRequired}
        />
      </DxcFlex>
      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={() => onCancelClick()}
            mode="text"
          ></DxcButton>
          <DxcButton
            label="Save"
            onClick={saveParamClick}
            disabled={
              newDeploymentPackageParameterName === "" ||
              newDeploymentPackageParameterType === "" ||
              (newDeploymentPackageParameterType === "enum" && newEnumValues === "")
            }
            mode="primary"
          ></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default AddDeploymentPackageParameter;
