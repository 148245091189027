//React
import React, { useMemo } from "react";
import styled from "styled-components";

//DXC CDK
import {
  DxcHeading,
  DxcResultsetTable,
  DxcSpinner,
  DxcButton,
  DxcDropdown,
  DxcFlex,
} from "@dxc-technology/halstack-react";

import UnregisterAccountDialog from "./dialogs/UnregisterAccount";

//constant objects
import { environmentsTableColumns, environmentItemActionOptions } from "./AccountDashboardHelper";

//Image
import more_horiz from "./images/more_horiz-24px.svg";
import plusImage from "./images/add-24px.svg";
import publicImage from "./images/public.png";
import privateImage from "./images/private.png";
import { editImage } from "./editImage.js";
import { deleteImage } from "./deleteImage.js";
import EditAccountDialog from "./dialogs/EditAccount";

function Account({
  customerName,
  accountIndex,
  accountName,
  accountId,
  accountRole,
  accountIsLoading,
  environmentsList,
  onClickExpand,
  onClickCollapse,
  onClickDropDownOption,
  onClickRegisterEnv,
  unregisterAccountInfo,
  editAccountInfo,
}) {
  const environmentsDataList = useMemo(() => {
    const _envDataList = environmentsList ? environmentsList : [];

    return _envDataList.map((item) => [
      { displayValue: item.environmentName },
      { displayValue: item.awsRegion },
      { displayValue: item.isLocked },
      {
        displayValue: (
          <DxcFlex justifyContent="flex-start" alignItems="center">
            <span>{item.domain}</span>
            {item.domain === "Public" && <DomainImage src={publicImage}></DomainImage>}
            {item.domain === "Private" && <DomainImage src={privateImage}></DomainImage>}
          </DxcFlex>
        ),
        sortValue: item.domain,
      },
      { displayValue: item.creator },
      { displayValue: item.lastDeployment, sortValue: item.lastDeploymentDate },
      {
        displayValue: (
          <DxcDropdown
            options={environmentItemActionOptions}
            onSelectOption={(option) =>
              onClickDropDownOption(
                option,
                item.resourceName,
                item.awsRegion,
                item.domain,
                item.environmentName,
                item.envAccountZoneName
              )
            }
            icon={more_horiz}
            caretHidden={true}
            theme="dark"
          ></DxcDropdown>
        ),
      },
    ]);
  }, [environmentsList, onClickDropDownOption]);

  return (
    <AccountCard>
      <AccountDetails>
        <AccountTitle>
          <DxcHeading level={3} text={accountName} />
          <EditAccountActionIcon onClick={editAccountInfo.onOpenClick}>
            <DxcButton
              alt="edit"
              mode="text"
              onClick={editAccountInfo.onOpenClick}
              icon={editImage}
              margin={{ left: "xxsmall" }}
              size="small"
            />
          </EditAccountActionIcon>
          <DeleteAccountActionIcon onClick={unregisterAccountInfo.onOpenClick}>
            <DxcButton
              alt="delete"
              mode="text"
              onClick={unregisterAccountInfo.onOpenClick}
              icon={deleteImage}
              margin={{ left: "xxsmall" }}
              size="small"
            />
          </DeleteAccountActionIcon>
        </AccountTitle>
        <LabelWrapper>
          <LabelLiteral>AWS account</LabelLiteral>
          <LabelText>{accountId}</LabelText>
        </LabelWrapper>
        <LabelWrapper>
          <LabelLiteral>Account role ARN</LabelLiteral>
          <LabelText>{accountRole}</LabelText>
        </LabelWrapper>
      </AccountDetails>

      <AccountActions>
        <EnvironmentsToggle>
          {accountIsLoading === true && (
            <DxcSpinner role="progressbar" theme="light" mode="small" margin={{ right: "small" }} />
          )}
          {!environmentsList && (
            <SeeEnvironmentsButton onClick={onClickExpand}>See environments</SeeEnvironmentsButton>
          )}
          {environmentsList && (
            <SeeEnvironmentsButton onClick={onClickCollapse}>
              Less information
            </SeeEnvironmentsButton>
          )}
        </EnvironmentsToggle>
      </AccountActions>

      {environmentsList && (
        <AccountEnvironments>
          <DxcHeading level={4} text="Environments" margin={{ top: "medium", bottom: "medium" }} />
          {environmentsList && (
            <React.Fragment>
              <DxcResultsetTable
                columns={environmentsTableColumns}
                rows={environmentsDataList}
                itemsPerPage={5}
                margin={{ top: "medium" }}
              ></DxcResultsetTable>
              <StyledLink onClick={onClickRegisterEnv}>
                <PlusImg src={plusImage} />
                Register new environment
              </StyledLink>
            </React.Fragment>
          )}
        </AccountEnvironments>
      )}

      {unregisterAccountInfo.isDialogIndexOpen === accountIndex && (
        <UnregisterAccountDialog
          accountName={accountName}
          unregisterStatus={unregisterAccountInfo.accountStatus}
          unregisterErrorMessage={unregisterAccountInfo.accountMessage}
          onUnregisterClick={unregisterAccountInfo.onSubmitClick}
          onCancelClick={unregisterAccountInfo.onCancelClick}
        />
      )}
      {editAccountInfo.isDialogIndexOpen === accountIndex && (
        <EditAccountDialog
          accountName={accountName}
          accountRole={accountRole}
          accountId={accountId}
          editStatus={editAccountInfo.accountStatus}
          editErrorMessage={editAccountInfo.accountMessage}
          onEditClick={editAccountInfo.onSubmitClick}
          onCancelClick={editAccountInfo.onCancelClick}
        />
      )}
    </AccountCard>
  );
}

const AccountCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: white;
  width: 100%;
  min-height: 136px;
`;

const AccountActions = styled.div`
  display: flex;
  flex-direction: column;
`;

const AccountDetails = styled.div`
  flex-grow: 1;
  & h5 {
    font-weight: 600;
  }
`;

const EnvironmentsToggle = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 50px;
`;

const AccountEnvironments = styled.div`
  width: 100%;
  & h5 {
    font-weight: 600;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: baseline;
  letter-spacing: 0.49px;
`;

const LabelLiteral = styled.label`
  width: 160px;
  width: 160px;
  color: #666666cc;
`;
const LabelText = styled.label`
  line-height: 30px;
`;

const SeeEnvironmentsButton = styled.div`
  color: #006bf6;
  cursor: pointer;
  letter-spacing: 0.49px;
`;

const StyledLink = styled.div`
  display: flex;
  margin-top: -43px;
  margin-left: 20px;
  margin-bottom: 36px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  color: #006bf6;
  font-size: 16px;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`;

const PlusImg = styled.img`
  width: 20px;
  height: 20px;
`;

const DomainImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 6px;
  margin-left: 6px;
`;

const AccountTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 36px;
`;

const EditAccountActionIcon = styled.div`
  /**
  Temp change: to be removed after CDK provides a altText with images */
`;

const DeleteAccountActionIcon = styled.div`
  /**
  Temp change: to be removed after CDK provides a altText with images */
`;

export default Account;
