import React, { useMemo } from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcResultsetTable,
  DxcSelect,
  DxcTextInput,
  DxcButton,
  DxcTag,
  DxcDropdown,
  DxcFlex,
} from "@dxc-technology/halstack-react";

//custom hook
import useEnvironmentTypeDashboard from "./useEnvironmentTypeDashboard";

//search image
import CreateEnvironmentType from "./CreateEnvironmentType";
import DeleteEnvironmentType from "./DeleteEnvironmentType";

//images
import more_horiz from "./more_horiz-24px.svg";
import binImage from "./delete-24px.svg";
import publicImage from "./public.png";
import privateImage from "./private.png";

//constant objects
import { columns, options } from "./EnvironmentTypeDashboardHelper";

//
import DeleteEnvironment from "./dialog/DeleteEnvironment";
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";

function EnvironmentTypeDashboard() {
  const [
    isLoading,
    environmentTypeMessage,
    rows,
    environmentTypesSelected,
    tagsEnvironmentTypes,
    environmentTypes,
    textFilter,
    isCreateDialogVisible,
    onChangeFilterEnvironmentTypes,
    onChangeFilterText,
    dismissMessage,
    toggleCreateDialogVisible,
    saveEnvironmentType,
    deleteErrorMessage,
    isDeleteDialogVisible,
    isDeleteLoading,
    isCreating,
    isDeleteEnvironmentDialogVisible,
    errorMessageDeleteEnvironment,
    isDeletingEnvironment,
    error,
  ] = useEnvironmentTypeDashboard();
  useErrorHandler(error);

  const textFilterHandler = ({ value }) => {
    onChangeFilterText(value);
  };

  const typesFilterHandler = ({ value }) => {
    onChangeFilterEnvironmentTypes(value);
  };

  const environmentsDataList = useMemo(() => {
    const _envDataList = rows ? rows : [];

    return _envDataList.map((item) => [
      { displayValue: item.customerName },
      { displayValue: item.accountName },
      { displayValue: item.environmentName },
      { displayValue: item.environmentType },
      { displayValue: item.isLocked },
      {
        displayValue: (
          <DxcFlex justifyContent="flex-start" alignItems="center">
            <span>{item.domain}</span>
            {item.domain === "Public" && <DomainImage src={publicImage}></DomainImage>}
            {item.domain === "Private" && <DomainImage src={privateImage}></DomainImage>}
          </DxcFlex>
        ),
        sortValue: item.domain,
      },

      {
        displayValue: (
          <DxcDropdown
            options={options}
            onSelectOption={(option) => item.onClickOption(option)}
            icon={more_horiz}
            caretHidden={true}
            theme="dark"
          ></DxcDropdown>
        ),
      },
    ]);
  }, [rows]);

  return (
    <Content>
      <DxcHeading
        level={1}
        weight="normal"
        text="Environments"
        margin={{ top: "medium", bottom: "xsmall" }}
      />
      {environmentTypeMessage && !isCreateDialogVisible && (
        <DxcAlert
          type={environmentTypeMessage.type}
          mode="inline"
          inlineText={environmentTypeMessage.message}
          onClose={dismissMessage}
          size="fillParent"
          margin={{ bottom: "small" }}
        />
      )}
      <div>
        <FilterWrapper>
          <InputWrapper75>
            <DxcTextInput
              label="Search by customer, account or environment"
              value={textFilter}
              onChange={textFilterHandler}
              onBlur={textFilterHandler}
              margin={{ bottom: "xsmall" }}
              size={"fillParent"}
              placeholder="e.g.: my-environment"
              clearable={true}
            />
          </InputWrapper75>

          <SelectWrapper25>
            <DxcSelect
              options={environmentTypes}
              onChange={typesFilterHandler}
              label="Select environment type"
              multiple={true}
              value={environmentTypesSelected}
              size={"fillParent"}
              margin={{ left: "medium" }}
            ></DxcSelect>
          </SelectWrapper25>
        </FilterWrapper>

        {environmentsDataList && (
          <DxcResultsetTable
            columns={columns}
            rows={environmentsDataList}
            itemsPerPage={5}
            margin={{ top: "medium" }}
          ></DxcResultsetTable>
        )}

        <EnvironmentTitleWrapper>
          <DxcHeading
            level={3}
            weight="normal"
            text="Environment types"
            margin={{ top: "xsmall", bottom: "xsmall" }}
          />
          <DxcButton
            mode="primary"
            label="Create new environment type"
            onClick={toggleCreateDialogVisible}
          />
        </EnvironmentTitleWrapper>

        <EnvironmentTypesContainer>
          {tagsEnvironmentTypes &&
            tagsEnvironmentTypes.map((environmentType) => (
              <DxcTag
                key={environmentType.name}
                icon={binImage}
                iconBgColor="white"
                labelPosition="before"
                size="medium"
                label={environmentType.name}
                margin={{ bottom: "medium", right: "xxsmall" }}
                onClick={environmentType.onTagClick}
              ></DxcTag>
            ))}

          {isDeleteDialogVisible != null && (
            <DeleteEnvironmentType
              errorMessage={deleteErrorMessage}
              isLoading={isDeleteLoading}
              onCancel={tagsEnvironmentTypes[isDeleteDialogVisible].onDeleteCancel}
              onDelete={tagsEnvironmentTypes[isDeleteDialogVisible].onDeleteConfirm}
              environmentName={tagsEnvironmentTypes[isDeleteDialogVisible].name}
            ></DeleteEnvironmentType>
          )}
        </EnvironmentTypesContainer>
      </div>
      {isCreateDialogVisible && (
        <CreateEnvironmentType
          environmentTypeMessage={environmentTypeMessage}
          isCreating={isCreating}
          toggleDialogVisible={toggleCreateDialogVisible}
          saveEnvironmentType={saveEnvironmentType}
        ></CreateEnvironmentType>
      )}
      {!!isDeleteEnvironmentDialogVisible ? (
        <DeleteEnvironment
          errorMessage={errorMessageDeleteEnvironment}
          isDeletingEnvironment={isDeletingEnvironment}
          onCancel={isDeleteEnvironmentDialogVisible.cancelClick}
          onDelete={isDeleteEnvironmentDialogVisible.deleteClick}
          environment={isDeleteEnvironmentDialogVisible.enviromentName}
        />
      ) : null}
      {isLoading === "fetching" ? (
        <DxcSpinner theme="light" label="Loading..." mode="overlay" />
      ) : null}
    </Content>
  );
}

const Content = styled.div``;

const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const InputWrapper75 = styled.div`
  width: 66%;
`;

const SelectWrapper25 = styled.div`
  width: 34%;
`;

const EnvironmentTitleWrapper = styled.div`
  margin-top: 120px;
  margin-bottom: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EnvironmentTypesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const DomainImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 6px;
  margin-left: 6px;
`;

export default withErrorBoundary(EnvironmentTypeDashboard, { FallbackComponent: ApplicationError });
