//React imports
import React from "react";
import styled from "styled-components";

//DXC CDK Components
import { DxcHeading, DxcSpinner, DxcAlert } from "@dxc-technology/halstack-react";

//child components
import CustomerDialog from "./CustomerDialog";
import CustomerCard from "./CustomerCard";
import CreateCustomerCard from "./CreateCustomerCard";

//custom hook
import useCustomerDashboard from "./useCustomerDashboard";

//error
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";

function CustomerDashboard() {
  const {
    fetchStatus,
    customerMessage,
    customersList,
    isCreateDialogOpen,
    dismissMessage,
    saveCustomer,
    toggleCreateDialogVisibility,
    error,
  } = useCustomerDashboard();

  useErrorHandler(error);
  return (
    <Content>
      <DxcHeading
        level={1}
        weight="normal"
        text="Customers"
        margin={{ top: "medium", bottom: "medium" }}
      />

      {customerMessage && !isCreateDialogOpen && (
        <DxcAlert
          type={customerMessage.type}
          mode="inline"
          inlineText={customerMessage.message}
          size="fillParent"
          onClose={dismissMessage}
          margin={{ bottom: "small" }}
        />
      )}

      <CustomerCardsContainer customers={customersList} status={fetchStatus}>
        {customersList && (
          <CreateCustomerCard onClick={toggleCreateDialogVisibility}></CreateCustomerCard>
        )}
        {customersList &&
          customersList.map((row) => (
            <CustomerCard
              key={row.customerName}
              customerName={row.customerName}
              accountsNumber={row.accountsNumber}
              environmentsNumber={row.environmentsNumber}
              lastDeploymentDate={row.lastDeploymentDate}
              onClick={row.navigateCustomerDetails}
            ></CustomerCard>
          ))}
      </CustomerCardsContainer>

      {fetchStatus === "fetching" && <DxcSpinner theme="light" label="Loading..." mode="overlay" />}

      {isCreateDialogOpen && (
        <CustomerDialog
          role="dialog"
          creationErrorMessage={customerMessage}
          creationStatus={fetchStatus}
          onCancelClick={toggleCreateDialogVisibility}
          onSaveCustomerClick={saveCustomer}
        ></CustomerDialog>
      )}
    </Content>
  );
}

const Content = styled.div``;

const CustomerCardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: 705px;
  background: ${({ customers, status }) =>
    (!customers && !status && "#D9D9D948 0% 0% no-repeat padding-box") ||
    "white 0% 0% no-repeat padding-box"};
`;

export default withErrorBoundary(CustomerDashboard, { FallbackComponent: ApplicationError });
