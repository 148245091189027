import React from "react";
import styled from "styled-components";
import { DxcBox, DxcHeading } from "@dxc-technology/halstack-react";
import { ReactComponent as GeneralErrorImage } from "./img/Detected_Error.svg";
import { ReactComponent as UnauthorizedErrorImage } from "./img/Security_Lock.svg";
import { ReactComponent as ForbiddenErrorImage } from "./img/Security_Shield.svg";
import { ReactComponent as NotFoundErrorImage } from "./img/Search_Search.svg";
import { ReactComponent as Engineering } from "./img/Engineering.svg";
import { ReactComponent as CloudTransfer } from "./img/Cloud_Transfer.svg";
import { ReactComponent as ArrowBack } from "./img/Arrow_Back.svg";

import { useMemo } from "react";
import { HTTP_ERROR_CODES } from "../../common/utils";
import { useHistory } from "react-router-dom";

function ApplicationError({ error }) {
  const history = useHistory();

  const errorData = (status) => {
    if (window.assureConfig?.migrated === true) {
      return {
        image: <CloudTransfer />,
        title: "This instance has been migrated",
      };
    } else if (window.assureConfig?.on_maintenance === true) {
      return {
        image: <Engineering />,
        title: "We are under maintenance",
      };
    } else {
      switch (status) {
        case HTTP_ERROR_CODES.UNAUTHORIZED:
          return {
            image: <UnauthorizedErrorImage />,
            title: "Unauthorized",
          };
        case HTTP_ERROR_CODES.FORBIDDEN:
          return {
            image: <ForbiddenErrorImage />,
            title: "Forbidden",
          };
        case HTTP_ERROR_CODES.NOT_FOUND:
          return {
            image: <NotFoundErrorImage />,
            title: "Not Found",
          };
        default:
          return {
            image: <GeneralErrorImage />,
            title: "Ups! Something was wrong",
          };
      }
    }
  };

  const { title, image } = useMemo(() => errorData(error.status), [error.status]);

  return (
    <>
      {!window.assureConfig?.migrated && window.assureConfig?.on_maintenance ? (
        <StyledLink onClick={() => history.goBack()}>
          <ArrowBack style={{ width: "20px", height: "20px" }} />
          Go back
        </StyledLink>
      ) : null}
      <DxcBox padding="xlarge" display="column" size="fillParent" margin={{ top: "large" }}>
        <Heading>
          <ImageContainer>{image}</ImageContainer>
          <DxcHeading level={3} text={title} margin={{ top: "medium", bottom: "medium" }} />
        </Heading>
        {window.assureConfig?.error_text ? (
          <MessageSection>
            {
              <p
                dangerouslySetInnerHTML={{
                  __html: window.assureConfig?.error_text,
                }}
              />
            }
          </MessageSection>
        ) : error.message !== title ? (
          <MessageSection>{error.message}</MessageSection>
        ) : null}
      </DxcBox>
    </>
  );
}

const Heading = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
`;

const MessageSection = styled.div`
  text-align: center;
  margin: 24px;
`;

const ImageContainer = styled.div`
  > * {
    width: 48px;
    margin: 24px;
  }
`;

const StyledLink = styled.div`
  display: flex;
  align-self: flex-start;
  width: fit-content;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  top: 16px;
  :hover {
    cursor: pointer;
  }
`;

export default ApplicationError;
