//React imports
import React, { useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcButton,
  DxcDialog,
  DxcTextInput,
  DxcFlex,
  DxcInset,
  DxcParagraph,
} from "@dxc-technology/halstack-react";

function CustomerDialog({
  creationErrorMessage,
  creationStatus,
  onSaveCustomerClick,
  onCancelClick,
}) {
  const [customerName, changeCustomerName] = useState("");
  const [customerID, changeCustomerID] = useState("");
  const [customerNameError, setCustomerNameError] = useState();
  const [customerIDError, setCustomerIDError] = useState("");

  const customerNameHandler = ({ value, error }) => {
    changeCustomerName(value);
    setCustomerNameError(error);
  };

  const customerIDHandler = ({ value, error }) => {
    changeCustomerID(value);
    setCustomerIDError(error);
  };
  return (
    <DxcDialog isCloseVisible onCloseClick={() => onCancelClick()}>
      <DialogHeader>
        <DxcHeading level={2} text="Create new customer" margin={{ bottom: "xsmall" }} />
        {creationStatus === "interaction" && (
          <DxcSpinner
            role="progressbar"
            theme="light"
            mode="small"
            margin={{ left: "xsmall", bottom: "xsmall" }}
          />
        )}
      </DialogHeader>

      {creationErrorMessage && (
        <DxcAlert type="error" mode="inline" size="fillParent" margin={{ bottom: "small" }}>
          <DxcParagraph>{creationErrorMessage.message}</DxcParagraph>
        </DxcAlert>
      )}

      <DxcFlex direction="column">
        <DxcTextInput
          label="Customer name"
          id="customerName"
          placeholder="e.g.: my-customer-name"
          size="fillparent"
          name="customerName"
          value={customerName}
          onChange={customerNameHandler}
          onBlur={customerNameHandler}
          clearable={true}
          error={customerNameError == null ? "" : customerNameError}
          optional={false}
        />
        <DxcTextInput
          label="Customer ID"
          size="fillparent"
          id="customerId"
          placeholder="e.g.: my-customer-id"
          assistiveText="Lowercase, numbers and hyphens"
          name="customerId"
          value={customerID}
          onChange={customerIDHandler}
          onBlur={customerIDHandler}
          clearable={true}
          pattern="^[a-z0-9-]+$"
          helperText="Only alphanumeric and hyphens accepted"
          error={customerIDError == null ? "" : customerIDError}
          optional={false}
        />
      </DxcFlex>

      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={() => onCancelClick()}
            mode="text"
          ></DxcButton>
          <DxcButton
            label="Create"
            onClick={() => onSaveCustomerClick(customerName, customerID)}
            mode="primary"
            disabled={customerIDError || customerNameError || !(customerID && customerName)}
          ></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default CustomerDialog;
