import React from "react";
import styled from "styled-components";
//DXC components
import { DxcHeading, DxcButton, DxcBox } from "@dxc-technology/halstack-react";

import Account from "./AccountCard";
import CreateAccountDialog from "./dialogs/CreateAccount";

//Image
import plusImage from "./images/add-24px.svg";

function Accounts({
  accountList,
  fetchStatus,
  creationStatus,
  creationErrorMessage,
  isCreateAccountDialogVisible,
  onRegisterClick,
  onRegisterConfirmClick,
  onRegisterCancelClick,
}) {
  return (
    <React.Fragment>
      {accountList && (
        <AccountsSection>
          <DxcHeading
            level={2}
            weight="normal"
            text={`Accounts (${accountList.length})`}
            margin={{ bottom: "medium" }}
          />
          <DxcButton
            mode="primary"
            label="Register new account"
            onClick={onRegisterClick}
          ></DxcButton>
        </AccountsSection>
      )}
      {accountList &&
        accountList.map((account, index) => (
          <DxcBox
            padding={{
              left: "small",
              right: "xlarge",
              bottom: "small",
              top: "small",
            }}
            margin={{ top: "small", bottom: "small" }}
            key={account.accountName}
            id={index}
          >
            <Account
              customerName={account.customerName}
              accountIndex={index}
              accountName={account.accountName}
              accountId={account.accountId}
              accountRole={account.accountRole}
              accountIsLoading={account.isLoading}
              environmentsList={account.environmentList}
              onClickExpand={account.onClickExpand}
              onClickCollapse={account.onClickCollapse}
              onClickDropDownOption={account.onClickOption}
              onClickRegisterEnv={account.onClickRegisterEnv}
              unregisterAccountInfo={account.unregisterAccountInfo}
              editAccountInfo={account.editAccountInfo}
            ></Account>
          </DxcBox>
        ))}
      {accountList?.length === 0 && fetchStatus === "resolved" && (
        <AccountEmptySection>
          <TextParagraph>
            <TextLine>There are no accounts related with this customer</TextLine>
            <TextLine>Create yor first account to start to operate</TextLine>
          </TextParagraph>
          <LinkLine onClick={onRegisterClick}>
            <PlusImg src={plusImage} />
            Register your first account
          </LinkLine>
        </AccountEmptySection>
      )}
      {isCreateAccountDialogVisible && (
        <CreateAccountDialog
          creationStatus={creationStatus}
          creationErrorMessage={creationErrorMessage}
          onRegisterClick={onRegisterConfirmClick}
          onCancelClick={onRegisterCancelClick}
        />
      )}
    </React.Fragment>
  );
}

const AccountsSection = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const AccountEmptySection = styled.div`
  background-color: #d9d9d948;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const TextParagraph = styled.p`
  display: flex;
  flex-direction: column;
`;

const TextLine = styled.span`
  width: 100%;
  font-size: 20px;
  letter-spacing: 0.26px;
`;

const LinkLine = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  letter-spacing: 0.49px;
  color: #006bf6;
  :hover {
    cursor: pointer;
  }
`;

const PlusImg = styled.img`
  width: 20px;
  height: 20px;
`;
export default Accounts;
