import React, { useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcWizard,
  DxcButton,
  DxcAlert,
  DxcChip,
} from "@dxc-technology/halstack-react";

//custom hook
import { useInstallDeploymentPackage } from "./useInstallDeploymentPackage";
//search image
import backImage from "./images/arrow_back_ios-24px.svg";
import readyImage from "../deploy-deployment-package-steps/images/ready.svg";
import { deploySteps } from "../deploy-deployment-package-steps/helper/DeployDeploymentPackageHelper";

import DeployInfo from "../deploy-deployment-package-steps/DeployInfo";
import DeploymentPackageParameters from "../deploy-deployment-package-steps/DeploymentPackageParameters";
import DeploymentPackagesArtifacts from "../deploy-deployment-package-steps/DeploymentPackageArtefacts";
import DeploymentPackageDependencyParameters from "../deploy-deployment-package-steps/DeploymentPackageDependencyParameters";
import ReviewDeployRequest from "../deploy-deployment-package-steps/ReviewDeployRequest";
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";

function InstallDeploymentPackage() {
  const [
    installDeploymentPackageStatus,
    installDeploymentPackageMessage,
    deploymentPackageDeployDataInfo,
    deploymentPackageParametersInfo,
    deploymentPackageArtefactInfo,
    deploymentPackageDependenciesInfo,
    reviewDeploymentInfo,
    error,
    navigateToDeploymentDashboard,
    installDeploymentPackageHandler,
    dismissMessage,
  ] = useInstallDeploymentPackage();
  useErrorHandler(error);

  //Wizard Step Management
  const [currentStep, setCurrentStep] = useState(0);
  const onStepClick = (i) => {
    setCurrentStep(i);
  };

  const moveNext = () => {
    onStepClick(currentStep + 1);
  };
  const movePrev = () => {
    onStepClick(currentStep - 1);
  };
  //read-only/editable steps management
  const [readOnly, changeReadOnly] = useState(false);

  return (
    <Content>
      <StyledLink onClick={navigateToDeploymentDashboard}>
        <BackImg src={backImage} />
        Back to Deployments
      </StyledLink>
      <Title>
        <DxcHeading
          level={2}
          weight="normal"
          text="Install deployment package:"
          margin={{ top: "medium", bottom: "medium" }}
        />
        {deploymentPackageDeployDataInfo.deploymentPackageId ? (
          <DxcHeading
            level={2}
            text={deploymentPackageDeployDataInfo.deploymentPackageId}
            margin={{ top: "medium", bottom: "medium", left: "xsmall" }}
          />
        ) : null}
        {deploymentPackageDeployDataInfo.deploymentType !== "default" ? (
          <DxcChip
            label={deploymentPackageDeployDataInfo.resourceVersion}
            margin="small"
            suffixIcon={
              <img alt={deploymentPackageDeployDataInfo.resourceVersion} src={readyImage}></img>
            }
          />
        ) : null}
      </Title>
      {installDeploymentPackageMessage ? (
        <DxcAlert
          type={installDeploymentPackageMessage.type}
          mode="inline"
          inlineText={installDeploymentPackageMessage.message}
          size="fillParent"
          onClose={dismissMessage}
          margin={{ bottom: "small" }}
        />
      ) : null}
      <DxcWizard
        currentStep={currentStep}
        onStepClick={onStepClick}
        steps={deploySteps}
        margin={{ bottom: "medium" }}
      ></DxcWizard>
      <MainContainer>
        {currentStep === 0 ? (
          <DeployInfo
            operation="install"
            source={deploymentPackageDeployDataInfo.source}
            tag={deploymentPackageDeployDataInfo.tag}
            invalidTag={deploymentPackageDeployDataInfo.invalidTag}
            tfAction={deploymentPackageDeployDataInfo.tfAction}
            deploymentMode={deploymentPackageDeployDataInfo.deployDependencies}
            deploymentType={deploymentPackageDeployDataInfo.deploymentType}
            resourceVersion={deploymentPackageDeployDataInfo.resourceVersion}
            version={deploymentPackageDeployDataInfo.version}
            releasesVersionList={deploymentPackageDeployDataInfo.releasesVersionList}
            readOnly={readOnly}
            setSource={deploymentPackageDeployDataInfo.onChangeSource}
            setTag={deploymentPackageDeployDataInfo.onChangeTag}
            setTfAction={deploymentPackageDeployDataInfo.onChangeTfAction}
            setDeploymentMode={deploymentPackageDeployDataInfo.onChangeDeployDependencies}
            setDeploymentType={deploymentPackageDeployDataInfo.onChangeDeploymentType}
            setVersion={deploymentPackageDeployDataInfo.onChangeVersion}
            toggleReadOnly={changeReadOnly}
          ></DeployInfo>
        ) : null}

        {currentStep === 1 ? (
          <DeploymentPackageParameters
            deploymentPackageParametersList={
              deploymentPackageParametersInfo.deploymentPackageParametersList
            }
            expertParametersList={deploymentPackageParametersInfo.expertParametersList}
            onAddExpertParamClick={deploymentPackageParametersInfo.onAddClick}
          ></DeploymentPackageParameters>
        ) : null}

        {currentStep === 2 ? (
          <DeploymentPackagesArtifacts
            deploymentPackageArtefactsList={
              deploymentPackageArtefactInfo.deploymentPackageArtefactsList
            }
            readOnly={readOnly}
            onAddArtefactClick={deploymentPackageArtefactInfo.onAddArtefactClick}
            onRemoveArtefactClick={deploymentPackageArtefactInfo.onRemoveArtefactClick}
          ></DeploymentPackagesArtifacts>
        ) : null}

        {currentStep === 3 ? (
          <DeploymentPackageDependencyParameters
            title={"List of deployment package dependencies to install"}
            linkedDeploymentPackagesList={
              deploymentPackageDependenciesInfo.deploymentPackageDependenciesList
            }
          ></DeploymentPackageDependencyParameters>
        ) : null}

        {currentStep === 4 ? (
          <ReviewDeployRequest
            operation="install"
            reviewDeploymentPackageDeployDataInfo={
              reviewDeploymentInfo.reviewDeploymentPackageDeployDataInfo
            }
            reviewDeploymentPackageParametersInfo={
              reviewDeploymentInfo.reviewDeploymentPackageParametersInfo
            }
            reviewDeploymentPackageArtefactsInfo={
              reviewDeploymentInfo.reviewDeploymentPackageArtefactsInfo
            }
            reviewLinkedDeploymentPackageInfo={
              reviewDeploymentInfo.reviewLinkedDeploymentPackageInfo
            }
          ></ReviewDeployRequest>
        ) : null}
      </MainContainer>
      <Navigation firstPage={currentStep === 0}>
        {currentStep !== 0 ? (
          <DxcButton
            disabled={currentStep === 0}
            mode="primary"
            label="Previous"
            onClick={movePrev}
          />
        ) : null}
        {currentStep === 4 ? (
          <DxcButton
            mode="primary"
            disabled={deploymentPackageDeployDataInfo.invalidTag}
            label="Deploy"
            onClick={installDeploymentPackageHandler}
          />
        ) : (
          <DxcButton mode="primary" label="Next" onClick={moveNext} />
        )}
      </Navigation>
      {installDeploymentPackageStatus === "loading" ? (
        <DxcSpinner theme="light" label="Loading..." mode="overlay" />
      ) : null}
    </Content>
  );
}

const Content = styled.div``;

const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledLink = styled.div`
  display: flex;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`;

const BackImg = styled.img`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-self: center;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ firstPage }) => (firstPage && "flex-end") || "space-between"};
`;

export default withErrorBoundary(InstallDeploymentPackage, { FallbackComponent: ApplicationError });
