import React from "react";
import styled from "styled-components";

function NonEditableField({ value, label, margin }) {
  return (
    <FieldContainer margin={margin}>
      <FieldLabel>{label}</FieldLabel>
      <FieldValue>{value}</FieldValue>
    </FieldContainer>
  );
}

//CDK defined sizes
const sizes = {
  none: "0px",
  xxsmall: "6px",
  xsmall: "16px",
  small: "24px",
  medium: "36px",
  large: "48px",
  xlarge: "64px",
  xxlarge: "100px",
};

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) =>
  margin && typeof margin !== "object" ? sizes[margin] : ""};
  margin-top: ${({ margin }) => (margin && margin.top ? sizes[margin.top] : "")};
  margin-right: ${({ margin }) => (margin && margin.right ? sizes[margin.right] : "")};
  margin-bottom: ${({ margin }) => (margin && margin.bottom ? sizes[margin.bottom] : "36px")};
  margin-left: ${({ margin }) => (margin && margin.left ? sizes[margin.left] : "")};
`;

const FieldLabel = styled.span`
  font-size: 12px;
`;

const FieldValue = styled.span`
  margin-top: 8px;
  font-weight: 600;
`;
export default NonEditableField;
