//React hooks imports
import { useMemo, useState, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PAGES } from "../utils";

//configuation helpers imports
import { config } from "../config";
import {
  getSessionHeaders,
  getPlatformHeaders,
  buildApplicationError,
  PROMISE_FINAL_STATUSES,
} from "../common/utils";

//DXC HAL
import { useHalResource } from "@dxc-technology/halstack-react-hal";

const sortByName = function (a, b) {
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
};

export const useDeploymentHistory = () => {
  //router
  const history = useHistory();
  const { customerId, accountId, environmentId, deploymentPackageId, deploymentId } = useParams();
  const [error, setError] = useState(null);

  //status and messages
  const [deploymentHistoryMessage, changeDeploymentHistoryMessage] = useState(null);

  //deployment history
  const [deploymentHistoryCollectionResource, setDeploymentHistoryCollectionResource] = useState(
    []
  );

  //active deployment
  const [deploymentPackageName, setDeploymentPackageName] = useState(null);
  const [deploymentPackageSource, setDeploymentPackageSource] = useState(null);
  const [deploymentPackageTag, setDeploymentPackageTag] = useState(null);
  const [deploymentPackageParameters, setDeploymentPackageParameters] = useState([]);
  const [deploymentPackageArtefacts, setDeploymentPackageArtefacts] = useState([]);
  const [deploymentPackageDeploymentDate, setDeploymentPackageDeploymentDate] = useState(null);
  const [deploymentPackageDeploymentStatus, setDeploymentPackageDeploymentStatus] = useState(null);
  const [deploymentPackageTestingStatus, setDeploymentPackageTestingStatus] = useState(null);
  const [deploymentPackageCreator, setDeploymentPackageCreator] = useState(null);
  const [deploymentPackageDeploymentResourceId, setDeploymentPackageDeploymentResourceId] =
    useState(null);

  //collapsable links state
  const [isArtefactLinkCollapsed, changeIsArtefactLinkCollapsed] = useState([]);

  // API urls
  const DEPLOYMENT_URI = `/customers/${customerId}/accounts/${accountId}/environments/${environmentId}/deployment-packages/${deploymentPackageId}/deployments/${deploymentId}`;
  //urls
  const DEPLOYMENTS_URL = `/customers/${customerId}/accounts/${accountId}/environments/${environmentId}/deployments`;

  const [deploymentResource, deploymentStatus, deploymentError] = useHalResource({
    url: `${config.environments_api_url}${DEPLOYMENT_URI}?trail=true`,
    asyncHeadersHandler: getSessionHeaders,
    headers: getPlatformHeaders(),
  });

  const dismissMessage = () => {
    changeDeploymentHistoryMessage(null);
  };

  //Message operations
  useEffect(() => {
    if (PROMISE_FINAL_STATUSES.includes(deploymentStatus) && deploymentError) {
      setError(buildApplicationError(deploymentError));
    }
  }, [deploymentError, deploymentStatus]);

  const setActiveDeployment = (
    deployInfo,
    parameterList,
    artefactList,
    deploymentPackageDeploymentDate,
    deploymentPackageDeploymentStatus,
    deploymentPackageTestingStatus,
    deploymentPackageCreator,
    deploymentPackageDeploymentResourceId
  ) => {
    setDeploymentPackageSource(deployInfo.git_url);
    setDeploymentPackageTag(deployInfo.tag);
    setDeploymentPackageDeploymentDate(deploymentPackageDeploymentDate);
    setDeploymentPackageDeploymentStatus(deploymentPackageDeploymentStatus);
    setDeploymentPackageTestingStatus(deploymentPackageTestingStatus);
    setDeploymentPackageCreator(deploymentPackageCreator);
    setDeploymentPackageDeploymentResourceId(deploymentPackageDeploymentResourceId);

    const _deploymentPackageParameters = Object.keys(parameterList)
      .map((key) => ({
        name: key,
        value: parameterList[key],
      }))
      .sort((a, b) => sortByName(a, b));
    setDeploymentPackageParameters(_deploymentPackageParameters);

    const _deploymentPackageArtefacts = artefactList.reduce((result, artefact) => {
      if (artefact.tf_properties) {
        return [
          ...result,
          {
            properties: Object.keys(artefact.tf_properties)
              .map((key) => ({
                name: key,
                value: artefact.tf_properties[key],
              }))
              .sort((a, b) => sortByName(a, b)),
          },
        ];
      } else return result;
    }, []);
    setDeploymentPackageArtefacts(_deploymentPackageArtefacts);
    changeIsArtefactLinkCollapsed(
      _deploymentPackageArtefacts.reduce((result, _, idx) => [...result, idx !== 0], [])
    );
  };

  useEffect(() => {
    const _deploymentProperties = deploymentResource ? deploymentResource.getProperties() : [];
    if (_deploymentProperties.length) {
      setDeploymentPackageName(
        _deploymentProperties.find((prop) => prop.key === "service_name")?.value
      );
      const _deploymentPackageDeployInfo = _deploymentProperties.find(
        (prop) => prop.key === "deploy_info"
      )?.value;
      const _deploymentPackageParametersObject = _deploymentProperties.find(
        (prop) => prop.key === "parameters"
      )?.value
        ? _deploymentProperties.find((prop) => prop.key === "parameters").value
        : {};
      const _deploymentPackageArtefactsCollection = _deploymentProperties.find(
        (prop) => prop.key === "artifacts"
      )?.value
        ? _deploymentProperties.find((prop) => prop.key === "artifacts").value
        : [];
      const _deploymentDate = _deploymentProperties.find(
        (prop) => prop.key === "deployment_date"
      )?.value;
      const _deploymentStatusValue = _deploymentProperties.find(
        (prop) => prop.key === "deployment_status"
      )?.value
        ? `${_deploymentProperties.find((prop) => prop.key === "deployment_status")?.value}`
        : "";
      const _deploymentStatus = `${
        _deploymentProperties.find((prop) => prop.key === "terraform_action")?.value
      } ${_deploymentStatusValue}`;
      const _testingStatus = _deploymentProperties.find((prop) => prop.key === "testing")?.value;
      const _creator = _deploymentProperties.find((prop) => prop.key === "creator")?.value;
      const _deploymentResourceId = _deploymentProperties.find(
        (prop) => prop.key === "resource_name"
      )?.value;

      setActiveDeployment(
        _deploymentPackageDeployInfo,
        _deploymentPackageParametersObject,
        _deploymentPackageArtefactsCollection,
        _deploymentDate,
        _deploymentStatus,
        _testingStatus,
        _creator,
        _deploymentResourceId
      );

      const _deploymentTrailCollectionResource =
        _deploymentProperties.find((prop) => prop.key === "trail") &&
        _deploymentProperties.find((prop) => prop.key === "trail").value.length > 0
          ? _deploymentProperties.find((prop) => prop.key === "trail").value
          : [getDeploymentTrailInfo(deploymentResource.resourceRepresentation)];
      setDeploymentHistoryCollectionResource(_deploymentTrailCollectionResource);
    }
  }, [deploymentResource]);

  const getDeploymentTrailInfo = (deployment) => {
    let simplifyDeployment = { ...deployment };
    delete simplifyDeployment._links;
    delete simplifyDeployment._options;
    delete simplifyDeployment.trail;
    delete simplifyDeployment.historical_record;
    return simplifyDeployment;
  };

  const deploymentHistoryList = useMemo(() => {
    return deploymentHistoryCollectionResource.map((item) => {
      return {
        deploymentResourceId: item.resource_name,
        deploymentStatus: `${item.terraform_action} ${
          item.deployment_status ? item.deployment_status : ""
        }`,
        testingStatus: item.testing,
        deploymentDate: item.deployment_date,
        creator: item.creator,
        isSelected: deploymentPackageDeploymentDate === item.deployment_date,
        logUrl: item.locationURL,
        version: item.version,
      };
    });
  }, [deploymentHistoryCollectionResource, deploymentPackageDeploymentDate]);

  const selectActiveDeployment = (deploymentResourceId) => {
    const _newActiveDeployment = deploymentHistoryCollectionResource.find(
      (item) => item.resource_name === deploymentResourceId
    );
    setActiveDeployment(
      _newActiveDeployment.deploy_info,
      _newActiveDeployment.parameters ? _newActiveDeployment.parameters : {},
      _newActiveDeployment.artifacts ? _newActiveDeployment.artifacts : [],
      _newActiveDeployment.deployment_date,
      `${_newActiveDeployment.terraform_action} ${
        _newActiveDeployment.deployment_status ? _newActiveDeployment.deployment_status : ""
      }`,
      _newActiveDeployment.testing,
      _newActiveDeployment.creator,
      _newActiveDeployment.deployment_resource_name
    );
  };

  const viewLogsHandler = useCallback(
    (logURL, deploymentId) => {
      const logId = logURL.split("/logs/")[1];
      const breadcrumbs = sessionStorage.getItem("origin") ? sessionStorage.getItem("origin") : "";
      let breadcrumbsArray = breadcrumbs.split(",");
      breadcrumbsArray.push(PAGES.DEPLOYMENT_HISTORY);
      sessionStorage.setItem("origin", breadcrumbsArray);
      history.push(`${DEPLOYMENT_URI}/logs/${logId}`);
    },
    [DEPLOYMENT_URI, history] 
  );

  //grouped active history entry data info
  const activeDeploymentPackageInfo = {
    name: deploymentPackageName,
    source: deploymentPackageSource,
    tag: deploymentPackageTag,
    parameters: deploymentPackageParameters,
    artefacts: deploymentPackageArtefacts,
    deploymentDate: deploymentPackageDeploymentDate,
    deploymentStatus: deploymentPackageDeploymentStatus,
    testingStatus: deploymentPackageTestingStatus,
    creator: deploymentPackageCreator,
    deploymentId: deploymentPackageDeploymentResourceId,
    isLinkCollapsed: isArtefactLinkCollapsed,
    onChangeIsLinkCollapsed: changeIsArtefactLinkCollapsed,
  };

  //grouped history list info
  const historyInfo = {
    deploymentHistoryList: deploymentHistoryList,
    selectActiveDeployment: selectActiveDeployment,
    viewLog: viewLogsHandler,
  };

  const navigateToDeployments = () => {
    const breadcrumbs = sessionStorage.getItem("origin") ? sessionStorage.getItem("origin") : "";
    let breadcrumbsArray = breadcrumbs.split(",");
    breadcrumbsArray.pop();
    sessionStorage.setItem("origin", breadcrumbsArray);
    history.push(DEPLOYMENTS_URL);
  };

  return [
    deploymentStatus,
    deploymentHistoryMessage,
    activeDeploymentPackageInfo,
    historyInfo,
    navigateToDeployments,
    dismissMessage,
    error,
  ];
};
