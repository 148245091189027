import { DxcHeading } from "@dxc-technology/halstack-react";
import { useState } from "react";
import styled from "styled-components";

function VerticalMenu({
  items = [],
  title,
  selectedValue,
  onSelectItem,
  size = "fitContent",
  margin,
  tabIndex = 0,
}) {
  const [selected, setSelected] = useState(selectedValue);

  function handleMenuItemClick(value) {
    setSelected(value);
    if (onSelectItem) {
      onSelectItem(value);
    }
  }

  return (
    <MenuContainer size={size} margin={margin}>
      {title && <DxcHeading level={3} text={title} margin={{ top: "xsmall", bottom: "small" }} />}
      <Menu>
        {items.length > 0 &&
          items.map((item, i) => (
            <MenuItem
              size={size}
              margin={margin}
              isSelected={selected === item.value}
              key={`${title ?? item.value}_${i}`}
              value={item.value}
              onClick={() => handleMenuItemClick(item.value)}
              tabIndex={tabIndex}
            >
              {item.label}
            </MenuItem>
          ))}
      </Menu>
    </MenuContainer>
  );
}

const sizes = {
  small: "60px",
  medium: "240px",
  large: "480px",
  fillParent: "100%",
  fitContent: "unset",
};

const spaces = {
  xxsmall: "6px",
  xsmall: "16px",
  small: "24px",
  medium: "36px",
  large: "48px",
  xlarge: "64px",
  xxlarge: "100px",
};

const getMargin = (marginProp, side) => {
  if (marginProp && typeof marginProp === "object") {
    return (marginProp[side] && spaces[marginProp[side]]) || "0px";
  } else {
    return (marginProp && spaces[marginProp]) || "0px";
  }
};

const calculateWidth = (margin, size) => {
  if (size === "fillParent") {
    return `calc(${sizes[size]} - ${getMargin(margin, "left")} - ${getMargin(margin, "right")})`;
  }
  return sizes[size];
};

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => calculateWidth(props.margin, props.size)};
  margin: ${(props) =>
    props.margin && typeof props.margin !== "object" ? spaces[props.margin] : "0px"};
  margin-top: ${(props) =>
    props.margin && typeof props.margin === "object" && props.margin.top
      ? spaces[props.margin.top]
      : ""};
  margin-right: ${(props) =>
    props.margin && typeof props.margin === "object" && props.margin.right
      ? spaces[props.margin.right]
      : ""};
  margin-bottom: ${(props) =>
    props.margin && typeof props.margin === "object" && props.margin.bottom
      ? spaces[props.margin.bottom]
      : ""};
  margin-left: ${(props) =>
    props.margin && typeof props.margin === "object" && props.margin.left
      ? spaces[props.margin.left]
      : ""};
`;

const Menu = styled.ul`
  margin-block-start: 0em;
  margin-block-end: 1em;
  padding-inline-start: 0px;
`;

const MenuItem = styled.li`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  list-style-type: none;
  padding-top: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 4px;
  height: 34px;
  border-radius: 4px;
  border-width: 2px;
  background-color: ${(props) => (props.isSelected ? "#5f249f" : "#ffffff")};
  color: ${(props) => (props.isSelected ? "#ffffff" : "#000000")};
  min-width: ${(props) => (props.key === "" ? "0px" : calculateWidth(props.margin, props.size))};

  :hover {
    cursor: pointer;
    background-color: ${(props) => (props.isSelected ? "#5f249f" : "#f2f2f2")};
  }

  :focus {
    outline-color: #0095ff;
    outline-width: 2px;
  }

  :active {
    background-color: ${(props) => (props.isSelected ? "#4b1c7d" : "#e6e6e6")};
    color: ${(props) => (props.isSelected ? "#ffffff" : "#000000")};
  }
`;

export default VerticalMenu;
