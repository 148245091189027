import React, { useState } from "react";
import styled from "styled-components";
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcButton,
  DxcDialog,
  DxcTextInput,
  DxcParagraph,
  DxcFlex,
  DxcInset,
} from "@dxc-technology/halstack-react";

function EditCustomerDialog({
  customerShortName,
  customerStatus,
  customerErrorMessage,
  onEditClick,
  onCancelClick,
}) {
  const [newCustomerShortName, setNewCustomerShortName] = useState(customerShortName);
  const [customerShortNameError, setCustomerShortNameError] = useState();

  const customerShortNameHandler = ({ value, error }) => {
    setNewCustomerShortName(value);
    setCustomerShortNameError(error);
  };

  return (
    <DxcDialog isCloseVisible onCloseClick={() => onCancelClick()}>
      <DialogHeader>
        <DxcHeading level={2} text="Edit customer" margin={{ bottom: "xsmall" }} />
        {customerStatus === "interaction" && (
          <DxcSpinner
            role="progressbar"
            theme="light"
            mode="small"
            margin={{ left: "xsmall", bottom: "xsmall" }}
            label="Updating..."
          />
        )}
      </DialogHeader>

      {customerErrorMessage && (
        <DxcAlert type={"error"} mode="inline" size="fillParent" margin={{ bottom: "small" }}>
          <DxcParagraph>{customerErrorMessage.message}</DxcParagraph>
        </DxcAlert>
      )}

      <DxcFlex direction="column">
        <DxcParagraph>
          Please, edit the information of the customer to be updated in the Assure Platform.
        </DxcParagraph>

        <DxcTextInput
          label="Customer short name"
          id="customerShortName"
          placeholder="e.g.: my-customer-short-name"
          name="customerShortName"
          margin={{ top: "xsmall", bottom: "xxsmall" }}
          size="fillParent"
          value={newCustomerShortName}
          onChange={customerShortNameHandler}
          onBlur={customerShortNameHandler}
          clearable={true}
          optional={false}
          error={customerShortNameError == null ? "" : customerShortNameError}
        />
      </DxcFlex>

      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={() => onCancelClick()}
            mode="text"
          ></DxcButton>
          <DxcButton
            label="Edit"
            onClick={() => onEditClick(newCustomerShortName)}
            mode="primary"
            disabled={customerShortNameError != null || newCustomerShortName == null}
          ></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default EditCustomerDialog;
