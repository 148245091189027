export const deploymentPackageTypes = [
  {
    value: "Infrastructure",
    label: "Infrastructure",
  },
  {
    value: "External",
    label: "External",
  },
  {
    value: "Integrated",
    label: "Integrated",
  },
];

export const servicesDeploymentPackageColumns = [
  { displayValue: "Name", isSortable: true },
  { displayValue: "Service", isSortable: false },
  { displayValue: "Linked", isSortable: true },
];

export const deploymentPackageColumns = [
  { displayValue: "Name", isSortable: true },
  { displayValue: "Type", isSortable: false },
  { displayValue: "Linked", isSortable: true },
];

export const parameterTypes = [
  { value: "string", label: "Text" },
  { value: "list", label: "List" },
  { value: "enum", label: "Enum" },
];

export const deploymentPackageParametersColumns = [
  { displayValue: "Name", isSortable: false },
  { displayValue: "Type", isSortable: false },
  { displayValue: "Is required", isSortable: false },
  { displayValue: "Actions", isSortable: false },
];

export const buildDeploymentPackageParametersPayload = (deploymentPackageParameters) =>
  deploymentPackageParameters.map((param) => ({
    [param.name]: {
      type: param.type,
      required: param.isRequired,
      enumValues: param.enumValues,
    },
  }));

export const buildDeploymentPackageDependenciesPayload = (deploymentPackageDependencies) =>
  deploymentPackageDependencies.map((dependency) => ({
    service_name: dependency.name,
    service_service: dependency.service,
  }));

export const buildDeploymentPackageArtefactsPayload = (deploymentPackageArtefacts) =>
  deploymentPackageArtefacts.map((artefact) => ({
    name: artefact.name,
    variable: artefact.variable,
    tf_properties: artefact.properties.reduce((tf_props, prop) => {
      if (prop.value && prop.name) {
        tf_props[prop.name] = prop.value;
      }
      return tf_props;
    }, {}),
  }));

export const buildDeploymentPackageConditionsPayload = (deploymentPackageConditions) =>
  deploymentPackageConditions.map((condition) => ({
    key: condition.conditionKey,
    value: condition.value,
    equality: condition.assert,
  }));
