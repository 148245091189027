import React from "react";
import styled from "styled-components";
import { DxcBox, DxcButton, DxcHeading, DxcSwitch } from "@dxc-technology/halstack-react";

function SectionContainer({
  title,
  customTitleContent,
  actionLabel,
  actionIcon,
  actionOnClick,
  padding = undefined,
  actionDisabled = false,
  children,
  switchLabel,
  switchAction,
  checked,
}) {
  return (
    <DxcBox shadowDepth={1} margin={{ bottom: "medium" }}>
      <BoxContent>
        <HeadingContainer>
          <HeadingWrapper>
            <DxcHeading level={3} text={title}></DxcHeading>
            {customTitleContent}
          </HeadingWrapper>
          {actionOnClick && (
            <DxcButton
              mode="secondary"
              label={actionLabel}
              onClick={actionOnClick}
              icon={actionIcon}
              disabled={actionDisabled}
            />
          )}
          {switchAction && (
            <DxcSwitch
              checked={checked}
              labelPosition="after"
              label={switchLabel}
              onChange={switchAction}
            />
          )}
        </HeadingContainer>
        <ComponentContainer padding={padding}>{children}</ComponentContainer>
      </BoxContent>
    </DxcBox>
  );
}
//CDK defined sizes
const sizes = {
  none: "0px",
  xxsmall: "6px",
  xsmall: "16px",
  small: "24px",
  medium: "36px",
  large: "48px",
  xlarge: "64px",
  xxlarge: "100px",
};

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #6f2c91;
  justify-content: space-between;
  align-items: first baseline;
  padding: 16px 24px;
  align-items: center;
`;
const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const ComponentContainer = styled.div`
  padding: ${({ padding }) =>
    padding && typeof padding !== "object" ? sizes[padding] : "32px 48px"};
  padding-top: ${({ padding }) => (padding && padding.top ? sizes[padding.top] : "")};
  padding-right: ${({ padding }) => (padding && padding.right ? sizes[padding.right] : "")};
  padding-bottom: ${({ padding }) => (padding && padding.bottom ? sizes[padding.bottom] : "")};
  padding-left: ${({ padding }) => (padding && padding.left ? sizes[padding.left] : "")};
`;

export default SectionContainer;
