export const addIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M16.667,11.667h-5v5H10v-5H5V10h5V5h1.667v5h5Z" transform="translate(-0.833 -0.833)" />
    <path class="b" d="M0,0H20V20H0Z" fill="none" />
  </svg>
);
