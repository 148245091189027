//React imports
import React, { useState } from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcButton,
  DxcDialog,
  DxcTextInput,
  DxcParagraph,
  DxcFlex,
  DxcInset,
  DxcTypography,
} from "@dxc-technology/halstack-react";

function DeleteEnvironment({
  errorMessage,
  isDeletingEnvironment,
  onCancel,
  onDelete,
  environment,
}) {
  const [environmentName, changeEnvironmentName] = useState("");
  const [nameError, setNameError] = useState("");

  const nameHandler = ({ value, error }) => {
    changeEnvironmentName(value);
    setNameError(error);
  };

  return (
    <DxcDialog isCloseVisible onCloseClick={onCancel}>
      <DialogHeader>
        <DxcHeading level={2} text={`Delete environment`} margin={{ bottom: "xsmall" }} />

        {isDeletingEnvironment === "interacting" ? (
          <DxcSpinner
            theme="light"
            mode="small"
            margin={{ left: "xsmall", bottom: "xsmall" }}
            label="Loading..."
          />
        ) : null}
      </DialogHeader>

      {errorMessage && (
        <DxcAlert type="error" mode="inline" size="fillParent" margin={{ bottom: "small" }}>
          <DxcParagraph>{errorMessage}</DxcParagraph>
        </DxcAlert>
      )}

      <DxcFlex direction="column">
        <DxcParagraph>
          The environment{" "}
          <DxcTypography as="span" fontWeight="600" display="inline">
            {environment}
          </DxcTypography>{" "}
          is going to be deleted, please type the name of the environment to confirm.
        </DxcParagraph>

        <DxcTextInput
          label="Environment name"
          margin={{ top: "xsmall" }}
          size="fillParent"
          value={environmentName}
          disable={isDeletingEnvironment !== "idle"}
          onChange={nameHandler}
          onBlur={nameHandler}
          clearable={true}
          optional={false}
          error={nameError == null ? "" : nameError}
          placeholder={environment}
        />
      </DxcFlex>

      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            mode="text"
            onClick={onCancel || isDeletingEnvironment !== "idle"}
          ></DxcButton>
          <DxcButton
            label="Delete"
            onClick={onDelete}
            mode="primary"
            disabled={
              environmentName !== environment ||
              isDeletingEnvironment === "interacting" ||
              nameError
            }
          ></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

const DialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export default DeleteEnvironment;
