import React from "react";
import styled from "styled-components";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcTextInput,
  DxcButton,
  DxcTable,
  DxcSelect,
} from "@dxc-technology/halstack-react";

import useEnvironmentMigration from "./useEnvironmentMigration";
import { ReactComponent as DeleteIcon } from "../assets/delete-icon.svg";
import { ReactComponent as AddIcon } from "../assets/add-icon.svg";
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";

const EnvironmentMigration = () => {
  const {
    customerId,
    accountId,
    environmentId,
    migrateActive,
    migrateEnvironmentStatus,
    errorMessage,
    envDeploymentPackagesList,
    deploymentPackagesList,
    deploymentPackagesToMigrate,
    onChangeDeploymentPackage,
    addDeploymentPackage,
    deleteDeploymentPackageToMigrate,
    dismissMessage,
    onMigrate,
    error,
  } = useEnvironmentMigration();
  useErrorHandler(error);

  return (
    <GlobalContent>
      <Content>
        {errorMessage && (
          <DxcAlert
            type={errorMessage.type}
            mode="inline"
            inlineText={errorMessage.message}
            size="fillParent"
            onClose={dismissMessage}
          />
        )}

        <Title>
          <DxcHeading level={3} text="Migrate environment" />
          <DxcButton mode="primary" label="Migrate" onClick={onMigrate} disabled={!migrateActive} />
        </Title>
        <Subtitle>
          <div>
            <SubtitleLabel>Customer:</SubtitleLabel>
            <SubtitleValue>{customerId}</SubtitleValue>
          </div>
          <div>
            <SubtitleLabel>Account:</SubtitleLabel>
            <SubtitleValue>{accountId}</SubtitleValue>
          </div>
          <div>
            <SubtitleLabel>Environment:</SubtitleLabel>
            <SubtitleValue>{environmentId}</SubtitleValue>
          </div>
        </Subtitle>

        {migrateEnvironmentStatus === "fetching" && (
          <DxcSpinner theme="light" label="Loading..." mode="overlay" />
        )}

        <DxcTable>
          <tr>
            <th>Source</th>
            <th>Target</th>
            <th>Options</th>
          </tr>
          {deploymentPackagesToMigrate.map((dp, index) => (
            <tr>
              <td>
                <DxcSelect
                  options={envDeploymentPackagesList}
                  onChange={({value}) =>
                    onChangeDeploymentPackage({ source: value, target: dp.target || "" }, index)
                  }
                  value={dp.source || ""}
                  size="fillParent"
                ></DxcSelect>
              </td>
              <td>
                <DxcTextInput
                  value={dp.target || ""}
                  onChange={({value}) =>
                    onChangeDeploymentPackage({ source: dp.source || "", target: value }, index)
                  }
                  suggestions={deploymentPackagesList}
                  size="fillParent"
                  clearable={true}
                />
              </td>
              <td>
                <DxcButton
                  mode="text"
                  onClick={() => deleteDeploymentPackageToMigrate(index)}
                  icon={<DeleteIcon />}
                />
              </td>
            </tr>
          ))}
        </DxcTable>

        <DxcButton
          mode="secondary"
          label="Add"
          icon={<AddIcon />}
          onClick={addDeploymentPackage}
          margin={{ top: "small" }}
        />
      </Content>
    </GlobalContent>
  );
};

const GlobalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
`;

const Subtitle = styled.div`
  margin-bottom: 36px;
  width: 100%;
`;

const SubtitleLabel = styled.span`
  color: #0009;
`;

const SubtitleValue = styled.span`
  font-weight: 600;
  margin-left: 4px;
`;

export default withErrorBoundary(EnvironmentMigration, { FallbackComponent: ApplicationError });
