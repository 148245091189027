//React imports
import React from "react";

//DXC CDK Components
import {
  DxcHeading,
  DxcAlert,
  DxcButton,
  DxcDialog,
  DxcParagraph,
  DxcFlex,
  DxcInset,
  DxcTypography,
} from "@dxc-technology/halstack-react";

function LinkDeprecatedDeploymentPackage({ onCancel, onAdd, deploymentPackageName, isDependency }) {
  return (
    <DxcDialog isCloseVisible onCloseClick={onCancel}>
      <DxcHeading level={2} text={`Add deployment package`} margin={{ bottom: "xsmall" }} />

      <DxcAlert
        type="warning"
        mode="inline"
        size="fillParent"
        margin={{ bottom: "small" }}
        inlineText="You are adding a deprecated deployment package"
      ></DxcAlert>

      <DxcFlex>
        <DxcParagraph>
          <DxcTypography as="span" fontWeight="600" display="inline">
            {deploymentPackageName}
          </DxcTypography>{" "}
          is deprecated. Are you sure you want to add this deployment package{" "}
          {isDependency ? "as a dependency" : "to the environment"}?
        </DxcParagraph>
      </DxcFlex>

      <DxcInset top="1rem">
        <DxcFlex justifyContent="flex-end">
          <DxcButton
            label="Cancel"
            margin={{ right: "xsmall" }}
            onClick={onCancel}
            mode="text"
          ></DxcButton>

          <DxcButton label="Add" onClick={onAdd} mode="primary"></DxcButton>
        </DxcFlex>
      </DxcInset>
    </DxcDialog>
  );
}

export default LinkDeprecatedDeploymentPackage;
