import React from "react";
import {
  DxcSpinner,
  DxcHeading,
  DxcAccordionGroup,
  DxcBox,
  DxcButton,
} from "@dxc-technology/halstack-react";
import useLog from "./useLog";
import styled from "styled-components";

//search image
import backImage from "../deployment-package-definition/images/arrow_back_ios-24px.svg";
import { copyIcon } from "../log-viewer/copyIcon.js";
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";

const LogViewer = () => {
  const {
    stages,
    loading,
    environmentId,
    customerId,
    titleAction,
    logStateIcon,
    logStateMessage,
    navigationInfo,
    copyLog,
    error,
  } = useLog();
  useErrorHandler(error);

  return (
    <LogViewerContainer>
      <StyledLink onClick={() => navigationInfo.goBack()}>
        <BackImg src={backImage} />
        {navigationInfo.goBackCaption}
      </StyledLink>

      {loading && <DxcSpinner mode="overlay" label="Loading..."></DxcSpinner>}
      {!loading && (
        <React.Fragment>
          <DxcHeading
            margin={{ top: "small" }}
            level={2}
            weight="normal"
            text={`Command '${titleAction}' in ${environmentId} for ${customerId}`}
          ></DxcHeading>
          <DxcBox padding="small" margin={{ top: "small", bottom: "small" }} size="fillParent">
            <LogStateContainer>
              <LogState>
                <StateIcon>{logStateIcon}</StateIcon>
                <DxcHeading
                  margin={{ left: "small" }}
                  level={3}
                  weight="normal"
                  text={`${logStateMessage}` || "loading"}
                ></DxcHeading>
              </LogState>

              <DxcButton mode="text" onClick={copyLog} icon={copyIcon} size="small" />
            </LogStateContainer>
          </DxcBox>
          <DxcAccordionGroup>
            {stages.map((stage) => (
              <DxcAccordionGroup.Accordion
                icon={stage.icon}
                label={stage.name}
                assistiveText={stage.status.toLowerCase()}
              >
                <StageLogContainer>
                  <StageLogBody>{stage.body}</StageLogBody>
                </StageLogContainer>
              </DxcAccordionGroup.Accordion>
            ))}
          </DxcAccordionGroup>
        </React.Fragment>
      )}
    </LogViewerContainer>
  );
};

const StyledLink = styled.div`
  display: flex;
  width: fit-content;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  top: 16px;
  :hover {
    cursor: pointer;
  }
`;

const LogViewerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogStateContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogState = styled.div`
  display: flex;
  align-items: center;
`;

const StateIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
`;

const StageLogContainer = styled.div`
  padding: 32px;
  background: #000000;
  color: #ffffff;
`;

const StageLogBody = styled.pre`
  max-height: 500px;
  overflow-y: auto;
  white-space: break-spaces;
  word-break: break-all;
  padding: 32px;
  margin: 0px;
  font-family: "Courier New", monospace !important;
  ::-webkit-scrollbar {
    width: 2px;
    margin: 5px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #eeeeee;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #999999;
  }
`;

const BackImg = styled.img`
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-self: center;
`;
export default withErrorBoundary(LogViewer, { FallbackComponent: ApplicationError });
