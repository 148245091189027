import React from "react";
import styled from "styled-components";
import { useMemo } from "react";

//DXC CDK Components
import {
  DxcHeading,
  DxcSpinner,
  DxcAlert,
  DxcResultsetTable,
  DxcTextInput,
  DxcCheckbox,
  DxcButton,
  DxcSelect,
  DxcFlex,
  DxcInset,
} from "@dxc-technology/halstack-react";

//custom hook
import useDeploymentPackagesDashboard from "./useDeploymentPackagesDashboard";

//search image
import editImage from "./create-24px.svg";
import seeDeployedEnvironmentsImage from "./see-environments-48px.png";
import { useErrorHandler, withErrorBoundary } from "react-error-boundary";
import ApplicationError from "../components/errors/ApplicationError";

const columns = [
  { displayValue: "Name", isSortable: true },
  { displayValue: "Sources", isSortable: true },
  { displayValue: "Type", isSortable: true },
  { displayValue: "Actions", isSortable: false },
];

const typeOptions = [
  {
    value: 1,
    label: "Integrated",
  },
  {
    value: 2,
    label: "External",
  },
  {
    value: 3,
    label: "Infrastructure",
  },
];

function DeploymentPackagesDashboard() {
  const [
    isLoading,
    deploymentPackageMessage,
    deploymentPackages,
    textFilter,
    onChangeFilterText,
    typesValue,
    checkedDeprecated,
    onChangeType,
    onChangeDeprecated,
    dismissMessage,
    onCreateNewDeploymentPackage,
    error,
  ] = useDeploymentPackagesDashboard();

  useErrorHandler(error);

  const textFilterHandler = ({ value }) => {
    onChangeFilterText(value);
  };

  const typesFilterHandler = ({ value }) => {
    onChangeType(value);
  };

  const rows = useMemo(() => {
    return deploymentPackages.map((deploymentPackage) => [
      {
        displayValue: (
          <DeploymentPackageNameContainer>
            {deploymentPackage.name}{" "}
            {deploymentPackage.deprecated && (
              <Tag>
                <TagLabel>Deprecated</TagLabel>
              </Tag>
            )}
          </DeploymentPackageNameContainer>
        ),
      },
      { displayValue: deploymentPackage.source },
      { displayValue: deploymentPackage.type },
      {
        displayValue: (
          <ImageContainer>
            <Image
              src={editImage}
              alt={`edit ${deploymentPackage.name}`}
              onClick={deploymentPackage.onEditClick}
              isFirst
            ></Image>
            <Image
              src={seeDeployedEnvironmentsImage}
              alt={`See ${deploymentPackage.name} deployed environments`}
              onClick={deploymentPackage.onSeeDeployedEnvironmentsClick}
            ></Image>
          </ImageContainer>
        ),
      },
    ]);
  }, [deploymentPackages]);

  return (
    <Content>
      <TitleWrapper>
        <DxcHeading
          level={1}
          weight="normal"
          text="Deployment packages"
          margin={{ top: "medium" }}
        />
        <DxcButton
          mode="primary"
          label="Create new deployment package"
          onClick={onCreateNewDeploymentPackage}
        />
      </TitleWrapper>

      {deploymentPackageMessage && (
        <DxcAlert
          type={deploymentPackageMessage.type}
          mode="inline"
          inlineText={deploymentPackageMessage.message}
          size="fillParent"
          onClose={dismissMessage}
          margin={{ bottom: "small" }}
        />
      )}

      <DxcFlex justifyContent="space-evenly" gap={"2rem"} alignItems="center">
        <DxcTextInput
          label="Search for a deployment package"
          value={textFilter}
          onChange={textFilterHandler}
          onBlur={textFilterHandler}
          size={"fillParent"}
          placeholder="e.g.: my-deployment-package-name"
          clearable={true}
        />
        <DxcSelect
          options={typeOptions}
          onChange={typesFilterHandler}
          label="Type"
          value={typesValue}
          multiple
          size="large"
        ></DxcSelect>
        <DxcInset top="2rem">
          <DxcCheckbox
            checked={checkedDeprecated}
            labelPosition="after"
            label="Show deprecated"
            onChange={onChangeDeprecated}
            size="medium"
          />
        </DxcInset>
      </DxcFlex>
      <DxcInset space="1rem"></DxcInset>
      {rows && (
        <DxcResultsetTable columns={columns} rows={rows} itemsPerPage={5}></DxcResultsetTable>
      )}

      {isLoading ? <DxcSpinner theme="light" label="Loading..." mode="overlay" /> : null}
    </Content>
  );
}

const Content = styled.div``;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 30px;
`;

const ImageContainer = styled.div`
  display: flex;
`;

const Image = styled.img`
  cursor: pointer;
  height: 24px;
  width: 24px;
  ${(props) => props.isFirst && `margin-right: 5px;`}
`;

const DeploymentPackageNameContainer = styled.div`
  display: flex;
`;

const Tag = styled.div`
  margin-left: 10px;
  height: calc(1.5rem - 2 * 1px);
  padding: 0 1rem;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  background-color: #fef9e6;
  border: 1px solid #c59f07;
  color: #947705;
`;

const TagLabel = styled.p`
  font-family: "Open Sans", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0;
`;

export default withErrorBoundary(DeploymentPackagesDashboard, {
  FallbackComponent: ApplicationError,
});
