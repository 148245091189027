export const columns = [
  { displayValue: "Customer", isSortable: true },
  { displayValue: "Account", isSortable: true },
  { displayValue: "Environment", isSortable: true },
  { displayValue: "Status", isSortable: true },
  { displayValue: "Date", isSortable: true },
  { displayValue: "Version", isSortable: true },
  { displayValue: "Actions", isSortable: false },
];

export const options = [
  { value: 1, label: "Edit environment" },
  { value: 2, label: "See deployments" },
];
